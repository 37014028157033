<template>
  <div
    :style="{
      padding: '0 !important',
      width: '320px',
      display: 'inline-block',
      backgroundColor: '#FAFAFA',
      // border: '1px solid #D3D4D6',
      padding: '16px 21px',
      height: '100vh',
      overflowY: 'scroll',
    }"
  >
    <h4>ค้นหาประวัติทริป</h4>
    <vehicleSearch />
    <a-range-picker
      @change="onDateChange"
      :style="{ marginTop: '5px', width: '280px' }"
      :allowClear="true"
      :placeholder="['เริ้มต้น', 'สิ้นสุด']"
      :disabled="selectedVehicleTrip.id == null"
      v-model="dateRange"
      format="D MMM YYYY HH:mm"
      :showTime="{
        defaultValue: [
          moment().hour(0).minute(0).second(0).millisecond(0),
          moment().hour(23).minute(59).second(59).millisecond(999),
        ],
      }"
    />
    <a-button
      type="primary"
      @click="search"
      :style="{
        width: '280px',
        marginTop: '5px',
      }"
      :loading="searchLoading"
      :disabled="dateRange.length <= 0"
      >ค้นหา</a-button
    >
    <listResultTrip @setCenterPath="setCenterPath" />
  </div>
</template>
<script>
import listResultTrip from '@/views/components/trip/listResultTrip';
import vehicleSearch from '@/views/components/trip/vehicleSearch';
import { mapState, mapActions } from 'vuex';
import moment from '@/lib/time';
export default {
  components: { listResultTrip, vehicleSearch },
  props: {},
  data() {
    return {
      collapsed: true,
      dateRange: [
        moment().hour(0).minute(0).second(0).millisecond(0),
        moment().hour(23).minute(59).second(59).millisecond(999),
      ],
      searchLoading: false,
    };
  },
  methods: {
    moment,
    ...mapActions({
      searchTrip: 'trip/searchTrip',
    }),
    onDateChange(value) {
      if (this.dateRange[0].unix() == this.dateRange[1].unix()) {
        this.dateRange[0].subtract(1, 'd').unix();
      }
      this.dateRange = value;
    },
    async search() {
      //////// GA /////////
      this.$gtag.event('trip_search_clicked');
      ////////////////////

      this.searchLoading = true;

      await this.searchTrip({
        imei: this.selectedVehicleTrip.device.imei,
        start: this.dateRange[0].unix(),
        stop: this.dateRange[1].unix(),
      });
      this.searchLoading = false;
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    setCenterPath() {
      this.$emit('setCenterPath');
    },
  },
  computed: mapState({
    vehicles: (state) => state.vehicle.vehicles,
    trips: (state) => state.vehicle.vehicles,
    selectedTrip: (state) => state.vehicle.selectedVehicle,
    selectedVehicleTrip: (state) => state.trip.selectedVehicleTrip,
  }),
};
</script>
<style lang="scss"></style>
