<template>
  <div
    id="survey__modal"
    class="survey__modal"
    v-if="surveyContent.questions.length > 0"
  >
    <div class="modal__wrapper">
      <a-icon type="close" class="close" @click="onCloseModal()" />
      <div class="modal__content">
        <div v-if="!isSubmit">
          <!-- <div
            class="content__question"
            v-for="(question, i) in surveyContent.questions"
            :key="i"
          > -->
          <div class="content__question">
            <h3>{{ surveyContent.questions[0].name }}</h3>
            <div class="content__answer" @change="onChangeAnswer">
              <div v-if="surveyContent.questions[0].type === 'multiple_choice'">
                <label
                  class="choice"
                  v-for="(choice, j) in surveyContent.questions[0].choices"
                  :key="j"
                >
                  <input type="radio" :name="`question1`" :value="choice" />
                  {{ choice }}
                  <div class="circle"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="content__submit">
            <div class="submit__icon">
              <img
                loading="lazy"
                :src="require('/src/assets/gif/thumbs_up.gif')"
              />
            </div>
            <div>
              ขอบคุณสำหรับ <br />
              ความคิดเห็น
            </div>
          </div>
        </div>
      </div>
      <div class="modal__cta">
        <custom-button
          :disabled="isDisabled"
          :label="!isSubmit ? 'ส่ง' : 'ปิด'"
          @onClick="!isSubmit ? onClickSubmit() : onCloseModal()"
        />
      </div>
    </div>
  </div>
</template>
<script>
import customButton from '@/views/components/customButton.vue';
import { mapState } from 'vuex';
import AxiosService from '@/plugins/axios';
import endpoints from '@/config/config';

export default {
  components: { customButton },
  props: ['surveyContent'],
  emits: ['onClose'],
  data() {
    return {
      answers: {},
      isSubmit: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.authen.user,
    }),
    isDisabled() {
      return Object.keys(this.answers).length > 0 ? false : true;
    },
  },
  methods: {
    onCloseModal() {
      this.$emit('onClose');
    },
    onChangeAnswer(event) {
      this.$set(this.answers, event.target.name, event.target.value);
    },
    async onClickSubmit() {
      try {
        await AxiosService.post(`${endpoints.strapiAPI}/surveys/submit`, {
          name: this.user.username,
          response: this.answers,
          survey: this.surveyContent._id,
          customer: this.user._id,
        });
        this.isSubmit = !this.isSubmit;
      } catch (err) {
        throw new Error(err);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content__submit {
  text-align: center;
  font-size: 2rem;
  line-height: 34px;
  font-weight: 400;

  .submit__icon {
    margin-inline: auto;
    inline-size: 150px;
    block-size: 150px;
  }
}
.content__answer {
  padding: 0;
  margin: 0;
  margin-block-start: 24px;

  .choice {
    position: relative;
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 13px;
    margin-block-start: 8px;
  }
}

.survey__modal {
  position: absolute;
  z-index: 990;
  background-color: white;
  width: 280px;
  height: 350px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  bottom: 20px;
  right: 20px;

  .modal__wrapper {
    padding-block-start: 40px;
    padding-block-end: 20px;
    padding-inline: 28px;
    position: relative;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;

    > :first-child {
      flex: 1;
    }
    > :last-child {
      flex: 0;
    }

    .close {
      position: absolute;
      font-size: 20px;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
  }

  .modal__content {
    text-align: left;
    h3 {
      width: 95%;
    }
  }

  .modal__cta {
    width: fit-content;
    // margin-block-start: 32px;
    margin-inline: auto;
  }
}

input[type='radio'] {
  position: relative;
  appearance: none !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;

  width: 20px;
  height: 20px;

  border-radius: 50%;
  border: 1px solid #523ee8;
}

.circle {
  position: absolute;
  height: 12px;
  width: 12px;
  display: block;
  background-color: #523ee8;
  left: 4px;
  top: 4.5px;
  border-radius: 50%;
  opacity: 0;
}

input[type='radio']:checked ~ .circle {
  opacity: 1;
}
</style>
