<template>
    <a-select
      mode="multiple"
      :class="['select-input', 'input-box']"
      :max-tag-count="1"
      placeholder="เลือกรถ"
      @select="onMultipleVehiclesSelect"
      @deselect="onDeselect"
      :value="selectedMultiVehicles.filter((plate)=> plate !== 'all_vehicles_001')"
      option-label-prop="label"
      :show-arrow="true"
    >
        <a-select-option
          id="vehicle-selection-item-list"
          v-for="vehicle in addAllVehicles(vehicles)"
          :key="vehicle.id"
          :value="mergedVehicleKey(vehicle.plate_number, vehicle.name)"
          :label="vehicle.plate_number"
        >
          <div 
            v-if="vehicle.id === 'all_vehicles_001'" 
            class="vehicle-item-container"
          >
            <div class="vehicle-checkbox">
              <input 
              :value="vehicle.plate_number"
              type="checkbox"
              :checked="selectedMultiVehicles.includes('all_vehicles_001') 
              || selectedMultiVehicles.length === vehicles.length"
              />
              <span class="checkmark"></span>
              <label>{{ vehicle.plate_number }}</label>
            </div>
            <div style="margin-top: 8px; margin-bottom: -4px; border-bottom: 1px solid #EAEAEA;"></div>
          </div>
          <div v-else class="vehicle-item-container">
            <div class="vehicle-checkbox">
              <input  
                :value="vehicle.plate_number"  
                :checked="selectedMultiVehicles.includes(mergedVehicleKey(vehicle.plate_number, vehicle.name))"
                type="checkbox"
              />
              <span class="checkmark"></span>
              <label>{{ vehicle.plate_number }} ( {{ vehicle.name }} )</label>
            </div>
            <div style="margin-top: 8px; margin-bottom: -4px; border-bottom: 1px solid #EAEAEA;"></div>
          </div>
        </a-select-option>
    </a-select>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name: "vehicleMultiSelect",
        props: [
            "multipleVehicleValues",
        ],
        emits: ["onMultiVehiclesSelect", "onUnselect"],

        data() {
            return {
              selectedMultiVehicles: [],
            }
        },

        mounted() {
          this.selectedMultiVehicles = this.multipleVehicleValues.length === 0 ? [] : this.multipleVehicleValues
        },

        watch: {
          multipleVehicleValues(value){
            this.selectedMultiVehicles = value === '' ? [] : this.multipleVehicleValues
          },
        },

        computed: {
          ...mapGetters({
            vehicles: 'vehicle/vehicles',
          }),
        },

        methods: {
          addAllVehicles(vehicles_data) {
              const reformattedVehicles = vehicles_data.map(({id, name, plate_number}) => ({id, name, plate_number}));
              reformattedVehicles.unshift({ id: 'all_vehicles_001', name: 'ทั้งหมด', plate_number: 'ทั้งหมด'});
              return reformattedVehicles;
          },

          mergedVehicleKey(plate, name) {
            const mergedValue = `${plate} (${name})`
            return mergedValue
          },

          async onMultipleVehiclesSelect(value) {
            const allVehicles = this.vehicles.map(({ plate_number, name }) => this.mergedVehicleKey(plate_number, name));
            const isAllVehicles = value === 'ทั้งหมด (ทั้งหมด)' ? 'all_vehicles_001' : value; //Convert value string for all vehicles option

            if (isAllVehicles === 'all_vehicles_001') {
              const foundAllVehicleOpt = this.multipleVehicleValues.includes('all_vehicles_001');
              this.selectedMultiVehicles = foundAllVehicleOpt ? [] : ['all_vehicles_001', ...allVehicles];
            } else {
              this.selectedMultiVehicles.push(value);
              if (this.selectedMultiVehicles.length === this.vehicles.length) {
                this.selectedMultiVehicles = ['all_vehicles_001', ...allVehicles];
              }
            }

            this.$emit("onMultiVehiclesSelect", this.selectedMultiVehicles);
          },

          async onDeselect(value){
            const indexToDelete = this.selectedMultiVehicles.indexOf(value);

            if (indexToDelete !== -1) {
              this.selectedMultiVehicles.splice(indexToDelete, 1);

              const allVehiclesIndex = this.selectedMultiVehicles.indexOf('all_vehicles_001');
              if (allVehiclesIndex !== -1) {
                this.selectedMultiVehicles.splice(allVehiclesIndex, 1); // Delete 'all_vehicles_001' option.
              }

              this.$emit(
                "onUnselect",
                this.selectedMultiVehicles
              )
            }
          },

        },
    }
</script>

<style lang="scss" scoped>
.select-input .ant-select-selection--multiple {
  height: 48px !important;
  &:hover {
    border-color: #523ee8;
  }

  .ant-select-selection__placeholder{
    margin-top: -1px;
  }

  .ant-select-selection__rendered > ul > li {
    height: 40px !important;
    padding: 10px 20px 10px 10px; 
  }
  .ant-select-selection-selected-value {
    padding-top: 8px;
  }

  .ant-select-arrow {
    top: 24px;
    cursor: pointer;
  }
}

.input-box .ant-input {
  height: 48px !important;
  width: 100%;

  &:hover {
    border-color: #523ee8 !important;
  }
}

.input-box {
  height: 48px;
  width: 100%;

  &:hover {
    border-color: #523ee8 !important;
  }
}
</style>