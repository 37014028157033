<template>
  <div class="trips">
    <a-drawer
      disableAnimation="false"
      :title="
        selectedVehicleTrip
          ? `ข้อมูลทริป รถทะเบียน ${selectedVehicleTrip.plate_number} (${selectedVehicleTrip.name})`
          : ''
      "
      :width="isMobileDevice() ? 400 : 660"
      :closable="true"
      :visible="showTripInfo"
      placement="left"
      :mask="false"
      @close="onCloseDrawer"
    >
      <div v-if="trip">
        <a-row class="card-info info-engine">
          <a-col :span="6" v-if="!isMobileDevice()">
            <IEcharts :option="tripsEngine" :loading="chartEngineLoading" />
          </a-col>
          <a-col :span="isMobileDevice() ? 24 : 18">
            <a-descriptions size="small" title :column="3">
              <a-descriptions-item label span="3">
                <span style="font-weight: 300; font-size: 1rem">
                  <icon-font type="icon-Calendar" style="padding-right: 5px" />
                  {{
                    trip &&
                    trip.positionDataStart &&
                    trip.positionDataEnd &&
                    `${formatDate(trip.startTime)} ถึง ${formatDate(
                      trip.stopTime
                    )}`
                  }}
                </span>
              </a-descriptions-item>
              <a-descriptions-item label>
                <icon-font
                  type="icon-Iconmaterial-access-time"
                  style="padding-right: 5px"
                />
                {{ trip && formatDuration(trip.stopTime, trip.startTime) }}
                <template v-if="!isMobileDevice()">ชม:นาที:วินาที</template>
              </a-descriptions-item>
              <a-descriptions-item label>
                <icon-font
                  type="icon-Iconawesome-route"
                  style="padding-right: 5px"
                />
                {{
                  trip &&
                  trip.positionDataEnd &&
                  (trip.positionDataEnd.io[65] -
                    trip.positionDataStart.io[65]) /
                    1000
                }}
                กม.
              </a-descriptions-item>
              <a-descriptions-item label>
                <icon-font
                  type="icon-Iconawesome-tachometer-alt"
                  style="padding-right: 5px"
                />
                เฉลี่ย {{ positions && tripAvgSpeed }} กม./ชม.
              </a-descriptions-item>

              <a-descriptions-item label span="2">
                <icon-font type="icon-location1" style="padding-right: 5px" />
                {{ positions && positions[0] && displayAddress(positions[0]) }}
                ถึง
                {{
                  positions &&
                  positions[positions.length - 1] &&
                  displayAddress(positions[positions.length - 1])
                }}
              </a-descriptions-item>
              <a-descriptions-item label>
                <icon-font
                  type="icon-drivers-license"
                  style="padding-right: 5px"
                />
                {{
                  mcr_license_no
                    ? displayDriverName(
                        mcr_license_no,
                        mcr_driver_name,
                        getDriverNameByLicenseNo(mcr_license_no)
                      )
                    : 'ไม่มีข้อมูลรูดบัตร'
                }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
        </a-row>
        <a-row style="text-align: right">
          <a-button @click="downloadTripReport" :loading="reportLoading">
            <icon-font
              type="icon-Excel"
              style="padding-right: 5px"
            />ดาวน์โหลดรายงาน
          </a-button>
        </a-row>
        <a-row>
          <ve-table
            ref="tableRef"
            class="trip-table-row"
            style="margin-top: 15px"
            :columns="columns"
            :virtual-scroll-option="virtualScrollOption"
            :table-data="tripTableWrapper()"
            max-height="calc(100vh - 260px)"
            :event-custom-option="eventCustomOption"
            :rowStyleOption="rowStyleOption"
            :cellSelectionOption="cellSelectionOption"
            rowKeyFieldName="key"
          />
        </a-row>
      </div>
    </a-drawer>
    <div class="all-trip-summary" v-if="tripsEnd && tripsEnd.length > 0">
      ทั้งหมด
      <icon-font
        title="Virtual ODOMeter (km)"
        type="icon-Iconawesome-route"
        style="padding-right: 5px"
      />
      {{ allTripsDistance }} กม.
      <a-icon type="clock-circle" />
      {{ allTripsDuration }}
    </div>
    <a-timeline>
      <a-timeline-item
        v-for="trip in tripsEnd"
        :key="'trip' + trip.id"
        @click="clickTrip(trip)"
        color="#00c246"
      >
        <!-- <div class="heliot-avatar">
        <a-avatar icon="user" size="large" />
        </div>-->
        <div
          :class="[
            'heliot-info-trip',
            selectedTrip.id == trip.id ? 'active' : '',
          ]"
        >
          <div class="trip-info">
            <div class="date">
              {{ formatDate(trip.startTime) }} ถึง
              {{ formatDate(trip.stopTime) }}
            </div>
            <div class="distance">
              <a-icon type="clock-circle" />
              {{ formatDuration(trip.stopTime, trip.startTime) }}
              <a-icon type="environment" />
              {{
                (trip.positionDataEnd.io[65] - trip.positionDataStart.io[65]) /
                1000
              }}
              กม.
            </div>
          </div>
        </div>
      </a-timeline-item>
    </a-timeline>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from '@/lib/time';
import { IconFont } from '@/config/config';
import IEcharts from 'vue-echarts-v3/src/full.js';
import report from '@/lib/report';
import { getGeofenceName } from '@/lib/device';
import { isMobile } from 'mobile-device-detect';
import { displayDriverName } from '@/lib/device';

export default {
  components: { IconFont, IEcharts },
  props: {
    setCenterPath: { type: Function },
  },
  data() {
    return {
      mcr_license_no: '',
      mcr_driver_name: '',
      collapsed: true,
      bottomDrawer: true,
      chartEngineLoading: false,
      cellSelectionOption: {
        // disble cell selection
        enable: false,
      },
      virtualScrollOption: {
        enable: true,
        minRowHeight: 25,
      },
      rowStyleOption: {
        clickHighlight: true,
        hoverHighlight: true,
      },
      eventCustomOption: {
        bodyRowEvents: ({ row }) => {
          return {
            click: () => this.tripTableRowClick(row),
          };
        },
      },
      columns: [
        {
          field: 'key',
          key: 'index',
          title: 'ที่',
          align: 'center',
          width: '5%',
          operationColumn: true,
        },
        {
          title: 'เวลา',
          field: 'timestamp',
          key: 'timestamp',
          width: '15%',
        },
        {
          title: 'สถานะ',
          field: 'engineStatus',
          key: 'engineStatus',
          width: '15%',
          renderBodyCell: ({ row, column }) => {
            let engineStatus = row[column.field];
            let className = `${engineStatus} engine-status`;
            return (
              <div class={className}>
                {engineStatus == 'OVER_SPEED'
                  ? 'ความเร็วเกิน'
                  : row['engineStatusTranslated']}
              </div>
            );
          },
        },
        {
          title: 'ความเร็ว',
          field: 'speed',
          key: 'speed',
        },
        {
          title: 'ตำแหน่ง',
          field: 'location',
          key: 'location',
        },
        {
          title: 'PTO',
          field: 'pto',
          key: 'pto',
          renderBodyCell: ({ row }) => {
            return (
              <div>
                <span
                  class={`${this.getPTOStatusClass(row['pto1'])} pto-status`}
                >
                  PTO1
                </span>
                <span
                  class={`${this.getPTOStatusClass(row['pto2'])} pto-status`}
                >
                  PTO2
                </span>
                <span
                  class={`${this.getPTOStatusClass(row['pto3'])} pto-status`}
                >
                  PTO3
                </span>
              </div>
            );
          },
        },
        {
          title: 'TEMP',
          field: 'temp',
          key: 'temp',
        },
      ],
    };
  },
  computed: mapState({
    selectedGroup: (state) => state.authen.selectedGroup,
    drivers: (state) => state.driver.drivers,
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      tripsEnd: 'trip/tripsEnd',
      path: 'position/path',
      tripAvgSpeed: 'position/avgSpeed',
      tripsEngine: 'trip/tripsEngine',
      tripTable: 'position/tripTable',
      vehicles: 'vehicle/vehicles',
      driverByLicenseNo: 'driver/driverByLicenseNo',
    }),
    allTripsDistance() {
      if (this.tripsEnd.length > 0)
        return (
          (this.tripsEnd[0].positionDataEnd.io[65] -
            this.tripsEnd[this.tripsEnd.length - 1].positionDataStart.io[65]) /
          1000
        ).toFixed(2);
      return 0;
    },

    allTripsDuration() {
      // console.log("len", this.tripsEnd.length);

      if (this.tripsEnd.length > 0) {
        // console.log(
        //   "start",
        //   this.tripsEnd[this.tripsEnd.length - 1].startTime,
        //   "stop",
        //   this.tripsEnd[this.tripsEnd.length - 1].stopTime
        // );
        return this.formatDuration(
          this.tripsEnd[0].stopTime,
          this.tripsEnd[this.tripsEnd.length - 1].startTime
        );
      }
      return '0:0:0';
    },
    selectedTrip: (state) => state.trip.selectedTrip,
    // selectedTrip: (state) => state.trip.showTripInfo,
    trip: (state) => state.trip.selectedTrip,
    selectedVehicleTrip: (state) => state.trip.selectedVehicleTrip,
    positions(state) {
      this.mcr_license_no = '';
      if (state.position.positions.length > 0) {
        let k;
        for (k = state.position.positions.length - 1; k > 0; k--) {
          const mcr_license_no = state.position.positions[k].mcr_license_no;
          if (
            mcr_license_no &&
            mcr_license_no != 'null' &&
            mcr_license_no != ''
          ) {
            this.mcr_license_no = state.position.positions[k].mcr_license_no;
            break;
          }
        }

        for (k = state.position.positions.length - 1; k > 0; k--) {
          const mcr_driver_name = state.position.positions[k].mcr_driver_name;
          if (
            mcr_driver_name &&
            mcr_driver_name != 'null' &&
            mcr_driver_name != ''
          ) {
            this.mcr_driver_name = state.position.positions[k].mcr_driver_name;
            break;
          }
        }
        return state.position.positions;
      }
    },
    geofences: (state) => state.geofence.geofences,
    showTripInfo: (state) => state.trip.showTripInfo,
    selectedPosition: (state) => state.trip.selectedPosition,
    reportLoading: (state) => state.loading.reportLoading,
  }),
  async mounted() {
    //get all drivers
    if (this.drivers.length <= 0) {
      await this.getAllDrivers([this.selectedGroup.id]);
    }
  },
  watch: {
    selectedPosition(pos) {
      if (pos) {
        this.$refs['tableRef'].setHighlightRow({ rowKey: pos.key });
        this.$refs['tableRef'].scrollToRowKey({ rowKey: pos.key });
      } else {
        this.$refs['tableRef'].setHighlightRow({ rowKey: 0 });
      }
    },
  },
  methods: {
    ...mapActions({
      selectTrip: 'trip/selectTrip',
      searchPositions: 'position/searchPositions',
      showTripSecondInfo: 'trip/showTripSecondInfo',
      selectPosition: 'trip/selectPosition',
      setReportLoading: 'loading/setReportLoading',
      getAllDrivers: 'driver/getAllDrivers',
    }),
    displayDriverName(licenseNo, driverName, getDriverNameByLicenseNo) {
      const vehicle = {
        device: {
          position: {
            mcr_driver_name: driverName,
            mcr_license_no: licenseNo,
          },
        },
      };
      return displayDriverName(true, vehicle, getDriverNameByLicenseNo);
    },
    isToday(date) {
      return moment(date, 'X').fromNow();
    },
    isMobileDevice() {
      return isMobile;
    },
    formatDate(timestamp) {
      return moment.unix(timestamp).format('D MMM YYYY HH:mm:ss');
    },
    tripTableWrapper() {
      let objTripTable = JSON.parse(JSON.stringify(this.tripTable));
      let retData = [];
      let cnt = 0;
      let lastPosition = {};
      for (const k in objTripTable) {
        const curPos = objTripTable[k];
        if (
          ((lastPosition.engineStatus === 'ENGINE_STOP' &&
            curPos.engineStatus === 'ENGINE_STOP') ||
            (lastPosition.engineStatus === 'ENGINE_IDLE' &&
              curPos.engineStatus === 'ENGINE_IDLE')) &&
          lastPosition.location === curPos.location
        ) {
          // console.log("delete " + k);
        } else {
          retData[cnt] = objTripTable[k];
          retData[cnt].key = cnt + 1;

          if (curPos.engineStatus === 'ENGINE_STOP') {
            retData[cnt].speed = '0.00';
          }
          if (
            parseInt(curPos.speedNum) >
            parseInt(this.selectedVehicleTrip.overspeed_limit)
            //use curPos ----> retData[cnt]
          ) {
            retData[cnt].engineStatus = 'OVER_SPEED';
          }

          cnt++;
        }
        lastPosition = objTripTable[k];
      }

      for (const k in retData) {
        retData[k]['location'] = this.displayAddress(retData[k]);
      }
      return retData;
    },
    formatDuration(start, stop) {
      const duration = moment.duration(
        moment.unix(start).diff(moment.unix(stop))
      );
      return `${duration.get('hours')}:${duration.get(
        'minutes'
      )}:${duration.get('seconds')}`;
    },
    async clickTrip(trip) {
      this.selectPosition(null);
      //////// GA /////////
      this.$gtag.event('trip_trip-info_clicked');
      ////////////////////
      const loading = this.$loading.show({});
      await this.selectTrip(trip);
      await this.searchPositions({
        imei: trip.imei,
        start: trip.startTime,
        stop: trip.stopTime,
      });
      // this.showVehicleInfo(true);

      loading.hide();
      if (this.path.length > 0) {
        this.showTripSecondInfo(true);
        // this.setCenterPath();
        this.$emit('setCenterPath');
      } else {
        this.$message.warning('ไม่พบข้อมูลตำแหน่ง', 5);
        this.showTripSecondInfo(false);
      }
    },
    getDriverNameByLicenseNo(licenseNo) {
      return this.driverByLicenseNo(licenseNo);
    },
    onCloseDrawer() {
      this.selectPosition(null);
      this.showTripSecondInfo(false);
    },
    getEngineStatusClass(status) {
      return status;
    },
    getPTOStatusClass(status) {
      return status == 1 ? 'ON' : 'OFF';
    },
    tripTableRowClick(record) {
      //////// GA /////////
      this.$gtag.event('trip_vehicle-position_displayed');
      ////////////////////
      this.selectPosition(record);
      // console.log(record);
    },
    getIsActiveRow(record) {
      if (this.selectedPosition && record.id == this.selectedPosition.timestamp)
        return 'trip-active-row';
      return '';
    },
    async downloadTripReport() {
      //////// GA /////////
      this.$gtag.event('trip_report-download_clicked');
      ////////////////////
      this.setReportLoading(true);

      await report.positionReport(
        [
          {
            plate: this.selectedVehicleTrip.plate_number,
            odo_canbus_offset: this.selectedVehicleTrip.odo_canbus_offset,
            canbus_fuel_enable: this.selectedVehicleTrip.canbus_fuel_enable,
            odo_canbus_enable: this.selectedVehicleTrip.odo_canbus_enable,
            overspeed_limit: this.selectedVehicleTrip.overspeed_limit,
            imei: this.selectedVehicleTrip.device
              ? parseFloat(this.selectedVehicleTrip.device.imei)
              : '',
          },
        ],

        [parseFloat(this.selectedVehicleTrip.device.imei)],
        this.selectedTrip.startTime,
        this.selectedTrip.stopTime,
        0,
        this.geofences
      );
    },
    displayAddress(position) {
      let displayedStr = '';
      const location = position.location;
      const geofenceName = getGeofenceName(
        this.geofences,
        position.latitude / 10000000,
        position.longitude / 10000000
      );

      if (geofenceName && geofenceName !== '') {
        displayedStr = geofenceName;
      } else {
        displayedStr = location.address;
      }

      return displayedStr && displayedStr !== '' ? displayedStr : '-';
    },
  },
};
</script>

<style lang="scss" scoped>
.trips {
  margin-top: 30px;
}
.heliot-info-trip {
  &.active {
    background: #e3e3e3 0% 0% no-repeat padding-box;
    cursor: pointer;
  }
  &:hover {
    background: #efefef 0% 0% no-repeat padding-box;
    cursor: pointer;
  }

  background: none;
  /* margin: 5px 0px; */
  border-radius: 7px;
  padding: 5px 10px 10px 10px;
}

.trip-info {
  text-align: left;
  .date {
    font-size: 16px;
    font-weight: bold;
    color: #505050;
    line-height: 24px;
    white-space: nowrap;
    margin-top: 0px;
  }
  .distance {
    font-size: 15px;
    font-weight: normal;
    color: #505050;
    line-height: 24px;
    white-space: nowra;
  }
}

.x-small {
  font-size: 13px;
}
.ant-drawer-body {
  padding: 15px 30px;
}
.heliot-bottom-title {
  text-align: left;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  color: #00c246;
  opacity: 1;
  line-height: 24px;
  margin-bottom: 0;
  margin-right: 0px;
}
// .heliot-bottom-group {
//   padding-top: 15px;
// }
// .heliot-button-header {
// }

.col-info-right {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  opacity: 1;
  margin-left: 10px;
}
.col-info-left {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 7px;
  opacity: 1;
  margin-right: 10px;
}

.heliot-col-info-dotted {
  border-right-style: dotted;
  width: 2px;
}
.heliot-info-icon-left {
  line-height: 16px;
  margin: 8px 12px !important;
}
.engine-status {
  color: #fff;
  padding: 2px 8px;
  border-radius: 300px;
  &.ENGINE_STOP {
    background-color: #f5223e;
  }
  &.ENGINE_IDLE,
  &.ENGINE_IDLE_END {
    background-color: #faad14;
  }

  &.VEHICLE_MOVING {
    background-color: #00c246;
  }
  &.OVER_SPEED {
    background-color: violet;
  }
}
.pto-status {
  color: #fff;
  padding: 2px 6px;
  border-radius: 300px;
  margin-left: 2px;
  font-size: 0.6rem;
  &.ON {
    background-color: #00c246;
  }
  &.OFF {
    background-color: #aaa;
  }
}
.all-trip-summary {
  //background-color: #f6b85b;

  background-color: #faad14;
  color: #fff;
  padding: 3px 10px;
  text-align: center;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 15px;
}
</style>
<style global>
.trip-table-row {
  cursor: pointer;
}
.trip-active-row {
  background-color: #ddd;
}
</style>
