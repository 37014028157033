<template>
  <div id="container" ref="container" @click="onClickPlayer"></div>
</template>
<script>
export default {
  name: 'JessibucaPlayer',
  props: ['selectedVideo'],
  data() {
    return {
      jessibuca: null,
      err: '',
      playing: false,
      url: '',
    };
  },
  mounted() {
    this.create();
    window.onerror = (msg) => (this.err = msg);
    this.url = this.selectedVideo;
  },
  unmounted() {
    this.jessibuca.destroy();
  },
  watch: {
    url() {
      this.onClickPlayer();
    },
  },
  methods: {
    create(options) {
      options = options || {};
      this.jessibuca = new window.Jessibuca(
        Object.assign(
          {
            container: this.$refs.container,
            videoBuffer: 0.2, // 缓存时长
            isResize: false,
            useWCS: false,
            useMSE: true,
            text: '',
            // background: "bg.jpg",
            loadingText: 'loading...',
            // hasAudio:false,
            debug: false,
            supportDblclickFullscreen: true,
            showBandwidth: false, // 显示网速
            operateBtns: {
              // fullscreen: true,
              // screenshot: true,
              play: true,
              audio: true,
            },
            forceNoOffscreen: true,
            isNotMute: false,
            timeout: 10,
          },
          options
        )
      );
      var _this = this;

      this.jessibuca.on('pause', function () {
        console.log('on pause');
        _this.playing = false;
      });
      this.jessibuca.on('play', function () {
        console.log('on play');
        _this.playing = true;
      });

      this.jessibuca.on('error', function (error) {
        console.log('error', error);
      });

      this.jessibuca.on('timeout', function () {
        console.log('timeout');
      });
    },
    onClickPlayer() {
      if (!this.playing) {
        this.jessibuca.play(this.url);
      } else {
        this.jessibuca.pause().then(() => {
          this.playing = false;
        });
      }
    },
  },
  destroyed() {
    this.jessibuca.destroy();
  },
};
</script>
<style>
#container {
  cursor: pointer;
}
</style>
