<template>
  <defaultLayout>
    <div class="TMS">
      <div class="task-menu">
        <a-button
          size="large"
          shape="round"
          :type="AllJobsbtn ? 'primary' : ''"
          @click="getallJobs"
        >
          งานทั้งหมด
        </a-button>
        <a-button
          size="large"
          shape="round"
          :type="todayJobsbtn ? 'primary' : ''"
          @click="TodayJobs"
        >
          งานวันนี้
        </a-button>
      </div>
      <a-row :gutter="[0, 40]">
        <a-col :span="17">
          <a-button type="primary" shape="round" @click="showAddTaskDrawer">
            <icon-font
              type="icon-Add"
              style="font-size: 21px; vertical-align: middle"
            />เพิ่มงานใหม่</a-button
          >
          <a-date-picker
            v-show="isTodayJobs ? false : true"
            style="width: 100px; margin-right: 8px"
            v-model="dateSelection"
            @change="onChangeDateSelection"
            format="D MMM YYYY"
            :locale="locale"
            placeholder="เริ่ม"
          />
          <a-date-picker
            v-show="isTodayJobs ? false : true"
            style="width: 100px; margin-right: 8px"
            v-model="dateSelectionEnd"
            @change="onChangeDateSelection"
            format="D MMM YYYY"
            :locale="locale"
            placeholder="สิ้นสุด"
          />
          <a-select
            :allowClear="true"
            placeholder="คนขับ"
            show-search
            @change="onChangeDateSelection"
            option-filter-prop="children"
            style="width: 130px; margin-right: 8px"
            v-model="reportDriverID"
          >
            <a-select-option
              v-for="driver in drivers"
              :key="driver.id"
              :value="driver.id"
            >
              {{ driver.name }}
            </a-select-option>
          </a-select>
          <a-button type="default" shape="round" @click="dateSorting"
            >เรียงตามวัน
            <icon-font
              type="icon-ArrowRight"
              :class="['dateSortedArrow', this.dateSorted ? `DESC` : `ASC`]"
              shape="round"
            />
          </a-button>
          <a-dropdown :trigger="['click']" :disabled="showStatusSelection">
            <a-menu
              slot="overlay"
              style="text-align: left"
              @click="statusSelection"
            >
              <a-menu-item
                v-for="status in statusSelectionlists"
                :key="status.key"
              >
                {{ status.name }}
              </a-menu-item>
            </a-menu>
            <a-button type="default" shape="round"
              >สถานะ: {{ displayStatusSelected }}</a-button
            >
          </a-dropdown>
          <a-dropdown :trigger="['click']">
            <a-menu slot="overlay" style="text-align: left">
              <a-menu-item key="1" @click="downloadExcel('xlsx')">
                <icon-font
                  type="icon-Excel"
                  style="font-size: 21px; vertical-align: middle"
                />ตาราง Excel
              </a-menu-item>
              <a-menu-item key="2" @click="downloadExcel('xlsx', `noimage`)">
                <icon-font
                  type="icon-Excel"
                  style="font-size: 21px; vertical-align: middle"
                />ตาราง Excel แบบไม่มีรูปภาพ
              </a-menu-item>
              <a-menu-item key="3" @click="downloadExcel('pdf')">
                <icon-font
                  type="icon-pdf"
                  style="font-size: 21px; vertical-align: middle"
                />ไฟล์ PDF
              </a-menu-item>
              <a-menu-item key="4" @click="downloadExcel('pdf', 'noimage')">
                <icon-font
                  type="icon-pdf"
                  style="font-size: 21px; vertical-align: middle"
                />ไฟล์ PDF แบบไม่มีรูปภาพ
              </a-menu-item>
              <!-- <a-menu-item key="2"
                ><ไฟล์ PDF
              </a-menu-item> -->
            </a-menu>
            <a-button type="default" shape="round"
              ><icon-font
                type="icon-printer"
                style="font-size: 21px; vertical-align: middle"
              />พิมพ์รายงาน</a-button
            >
          </a-dropdown>
        </a-col>
        <a-col :span="7">
          <a-row>
            <a-input-group compact>
              <a-select v-model="searchKey" style="width: 110px">
                <a-select-option value="tripno"> ชื่องาน </a-select-option>

                <!-- <a-select-option value="refno"> หมายเลข Task</a-select-option> -->
                <a-select-option value="plate_number">
                  ทะเบียนรถ</a-select-option
                >
                <a-select-option value="stores_name">
                  ชื่อสถานี</a-select-option
                >
                <a-select-option value="refno"> หมายเลขอ้างอิง</a-select-option>
                <a-select-option value="drivers.name">
                  ชื่อคนขับ</a-select-option
                >
              </a-select>
              <a-input-search
                shape="round"
                placeholder="กรอกข้อมูลที่ต้องการค้นหา"
                @search="onSearch"
                @change="inputSearchonChange"
                v-model="inputSearch"
                enter-button
                style="width: 200px"
              >
              </a-input-search>
            </a-input-group>
          </a-row>
        </a-col>
      </a-row>

      <!------------------------------- table part ------------------------------->
      <a-row>
        <a-col :span="24">
          <a-table
            :columns="columns"
            :data-source="byPaginationJobs"
            :showHeader="true"
            :expandIcon="expandIcon"
            :pagination="pagination"
            :row-key="(record) => record._id"
            class="taskrow"
            @change="handleTableChange"
          >
            <span slot="idtag" slot-scope="record">
              <!-- #{{ record.substring(0, 7) }} -->
              {{ dtFormat(record.startdate).format("DD MMM YYYY") }}
            </span>
            <span slot="trip" slot-scope="Data">
              <a-row type="flex" align="middle">
                <icon-font
                  type="icon-location1"
                  style="font-size: 24px; margin-right: 10px"
                />
                <a-col>
                  <h1 style="font-size: 18px">งาน {{ Data.tripno }}</h1>
                  <a-row>
                    {{ Data.store_list[0].stores_name }}
                    <!-- {{
                      Data.store_list[0].stores_name &&
                      Data.store_list.length > 2
                        ? Data.store_list[0].stores_name ===
                          Data.store_list[Data.store_list.length - 1]
                            .stores_name
                          ? `${Data.store_list[1].store_no} ${Data.store_list[1].stores_name}`
                          : `${Data.store_list[0].store_no} ${Data.store_list[0].stores_name}`
                        : "-"
                    }} -->
                    <icon-font
                      type="icon-Arrow"
                      style="
                        margin-right: 20px;
                        margin-left: 20px;
                        vertical-align: middle;
                      "
                    />
                    {{
                      Data.store_list[Data.store_list.length - 1].stores_name
                    }}
                    <!-- {{
                      Data.store_list[Data.store_list.length - 1].stores_name &&
                      Data.store_list.length > 2
                        ? `${
                            Data.store_list[Data.store_list.length - 1].store_no
                          } ${
                            Data.store_list[Data.store_list.length - 1]
                              .stores_name
                          }`
                        : "-"
                    }} -->
                  </a-row>
                </a-col>
              </a-row>
            </span>

            <span slot="stationcount" slot-scope="record">
              <div class="scrollable">
                <div
                  v-for="station in record"
                  :key="station.id"
                  :class="['stationicon', statusIconColor(station.status)]"
                >
                  {{ record.indexOf(station) + 1 }}
                </div>
              </div>
            </span>
            <span slot="status" slot-scope="record">
              <div :class="['status', record]">{{ statusShow(record) }}</div>
            </span>

            <span
              slot="edit"
              style="display: flex; justify-content: center"
              slot-scope="record"
            >
              <a @click="showEditTaskDrawer(record)">
                <icon-font type="icon-edit" style="font-size: 25px" />
              </a>
            </span>
            <span
              slot="link"
              style="display: flex; justify-content: center"
              slot-scope="record"
            >
              <a @click="showCopyLinkModal(record)">
                <icon-font type="icon-link" style="font-size: 21px" />
              </a>
            </span>
            <div slot="expandedRowRender" slot-scope="record">
              <div class="inner-row-head">
                <a-row type="flex" align="middle">
                  <a-col :span="3"
                    ><icon-font
                      type="icon-location3"
                      style="
                        font-size: 18px;
                        margin-right: 10px;
                        vertical-align: middle;
                      "
                    />ชื่องาน {{ record.tripno ? record.tripno : "-" }}
                    <div style="color: #7e7e7e">
                      หมายเลขอ้างอิง: {{ record.refno ? record.refno : "-" }}
                    </div></a-col
                  >
                  <a-col :span="3"
                    ><icon-font
                      type="icon-truck1"
                      style="
                        font-size: 15px;
                        margin-right: 10px;
                        vertical-align: middle;
                      "
                    />
                    {{
                      record.vehicle_type && record.vehicle_type != ""
                        ? record.vehicle_type
                        : "-"
                    }}
                  </a-col>
                  <a-col :span="6"
                    ><icon-font
                      type="icon-Iconawesome-user-circle"
                      style="
                        font-size: 15px;
                        margin-right: 10px;
                        vertical-align: middle;
                      "
                    />
                    {{
                      record.drivers[0]
                        ? record.drivers[1]
                          ? `${record.drivers[0].name} / ${record.drivers[1].name}`
                          : record.drivers[0].name
                        : "ไม่มีข้อมูล"
                    }}
                  </a-col>
                  <a-col :span="3"
                    >เลขรถ/หัวลาก
                    {{ record.plate_number ? record.plate_number : "-" }}</a-col
                  >
                  <a-col :span="2"
                    >ประตู
                    {{ record.door_number ? record.door_number : "-" }}</a-col
                  >
                  <a-col :span="3" style="text-align: left">
                    <icon-font
                      type="icon-money"
                      style="
                        font-size: 18px;
                        margin-right: 2px;
                        vertical-align: middle;
                      "
                    />ทางด่วน
                    <span style="color: #7e7e7e">
                      {{
                        record.expressway_charges
                          ? record.expressway_charges
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : 0.0
                      }}
                      บาท
                    </span>
                    <div>
                      <icon-font
                        type="icon-money"
                        style="
                          font-size: 18px;
                          margin-right: 2px;
                          vertical-align: middle;
                        "
                      />น้ำมัน
                      <span style="color: #7e7e7e">
                        {{
                          record.fuel_charges
                            ? record.fuel_charges
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : 0.0
                        }}
                        บาท
                      </span>
                    </div>
                    <div>
                      <icon-font
                        type="icon-money"
                        style="
                          font-size: 18px;
                          margin-right: 2px;
                          vertical-align: middle;
                        "
                      />ทริป
                      <span style="color: #7e7e7e">
                        {{
                          record.trip_charges
                            ? record.trip_charges
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : 0.0
                        }}
                        บาท
                      </span>
                    </div>
                  </a-col>
                  <a-col :span="4" style="text-align: right"
                    ><icon-font
                      type="icon-Calendar"
                      style="
                        font-size: 20px;
                        margin-right: 5px;
                        vertical-align: middle;
                      "
                    />{{ dtFormat(record.startdate).format("DD MMM YYYY") }}
                    <div style="color: #7e7e7e">
                      เพิ่มเมื่อ
                      {{
                        dtFormat(record.startdate).format("DD MMM YYYY HH:mm")
                      }}
                    </div>
                    <div>
                      <a-popover>
                        <template slot="content">
                          <p>{{ record.note ? record.note : "ไม่มี" }}</p>
                        </template>
                        <a>หมายเหตุ</a>
                      </a-popover>
                    </div>
                  </a-col>
                </a-row>
              </div>
              <div style="margin-top: 20px">
                <a-row :gutter="[8, 15]">
                  <a-col :span="2">#</a-col>
                  <a-col :span="3">หมายเลขสถานี</a-col>
                  <a-col :span="5">ชื่อ</a-col>
                  <a-col :span="4">เวลาถึงสถานี</a-col>
                  <a-col :span="2">ภาพถ่าย</a-col>
                  <a-col :span="2">สถานะ</a-col>
                  <a-col :span="3"></a-col>
                </a-row>
                <a-row
                  :gutter="[8, 15]"
                  v-for="store in record.store_list"
                  :key="store.id"
                >
                  <a-col :span="2">{{ store.no }}</a-col>
                  <a-col :span="3">{{ store.store_no }}</a-col>
                  <a-col :span="5">{{ store.stores_name }}</a-col>
                  <a-col :span="4">
                    {{ dtFormatFixed(store.arrived) }}
                  </a-col>
                  <a-col :span="2"
                    ><a @click="GalleryPopup(record, store)"
                      ><icon-font
                        type="icon-photo1"
                        style="
                          font-size: 20px;
                          margin-right: 5px;
                          vertical-align: middle;
                        "
                      />
                      x {{ store.photos.length }}</a
                    >
                  </a-col>
                  <a-col :span="2">{{ statusStoreFt(store.status) }}</a-col>
                  <a-col :span="2"
                    ><a
                      style="text-decoration: underline"
                      @click="openTaskDescription(store.description, store.id)"
                      >รายละเอียดงาน</a
                    ></a-col
                  >
                  <a-col :span="3">
                    <span style="margin-left: 5px">
                      <a-tag v-show="store.status === 'finish'">
                        <a-popconfirm
                          title="คุณต้องการที่จะอัพโหลดรูปเพิ่มหรือไม่"
                          ok-text="ยืนยัน"
                          cancel-text="ยกเลิก"
                          @confirm="upload_more(record, store)"
                        >
                          <a href="#">อัพโหลดรูปเพิ่ม</a></a-popconfirm
                        >
                      </a-tag>
                    </span>
                  </a-col>
                </a-row>
              </div>
            </div>
          </a-table>
        </a-col>
      </a-row>
    </div>
    <!---------------------------------TaskDescription--------------------------------------->
    <a-modal
      title="รายละเอียดงาน"
      :class="['task-description-modal']"
      :visible="showTaskDescription"
      @cancel="showTaskDescription = false"
      centered
    >
      <a-textarea
        v-model="taskDescription"
        :disabled="closeEditTaskDescriptionInput"
        :rows="4"
      />
      <template slot="footer">
        <a-button
          v-show="closeEditTaskDescriptionInput === false"
          type="primary"
          @click="editTaskDescription()"
          :disabled="!taskDescription"
        >
          ยืนยัน</a-button
        >
        <a-button
          @click="closeEditTaskDescriptionInput = false"
          v-if="closeEditTaskDescriptionInput"
        >
          แก้ไข</a-button
        >
        <a-button @click="closeEditTaskDescriptionInput = true" v-else>
          ยกเลิก</a-button
        >
        <!-- <a-button key="submit" type="primary"> Submit </a-button> -->
      </template>
    </a-modal>
    <!----------------------------------------------------------------------------->
    <a-modal
      v-model="showGallery"
      title="แกลลอรี่"
      :footer="null"
      :afterClose="clearImage"
      :class="[`gallery-modal`]"
    >
      <a-row style="text-align: center" v-show="ImageLoading">
        <a-spin size="large" />
      </a-row>
      <a-result title="ยังไม่มีการอัพโหลดรูปภาพเข้าสถานี" v-show="noPhotos">
      </a-result>
      <a-result
        status="warning"
        title="เกิดความผิดพลาดบางอย่าง ไม่สามารถแสดงรูปภาพได้"
        v-show="photoError"
      >
      </a-result>
      <Tinybox v-model="index" :images="images"></Tinybox>
      <img
        v-for="(img, idx) in images"
        :key="idx"
        :src="img.thumbnail"
        :alt="img.alt"
        class="open-tinybox"
        @click="index = idx"
        style="display: inline-block; margin: 1px"
        width="24.5%"
        height="25%"
      />
    </a-modal>
    <!------------------------------- drawer part ------------------------------->
    <a-drawer
      :visible="visible"
      placement="right"
      :closable="false"
      :width="768"
    >
      <a-form class="task-drawer" style="margin: 20px">
        <a-row :gutter="[0, 40]">
          <h1>
            <icon-font
              v-show="addTaskMode"
              type="icon-Add"
              style="font-size: 27px; margin-right: 10px"
            />
            <icon-font
              v-show="editTaskMode"
              type="icon-edit"
              style="font-size: 27px; margin-right: 10px"
            />{{ addTaskMode ? " เพิ่มงาน" : "แก้ไขงาน" }}
          </h1>
        </a-row>
        <a-row :gutter="[0, 40]">
          <a-col :span="12">
            <a-col>
              <p class="drawerlabel">
                งานวันที่
                <span v-show="isInputsError[0]" class="error_msg">
                  *กรุณากรอกข้อมูล</span
                >
              </p>
              <a-date-picker
                size="large"
                format="D MMM YYYY"
                :locale="locale"
                v-model="Inputstartdate"
                @change="onChangeStartDate"
              />
              <p class="drawerlabel">
                ชื่องาน
                <span v-show="isInputsError[1]" class="error_msg">
                  *กรุณากรอกข้อมูล</span
                >
              </p>
              <a-input v-model="Data.tripno" @change="onChangeTripno" />
              <p class="drawerlabel">
                หมายเลขอ้างอิง
                <!-- <span v-show="isInputsError[1]" class="error_msg">
                *กรุณากรอกข้อมูล</span
              > -->
              </p>
              <a-input v-model="InputRefno" />
              <p class="drawerlabel">
                ประเภทรถ
                <span v-show="isInputsError[2]" class="error_msg">
                  *กรุณากรอกข้อมูล</span
                >
              </p>
              <a-select
                size="large"
                v-model="InputvehicleType"
                show-search
                :show-arrow="false"
                @change="onChangeVehicleType"
              >
                <a-select-option
                  v-for="vehicle_type in vehicle_types"
                  :key="vehicle_type.key"
                  :value="vehicle_type.name"
                >
                  {{ vehicle_type.name }}
                </a-select-option>
              </a-select>
              <p class="drawerlabel">
                ทะเบียนรถ
                <span
                  v-show="isInputsError[3] && isSaveDataBTNtrigger"
                  class="error_msg"
                >
                  *กรุณากรอกข้อมูล</span
                >
                <span
                  v-show="showPlateNumberErrMsg && !isInputsError[3]"
                  class="error_msg"
                >
                  *{{ PlateNumberErrMsg }}</span
                >
              </p>
              <a-input
                v-model="Data.plate_number"
                @change="onChangePlate_number"
                placeholder="XXX-XXXX"
              />
              <p class="drawerlabel">
                หมายเลขประตู DC
                <!-- <span v-show="isInputsError[4]" class="error_msg">
                  *กรุณากรอกข้อมูล</span
                > -->
              </p>
              <a-input
                v-model="Data.door_number"
                :disabled="editTaskMode ? true : false"
              />
              <p class="drawerlabel">
                {{ addTaskMode ? "สถานะงานเริ่มต้น" : "สถานะงาน" }}
              </p>
              <a-select size="large" v-model="Inputstatus">
                <a-select-option
                  v-for="status in addTaskMode
                    ? statusSelectionlists.filter(
                        (status) => status.key != 'all'
                      )
                    : statusSelectionlists.filter(
                        (status) =>
                          status.key != 'all' &&
                          status.key != 'require_upload_photo'
                      )"
                  :key="status.key"
                  :value="status.key"
                  >{{ status.name }}</a-select-option
                >
                <!-- <a-select-option value="prepare"> เตรียมจัดส่ง </a-select-option>
              <a-select-option value="transporting">
                กำลังจัดส่ง
              </a-select-option>             
              <a-select-option value="require_upload_photo">
                อัพโหลดรูปเพิ่ม
              </a-select-option>
              <a-select-option value="complete">
                จัดส่งครบแล้ว
              </a-select-option>
              <a-select-option value="cancel"> ยกเลิกจัดส่ง </a-select-option> -->
              </a-select>
              <p class="drawerlabel">
                คนขับ1
                <span v-show="isInputsError[5]" class="error_msg">
                  *กรุณากรอกข้อมูล</span
                >
              </p>
              <a-select
                show-search
                option-filter-prop="children"
                size="large"
                v-model="InputfirstDriver"
                @change="onChangefirstDriver"
                :show-arrow="false"
              >
                <a-select-option
                  v-for="firstdriverlist in drivers"
                  :key="firstdriverlist.id"
                  :value="firstdriverlist.id"
                >
                  {{ firstdriverlist.name }}
                </a-select-option>
              </a-select>
              <p class="drawerlabel">คนขับ2</p>
              <a-select
                show-search
                option-filter-prop="children"
                size="large"
                v-model="InputsecondDriver"
                :disabled="displaysecondDriverInput"
                :show-arrow="false"
              >
                <a-select-option
                  v-for="secondDriverlist in secondDriverlists"
                  :key="secondDriverlist.id"
                  :value="secondDriverlist.id"
                >
                  {{ secondDriverlist.name }}
                </a-select-option>
              </a-select>
              <p class="drawerlabel">
                หมายเหตุ
                <!-- <span v-show="isInputsError[1]" class="error_msg">
                *กรุณากรอกข้อมูล</span
              > -->
              </p>
              <a-input v-model="InputNoted" />
            </a-col>
          </a-col>
          <a-col :span="12">
            <a-col :span="8">
              <p class="drawerlabel">ค่าทางด่วน</p>
              <a-input-number
                v-model="InputExpressway_cost"
                :min="0"
                :step="0.01"
                :precision="2"
                :formatter="
                  (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
              />
            </a-col>
            <a-col :span="8">
              <p class="drawerlabel">ค่าน้ำมัน</p>
              <a-input-number
                v-model="InputFuel_cost"
                :min="0"
                :step="0.01"
                :precision="2"
                :formatter="
                  (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
              />
            </a-col>
            <a-col :span="8">
              <p class="drawerlabel">ค่าทริป</p>
              <a-input-number
                v-model="InputTrip_cost"
                :min="0"
                :step="0.01"
                :precision="2"
                :formatter="
                  (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                "
              />
            </a-col>
            <!-- <span class="error_msg"> *กรุณากรอกข้อมูล</span> -->
            <div>
              <div class="iconindrawer">เริ่ม</div>
              {{ Data.door_number ? `DC ${Data.door_number}` : "-" }}
              <!-- {{
                Data.store_list[0].stores_name && Data.store_list.length > 2
                  ? Data.store_list[0].stores_name ===
                    Data.store_list[Data.store_list.length - 1].stores_name
                    ? `${Data.store_list[1].store_no} ${Data.store_list[1].stores_name}`
                    : `${Data.store_list[0].store_no} ${Data.store_list[0].stores_name}`
                  : "-"
              }} -->
            </div>
            <!-- <div v-show="isSaveDataBTNtrigger"> -->
            <span
              v-show="isInputsError[6] && isSaveDataBTNtrigger"
              class="error_msg"
            >
              *กรุณากรอกข้อมูล</span
            >
            <span
              v-show="showStorelistsErrMsg && !isInputsError[6]"
              class="error_msg"
            >
              *{{ StorelistsErrMsg }}</span
            >
            <!-- </div> -->
            <a-textarea
              :rows="4"
              style="height: 500px"
              v-model="Inputstorelists"
              @change="onChangeStoreLists"
              :placeholder="suggestion"
              :disabled="editTaskMode ? true : false"
            />
            <div>
              <div class="iconindrawer">สุดท้าย</div>
              {{ Data.door_number ? `DC ${Data.door_number}` : "-" }}
              <!-- {{
                Data.store_list[Data.store_list.length - 1].stores_name &&
                Data.store_list.length > 2
                  ? `${Data.store_list[Data.store_list.length - 1].store_no} ${
                      Data.store_list[Data.store_list.length - 1].stores_name
                    }`
                  : "-"
              }} -->
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="[0, 40]">
          <a-popconfirm
            v-show="editTaskMode"
            title="คุณต้องการที่จะลบรายการนี้หรือไม่"
            ok-text="ยืนยัน"
            cancel-text="ยกเลิก"
            @confirm="deleteTask"
          >
            <a-button type="danger">
              <icon-font
                type="icon-delete"
                style="font-size: 21px; vertical-align: middle"
              />ยกเลิก</a-button
            >
          </a-popconfirm>
          <a-button
            type="primary"
            @click="editTaskMode ? editData() : addData()"
          >
            <icon-font
              type="icon-save"
              style="font-size: 21px; vertical-align: middle"
            />บันทึก</a-button
          >
          <a-button type="default" @click="closeDrawer">
            <icon-font
              type="icon-cancel1"
              style="font-size: 17px; vertical-align: middle"
            />ยกเลิก</a-button
          >
        </a-row>
      </a-form>
    </a-drawer>
  </defaultLayout>
</template>

<script>
// import TmsDrawer from "./components/TMSDrawer.vue";
import AxiosService from "@/plugins/axios";
import endpoints from "@/config/config";
import defaultLayout from "@/views/layouts/defaultLayout.vue";
import { IconFont } from "@/config/config";
import DefaultLayout from "./layouts/defaultLayout.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "@/lib/time";
import locale from "ant-design-vue/es/date-picker/locale/th_TH";
import fileDownload from "js-file-download";
import Tinybox from "vue-tinybox";
export default {
  name: "Tms",
  components: {
    // TmsDrawer,
    defaultLayout,
    IconFont,
    DefaultLayout,
    Tinybox,
  },
  data() {
    return {
      images: [],
      index: null,
      TodayTask: false,
      History: false,
      addTaskMode: false,
      editTaskMode: false,
      // CopyLinkModal: false,
      ImageLoading: true,
      noPhotos: false,
      photoError: false,
      visible: false,
      displaysecondDriverInput: true,
      displayStatusSelected: "ทั้งหมด",
      isTodayJobs: false,
      todayJobsbtn: false,
      AllJobsbtn: true,
      searchMode: false,
      showStatusSelection: false,
      isSaveDataBTNtrigger: false,
      statusSelectionlists: [
        {
          key: "all",
          name: "ทั้งหมด",
        },
        {
          key: "prepare",
          name: "เตรียมจัดส่ง",
        },
        {
          key: "transporting",
          name: "กำลังจัดส่ง",
        },
        {
          key: "complete",
          name: "จัดส่งครบแล้ว",
        },
        {
          key: "require_upload_photo",
          name: "อัพโหลดเพิ่ม",
        },
        {
          key: "cancel",
          name: "ยกเลิกจัดส่ง",
        },
      ],
      vehicle_types: [
        {
          key: "sedan",
          name: "รถเก๋ง",
        },
        {
          key: "truck",
          name: "รถกระบะ",
        },
        {
          key: "bigTruck",
          name: "รถบรรทุก",
        },
        {
          key: "machine",
          name: "รถตัก ขุด บด",
        },
        {
          key: "concreteTruck",
          name: "รถโม่ผสมคอนกรีต",
        },
        {
          key: "trailer",
          name: "รถพ่วง",
        },
        {
          key: "TruckWithCrane3Ton",
          name: "รถเฮี๊ยบ3ตัน",
        },
        {
          key: "TruckWithCrane5Ton",
          name: "รถเฮี๊ยบ5ตัน",
        },
        {
          key: "Truck6Wheel",
          name: "บรรทุก6ล้อ",
        },
        {
          key: "Truck10Wheel",
          name: "บรรทุก10ล้อ",
        }
      ],
      isStorelistsFormValid: false,
      showStorelistsErrMsg: false,
      showGallery: false,
      showTaskDescription: false,
      closeEditTaskDescriptionInput: true,
      EditTaskDescriptionID: "",
      taskDescription: "",
      reportDriverID: undefined,
      setStoreNo: "",
      setStoreName: "",
      StorelistsErrMsg: "",
      isPlateNumberFormValid: false,
      showPlateNumberErrMsg: false,
      PlateNumberErrMsg: "",
      pagination: { pageSize: 20, total: 100, current: 1 },
      ApiPagination: { start: 0, limit: 20 },
      dateSorted: false,
      suggestion: `กรุณากรอกรายชือสถานีในรูปแบบดังนี้ \nหมายเลขสถานี (เว้นวรรค 1 ครั้ง) ชื่อสถานี ตัวอย่างเช่น\n1500 ร้านอาหาร\n1501 ร้านขายของชำ\n1502 บ้านแสนสุข`,
      locale,
      dateSelection: "",
      dateSelectionEnd: "",
      getJobPayloads: {
        Groupid: "",
        start: moment()
          .utc()
          .hour(0)
          .minute(0)
          .second(0)
          .format(),
        end: moment()
          .utc()
          .hour(23)
          .minute(59)
          .second(59)
          .format(),
        pagination: { start: 0, limit: 20 },
        url: "",
      },
      isSearchStoreName: false,
      searchStoreNamePayloads: {
        action: "search_store_name",
        customer_group: "",
        search_txt: "",
        startdate_gte: "",
        startdate_lte: "",
        _limit: "",
        _start: "",
        _sort: "",
      },
      inputSearch: "",
      searchKey: "tripno",
      InputfirstDriver: "",
      InputsecondDriver: "",
      secondDriverlists: [],
      Inputstorelists: "",
      Inputstartdate: "",
      InputvehicleType: "",
      Inputstatus: "prepare",
      InputRefno: "",
      InputExpressway_cost: 0,
      InputTrip_cost: 0,
      InputFuel_cost: 0,
      InputNoted: "",
      Data: {
        tripno: "",
        status: "",
        door_number: "",
        startdate: "",
        plate_number: "",
        refno: "",
        fuel_charges: 0,
        expressway_charges: 0,
        trip_charges: 0,
        note: "",
        drivers: [],
        vehicle_type: "",
        customer_group: {
          _id: "",
        },
        store_list: [
          {
            stores_name: "",
          },
        ],
      },
      isInputsError: [false, false, false, false, false, false, false],
      selectedRowData: {},
      columns: [
        {
          key: "idtag",
          title: "วันที่",
          align: "center",
          scopedSlots: { customRender: "idtag" },
          width: "10%",
        },
        {
          key: "trip",
          title: "สถานี",
          scopedSlots: { customRender: "trip" },
          width: "30%",
        },
        {
          dataIndex: "iconstationcount",
          key: "iconstationcount",
          scopedSlots: { customRender: "iconstationcount" },
          width: "3%",
        },
        {
          dataIndex: "store_list",
          title: "รูปภาพ",
          align: "left",
          key: "stationcount",
          scopedSlots: { customRender: "stationcount" },
          // width: "45%",
        },
        {
          dataIndex: "status",
          title: "สถานะงาน",
          align: "center",
          key: "status",
          scopedSlots: { customRender: "status" },
          width: "12%",
        },

        {
          key: "edit",
          title: "แก้ไข",
          scopedSlots: { customRender: "edit" },
          width: "2.5%",
        },
        {
          key: "link",
          title: "ลิ้งค์",
          scopedSlots: { customRender: "link" },
          width: "2.5%",
        },
      ],
    };
  },
  mounted() {
    this.getJobPayloads.Groupid = this.selectedGroup.id;
    this.getJobs(this.selectedGroup.id);
    // this.pagination.total = this.jobs.length
    this.ApiPagination.limit = this.pagination.pageSize;
    this.getJobPayloads.url = `${endpoints.strapiAPI}/jobs?customer_group=${this.selectedGroup.id}`;
    this.getJobsbyPagination(this.getJobPayloads);
    // this.getJobsbyDate(this.getJobPayloads);
    this.getAllDrivers(this.selectedGroup.id);
    // console.log(this.getJobPayloads);
    // console.log(this.pagination)
  },
  watch: {
    selectedGroup: function() {
      this.inputSearch = "";
      this.getJobPayloads.Groupid = this.selectedGroup.id;
      if (this.isTodayJobs) {
        this.getJobPayloads.start = this.startRangeFormat(moment());
        this.getJobPayloads.end = this.endRangeFormat(moment());
        // console.log(payloads)
        return (
          this.getJobsbyDate(this.getJobPayloads),
          this.reset_initData(),
          (this.dateSelection = ""),
          (this.displayStatusSelected = "ทั้งหมด")
        );
      } else {
        return (
          this.getJobs(this.selectedGroup.id),
          this.reset_initData(),
          (this.dateSelection = ""),
          (this.displayStatusSelected = "ทั้งหมด")
        );
      }
    },
    JobsUpdated: function() {
      this.inputSearch = "";
      // console.log("JobUpdated", this.url);
      this.getJobPayloads.url = this.url;
      this.getJobPayloads.pagination = this.ApiPagination;
      this.getJobPayloads.pagination.start =
        this.ApiPagination.limit * this.pagination.current -
        this.ApiPagination.limit;
      if (this.isTodayJobs) {
        this.getJobPayloads.start = this.startRangeFormat(moment());
        this.getJobPayloads.end = this.endRangeFormat(moment());
        // console.log("Update Today", this.getJobPayloads);
        return (
          // this.getJobsbyDate(this.getJobPayloads),
          this.reset_initData(), this.getJobsbyPagination(this.getJobPayloads)
        );
      } else {
        // console.log("Update All", this.getJobPayloads);
        return (
          // this.getJobs(this.selectedGroup.id),
          this.getJobsbyPagination(this.getJobPayloads),
          this.resetJobUpdatedInitial()
        );
      }
    },
    jobs: function() {
      this.pagination.total = this.jobs.length;
      // console.log(this.pagination.total);
    },
    url: function(value) {
      // console.log("Url", value);
      if (value.includes("core-api.heliot")) {
        (this.searchStoreNamePayloads.customer_group = this.selectedGroup.id),
          (this.searchStoreNamePayloads.search_txt = this.inputSearch),
          (this.searchStoreNamePayloads.startdate_gte = this.isTodayJobs
            ? this.startRangeFormat(moment())
            : ""),
          (this.searchStoreNamePayloads.startdate_lte = this.isTodayJobs
            ? this.endRangeFormat(moment())
            : ""),
          (this.searchStoreNamePayloads._limit = this.ApiPagination.limit),
          (this.searchStoreNamePayloads._start = 0),
          (this.pagination.current = 1);
        this.getJobsbyPagination(this.searchStoreNamePayloads);
      } else {
        this.getJobPayloads.url = value;
        this.getJobPayloads.pagination = this.ApiPagination;
        this.getJobPayloads.pagination.start = 0;
        this.pagination.current = 1;
        // console.log(this.getJobPayloads)
        this.getJobsbyPagination(this.getJobPayloads);
      }
    },
  },
  computed: {
    ...mapState({
      jobs: (state) => state.jobs.jobs,
      drivers: (state) => state.driver.drivers,
      selectedGroup: (state) => state.authen.selectedGroup,
      showLink: (state) => state.jobs.showLink,
      JobsUpdated: (state) => state.jobs.JobsUpdated,
      byPaginationJobs: (state) => state.jobs.byPaginationJobs,
      url: (state) => state.jobs.url,
    }),
    ...mapGetters({
      allJobs: "jobs/allJobs",
      byStatusJobs: "jobs/byStatusJobs",
    }),
  },
  methods: {
    ...mapActions({
      getJobs: "jobs/getJobs",
      getJobsbyDate: "jobs/getJobsbyDate",
      getJobsbyStatus: "jobs/getJobsbyStatus",
      getJobsbySearch: "jobs/getJobsbySearch",
      getJobsbyPagination: "jobs/getJobsbyPagination",
      getAllDrivers: "driver/getAllDrivers",
      getLink: "jobs/getLink",
      dateSortedJobs: "jobs/dateSortedJobs",
      AddJobsTask: "jobs/addJobsTask",
      deleteJobsTask: "jobs/deleteJobsTask",
      updateJobsTask: "jobs/updateJobsTask",
      resetJobUpdatedInitial: "jobs/resetJobUpdatedInitial",
      searchStoreName: "jobs/searchJobsbyStoreName",
      uploadMore: "jobs/uploadMore",
      editTaskDesc: "jobs/editTaskDesc",
    }),
    handleTableChange(pagination) {
      // console.log(pagination.current);
      this.pagination.current = pagination.current;
      this.ApiPagination.start =
        this.ApiPagination.limit * pagination.current -
        this.ApiPagination.limit;
      if (this.url.includes("core-api.heliot")) {
        this.searchStoreNamePayloads._limit = this.ApiPagination.limit;
        this.searchStoreNamePayloads._start = this.ApiPagination.start;
        this.getJobsbyPagination(this.searchStoreNamePayloads);
      } else {
        this.getJobPayloads.url = this.url;
        this.getJobPayloads.Groupid = this.selectedGroup.id;
        this.getJobPayloads.pagination = this.ApiPagination;
        this.getJobsbyPagination(this.getJobPayloads);
      }

      // console.log(this.pagination)
      // console.log(this.getJobPayloads)
    },
    getallJobs() {
      this.dateSelection = "";
      this.dateSelectionEnd = "";
      this.inputSearch = "";
      this.isTodayJobs = false;
      // console.log(this.isTodayJobs);
      this.displayStatusSelected = "ทั้งหมด";
      this.AllJobsbtn = true;
      this.todayJobsbtn = false;
      this.getJobs(this.selectedGroup.id);
      this.dateSorted = false;
      // this.dateSelection = moment().subtract(1, "days");
      // this.getJobPayloads.start = this.startRangeFormat(this.dateSelection);
      // this.getJobPayloads.end = this.endRangeFormat(this.dateSelection);
      // this.getJobsbyDate(this.getJobPayloads);
    },
    TodayJobs() {
      this.inputSearch = "";
      this.AllJobsbtn = false;
      this.todayJobsbtn = true;
      this.isTodayJobs = true;
      this.displayStatusSelected = "ทั้งหมด";
      this.getJobPayloads.Groupid = this.selectedGroup.id;
      this.getJobPayloads.start = this.startRangeFormat(moment());
      this.getJobPayloads.end = this.endRangeFormat(moment());
      this.getJobsbyDate(this.getJobPayloads);
      // console.log(this.isTodayJobs);
    },
    showAddTaskDrawer() {
      this.visible = true;
      this.addTaskMode = true;
      this.editTaskMode = false;
      this.reset_initData();
    },
    showEditTaskDrawer(record) {
      // console.log("selectedRowData", record);
      this.selectedRowData = record;
      this.Data._id = record._id;
      this.Data.tripno = record.tripno;
      this.Inputstatus =
        record.status == "require_upload_photo"
          ? "อัพโหลดเพิ่ม"
          : record.status;
      this.Data.door_number = record.door_number;
      this.Data.plate_number = record.plate_number;
      this.InputNoted = record.note;
      this.InputFuel_cost = record.fuel_charges;
      this.InputTrip_cost = record.trip_charges;
      this.InputExpressway_cost = record.expressway_charges;
      this.InputRefno = record.refno;
      this.Data.store_list = record.store_list;
      this.InputvehicleType = record.vehicle_type;
      this.Inputstartdate = moment(record.startdate);
      if (record.drivers[0]) {
        if (record.drivers[1]) {
          this.InputfirstDriver = record.drivers[0].id;
          this.InputsecondDriver = record.drivers[1].id;
          this.displaysecondDriverInput = false;
          this.secondDriverlists = this.drivers.filter(
            (driver) => driver.id != record.drivers[0].id
          );
        } else {
          this.InputfirstDriver = record.drivers[0].id;
          this.displaysecondDriverInput = false;
          this.secondDriverlists = this.drivers.filter(
            (driver) => driver.id != record.drivers[0].id
          );
        }
      }
      this.onChangePlate_number();
      if (record.store_list[0].store_no.includes("DC")) {
        for (let i = 1; i < record.store_list.length - 1; i++) {
          let myString = "";
          if (i !== record.store_list.length - 2) {
            myString = `${record.store_list[i].store_no} ${record.store_list[i].stores_name}\n`;
            this.Inputstorelists = this.Inputstorelists + myString;
          } else {
            myString = `${record.store_list[i].store_no} ${record.store_list[i].stores_name}`;
            this.Inputstorelists = this.Inputstorelists + myString;
          }
          this.onChangeStoreLists();
        }
      } else {
        for (let i in record.store_list) {
          let myString = "";
          myString = `${record.store_list[i].store_no} ${record.store_list[i].stores_name}\n`;
          this.Inputstorelists = this.Inputstorelists + myString;
          this.onChangeStoreLists();
        }
      }
      // console.log(this.Inputstorelists);
      // console.log(this.Data);
      // this.isStorelistsFormValid = true;
      this.myString = "";
      this.visible = true;
      this.editTaskMode = true;
      this.addTaskMode = false;
    },
    async showCopyLinkModal(record) {
      // this.CopyLinkModal = true;
      // console.log(record)
      let payloads = {
        action: "generate_link",
        customer_group: "",
        job: "",
      };
      const key = "updatable";
      payloads.customer_group = this.selectedGroup.id;
      payloads.job = record.id;
      this.$message.loading({ content: "กำลังทำการคัดลอกลิงก์", key });
      // console.log(payloads)
      await this.getLink(payloads)
        .then(async () => {
          await navigator.clipboard
            .writeText(
              `งาน ${record.tripno} สำหรับ: คุณ${record.drivers[0].name}\n${this.showLink}`
            )
            .then(() => {
              this.$message.success({
                content: "คัดลอกลิงก์สำเร็จ",
                key,
                duration: 3,
              });
            })
            .catch(() => {
              this.$message.warning({
                content: "เกิดข้อผิดพลาดบางอย่าง กรุณาลองอีกครั้ง",
                key,
                duration: 3,
              });
            });
        })
        .catch(() => {
          this.$message.warning({
            content: "เกิดข้อผิดพลาดบางอย่าง กรุณาลองอีกครั้ง",
            key,
            duration: 3,
          });
        });
      // await navigator.clipboard.writeText(this.test1).then(console.log('success'))
    },
    closeDrawer() {
      this.visible = false;
      this.reset_initData();
    },
    statusShow(value) {
      let status;
      if (value == "prepare") {
        status = "เตรียมจัดส่ง";
      } else if (value == "transporting") {
        status = "กำลังจัดส่ง";
      } else if (value == "require_upload_photo") {
        status = "อัพโหลดเพิ่ม";
      } else if (value == "complete") {
        status = "จัดส่งแล้ว";
      } else if (value == "cancel") {
        status = "ยกเลิกจัดส่ง";
      }
      return status;
    },
    addData() {
      // console.log(this.Data);
      this.isSaveDataBTNtrigger = true;
      let checkEmptyArray = [];
      checkEmptyArray.push(
        this.Inputstartdate,
        this.Data.tripno,
        this.InputvehicleType,
        this.Data.plate_number,
        this.Data.door_number,
        this.InputfirstDriver,
        this.Inputstorelists
      );
      let FirstStore = {};
      let LastStore = {};
      // noCheckDoorNumber
      const noDC = checkEmptyArray.filter((_, index) => index != 4);
      if (this.isStorelistsFormValid === false) {
        this.showStorelistsErrMsg = true;
      } else {
        this.showStorelistsErrMsg = false;
      }
      if (this.isPlateNumberFormValid === false) {
        this.showPlateNumberErrMsg = true;
      } else {
        this.showPlateNumberErrMsg = false;
      }
      if (noDC.includes("") != true) {
        this.isInputsError = [false, false, false, false, false, false, false];
        if (this.Data.door_number !== "") {
          FirstStore = {
            no: "1",
            store_no: `DC${this.Data.door_number}`,
            stores_name: `DC ${this.Data.door_number}`,
            photos: [],
            status: "waiting_upload",
          };
          LastStore = {
            no: `${this.Data.store_list.length + 2}`,
            store_no: `DC${this.Data.door_number}`,
            stores_name: `DC ${this.Data.door_number}`,
            photos: [],
            status: "waiting_upload",
          };
        }
        if (this.isStorelistsFormValid && this.isPlateNumberFormValid) {
          this.showPlateNumberErrMsg = false;
          this.showStorelistsErrMsg = false;
          // console.log("Success");
          if (this.Data.door_number !== "") {
            this.Data.store_list.unshift(FirstStore);
            this.Data.store_list.push(LastStore);
          }
          if (
            this.Data.door_number === "" &&
            this.Data.store_list[0].store_no.includes("DC")
          ) {
            this.Data.store_list.pop();
            this.Data.store_list.shift();
          }
          if (this.Data.store_list.length > 0) {
            if (this.Data.store_list[0].no !== "1") {
              for (let i in this.Data.store_list) {
                // console.log(i)
                this.Data.store_list[i].no = `${Number(i) + 1}`;
              }
            } else if (this.Data.store_list[1].no !== "2") {
              for (let i = 1; i < this.Data.store_list.length; i++) {
                this.Data.store_list[i].no = `${Number(i) + 1}`;
              }
            }
          }
          // for(let index in this.Data.store_list){
          //   console.log(index+1)
          //   console.log(this.Data.store_list[index].stores_name)
          //   this.Data.store_list[index].no = index+1
          // }
          let driversArray = [];
          if (this.InputsecondDriver) {
            driversArray.push(this.InputfirstDriver, this.InputsecondDriver);
            for (let j = 0; j < driversArray.length; j++) {
              let driversObj = {};
              driversObj["_id"] = driversArray[j];
              driversArray[j] = driversObj;
            }
          } else {
            driversArray.push(this.InputfirstDriver);
            for (let j = 0; j < driversArray.length; j++) {
              let driversObj = {};
              driversObj["_id"] = driversArray[j];
              driversArray[j] = driversObj;
            }
          }

          this.Data.vehicle_type = this.InputvehicleType;
          this.Data.startdate = this.Inputstartdate.utc().format();
          this.Data.status = this.Inputstatus;
          this.Data.drivers = driversArray;
          this.Data.refno = this.InputRefno;
          this.Data.expressway_charges = this.InputExpressway_cost;
          this.Data.trip_charges = this.InputTrip_cost;
          this.Data.fuel_charges = this.InputFuel_cost;
          this.Data.note = this.InputNoted;
          this.Data.customer_group._id = this.selectedGroup.id;
          let toJSON = JSON.stringify(this.Data);
          // console.log(toJSON);
          this.$message
            .loading("กำลังบันทึกข้อมูล กรุณารอสักครู่", 2.5)
            .then(async () => {
              await this.AddJobsTask(toJSON).then((res) => {
                if (res) {
                  this.$message.success("บันทึกข้อมูลสำเร็จ", 2.5);
                  this.visible = false;
                  if (
                    this.startRangeFormat(this.Inputstartdate) ===
                    this.startRangeFormat(moment())
                  ) {
                    this.TodayJobs();
                  } else {
                    this.getallJobs();
                  }
                  this.reset_initData();
                } else {
                  this.$message.error(
                    "ไม่สามารถบันทึกข้อมูลได้ กรุณาลองอีกครั้ง",
                    3
                  );
                  this.visible = false;
                  if (
                    this.startRangeFormat(this.Inputstartdate) ===
                    this.startRangeFormat(moment())
                  ) {
                    this.TodayJobs();
                  } else {
                    this.getallJobs();
                  }
                  this.reset_initData();
                }
              });
            });

          // console.log(toJSON);
        }
      } else {
        // console.log(this.isInputsError);
        let isError = [];
        for (let index in checkEmptyArray) {
          if (checkEmptyArray[index] === "") {
            isError.push(true);
          } else {
            isError.push(false);
          }
        }
        this.isInputsError = isError;
        // console.log(this.isInputsError);
      }
    },
    editData() {
      // console.log(this.Data);
      this.isSaveDataBTNtrigger = true;
      let checkEmptyArray = [];
      checkEmptyArray.push(
        this.Inputstartdate,
        this.Data.tripno,
        this.InputvehicleType,
        this.Data.plate_number,
        this.Data.door_number,
        this.InputfirstDriver,
        this.Inputstorelists
      );
      if (this.isStorelistsFormValid === false) {
        this.showStorelistsErrMsg = true;
      } else {
        this.showStorelistsErrMsg = false;
      }
      if (this.isPlateNumberFormValid === false) {
        this.showPlateNumberErrMsg = true;
      } else {
        this.showPlateNumberErrMsg = false;
      }
      const noDC = checkEmptyArray.filter((_, index) => index != 4);
      if (noDC.includes("") != true) {
        this.isInputsError = [false, false, false, false, false, false, false];
        if (this.isPlateNumberFormValid) {
          let checkInputData = [
            { key: "startdate", value: this.Inputstartdate.utc().format() },
            { key: "tripno", value: this.Data.tripno },
            { key: "vehicle_type", value: this.InputvehicleType },
            { key: "plate_number", value: this.Data.plate_number },
            { key: "status", value: this.Inputstatus },
            { value: this.InputfirstDriver },
            { value: this.InputsecondDriver },
            { key: "refno", value: this.InputRefno },
            { key: "expressway_charges", value: this.InputExpressway_cost },
            { key: "trip_charges", value: this.InputTrip_cost },
            { key: "fuel_charges", value: this.InputFuel_cost },
            { key: "note", value: this.InputNoted },
          ];
          let Data = [
            `${this.selectedRowData.startdate.substring(
              0,
              this.selectedRowData.startdate.indexOf(".")
            )}Z`,
            this.selectedRowData.tripno,
            this.selectedRowData.vehicle_type,
            this.selectedRowData.plate_number,
            this.selectedRowData.status,
            `${
              this.selectedRowData.drivers[0]
                ? this.selectedRowData.drivers[0]._id
                : ""
            }`,
            `${
              this.selectedRowData.drivers[1]
                ? this.selectedRowData.drivers[1]._id
                : ""
            }`,
            this.selectedRowData.refno,
            this.selectedRowData.expressway_charges,
            this.selectedRowData.trip_charges,
            this.selectedRowData.fuel_charges,
            this.selectedRowData.note,
          ];
          let updateData = [{ id: this.selectedRowData._id }, {}];
          let includeFirstDriver = false;
          // console.log(checkInputData)
          // console.log(Data)
          for (let i = 0; i < checkInputData.length; i++) {
            if (checkInputData[i].value !== Data[i]) {
              if (checkInputData[i].key === "vehicle_type") {
                // console.log("vehicle_type");
                updateData[1][checkInputData[i].key] = checkInputData[i].value;
              } else if (i === 5) {
                // console.log("driver1");
                updateData[1]["drivers"] = [{ _id: checkInputData[i].value }];
                includeFirstDriver = true;
              } else if (i === 6) {
                if (includeFirstDriver) {
                  // console.log("driver1&driver2");
                  updateData[1]["drivers"] = [
                    { _id: checkInputData[5].value },
                    { _id: checkInputData[i].value },
                  ];
                } else {
                  updateData[1]["drivers"] = [
                    { _id: Data[5] },
                    { _id: checkInputData[i].value },
                  ];
                }
              } else {
                // console.log("ETC");
                updateData[1][checkInputData[i].key] = checkInputData[i].value;
              }
            }
          }
          // console.log(updateData);
          this.$message
            .loading("กำลังบันทึกข้อมูล กรุณารอสักครู่", 2.5)
            .then(async () => {
              await this.updateJobsTask(updateData).then((res) => {
                if (res) {
                  this.$message.success("บันทึกข้อมูลสำเร็จ", 2.5);
                  this.visible = false;
                  if (
                    this.startRangeFormat(this.Inputstartdate) ===
                    this.startRangeFormat(moment())
                  ) {
                    this.TodayJobs();
                  } else {
                    this.reset_initData();
                  }
                } else {
                  this.$message.error(
                    "ไม่สามารถบันทึกข้อมูลได้ กรุณาลองอีกครั้ง",
                    3
                  );
                  this.visible = false;
                  if (
                    this.startRangeFormat(this.Inputstartdate) ===
                    this.startRangeFormat(moment())
                  ) {
                    this.TodayJobs();
                  } else {
                    this.reset_initData();
                  }
                }
              });
            });
        }
      } else {
        let isError = [];
        for (let index in checkEmptyArray) {
          if (checkEmptyArray[index] === "") {
            isError.push(true);
          } else {
            isError.push(false);
          }
        }
        this.isInputsError = isError;
      }
    },
    deleteTask() {
      this.$message
        .loading("กำลังทำการลบข้อมูล กรุณารอสักครู่", 1.5)
        .then(() => {
          this.deleteJobsTask(this.Data._id);
          this.$message.success("ลบข้อมูลข้อมูลสำเร็จ", 2.5);
          this.visible = false;
          this.reset_initData();
        });
    },
    openTaskDescription(value, id) {
      this.showTaskDescription = true;
      this.taskDescription = value;
      this.closeEditTaskDescriptionInput = true;
      this.EditTaskDescriptionID = id;
    },
    async editTaskDescription() {
      // console.log(this.taskDescription, this.EditTaskDescriptionID);
      this.editTaskDesc({
        value: this.taskDescription,
        id: this.EditTaskDescriptionID,
      }).then(() => {
        this.showTaskDescription = false;
        this.$message.success("บันทึกข้อมูลสำเร็จ", 3);
      });
    },
    statusSelection(item) {
      // console.log();
      let index = this.statusSelectionlists.findIndex(
        (list) => list.key == item.key
      );
      let payloads = {
        status: "",
        value: "",
        searchMode: false,
        searchKey: "",
        Groupid: "",
        isTodayJobs: this.dateSelection,
        rangeDate: {
          start: this.isTodayJobs
            ? this.startRangeFormat(moment())
            : this.startRangeFormat(this.dateSelection),
          end: this.isTodayJobs
            ? this.endRangeFormat(moment())
            : this.endRangeFormat(this.dateSelection),
        },
      };
      this.displayStatusSelected = this.statusSelectionlists[index].name;
      if (!this.searchMode) {
        if (item.key !== "all") {
          payloads.isTodayJobs = this.isTodayJobs ? true : this.dateSelection;
          payloads.status = item.key;
          payloads.searchMode = false;
          payloads.Groupid = this.selectedGroup.id;
          this.getJobsbyStatus(payloads);
        } else {
          if (this.isTodayJobs) {
            this.getJobPayloads.start = this.startRangeFormat(moment());
            this.getJobPayloads.end = this.endRangeFormat(moment());
            this.getJobsbyDate(this.getJobPayloads);
          } else {
            this.getJobPayloads.Groupid = this.selectedGroup.id;
            if (this.dateSelection === "") {
              this.getJobs(this.selectedGroup.id);
            } else {
              this.getJobPayloads.start = this.startRangeFormat(
                this.dateSelection
              );
              this.getJobPayloads.end = this.endRangeFormat(
                this.dateSelectionEnd
              );
              this.getJobsbyDate(this.getJobPayloads);
            }
          }
        }
      } else {
        if (item.key !== "all") {
          payloads.status = item.key;
          payloads.value = this.inputSearch;
          payloads.searchMode = true;
          payloads.searchKey = this.searchKey;
          payloads.Groupid = this.selectedGroup.id;
          payloads.isTodayJobs = this.isTodayJobs ? true : this.dateSelection;
          this.getJobsbyStatus(payloads);
        } else {
          this.getJobsbySearch({
            value: this.inputSearch,
            searchKey: this.searchKey,
            customer_groups_id: this.selectedGroup.id,
            isTodayJobs: this.isTodayJobs,
            rangeDate: {
              start: this.startRangeFormat(moment()),
              end: this.endRangeFormat(moment()),
            },
          });
        }
      }
    },
    inputSearchonChange(input) {
      if (
        input.inputType == "deleteContentBackward" &&
        this.inputSearch == ""
      ) {
        // console.log("Search Mode OFF");
        this.isSearchStoreName = false;
        this.searchMode = false;
        this.showStatusSelection = false;
        this.displayStatusSelected = "ทั้งหมด";
        if (this.isTodayJobs) {
          let payloads = {
            Groupid: this.selectedGroup.id,
            start: this.startRangeFormat(moment()),
            end: this.endRangeFormat(moment()),
          };
          // console.log(payloads)
          this.getJobsbyDate(payloads);
          ``;
        } else {
          this.displayStatusSelected = "ทั้งหมด";
          this.dateSelection = "";
          this.dateSelectionEnd = "";
          this.getJobs(this.selectedGroup.id);
        }
      }
    },
    onSearch(value) {
      let payloads = {
        searchKey: "",
        value: "",
        customer_groups_id: "",
        isTodayJobs: this.isTodayJobs,
        rangeDate: {
          start: this.startRangeFormat(moment()),
          end: this.endRangeFormat(moment()),
        },
      };
      let body = {
        action: "search_store_name",
        customer_group: "",
        search_txt: "",
        startdate_gte: "",
        startdate_lte: "",
        // _limit: this.ApiPagination.limit,
        // _start: this.ApiPagination.start
      };
      if (value) {
        this.dateSelection = "";
        this.dateSelectionEnd = "";
        if (this.searchKey === "stores_name") {
          this.isSearchStoreName = true;
          // console.log("Store_name");
          if (this.isTodayJobs) {
            this.showStatusSelection = true;
            body.customer_group = this.selectedGroup.id;
            body.search_txt = this.inputSearch;
            body.startdate_gte = this.startRangeFormat(moment());
            body.startdate_lte = this.endRangeFormat(moment());
            this.searchMode = true;
            this.displayStatusSelected = "ทั้งหมด";
            this.searchStoreName(body);
          } else {
            this.showStatusSelection = true;
            body.customer_group = this.selectedGroup.id;
            body.search_txt = this.inputSearch;
            this.searchMode = true;
            this.displayStatusSelected = "ทั้งหมด";
            this.searchStoreName(body);
          }
        } else {
          // console.log("ETC");
          payloads.value = this.inputSearch;
          payloads.searchKey = this.searchKey;
          this.searchMode = true;
          this.displayStatusSelected = "ทั้งหมด";
          payloads.customer_groups_id = this.selectedGroup.id;
          // console.log("Search Mode ON");
          this.getJobsbySearch(payloads);
        }
      }
    },
    onChangeDateSelection() {
      this.getJobPayloads.Groupid = this.selectedGroup.id;
      this.displayStatusSelected = "ทั้งหมด";
      this.inputSearch = "";
      this.getJobPayloads.start = this.startRangeFormat(this.dateSelection);
      this.getJobPayloads.end =
        this.dateSelectionEnd === ""
          ? this.endRangeFormat(this.dateSelection)
          : this.endRangeFormat(this.dateSelectionEnd);
      if (
        typeof this.reportDriverID !== "undefined" &&
        this.reportDriverID !== ""
      ) {
        this.getJobPayloads.driverID = this.reportDriverID;
      } else {
        this.getJobPayloads.driverID = "";
      }
      this.dateSelection || this.reportDriverID
        ? this.getJobsbyDate(this.getJobPayloads)
        : this.getallJobs();
      // console.log(this.getJobPayloads)
    },
    onChangeStartDate() {
      if (this.isSaveDataBTNtrigger) {
        if (this.Inputstartdate) {
          this.isInputsError[0] = false;
        } else {
          this.isInputsError[0] = true;
        }
      }
    },
    onChangeTripno() {
      if (this.isSaveDataBTNtrigger) {
        if (this.Data.tripno) {
          this.isInputsError[1] = false;
        } else {
          this.isInputsError[1] = true;
        }
      }
    },
    onChangeVehicleType() {
      if (this.isSaveDataBTNtrigger) {
        if (this.InputvehicleType) {
          this.isInputsError[2] = false;
        } else {
          this.isInputsError[2] = true;
        }
      }
    },
    onChangePlate_number() {
      if (this.Data.plate_number) {
        this.isInputsError[3] = false;
        if (this.Data.plate_number.match("-")) {
          let checkFormat = this.Data.plate_number.split("-");
          // console.log(checkFormat[0].length);
          if (checkFormat[0].length > 3 || checkFormat[0].length == 0) {
            this.isPlateNumberFormValid = false;
            this.showPlateNumberErrMsg = true;
            this.PlateNumberErrMsg =
              "ระบุด้านหน้าตัวขีดและไม่ควรเกิน 3 ตัวอักษร";
          } else {
            if (checkFormat[1].length > 4 || checkFormat[1].length == 0) {
              this.isPlateNumberFormValid = false;
              this.showPlateNumberErrMsg = true;
              this.PlateNumberErrMsg =
                "ระบุด้านหลังตัวขีดและไม่ควรเกิน 4 ตัวอักษร";
            } else {
              this.isPlateNumberFormValid = true;
              this.showPlateNumberErrMsg = false;
              this.PlateNumberErrMsg = "";
            }
          }
        } else {
          this.isPlateNumberFormValid = false;
          this.showPlateNumberErrMsg = true;
          this.PlateNumberErrMsg = "กรุณากรอกตามรูปแบบ XXX-XXXX ";
          // console.log("not pass");
        }
      } else {
        this.isInputsError[3] = true;
      }
    },
    // onChangeDoorNumber() {
    //   if (this.isSaveDataBTNtrigger) {
    //     if (this.Data.door_number) {
    //       this.isInputsError[4] = false;
    //     } else {
    //       this.isInputsError[4] = true;
    //     }
    //   }
    // },
    onChangefirstDriver(value) {
      // console.log(value);
      this.displaysecondDriverInput = false;
      this.secondDriverlists = this.drivers.filter(
        (driver) => driver.id != value
      );
      // console.log(this.secondDriverlists);
      if (value === this.InputsecondDriver) {
        // console.log("same")
        this.InputsecondDriver = "";
      }
      if (this.isSaveDataBTNtrigger) {
        if (this.InputfirstDriver) {
          this.isInputsError[5] = false;
        } else {
          this.isInputsError[5] = true;
        }
      }
    },
    checkFormatStoreLists(array) {
      let myLists = [];
      let count = 0;
      let isValid = false;
      for (let index in array) {
        if (array[index] !== "") {
          myLists.push(array[index]);
          count++;
        }
      }
      if (count === 1) {
        this.isStorelistsFormValid = false;
        this.showStorelistsErrMsg = true;
        this.StorelistsErrMsg = "กรุณากรอกชื่อสถานีหรือหมายเลขสถานีให้ครบถ้วน";
        isValid = false;
      } else if (count === 2) {
        if (myLists[0].match(/\s/)) {
          this.isStorelistsFormValid = false;
          this.showStorelistsErrMsg = true;
          this.StorelistsErrMsg = "ไม่ควรเว้นวรรคในช่องหมายเลขสถานี";
          isValid = false;
        } else {
          let trim = myLists[0].trim();
          this.setStoreNo = trim.match(/\s/) ? trim.replace(/\s/, "") : trim;
          let noTab = myLists[1].replace(/\t/g, " ");
          // console.log(noTab)
          this.setStoreName = noTab.trim();
          this.isStorelistsFormValid = true;
          this.showStorelistsErrMsg = false;
          this.StorelistsErrMsg = "";
          isValid = true;
        }
      } else if (count >= 3) {
        this.isStorelistsFormValid = false;
        this.showStorelistsErrMsg = true;
        this.StorelistsErrMsg = "ไม่ควรเว้นวรรคในช่องหมายเลขสถานี";
        isValid = false;
      }
      // else {
      //   this.isStorelistsFormValid = false;
      //   this.showStorelistsErrMsg = true;
      //   this.StorelistsErrMsg =
      //     "รูปแบบการเว้นวรรคไม่ถูกต้อง";
      //   isValid = false;
      // } myString.split(/\t|\s+/);/\t|(?<=^\S+)\s+/
      return isValid;
    },
    splitArrayFunction(str) {
      // console.log(str)
      let myArray = [str.replace(/\s.*/, ""), str.replace(/\S+\s/, "")];
      // console.log(myArray)
      return myArray;
    },
    onChangeStoreLists() {
      if (this.Inputstorelists) {
        this.isInputsError[6] = false;
        if (this.Inputstorelists.match("\n")) {
          let inputArray = this.Inputstorelists.split("\n");
          let myStorelistArray = [];
          for (let i = 0; i < inputArray.length; i++) {
            if (inputArray[i]) {
              if (inputArray[i].match(/\t|\s/)) {
                // let spaceSplitArray = inputArray[i].split(/\t|\s/);
                let spaceSplitArray = this.splitArrayFunction(inputArray[i]);
                // console.log('spaceSplitArray',spaceSplitArray);
                if (this.checkFormatStoreLists(spaceSplitArray)) {
                  // console.log(spaceSplitArray.length);
                  // if (!isNaN(spaceSplitArray[0])) {
                  // if (this.setStoreName) {
                  let Obj = {};
                  Obj["no"] = `${i + 2}`;
                  // Obj["no"] = null;
                  // Obj["store_no"] = spaceSplitArray[0];
                  // Obj["stores_name"] = spaceSplitArray[1];
                  Obj["store_no"] = this.setStoreNo;
                  Obj["stores_name"] = this.setStoreName;
                  Obj["status"] = "waiting_upload";
                  Obj["photos"] = [];
                  myStorelistArray.push(Obj);
                  // console.log(myStorelistArray);
                  // this.isStorelistsFormValid = true;
                  // this.showStorelistsErrMsg = false;
                  // this.StorelistsErrMsg = "";
                  // } else {
                  //   // console.log("Doesn't have Storename");
                  //   this.isStorelistsFormValid = false;
                  //   this.showStorelistsErrMsg = true;
                  //   this.StorelistsErrMsg = "กรุณากรอกชื่อสถานี";
                  //   break;
                  // }
                  // } else {
                  //   // console.log(spaceSplitArray[0], "Enter Only Number");
                  //   this.isStorelistsFormValid = false;
                  //   this.showStorelistsErrMsg = true;
                  //   this.StorelistsErrMsg = "หมายเลขสถานีควรเป็นตัวเลขเท่านั้น";
                  //   break;
                  // }
                } else {
                  // console.log("invalid Form");
                  // this.isStorelistsFormValid = false;
                  // this.showStorelistsErrMsg = true;
                  // this.StorelistsErrMsg =
                  //   "ในหนึ่งบรรทัดควรเว้นวรรคระหว่างหมายเลขและชื่อสถานีเท่านั้น";
                  break;
                }
              } else {
                this.isStorelistsFormValid = false;
                this.showStorelistsErrMsg = true;
                this.StorelistsErrMsg = "รูปแบบการเว้นวรรคไม่ถูกต้อง";
                break;
              }
            } else {
              break;
            }
          }
          this.Data.store_list = myStorelistArray;
          // console.log(this.Data.store_list);
        } else {
          if (this.Inputstorelists.match(/\t|\s/)) {
            // let oneLineInput = this.Inputstorelists.split(/\t|\s/);
            let oneLineInput = this.splitArrayFunction(this.Inputstorelists);
            // console.log(oneLineInput)
            if (this.checkFormatStoreLists(oneLineInput)) {
              // if (this.setStoreName) {
              let onlyOneStore = {};
              onlyOneStore["no"] = "2";
              onlyOneStore["store_no"] = this.setStoreNo;
              onlyOneStore["stores_name"] = this.setStoreName;
              onlyOneStore["status"] = "waiting_upload";
              onlyOneStore["photos"] = [];
              //   this.isStorelistsFormValid = true;
              //   this.showStorelistsErrMsg = false;
              this.Data.store_list = [onlyOneStore];
              //   this.StorelistsErrMsg = "";
              // } else {
              //   this.isStorelistsFormValid = false;
              //   this.showStorelistsErrMsg = true;
              //   this.StorelistsErrMsg = "กรุณากรอกชื่อสถานี";
              // }
            }
            // else {
            //   this.isStorelistsFormValid = false;
            //   this.showStorelistsErrMsg = true;
            //   this.StorelistsErrMsg =
            //     "ในหนึ่งบรรทัดควรเว้นวรรคระหว่างหมายเลขและชื่อสถานีเท่านั้น";
            // }
          } else {
            this.isStorelistsFormValid = false;
            this.showStorelistsErrMsg = true;
            this.StorelistsErrMsg = "รูปแบบการเว้นวรรคไม่ถูกต้อง";
          }
        }
      } else {
        this.isInputsError[6] = true;
      }
    },
    // onChangeStoreListsEditMode() {
    //   console.log(this.Data.store_list);
    // },
    dateSorting() {
      this.dateSorted = !this.dateSorted;
      if (this.isSearchStoreName) {
        this.searchStoreNamePayloads._sort = `startdate:${
          this.dateSorted ? `DESC` : `ASC`
        }`;
        // console.log(this.searchStoreNamePayloads);
        // console.log("Sorting StoreName")
        this.dateSortedJobs(this.searchStoreNamePayloads);
      } else {
        let payloads = {
          url: this.url.includes("&_sort")
            ? this.url.slice(0, this.url.indexOf("&_sort"))
            : this.url,
          dateSorted: this.dateSorted,
        };
        // console.log(payloads);
        this.dateSortedJobs(payloads);
      }
    },
    statusStoreFt(value) {
      let str;
      if (value == "finish") {
        str = "ปิดงาน";
      } else if (value == "cancel") {
        str = "ถูกยกเลิกจัดส่ง";
      } else {
        str = "รออัพโหลดรูป";
      }
      return str;
    },
    statusIconColor(value) {
      let str;
      if (value == "finish") {
        str = "done";
      } else if (value == "cancel") {
        str = "cancel";
      } else {
        str = "waiting_upload";
      }
      return str;
    },
    clearImage() {
      this.images = [];
      this.ImageLoading = true;
      this.noPhotos = false;
      this.photoError = false;
    },
    async GalleryPopup(record, st) {
      this.showGallery = true;
      const response = await AxiosService.get(
        `${endpoints.coreApi}/jobs/slug/${record.url_slug}/user`
      )
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          this.photoError = true;
          return [];
        });
      for (let store in response.store_list) {
        if (response.store_list[store]._id === st._id) {
          // console.log(response.store_list[store]);
          this.noPhotos =
            response.store_list[store].photos.length === 0 ? true : false;
          // console.log(this.noPhotos)
          for (let photo_index in response.store_list[store].photos) {
            let obj = {
              src: `${response.store_list[store].photos[photo_index].bucket}`,
              thumbnail: `${response.store_list[store].photos[photo_index].bucket_thumb}`,
              caption: `${
                response.store_list[store].photos[photo_index].filename
              } ${this.dtFormatFixed(
                response.store_list[store].photos[photo_index].created
              )}`,
            };
            // console.log(response.store_list[store].photos[photo_index].bucket)
            this.images.push(obj);
          }
          this.ImageLoading = false;
        }
      }
      // this.images = [
      //   {
      //     src: "http://localhost:3030/jobs/slug/1621183132-b3b119462b44cc508cabc9be5a6da3d5ccb3da1855be89aa51513a8f237a47a1/img/NjA5MGJlZWFhMjgwNWUwMDExNWJlNjc0LzYwYjllZTk3MTdlOGM2MDAxMTAxYzI3Yi8xNjIzNDE1MjI4LVRteC1ibG9iLmpwZw==/user",
      //     thumbnail:
      //       "http://localhost:3030/jobs/slug/1621183132-b3b119462b44cc508cabc9be5a6da3d5ccb3da1855be89aa51513a8f237a47a1/img/NjA5MGJlZWFhMjgwNWUwMDExNWJlNjc0LzYwYjllZTk3MTdlOGM2MDAxMTAxYzI3YS8xNjIzNDE1MDk2LTdaWS1yZXNpemUtYmxvYi5qcGc=/user",
      //   },
      // ];
    },
    dtFormat(timestamp) {
      if (timestamp) {
        return moment.utc(timestamp).local();
      } else {
        return "-";
      }
    },
    dtFormatFixed(timestamp) {
      if (timestamp) {
        return moment
          .utc(timestamp)
          .local()
          .format("DD MMM YYYY HH:mm");
      } else {
        return "-";
      }
    },
    // selectedRow(record) {
    //   return {
    //     on: {
    //       click: () => {
    //         console.log("selectedRowData", record);
    //       },
    //     },
    //   };
    // },
    upload_more(job, store) {
      let bodyUploadMore_api = {
        action: "set_store_status",
        status: "waiting_upload",
        store: `${store.id}`,
      };
      let bodyUpdatedStatus = {
        id: `${job._id}`,
        body: { status: "require_upload_photo" },
      };
      let payloads = [bodyUploadMore_api, bodyUpdatedStatus];
      // console.log("before",payloads)
      this.uploadMore(payloads);
    },
    async downloadExcel(format, type) {
      // console.log(value);
      let reportPayload = {
        action:
          format == "pdf" ? "generate_pdf_report" : "generate_excel_report",
        customer_group: this.selectedGroup.id,
        startdate_gte: "",
        startdate_lte: "",
        noImage: type == "noimage" ? true : false,
        driver: this.reportDriverID,
        format: format,
      };
      // console.log(reportPayload);
      let dateValid = false;
      const keyLoading = "downloadExcel";
      if (this.isTodayJobs) {
        reportPayload.startdate_gte = this.startRangeFormat(moment());
        reportPayload.startdate_lte = this.endRangeFormat(moment());
        dateValid = true;
      } else {
        if (this.dateSelection != "" && this.dateSelectionEnd != "") {
          reportPayload.startdate_gte = this.startRangeFormat(
            this.dateSelection
          );
          reportPayload.startdate_lte = this.endRangeFormat(
            this.dateSelectionEnd
          );
          dateValid = true;
        } else {
          dateValid = false;
        }
      }
      if (dateValid) {
        // console.log(JSON.stringify(reportPayload));

        this.$message.loading({
          content: "กำลังเตรียมข้อมูล กรุณารอสักครู่",
          key: keyLoading,
          duration: 0,
        });
        await AxiosService.post(
          `${endpoints.coreApi}/jobs/report`,
          reportPayload,
          {
            responseType: "blob",
          }
        )
          .then(async (response) => {
            // console.log(response);
            this.$message.destroy(keyLoading);
            this.$message.success({
              content: "เตรียมข้อมูลเรียบร้อย",
              keyLoading,
              duration: 2,
            });
            // const strTimerange = `${reportPayload.startdate_gte}-to-${reportPayload.startdate_lte}`;
            let startTime = reportPayload.startdate_gte.slice(
              0,
              reportPayload.startdate_gte.indexOf("T00")
            );
            let endTime = reportPayload.startdate_lte.slice(
              0,
              reportPayload.startdate_lte.indexOf("T")
            );
            const fileName = moment(startTime).isSame(moment(endTime), "day")
              ? `TMS-Job-Report-${startTime}.${
                  format == "pdf" ? "pdf" : "xlsx"
                }`
              : `TMS-Job-Report-${startTime}-to-${endTime}.${
                  format == "pdf" ? "pdf" : "xlsx"
                }`;
            fileDownload(response.data, fileName);
          })
          .catch((error) => {
            this.$message.error({
              content: "เกิดข้อมูลผิดพลาดบางอย่าง กรุณาลองอีกครั้ง",
              keyLoading,
              duration: 2,
            });
            console.log("error", error);
          });
      } else {
        this.$message.error(
          "กรุณาเลือกวันที่เริ่มต้นและสิ้นสุด หรือ คนขับ ให้เรียบร้อย"
        );
      }
    },
    startRangeFormat(value) {
      return value
        ? value
            .utc()
            .hour(0)
            .minute(0)
            .second(0)
            .format()
        : "";
    },
    endRangeFormat(value) {
      return value
        ? value
            .utc()
            .hour(23)
            .minute(59)
            .second(59)
            .format()
        : "";
    },
    expandIcon(props) {
      if (props.expanded) {
        return (
          <a
            onClick={(e) => {
              props.onExpand(props.record, e);
            }}
          >
            <icon-font
              type="icon-downarrow1"
              style="font-size: 12px"
              class="fa-arrow-down-open"
            />
          </a>
        );
      } else {
        return (
          <a
            onClick={(e) => {
              props.onExpand(props.record, e);
            }}
          >
            <icon-font
              type="icon-downarrow1"
              style="font-size: 12px"
              class="fa-arrow-down"
            />
          </a>
        );
      }
    },
    reset_initData() {
      // this.dateSelection = "";
      // this.displayStatusSelected = "ทั้งหมด"
      // (this.getJobPayloads = {
      //   Groupid: "",
      //   start: moment().utc().hour(0).minute(0).second(0).format(),
      //   end: moment().utc().hour(23).minute(59).second(59).format(),
      // }),
      (this.isSaveDataBTNtrigger = false),
        (this.displaysecondDriverInput = true),
        (this.InputfirstDriver = "");
      this.setStoreNo = "";
      this.setStoreName = "";
      this.Inputstatus = "prepare";
      this.InputsecondDriver = "";
      this.Inputstorelists = "";
      this.Inputstartdate = "";
      this.InputvehicleType = "";
      this.InputRefno = "";
      this.InputExpressway_cost = 0;
      this.InputTrip_cost = 0;
      this.InputFuel_cost = 0;
      this.InputNoted = "";
      (this.showPlateNumberErrMsg = false),
        (this.showStorelistsErrMsg = false),
        (this.isStorelistsFormValid = false),
        (this.isPlateNumberFormValid = false),
        (this.isInputsError = [
          false,
          false,
          false,
          false,
          false,
          false,
          false,
        ]),
        (this.selectedRowData = {});
      this.Data = {
        tripno: "",
        status: "",
        door_number: "",
        startdate: "",
        plate_number: "",
        refno: "",
        fuel_charges: 0,
        expressway_charges: 0,
        trip_charges: 0,
        note: "",
        drivers: [],
        vehicle_type: "",
        customer_group: {
          _id: "",
        },
        store_list: [
          {
            stores_name: "",
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$md: 768px;
.TMS {
  font-size: 22px;
  color: #505050;
  background-color: white;
  padding: 30px 45px;
  width: calc(100vw - 140px);
  @media screen and (max-width: $md) {
  }
}
.scrollable {
  overflow-x: auto;
  white-space: nowrap;
  max-width: 330px;
  margin: 0px;
}
.dropdownScrollbar {
  overflow-y: auto;
  white-space: nowrap;
  max-height: 100px;
  margin: 3px;
}
.fa-arrow-down {
  transform: rotate(0deg);
  transition: transform 0.1s linear;
  font-size: 25px;
}

.fa-arrow-down-open {
  transform: rotate(-180deg);
  transition: transform 0.1s linear;
  font-size: 25px;
}
.inner-row-head {
  text-align: left;
  padding: 10px;
  background-color: #e2e2e2;
  border-radius: 10px;
  height: 80px;
}
.dateSortedArrow {
  font-size: 18px;
  vertical-align: middle;
  transform-origin: 50% 42%;
  &.DESC {
    transform: rotate(90deg);
    transition: transform 0.1s linear;
  }
  &.ASC {
    transform: rotate(270deg);
    transition: transform 0.1s linear;
  }
}
.ant-btn {
  margin-right: 8px;
}
.task-drawer {
  font-size: 18px;
  .drawerlabel {
    margin-bottom: 5px;
    margin-top: 5px;
  }
  .ant-input {
    width: 312px;
    height: 40px;
  }
  .ant-calendar-picker {
    width: 312px;
  }
  .ant-form {
    font-size: 22px;
  }
  .ant-select {
    width: 312px;
  }
}
.iconindrawer {
  display: inline-block;
  background-color: #e2e2e2;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  margin: 10px 10px 10px 0px;
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
  padding-top: 10px;
}
.error_msg {
  color: red;
  font-size: 14px;
}
.status {
  text-align: center;
  border-radius: 16px;
  padding: 5px;
  &.prepare {
    background-color: #e2e2e2;
  }
  &.transporting {
    background-color: #faad1467;
  }
  &.require_upload_photo {
    background-color: #22b4f541;
  }
  &.complete {
    background-color: #00c2464b;
  }
  &.cancel {
    background-color: #f5223e41;
  }
}
.store {
  background-color: #513fe82c;
  text-align: center;
  border-radius: 16px;
  padding: 5px;
}
.stationicon {
  &:not(:first-child) {
    margin: 0 0 0 -5px;
  }
  &.waiting_upload {
    background-color: #b5b5b5;
  }
  &.done {
    background-color: #00c246;
  }
  &.cancel {
    background-color: #e74c3c;
  }
  display: inline-block;
  text-align: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin: 0 0 0 0;

  color: white;
  border: 2px solid #fff;
  line-height: 20px;
}
.tms-menu {
  display: inline-block;
  width: 200px;
  background-color: #f5f5f5;
  height: 100vh;
}
.task-menu {
  margin-bottom: 30px;
}
</style>
<style lang="scss">
.taskrow {
  td {
    padding: 3px 16px !important;
    overflow-wrap: break-word;
  }
  .ant-table-thead {
    tr {
      th {
        text-align: center;
      }
    }
  }
}
.gallery-modal {
  .ant-modal-title {
    text-align: center;
  }
}

.task-description-modal {
  .ant-modal-title {
    text-align: center;
  }
  .ant-modal-footer {
    border-top: 0px;
  }
}
</style>
