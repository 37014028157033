<template>
  <div>
    <div class="title">
      {{ routeDetail.id === '' ? 'เพิ่มเส้นทาง' : 'แก้ไขเส้นทาง' }}
    </div>
    <a-row type="flex" align="middle" class="header">
      <a-col :span="6">ชื่อเส้นทาง</a-col>
      <a-col :span="18">
        <a-input placeholder="กรอกชื่อเส้นทาง" v-model="routeDetail.name" />
      </a-col>
    </a-row>
    <div class="content">
      <a-timeline v-if="isPreviewRoute">
        <a-timeline-item
          v-for="(position, index) in displayedPreviewPositionList"
          :color="position.type === 'info' ? '#555' : 'green'"
          :key="`${position.name}-${index}`"
          style="height: 80px; padding: 0"
        >
          <a-icon
            v-if="position.type === 'info'"
            slot="dot"
            type="question-circle"
            style="font-size: 14px"
          />
          <icon-font
            v-else
            slot="dot"
            :type="getPositionIcon(position.type)"
            style="font-size: 20px"
          />
          <div class="item-info-container">
            <div class="item-info-name">
              {{ position.name }}
            </div>
          </div>
        </a-timeline-item>
      </a-timeline>
      <a-timeline v-else>
        <a-timeline-item
          v-for="(position, index) in displayedPositionList"
          :color="'green'"
          :key="`${position.name}-${index}`"
          style="height: 80px; padding: 0"
        >
          <a-icon
            v-if="position.type === undefined"
            slot="dot"
            type="plus-circle"
            :style="{
              'font-size': position.type === undefined ? '18px' : '14px',
            }"
          />
          <icon-font
            v-else
            slot="dot"
            :type="getPositionIcon(position.type)"
            style="font-size: 20px"
          />

          <div v-if="Object.keys(position).length > 0">
            <div class="position-list-item" v-if="editPositionIndex !== index">
              <div class="item-info-container">
                <div class="item-info-name">
                  {{ position.name }}
                </div>
                <div class="item-info-action">
                  <a-icon type="form" @click="onEditingPosition(index)" />
                  <a-icon type="delete" @click="onRemovePosition(index)" />
                </div>
              </div>
              <div class="item-switch-container">
                <a-button
                  class="item-switch"
                  :disabled="index === 0 || displayedPositionList.length < 2"
                  @click="onSwitchPositionList(true, index)"
                >
                  <a-icon type="arrow-up" />
                </a-button>
                <a-button
                  class="item-switch"
                  :disabled="index === displayedPositionList.length - 2"
                  @click="onSwitchPositionList(false, index)"
                >
                  <a-icon type="arrow-down" />
                </a-button>
              </div>
            </div>
            <div v-else>
              <!-- Edit route -->
              <positionSelection
                :index="index"
                :position="position"
                :latlngMap="latlngMap"
                @onClose="onCloseEditPosition"
                @onSubmit="onSubmitEditingPosition"
                @onCoordinatesChange="onCoordinatesChange"
              />
            </div>
          </div>
          <div v-else>
            <!-- Add route -->
            <positionSelection
              v-if="editPositionIndex === null"
              :index="index"
              :position="position"
              :latlngMap="latlngMap"
              @onSubmit="onSubmitAddingPosition"
              @onCoordinatesChange="onCoordinatesChange"
            />
          </div>
        </a-timeline-item>
      </a-timeline>
    </div>
    <div class="footer">
      <div v-if="isPreviewRoute" class="summary-container">
        รวมระยะทาง {{ sumDistance }} กม. รวมเวลาเดินทาง
        {{ sumDuration }}
        นาที
      </div>
      <div v-else class="options-btn-container">
        <div>
          <a-switch
            v-model="isOptimizedActive"
            :disabled="positionList.length < 2"
          />
          จัดเส้นทางที่ดีที่สุด
        </div>
        <div>
          <a-switch
            v-model="isMilkRunActive"
            :disabled="positionList.length < 2"
          />
          กลับสถานีแรก
        </div>
      </div>
      <div class="actions-btn-container">
        <a-button
          type="primary"
          @click="isPreviewRoute ? onClickSaveRoute() : onClickPreviewRoute()"
          :disabled="disableButton()"
        >
          {{ isPreviewRoute ? 'บันทึกเส้นทาง' : 'แสดงเส้นทาง' }}
        </a-button>
        <a-button
          @click="
            isPreviewRoute ? onClickCancelPreviewRoute() : onClickCancelRoute()
          "
        >
          {{ isPreviewRoute ? 'ย้อนกลับ' : 'ยกเลิก' }}
        </a-button>
      </div>
      <a-button
          v-if="isPreviewRoute"
          type="primary"
          class="share-link-btn"
          @click="() => (isDisplayedModal = true)"
        >
          <a-icon type="share-alt" />
          แชร์
        </a-button>
    </div>
    <a-modal
      :visible="isDisplayedModal"
      @cancel="() => (isDisplayedModal = false, posShareTabActivated = true, routeShareTabActivated = false)"
      :mask="true"
      :closable="true"
      :footer="false"
      centered
      :width="360"
    >
    <div class="share-modal">
        <!-- Menu tabs -->
        <div class="navigation-container">
          <div 
            @click="handleTabClick('postions_share')"
            :class="['modal-title','navigation-button', posShareTabActivated && 'active']"
          >แชร์ตำแหน่ง</div>
          <div 
            @click="handleTabClick('routes_share')"
            :class="['modal-title','navigation-button', routeShareTabActivated && 'active']"
          >แชร์เส้นทาง</div>
        </div>

        <div 
          v-if="posShareTabActivated"
          style="width: 100%;"
        >
          <div :class="positionList.length > 4 ? 'modal-routes-container' : 'modal-routes-container-fixed'">
            <div 
              v-for="(item, idx) in positionList"
              :key="idx"
              class="modal-route-item-layout"
            >
              <div class="route-item-input">
                {{ item.name }}
              </div>
              <a-button
                class="route-item-btn"
                @click="shareSinglePosUrl(item, true)"
              >
                <img src="@/assets/icons/copy-icon.svg" alt="copy-icon">
              </a-button>
              <a-button
                class="route-item-btn"
                @click="shareSinglePosUrl(item, false)"
              >
                <img src="@/assets/icons/gmap_pin.svg" alt="gmap-pin">
              </a-button>
            </div>
          </div>
          <a-button
            @click="shareAllPosUrl(true)"
            class="modal-share_all-btn"
          >
            <img src="@/assets/icons/copy-icon.svg" alt="">
            <span>คัดลอกลิงก์ทั้งหมด</span>
          </a-button>
        </div>

        <div 
          v-if="routeShareTabActivated"
          class="modal-btn-container"
        >
          <a-button
            class="modal-btn"
            :size="'large'"
            @click="shareLinkUrl(false)"
          >
            <img src="@/assets/icons/gmap_pin.svg" alt="gmap-pin"><span style="margin-left: 8px;">Google Map</span>
          </a-button>
          <a-button
            class="modal-btn"
            icon="copy"
            :size="'large'"
            @click="shareLinkUrl(true)"
          >
            คัดลอกลิงก์
          </a-button>
          <a-button
            v-if="canShareNative()"
            class="modal-btn"
            icon="share-alt"
            :size="'large'"
            @click="otherShare()"
          >
            แชร์ไปยัง
          </a-button>
        </div> 
      </div>
    </a-modal>
  </div>
</template>
<script>
import positionSelection from '@/views/components/route/positionSelection.vue';
import { IconFont } from '@/config/config';

export default {
  props: {
    route: Object,
    googleMap: Object,
    directionsDisplay: Object,
    calculateRouteDirection: Function,
    calculateInfoRouteList: Function,
    onCreateWaypoints: Function,
    onRemoveWaypoints: Function,
    latlngMap: String,
  },
  components: {
    IconFont,
    positionSelection,
  },
  data() {
    return {
      displayedPositionList: [{}],
      displayedPreviewPositionList: [],
      isDisplayedModal: false,
      routeDetail: {
        id: '',
        name: '',
        positionList: [],
      },
      previewWaypoints: [],
      sumDistance: 0,
      sumDuration: 0,
      isPreviewRoute: false,
      editPositionIndex: null,
      isMilkRunActive: false,
      isOptimizedActive: false,
      posShareTabActivated: true,
      routeShareTabActivated: false,
    };
  },
  watch: { 
    async positionList(list) {
      this.displayedPositionList = [...list, {}];
      this.previewWaypoints = [...list].map((positionInfo, index) => {
        return {
          marker: new window.google.maps.Marker({
            position: { lat: positionInfo.lat, lng: positionInfo.lng },
            label: { text: (index + 1).toString(), color: 'white' },
          }),
          info: { label: positionInfo.name },
        };
      });
      if (list.length > 0) {
        this.googleMap.$mapPromise.then((map) => {
          let bounds = new window.google.maps.LatLngBounds();
          for (let i = 0; i < list.length; i++) {
            bounds.extend(list[i]);
          }
          map.fitBounds(bounds);
          map.panTo(bounds.getCenter());
        });
      }
    },
    previewWaypoints(newWaypoints, oldWaypoints) {
      if (oldWaypoints.length > 0) {
        this.onRemoveWaypoints(oldWaypoints);
      }
      if (newWaypoints.length > 0) {
        this.onCreateWaypoints(newWaypoints, this.googleMap.$mapObject);
      }
    },
    isPreviewRoute(value) {
      if (this.previewWaypoints.length > 0) {
        if (value) {
          this.onRemoveWaypoints(this.previewWaypoints);
        } else {
          this.onCreateWaypoints(
            this.previewWaypoints,
            this.googleMap.$mapObject
          );
        }
      }
    },
  },
  mounted() {
    if (this.directionsDisplay) {
      this.directionsDisplay.setMap(null);
    }
    const copyPositionList = [...this.route.positionList];
    if (
      copyPositionList.length > 0 &&
      copyPositionList[0].lat ===
        copyPositionList[copyPositionList.length - 1].lat &&
      copyPositionList[0].lng ===
        copyPositionList[copyPositionList.length - 1].lng
    ) {
      copyPositionList.pop();
      this.isMilkRunActive = true;
    }
    this.routeDetail.positionList = copyPositionList;
    this.routeDetail.id = this.route.id;
    this.routeDetail.name = this.route.name;
  },
  computed: {
    positionList() {
      return this.routeDetail.positionList;
    },
    displayPreviewPositionList() {
      return this.displayedPreviewPositionList.filter((position, index) => index % 2 === 0);
    },
  },
  methods: {
    canShareNative() {
      return navigator.share ? true : false;
    },
    otherShare() {
      const key = 'updatable';
      let url = 'https://www.google.com/maps/dir/';
      if (this.displayPreviewPositionList.length > 0) {
        for (let i = 0; i < this.displayPreviewPositionList.length; i++) {
          url =
            url +
            this.displayPreviewPositionList[i].lat +
            ',' +
            this.displayPreviewPositionList[i].lng +
            '/';
        }
        if (navigator.share) {
          navigator
            .share({
              title: 'แชร์ตำแหน่งรถไปยัง',
              url: url,
            })
            .then(() => {})
            .catch(console.error);
        } else {
          this.visibleShareModal = true;
        }
      } else {
        this.$message.warning({
          content: 'ไม่พบข้่อมูล',
          key,
          duration: 3,
        });
      }
    },
    shareLinkUrl(clipboard) {
      const key = 'updatable';
      let url = 'https://www.google.com/maps/dir/';
      if (this.displayPreviewPositionList.length > 0) {
        for (let i = 0; i < this.displayPreviewPositionList.length; i++) {
          url =
            url +
            this.displayPreviewPositionList[i].lat +
            ',' +
            this.displayPreviewPositionList[i].lng +
            '/';
        }
        if (clipboard === false) {
          window.open(url, '_blank');
        } else {
          this.clipboardNavigator(key, url)
        }
      } else {
        this.$message.warning({
          content: 'ไม่พบข้่อมูล',
          key,
          duration: 3,
        });
      }
    },

    shareSinglePosUrl(data, status) {
      const key = 'updateable';
      const url = `https://www.google.com/maps/search/?api=1&query=${data.lat},${data.lng}`;

      if (this.positionList.length > 0) {
        if (status === false) {
          window.open(url, '_blank');
        } else {
          this.clipboardNavigator(key, url)
        }
      } else {
        this.$message.warning({
          content: 'ไม่พบข้่อมูล',
          key,
          duration: 3,
        });
      }
    },

    shareAllPosUrl(clipboard) {
      const key = 'updateable';
      let allUrlLinks = [];
      
      if (this.positionList.length > 0) {
        for (let i = 0; i < this.positionList.length; i++) {
          const url = `https://www.google.com/maps/search/?api=1&query=${this.positionList[i].lat},${this.positionList[i].lng}`;
          allUrlLinks.push(`${i+1}. ${this.positionList[i].name} ${url}\n`)
        }

        if (clipboard) {
          const textToCopy = allUrlLinks.join('')
          this.clipboardNavigator(key, textToCopy)
        }
      } else {
        this.$message.warning({
          content: 'ไม่พบข้่อมูล',
          key,
          duration: 3,
        });
      }
    },

    clipboardNavigator(key, url) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.$message.success({
            content: 'คัดลอกลิงก์สำเร็จ',
            key,
            duration: 3,
          });
        })
        .catch(() => {
          this.$message.warning({
            content: 'เกิดข้อผิดพลาดบางอย่าง กรุณาลองอีกครั้ง',
            key,
            duration: 3,
          });
        });
    },

    getPositionIcon(type) {
      switch (type) {
        case 'google':
          return 'icon-location2';
        case 'search':
          return 'icon-location2';
        case 'vehicle':
          return 'icon-car';
        case 'geofence':
          return 'icon-Geotagplace';
        case 'info':
          return 'question-circle';
        default:
          return 'icon-location2';
      }
    },
    onSubmitAddingPosition(value) {
      /* { index: Number,
            data:Object} */
      const data = value.data;
      this.routeDetail.positionList.push(data);
    },
    async onSubmitEditingPosition(value) {
      const data = value.data;
      const index = value.index;

      const copyPositionList = [...this.positionList];
      copyPositionList[index] = data;

      this.routeDetail.positionList = copyPositionList;
      this.onCloseEditPosition();
    },
    async onSwitchPositionList(isSwitchUp, currentIndex) {
      this.onCloseEditPosition();

      let swapIndex = isSwitchUp ? currentIndex - 1 : currentIndex + 1;
      let copyPositionList = [...this.positionList];

      [copyPositionList[currentIndex], copyPositionList[swapIndex]] = [
        copyPositionList[swapIndex],
        copyPositionList[currentIndex],
      ];
      this.routeDetail.positionList = copyPositionList;
    },
    async onRemovePosition(index) {
      const copyPositionList = [...this.positionList];

      copyPositionList.splice(index, 1);

      this.routeDetail.positionList = copyPositionList;
    },
    onEditingPosition(index) {
      this.editPositionIndex = index;
    },
    onCloseEditPosition() {
      this.editPositionIndex = null;
    },
    async onClickPreviewRoute() {
      const copyPositionList = [...this.positionList];
      if (this.isMilkRunActive) {
        copyPositionList.push(this.positionList[0]);
      }

      const response = await this.calculateRouteDirection(
        copyPositionList,
        this.isOptimizedActive
      );

      if (response) {
        const directionList = response.routes[0].legs;
        if (directionList.length > 0) {
          let sumDis = 0;
          let sumDur = 0;

          directionList.forEach((leg) => {
            sumDis += parseFloat(leg.distance.value);
            sumDur += parseFloat(leg.duration.value);
          });

          this.sumDistance = (sumDis / 1000).toFixed(2);
          this.sumDuration = Math.ceil(sumDur / 60);
        }
        this.directionsDisplay.setMap(this.googleMap.$mapObject);
        this.directionsDisplay.setDirections(response);

        this.displayedPreviewPositionList = this.calculateInfoRouteList(
          response.routes[0],
          copyPositionList
        );

        this.isPreviewRoute = true;
        this.onCloseEditPosition();
      }
    },
    onClickCancelPreviewRoute() {
      if (this.directionsDisplay) {
        this.directionsDisplay.setMap(null);
      }
      this.displayedPreviewPositionList = [];
      this.sumDistance = 0;
      this.sumDuration = 0;
      this.isPreviewRoute = false;
      this.$emit('clearWaypoints');
    },
    onClickSaveRoute() {
      this.$emit('onSave', {
        id: this.routeDetail.id,
        name: this.routeDetail.name,
        positions: this.displayedPreviewPositionList.filter(
          (position, index) => index % 2 === 0
        ),
      });
    },
    onClickCancelRoute() {
      this.$emit('onClose');
    },
    disableButton() {
      if (this.isPreviewRoute) {
        return this.routeDetail.name === '';
      } else {
        return this.positionList.length < 2;
      }
    },
    onCoordinatesChange(coordinates) {
      if (coordinates.lat > 0 && coordinates.lng > 0) {
        this.googleMap.$mapPromise.then((map) => {
          map.panTo(coordinates);
          map.setZoom(17);
        });
      }
    },
    handleTabClick(tab_name) {
      this.posShareTabActivated = tab_name === 'postions_share';
      this.routeShareTabActivated = tab_name === 'routes_share';
    },
  },
  destroyed() {
    if (this.previewWaypoints.length > 0) {
      this.onRemoveWaypoints(this.previewWaypoints);
    }
  },

};
</script>
<style lang="scss" scoped>
.share-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
  padding: 10px;
  .modal-title {
    font-weight: bold;
    font-size: 16px;
  }

  .modal-routes-container-fixed{
    width: 100%;
    max-height: 153px;
  }

  .modal-routes-container {
    width: 100%;
    overflow-y: scroll;
    max-height: 153px;
    padding-right: 8px;
  }

  .modal-route-item-layout {
      display: flex;
      gap: 8px;
      margin-bottom: 8px;

      .route-item-input {
        position: relative;
        width: 224px;
        height: 32px;
        padding: 4px 16px;
        border-radius: 8px;
        font-size: 14px;
        border: 1px solid #EEF1F4;

        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .route-item-btn {
        border-radius: 8px;
        padding: 4px 8px;
        width: 36px;
        height: 32px;
      }
  }

  .modal-share_all-btn {
    margin-top: 24px; 
    width: auto; 
    height: 40px; 
    padding: 8px 16px; 
    font-size: 16px;
    border-radius: 8px;
    display: flex;
    gap: 8px;
  }
  .modal-share_all-btn > img {
    vertical-align: middle;
    margin-top: 2px;
  }
    
  .modal-btn-container {
    display: flex;
    align-self: center;
    flex-direction: column;
    gap: 16px;
    margin-top: 14px;
  }

  .modal-btn {
    width: 120px;
    border-radius: 8px;
  }
}

.route-name-container {
  display: flex;
  column-gap: 10px;
}

.navigation-container {
  justify-items: flex-start;
  margin-top: 18px;
  font-size: 16px;
  display: inline-block;
  border-bottom: 1px solid #515151;
  width: 100%;
  .navigation-button {
    height: 30px;
    padding: 0 25px 0 0;
    display: inline-block;
    text-align: center;
    color: #b5b5b5;
    cursor: pointer;

    &.active {
      color: #515151;
    }
  }
}

.title {
  margin-bottom: 8px;
}
.content {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 16px 0 0 0;
  padding: 16px 15px 0 5px;
  max-height: calc(100vh - 350px);
  overflow: auto;
  .position-list-item {
    display: flex;
    justify-content: space-between;

    .item-info-container {
      flex-grow: 1;
      display: flex;
      justify-content: space-between;
      padding: 3px 10px;
      .item-info-action {
        width: 30px;
        display: flex;
        column-gap: 5px;
        margin-top: 3px;
        // display: inline-block;
        // margin-left: 16px;
      }
    }

    .item-switch-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .item-switch {
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        &:hover {
          color: #0e0e0e !important;
        }
        &:disabled {
          pointer-events: none;
        }
      }
    }
  }
}

.footer {
  margin-top: 16px;
  .options-btn-container {
    display: flex;
    column-gap: 16px;
  }
  .actions-btn-container {
    margin-top: 32px;
    display: flex;
    column-gap: 32px;
  }
  .share-link-btn {
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 9px;
  }
}

::-webkit-scrollbar {
  width: 5px !important;
}
::-webkit-scrollbar-track-piece:start {
  background: transparent !important;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent !important;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
}
</style>
