<template>
  <div :class="['notification', type]">
    <div :class="['notitype', type]">
      <icon-font :type="formatNotiIcon(type)" style="padding-right: 5px" />
      <div class="unread" v-if="message.timestamp > lastRead"></div>
    </div>
    <div class="notimessage">
      <h3>{{ formatNotiTitle(type, imei, message) }}</h3>
      <!-- <p>ID {{ id }}</p> -->
      <p>{{ formatNotiMessage(type, message) }}</p>
      <!-- <p>{{ message.timestamp }}</p> -->
      <!-- <p>{{ lastRead }}</p>  -->
    </div>
  </div>
</template>
<script>
import { IconFont } from "@/config/config";
import { formatMessage, formatTitle, formatIcon } from "@/lib/notification";
import { mapState } from "vuex";
export default {
  components: {
    IconFont
  },
  props: {
    imei: String,
    type: String,
    message: Object,
    arrive: Number,
    id: Number
  },
  computed: mapState({
    lastRead: state => state.fcm.lastRead
  }),
  methods: {
    formatNotiMessage(type, msg) {
      return formatMessage(type, msg);
    },
    formatNotiTitle(type, imei, msg) {
      return formatTitle(type, imei, msg);
    },
    formatNotiIcon(type) {
      return formatIcon(type);
    }
  },
  data() {
    return {};
  },
  destroy() {
    console.log("destroy");
    this.$destroy();
  },
  updated() {
    // console.log("updated");
  }
};
</script>
<style lang="scss" scoped>
.notification {
  &.OVERSPEED,
  &.THAI_MCR_SWIPE_IN,
  &.THAI_MCR_SWIPE_OUT,
  &.GEOFENCE_ENTER,
  &.GEOFENCE_EXIT {
    padding: 7px 20px;
    background: #eceafa;
    border-radius: 3px;
    margin: 0 0 15px 0;
    width: 400px;
  }

  h3 {
    color: #513fe8;
    font-size: 1.1rem;
    font-weight: 300;
    margin-bottom: 0 !important;
  }
  .notitype {
    display: inline-block;
    width: 80px;
    color: #513fe8;
    font-size: 2.2rem;
    position: relative;
  }
  .notimessage {
    display: inline-block;
    width: 280px;
    margin-bottom: 0 !important;
  }
  .unread {
    border-radius: 10px;
    width: 10px;
    height: 10px;
    background-color: red;
    display: block;
    position: absolute;
    top: 0px;
    left: 32px;
  }
}
</style>
