import axios from "axios";
import endpoints from "../config/config";

const AxiosService = axios.create({
    baseURL: endpoints.coreApi
})

AxiosService.interceptors.request.use(
    (config) => {
        if (localStorage.getItem('api-token')) {
            config.headers['Authorization'] = localStorage.getItem('api-token')
        }        
        return config
    },

    (error) => {
        return Promise.reject(error)
    }
)

export default AxiosService;