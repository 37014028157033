// import dailyReport from "./dailyReport";
import tripReport from "./tripReport";
import overSpeedReport from "./overSpeedReport";
import positionReport from "./positionReport";
import fuelReport from "./fuelReport";
import fuelChartData from "./fuelChart";
import ptoReport from "./ptoReport";
import idlingReport from "./idlingReport";
import geofenceReport from "./geofenceReport";
import summaryReport from "./summaryReport";
import temperatureReport from "./temperatureReport";
import fuelUsedRateSumReport from "./fuelUsedRateSumReport";
import tripA4Report from "./tripA4Report";
import enterExitGeofenceReport from "./enterExitGeofenceReport";

export default {
  // dailyReport,
  tripReport,
  overSpeedReport,
  positionReport,
  fuelChartData,
  fuelReport,
  ptoReport,
  idlingReport,
  geofenceReport,
  summaryReport,
  temperatureReport,
  fuelUsedRateSumReport,
  tripA4Report,
  enterExitGeofenceReport
};

export const reportTypesEnum = Object.freeze({
  S01GeofenceCurrent: 'geofence_current_report',
  S02FuelRate: 'fuel_rate_report',
  S03FleetSummary: 'fleet_summary_report',
  S04TempWithChart: 'temperature_with_chart',
  S05TempPdf: 'report_temperature_pdf',
  S06AllDevice: 'all_device_report',
  N01Daily: 'daily',
  N02Trip: 'trip',
  N03Trip24hr: 'trip24hr',
  N04TripOver4hour: 'tripOver4hour',
  N05Fuel: 'fuel',
  N06Temperature: 'temperature',
  N07Overspeed: 'overspeed',
  N08Position: 'position',
  N09FuelChart: 'fuelChart',
  N10TempChart: 'tempChart',
  N11Pto: 'pto',
  N12Idling: 'idling',
  N13IdlingSummary: 'idlingSummary',
  N14Geofences: 'geofences',
  N15Summary: 'summary',
  N16FuelUsedRateSummary: 'fuelUsedRateSummary',
  N17IdlingOpenPTO: 'idlingOpenPTO',
  N18TripA4: 'tripA4',
  N19SpeedChart: 'speedChart',
  N20VehicleUsageSummary: 'vehicleUsageSummary',
  N21EnterExitGeofenceReport: 'enterExitGeofenceReport'
})

export const specialReportTypeList = [
  {
    no: 1,
    label: 'ตรวจสอบพาหนะในสถานีทุกคัน',
    value: reportTypesEnum.S01GeofenceCurrent,
  },
  {
    no: 2,
    label: 'รายงานการใช้เชื้อเพลิง',
    value: reportTypesEnum.S02FuelRate,
  },
  {
    no: 3,
    label: 'รายสรุปการใช้งานรถ',
    value: reportTypesEnum.S03FleetSummary,
  },
  {
    no: 4,
    label: 'รายงานอุณหภูมิ + กราฟอุณหภูมิ',
    value: reportTypesEnum.S04TempWithChart,
  },
  {
    no: 5,
    label: 'รายงานอุณหภูมิ (PDF)',
    value: reportTypesEnum.S05TempPdf
  },
  // {
  //   no: 6,
  //   label: 'รายงานพี่ดวง',
  //   value: reportTypesEnum.S06AllDevice
  // },
]

export const normalReportTypeList = [
  { no: 1, label: 'รายงานประจำวัน', value: reportTypesEnum.N01Daily },
  { no: 2, label: 'รายงานการเดินทาง', value: reportTypesEnum.N02Trip },
  // { no: 3, label: 'รายงานการเดินทาง (ตัดเที่ยงคืน)', value: reportTypesEnum.N03Trip24hr },
  // {
  //   no: 4,
  //   label: 'รายงานขับต่อเนื่องเกิน 4 ชั่วโมง',
  //   value: reportTypesEnum.N04TripOver4hour,
  // },
  // { no: 5, label: 'รายงานเชื้อเพลิง', value: reportTypesEnum.N05Fuel },
  { no: 6, label: 'รายงานอุณหภูมิ', value: reportTypesEnum.N06Temperature },
  // { no: 7, label: 'รายงานความเร็ว', value: reportTypesEnum.N07overspeed },
  { no: 8, label: 'รายงานตำแหน่งพาหนะ', value: reportTypesEnum.N08Position },
  // { no: 9, label: 'กราฟเชื้อเพลิง', value: reportTypesEnum.N09FuelChart },
  // { no: 10, label: 'กราฟอุณหภูมิ', value: reportTypesEnum.N10TempChart },
  // { no: 11, label: 'รายงาน PTO', value: reportTypesEnum.N11Pto },
  // { no: 12, label: 'รายงาน Idling', value: reportTypesEnum.N12Idling },
  // { no: 13, label: 'รายงาน Idling+PTO Summary', value: reportTypesEnum.N13IdlingSummary },
  // { no: 14, label: 'รายงานสถานีถึงสถานี', value: reportTypesEnum.N14Geofences },
  // { no: 15, label: 'รายงานสรุป', value: reportTypesEnum.N15Summary },
  // {
  //   no: 16,
  //   label: 'รายงานสรุปพร้อมอัตราสิ้นเปลือง',
  //   value: reportTypesEnum.N16FuelUsedRateSummary,
  // },
  // {
  //   no: 17,
  //   label: 'รายงานจอดรถไม่ดับเครื่องยนต์และ PTO',
  //   value: reportTypesEnum.N17IdlingOpenPTO,
  // },
  // { no: 18, label: 'รายงานเดินทาง A4', value: reportTypesEnum.N18TripA4 },
  // { no: 19, label: 'กราฟความเร็ว', value: reportTypesEnum.N19SpeedChart },
  {
    no: 20,
    label: 'รายงานสรุปการใช้งานในภาพรวม',
    value: reportTypesEnum.N20VehicleUsageSummary,
  },
  // { no: 21, label: 'รายงานเข้า-ออกสถานี', value: reportTypesEnum.N21EnterExitGeofenceReport },
]