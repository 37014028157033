<template>
  <div>
    <div class="title">
      ข้อมูลเส้นทาง
      <a-icon
        v-if="positionList.length > 0"
        type="form"
        @click="() => this.$emit('onClickEditIcon')"
      />
    </div>
    <div class="header">
      <div class="name-container">
        <div class="name-container-title">เส้นทาง</div>
        <div class="name-container-name">
          {{ name }}
        </div>
      </div>
    </div>
    <a-timeline class="content">
      <a-timeline-item
        v-for="(position, index) in displayedPositionList"
        :color="position.type === 'info' ? '#555' : 'green'"
        :key="`${position.name}-${index}`"
        style="height: 80px; padding: 0"
      >
        <a-icon
          v-if="position.type === 'info'"
          slot="dot"
          type="question-circle"
          style="font-size: 14px"
        />
        <icon-font
          v-else
          slot="dot"
          :type="getPositionIcon(position.type)"
          style="font-size: 20px"
        />

        <div v-if="Object.keys(position).length > 0">
          <div class="item-info-container">
            <div class="item-info-name">
              {{ position.name }}
            </div>
          </div>
        </div>
      </a-timeline-item>
    </a-timeline>
    <div class="footer">
      <div>
        <div class="summary">
          รวมระยะทาง {{ sumDistance }} กม. รวมเวลาเดินทาง
          {{ sumDuration }}
          นาที
        </div>
        <a-button
          type="primary"
          class="share-link-btn"
          @click="() => (isDisplayedModal = true)"
        >
          <a-icon type="share-alt" />
          แชร์
        </a-button>
      </div>
    </div>
    <a-modal
      :visible="isDisplayedModal"
      @cancel="() => (isDisplayedModal = false, posShareTabActivated = true, routeShareTabActivated = false)"
      :mask="true"
      :closable="true"
      :footer="false"
      centered
      :width="360"
    >
      <div class="share-modal">
        <!-- Menu tabs -->
        <div class="navigation-container">
          <div 
            @click="handleTabClick('postions_share')"
            :class="['modal-title','navigation-button', posShareTabActivated && 'active']"
          >แชร์ตำแหน่ง</div>
          <div 
            @click="handleTabClick('routes_share')"
            :class="['modal-title','navigation-button', routeShareTabActivated && 'active']"
          >แชร์เส้นทาง</div>
        </div>

        <div 
          v-if="posShareTabActivated"
          style="width: 100%;"
        >
          <div :class="positionList.length > 4 ? 'modal-routes-container' : 'modal-routes-container-fixed'">
            <div 
              v-for="(item, idx) in positionList"
              :key="idx"
              class="modal-route-item-layout"
            >
              <div class="route-item-input">
                {{ item.name }}
              </div>
              <a-button
                class="route-item-btn"
                @click="shareSinglePosUrl(item, true)"
              >
                <img src="@/assets/icons/copy-icon.svg" alt="">
              </a-button>
              <a-button
                class="route-item-btn"
                @click="shareSinglePosUrl(item, false)"
              >
                <img src="@/assets/icons/gmap_pin.svg" alt="gmap-pin">
              </a-button>
            </div>
          </div>
          <a-button
            @click="shareAllPosUrl(true)"
            class="modal-share_all-btn"
          >
            <img src="@/assets/icons/copy-icon.svg" alt="">
            <span>คัดลอกลิงก์ทั้งหมด</span>
          </a-button>
        </div>

        <div 
          v-if="routeShareTabActivated"
          class="modal-btn-container"
        >
          <a-button
            class="modal-btn"
            :size="'large'"
            @click="shareLinkUrl(false)"
          >
            <img src="@/assets/icons/gmap_pin.svg" alt="gmap-pin"><span style="margin-left: 8px;">Google Map</span>
          </a-button>
          <a-button
            class="modal-btn"
            icon="copy"
            :size="'large'"
            @click="shareLinkUrl(true)"
          >
            คัดลอกลิงก์
          </a-button>
          <a-button
            v-if="canShareNative()"
            class="modal-btn"
            icon="share-alt"
            :size="'large'"
            @click="otherShare()"
          >
            แชร์ไปยัง
          </a-button>
        </div> 
      </div>
    </a-modal>
  </div>
</template>
<script>
import { IconFont } from '@/config/config';
export default {
  name: 'routeInfo',
  props: {
    route: Object,
    googleMap: Object,
    directionsDisplay: Object,
    calculateRouteDirection: Function,
    calculateInfoRouteList: Function,
  },
  components: {
    IconFont,
  },
  data() {
    return {
      displayedPositionList: [{}],
      name: '',
      positionList: [],
      sumDistance: 0,
      sumDuration: 0,
      isDisplayedModal: false,
      posShareTabActivated: true,
      routeShareTabActivated: false,
    };
  },
  async mounted() {
    const response = await this.calculateRouteDirection(
      this.route.positionList,
      false
    );
    if (response) {
      const directionList = response.routes[0].legs;
      if (directionList.length > 0) {
        let sumDis = 0;
        let sumDur = 0;

        directionList.forEach((leg) => {
          sumDis += parseFloat(leg.distance.value);
          sumDur += parseFloat(leg.duration.value);
        });

        this.sumDistance = (sumDis / 1000).toFixed(2);
        this.sumDuration = Math.ceil(sumDur / 60);
      }
      this.directionsDisplay.setMap(this.googleMap.$mapObject);
      this.directionsDisplay.setDirections(response);

      this.displayedPositionList = this.calculateInfoRouteList(
        response.routes[0],
        this.route.positionList
      );
      this.name = this.route.name;
      this.positionList = this.route.positionList;
    }
  },
  methods: {
    canShareNative() {
      return navigator.share ? true : false;
    },
    otherShare() {
      const key = 'updatable';
      let url = 'https://www.google.com/maps/dir/';
      if (this.positionList.length > 0) {
        for (let i = 0; i < this.positionList.length; i++) {
          url =
            url +
            this.positionList[i].lat +
            ',' +
            this.positionList[i].lng +
            '/';
        }
        if (navigator.share) {
          navigator
            .share({
              title: 'แชร์ตำแหน่งรถไปยัง',
              url: url,
            })
            .then(() => {})
            .catch(console.error);
        } else {
          this.visibleShareModal = true;
        }
      } else {
        this.$message.warning({
          content: 'ไม่พบข้่อมูล',
          key,
          duration: 3,
        });
      }
    },

    shareLinkUrl(clipboard) {
      const key = 'updatable';
      let url = 'https://www.google.com/maps/dir/';

      if (this.positionList.length > 0) {
        for (let i = 0; i < this.positionList.length; i++) {
          url =
            url +
            this.positionList[i].lat +
            ',' +
            this.positionList[i].lng +
            '/';
        }

        if (clipboard === false) {
          window.open(url, '_blank');
        } else {
          this.clipboardNavigator(key, url)
        }
      } else {
        this.$message.warning({
          content: 'ไม่พบข้่อมูล',
          key,
          duration: 3,
        });
      }
    },

    shareSinglePosUrl(data, status) {
      const key = 'updateable';
      const url = `https://www.google.com/maps/search/?api=1&query=${data.lat},${data.lng}`;

      if (this.positionList.length > 0) {
        if (status === false) {
          window.open(url, '_blank');
        } else {
          this.clipboardNavigator(key, url)
        }
      } else {
        this.$message.warning({
          content: 'ไม่พบข้่อมูล',
          key,
          duration: 3,
        });
      }
    },

    shareAllPosUrl(clipboard) {
      const key = 'updateable';
      let allUrlLinks = [];
      
      if (this.positionList.length > 0) {
        for (let i = 0; i < this.positionList.length; i++) {
          const url = `https://www.google.com/maps/search/?api=1&query=${this.positionList[i].lat},${this.positionList[i].lng}`;
          allUrlLinks.push(`${i+1}. ${this.positionList[i].name} ${url}\n`);
        }

        if (clipboard) {
          const textToCopy = allUrlLinks.join('');
          this.clipboardNavigator(key, textToCopy);
        }
      } else {
        this.$message.warning({
          content: 'ไม่พบข้่อมูล',
          key,
          duration: 3,
        });
      }
    },

    clipboardNavigator(key, url) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.$message.success({
            content: 'คัดลอกลิงก์สำเร็จ',
            key,
            duration: 3,
          });
        })
        .catch(() => {
          this.$message.warning({
            content: 'เกิดข้อผิดพลาดบางอย่าง กรุณาลองอีกครั้ง',
            key,
            duration: 3,
          });
        });
    },

    handleTabClick(tab_name) {
      this.posShareTabActivated = tab_name === 'postions_share';
      this.routeShareTabActivated = tab_name === 'routes_share';
    },

    getPositionIcon(type) {
      switch (type) {
        case 'google':
          return 'icon-location2';
        case 'search':
          return 'icon-location2';
        case 'vehicle':
          return 'icon-car';
        case 'geofence':
          return 'icon-Geotagplace';
        case 'info':
          return 'question-circle';
        default:
          return 'icon-location2';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.share-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  row-gap: 24px;
  padding: 10px;
  .modal-title {
    font-weight: bold;
    font-size: 16px;
  }

  .modal-routes-container-fixed {
    width: 100%;
    max-height: 153px;
  }

  .modal-routes-container {
    width: 100%;
    overflow-y: scroll;
    max-height: 153px;
    padding-right: 8px;
  }

  .modal-route-item-layout {
      display: flex;
      gap: 8px;
      margin-bottom: 8px;

      .route-item-input {
        position: relative;
        width: 224px;
        height: 32px;
        padding: 4px 16px;
        border-radius: 8px;
        font-size: 14px;
        border: 1px solid #EEF1F4;
        
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .route-item-btn {
        border-radius: 8px;
        padding: 4px 8px;
        width: 36px;
        height: 32px;
      }
  }

  .modal-share_all-btn {
    margin-top: 24px; 
    width: auto; 
    height: 40px; 
    padding: 8px 16px; 
    font-size: 16px;
    border-radius: 8px;
    display: flex;
    gap: 8px;
  }
  .modal-share_all-btn > img {
    vertical-align: middle;
    margin-top: 2px;
  }
  
  .modal-btn-container {
    display: flex;
    align-self: center;
    flex-direction: column;
    gap: 16px;
    margin-top: 14px;
  }
  .modal-btn {
    width: 120px;
    border-radius: 8px;
  }
}
.title {
  margin-bottom: 8px;
  display: flex;
  column-gap: 10px;
  align-items: center;
}

.navigation-container {
  justify-items: flex-start;
  margin-top: 18px;
  font-size: 16px;
  display: inline-block;
  border-bottom: 1px solid #515151;
  width: 100%;
  .navigation-button {
    height: 30px;
    padding: 0 25px 0 0;
    display: inline-block;
    text-align: center;
    color: #b5b5b5;
    cursor: pointer;

    &.active {
      color: #515151;
    }
  }
}

.header {
  .name-container {
    color: #fff;
    background-color: #00c246;
    border-radius: 3px;
    padding: 5px 10px;
    height: 32px;
    .name-container-title {
      display: inline-block;
      white-space: nowrap;
      vertical-align: middle;
      margin-right: 5px;
    }
    .name-container-name {
      display: inline-block;
      white-space: nowrap;
      vertical-align: middle;
      width: 235px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .name-container-action {
      position: absolute;
      right: 10px;
      top: 7px;
      cursor: pointer;
    }
  }
}

.content {
  margin: 16px 0 0 0;
  padding: 16px 15px 0 5px;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 350px);
  overflow: auto;

  .item-info-container {
    padding: 3px 10px;
    .item-info-action {
      display: inline-block;
      margin-left: 16px;
    }
  }

  .item-switch-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .item-switch {
      margin: 0;
      padding: 0;
      border: none;
      background-color: transparent;
      &:hover {
        color: #0e0e0e !important;
      }
      &:disabled {
        pointer-events: none;
      }
    }
  }
}
.footer {
  margin-top: 16px;
  .calculate-trip-options {
    display: flex;
    column-gap: 16px;
  }
  .route-action {
    margin-top: 32px;
    display: flex;
    column-gap: 32px;
  }
  .share-link-btn {
    margin-top: 20px;
    display: flex;
    align-items: center;
    padding: 9px;
  }
}

::-webkit-scrollbar {
  width: 5px !important;
}
::-webkit-scrollbar-track-piece:start {
  background: transparent !important;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent !important;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2) !important;
  border-radius: 5px !important;
}
</style>
