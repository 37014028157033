import AxiosService from '@/plugins/axios';
import endpoints from '../../config/config';

const headers = {
  "Content-Type": "application/json; charset=utf-8",
}

const state = () => ({
  maintenances: [],
  maintenancesEvent: [],
});

const getters = {};
const actions = {
  async getAllMaintenances({ commit }, id) {
    const response = await AxiosService.get(
      `${endpoints.strapiAPI}/maintenances?_limit=-1&customer_group=${id}`
    )
      .then((response) => {
        
        return response.data;
      })
      .catch((error) => {
        console.log('Error', error);
        return [];
      });
    commit('SET_MAINTENANCES', response);
  },

  // Create Maintenance items
  async createMaintenanceItem({ commit }, payload){
    try {
      const response = await AxiosService.post(
        `${endpoints.strapiAPI}/maintenances`,
        payload,
        { headers }
      )
        commit('CREATE_MAINTENANCES_ITEM', response.data);
        return response
    } catch (error) {
      console.log('Creating maintenance item error', error);
      throw error;
    }
  },

  // Update notification status (Enabled / Disabled)
  async updateMaintenanceItemStatus({ commit }, payload) {
    try {
      const response = await AxiosService.put(
        `${endpoints.strapiAPI}/maintenances/${payload[0]}`,
        JSON.stringify({
          status: payload[1]
        }),
        { headers }
      );
      
      commit('UPDATE_MAINTENANCES_STATUS', response.data);
      return response;
    } catch (error) {
      console.log('Updating maintenances status error', error);
      throw error; // Re-throw the error to handle it further up the chain if needed
    }
  },

  // Update maintenance/event data (Notification)
  async updateMaintenanceData({commit},payload) {
    try {
      const response = await AxiosService.put(
        `${endpoints.strapiAPI}/maintenances/${payload[0]}`,
        payload[1],
        { headers }
      );

      commit('UPDATE_MAINTENANCES_ITEM', response.data);
      return response;
    } catch (error){
      console.log('Updating notification data error:', error);
    }
  },

  async deleteEventMaintenanceItem({commit}, delItemData){
    try {
      await AxiosService.delete(`${endpoints.strapiAPI}/maintenances/${delItemData[0]}`, {headers})
      commit('DELETE_MAINTENANCE_EVENT', delItemData[1]);
    } catch (error) {
      console.log('Delete maintenance error', error);
      throw error; // Re-throw the error to handle it further up the chain if needed
    }
  },
};
const mutations = {
  SET_MAINTENANCES(state, payload) {
    state.maintenances = payload;

  },
  CREATE_MAINTENANCES_ITEM(state, payload) {
    state.maintenances.push(payload);
  },

  UPDATE_MAINTENANCES_ITEM(state, payload){
    state.maintenances = state.maintenances.map((item) =>
      item.id === payload.id ? payload : item
    );
  },

  UPDATE_MAINTENANCES_STATUS(state, payload) {
    state.maintenances = state.maintenances.map((item) => 
      item.id === payload.id ? payload : item
    );
  },

  DELETE_MAINTENANCE_EVENT(state, item_id){
    state.maintenances.splice(item_id, 1);
    state.maintenances = state.maintenances.filter((event_item) => event_item.id !== item_id);
  }
};
export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
};
