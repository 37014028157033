import _ from "lodash";
import updateVehicleData from "@/graphql/updateVehicle.gql";
import updateVehicleTemp from "@/graphql/updateVehicleTemp.gql"
import { getGeolocation } from "../../lib/vehicle";
import { apolloClient } from "../../strapi-apollo";

const state = () => ({
  isConnected: false,
  isTracking: true,
  vehiclesData: [],
  vehicleGroups: [],
  selectedVehicle: {},
  filteredEngineStatus: [],
  showVehicleInfo: false,
  ptoChecked: false,
  updatedTempSettingVehicle: [],

});

// getters
const getters = {
  vehicles: (state) => {
    let allV = state.vehicleGroups.map((group) => {
      return group.vehicles.filter((vehicle) => {
        return vehicle.device && vehicle.device.imei;
      });
    });

    let flat = [];
    if (allV.length > 0) {
      flat = allV.reduce((a, b) => {
        return a.concat(b);
      }, []);
    }
    // console.log ('getters vehicles',flat);
    flat = _.uniqBy(flat, "id");
    return flat;
  },
  vehicleByID: (state) => (id) => {
    let allV = state.vehicleGroups.map((group) => {
      return group.vehicles;
    });
    if (allV.length > 0) {
      let flat = [];
      flat = allV.reduce((a, b) => {
        return a.concat(b);
      }, []);

      //  console.log('all V', allV);
      //  console.log('find result', allV.find(
      //    vehicle => vehicle.id == state.selectedVehicle.id
      //  ))
      return flat.find((vehicle) => vehicle.id == id);
    } else return {};
  },
  vehicleByIMEI: (state) => (imei) => {
    let allV = state.vehicleGroups.map((group) => {
      return group.vehicles;
    });
    if (allV.length > 0) {
      let flat = [];
      flat = allV.reduce((a, b) => {
        return a.concat(b);
      }, []);

      //  console.log('all V', allV);
      //  console.log('find result', allV.find(
      //    vehicle => vehicle.id == state.selectedVehicle.id
      //  ))
      return flat.find(
        (vehicle) => vehicle.device && vehicle.device.imei == imei
      );
    } else return {};
  },
  getSelectedVehicle: (state) => {
    let allV = state.vehicleGroups.map((group) => {
      return group.vehicles;
    });
    if (allV.length > 0) {
      let flat = [];
      if (allV.length > 0) {
        flat = allV.reduce((a, b) => {
          return a.concat(b);
        }, []);
      }
      // console.log('all V', allV);
      //  console.log('find result', allV.find(
      //    vehicle => vehicle.id == state.selectedVehicle.id
      //  ))
      return flat.find((vehicle) => vehicle.id == state.selectedVehicle.id);
    } else return {};
  },
  getFilteredEngineStatus: (state) => {
    return state.filteredEngineStatus;
  },
  devicesArray: (state) => {
    return state.vehicleGroups.map((group) => {
      return group.vehicles
        .filter((vehicle) => {
          return vehicle.device && vehicle.device.imei;
        })
        .map((vehicle) => vehicle.device.imei);
    })[0];
    // return allV;
  },
};

// actions
const actions = {
  updateVehicle({ commit }, { groups, groupID }) {
    if (groups && groups.length > 0) {
      const vehicles = _.find(groups, {
        id: groupID,
      }).vehicle_groups;
      // console.log('updateVehicle->',vehicles);
      // const vehicles = payload.data.user.customer_groups.map(customerGroup => {
      //   return [
      //     _.find(songs, {id:id})
      //     ...vehicleGroup.vehicles.filter(vehicle => {
      //       return vehicle.device && vehicle.device.imei;
      //     })

      //   ];
      // });

      // commit("ADD_VEHICLES", vehicles[0]);
      //  console.log('vehicles', vehicles)
      commit("ADD_VEHICLES", vehicles);
    } else {
      // console.log('czarft clear');
      commit("ADD_VEHICLES", []);
    }
  },
  selectVehicle({ commit }, payload) {
    commit("SELECT_VEHICLE", payload);
    if (
      payload !== {} &&
      JSON.parse(JSON.stringify(payload)) &&
      JSON.parse(JSON.stringify(payload)).id
    ) {
      getGeolocation(payload.id);
    }
  },
  setFilteredEngineStatus({ commit }, payload) {
    commit("FILTER_ENGINE_STATUS", payload);
  },
  updateVehiclePosition({ commit, state }, payload) {
    //  var vehicleStateCopy = state.vehicleGroups.slice();
    //  vehicleStateCopy.forEach(vehicle => {
    //    vehicle.device.position = payload[vehicle.device.imei];

    //    //update selectedVehicle info
    //    if (vehicle.id == state.selectedVehicle.id) {
    //      commit("SELECT_VEHICLE", vehicle);
    //    }
    //  });
    //  commit("ADD_VEHICLES", vehicleStateCopy);
    //  console.log('payload', payload)
    let vehicleGroupStateCopy = state.vehicleGroups.slice();

    vehicleGroupStateCopy.forEach((vehicleGroup) => {
      vehicleGroup.vehicles.forEach((vehicle) => {
        if (vehicle) {
          // console.log('vehicle', vehicle)
          //  console.log(payload[vehicle.device.imei]);
          if (
            vehicle.device &&
            vehicle.device.imei &&
            vehicle.device.imei in payload
          ) {
            vehicle.device.position = payload[vehicle.device.imei];
            if ("lastLocation" in payload[vehicle.device.imei]) {
              vehicle.lastLocation = payload[vehicle.device.imei].lastLocation;
            }
            if ("lastFuelLitre" in payload[vehicle.device.imei]) {
              vehicle.lastFuelLitre =
                payload[vehicle.device.imei].lastFuelLitre;
            }
          }
          // console.log('Payload',payload)
          // console.log('before',vehicle.last_position_json.startEventTime)

          // console.log('StartEventTime',vehicle.last_position_json.startEventTime)
          if (
            vehicle.device &&
            vehicle.device.imei &&
            vehicle.device.imei in payload &&
            vehicle.last_position_json &&
            "startEventTime" in vehicle.last_position_json &&
            payload[vehicle.device.imei]
          ) {
            // console.log('StartEventTime',vehicle.last_position_json.startEventTime)
            vehicle.last_position_json.startEventTime =
              payload[vehicle.device.imei].startEventTime;
          }
          if (vehicle.id === state.selectedVehicle.id)
            commit("SELECT_VEHICLE", vehicle);
        }
      });
    });
    commit("ADD_VEHICLES", vehicleGroupStateCopy);
  },

  showVehicleBottomInfo({ commit }, payload) {
    commit("SHOW_VEHICLE_INFO", payload);
  },
  updateVehicleLocation({ commit, state }, payload) {
    let vehicleGroupStateCopy = state.vehicleGroups.slice();

    vehicleGroupStateCopy.forEach((vehicleGroup) => {
      vehicleGroup.vehicles.forEach((vehicle) => {
        if (vehicle && vehicle.id === payload.data.vehicle.id) {
          vehicle.lastLocation = payload.data.vehicle.lastLocation;
          //  console.log('update vehicle location for ', vehicle)
        }
      });
    });
    //  console.log('update all vehicles', vehicleGroupStateCopy)
    commit("ADD_VEHICLES", vehicleGroupStateCopy);
  },
  setTracking({ commit }, payload) {
    commit("SET_TRACKING", payload)
  },
  resetState({ commit }) {
    commit("RESET_STATE");
  },
  async updateVehicleData(
    { commit },
    {
      id,
      name,
      plate_number,
      overspeed_limit,
      sensor1_temp_min,
      sensor1_temp_max,
      sensor2_temp_min,
      sensor2_temp_max,
      temp_notify_enabled,
      vehicle_icon
    }
  ) {
    await apolloClient.resetStore();
    const result = await apolloClient
      .mutate({
        mutation: updateVehicleData,
        variables: {
          input: {
            where: {
              id: id,
            },
            data: {
              name: name,
              plate_number: plate_number,
              overspeed_limit: overspeed_limit,
              sensor1_temp_min: sensor1_temp_min,
              sensor1_temp_max: sensor1_temp_max,
              sensor2_temp_min: sensor2_temp_min,
              sensor2_temp_max: sensor2_temp_max,
              temp_notify_enabled: temp_notify_enabled,
              vehicle_icon: vehicle_icon
            },
          },
        },
      })
      .then(async (eventData) => {
        // console.log(eventData.data)
        commit("DO_NOTHING");
        return eventData;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
    return result;
  },

  async updateVehicleTempSetting({ commit }, payload) {
    await apolloClient.resetStore();
    const result = await apolloClient
      .mutate({
        mutation: updateVehicleTemp,
        variables: {
          input: {
            where: {
              id: payload.vehicle,
            },
            data: {
              // temp1_enabled: payload?.temp1_enabled,
              // temp2_enabled: payload?.temp2_enabled,
              sensor1_temp_min: payload.sensor1_temp_min,
              sensor1_temp_max: payload.sensor1_temp_max,
              sensor2_temp_min: payload.sensor2_temp_min,
              sensor2_temp_max: payload.sensor2_temp_max,
              temp_notify_enabled: payload.temp_notify_enabled,
            },
          },
        },
      })
      .then(async (updatedData) => {
        commit("UPDATE_VEHICLE_TEMP_SETTING", result);
        return updatedData;
      }).catch((err) => {
        console.log("Updating vehicle temp has an error", err);
        return false
      });
    return result;
  },
};
// mutations
const mutations = {
  ADD_VEHICLES(state, payload) {
    state.vehicleGroups = payload;
  },

  SELECT_VEHICLE(state, payload) {
    state.selectedVehicle = payload;
  },
  FILTER_ENGINE_STATUS(state, payload) {
    state.filteredEngineStatus = payload;
  },
  SHOW_VEHICLE_INFO(state, payload) {
    state.showVehicleInfo = payload;
  },
  DO_NOTHING() { },
  RESET_STATE(state) {
    state.selectedVehicle = {};
    state.showVehicleInfo = false;
  },
  PTO_CHECKED(state, payload) {
    state.ptoChecked = payload;
  },
  SET_TRACKING(state, payload) {
    state.isTracking = payload
  },
  UPDATE_VEHICLE_TEMP_SETTING(state, payload) {
    state.updatedTempSettingVehicle = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
