<template>
  <a-select
    showSearch
    placeholder="เลือกพาหนะ"
    optionFilterProp="children"
    style="width: 280px;border-radius: 30px;"
    @change="handleChange"
    :filterOption="filterOption"
  >
    <a-select-option
      v-for="(vehicle, index) in vehicles"
      :key="`vehicle-${vehicle.id}-${index}`"
      :value="vehicle.id"
      >{{ vehicle.plate_number }} - {{ vehicle.name }}</a-select-option
    >
  </a-select>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
  components: {},
  props: {
    clickVehicle: { type: Function },
  },
  data() {
    return {
      dataSource: [],
    };
  },
  computed: mapState({
    trips: (state) => state.trip.trips,
    selectedTrip: (state) => state.vehicle.selectedVehicle,
    selectedVehicleTrip: (state) => state.trip.selectedVehicleTrip,
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      vehicleByID: "vehicle/vehicleByID",
      vehicles: "vehicle/vehicles",
    }),
  }),
  methods: {
    ...mapActions({
      selectVehicleTrip: "trip/selectVehicleTrip",
    }),
    async handleChange(value) {
      await this.selectVehicleTrip(this.vehicleByID(value));

      // console.log(`selected`, this.selectedVehicleTrip);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style lang="scss">
.select-vehicle-input {
  width: 100%;
}
.device-drawer {
  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }
  margin-left: 80px !important;
  margin-top: 50px;
}
</style>
