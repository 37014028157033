import { apolloClient } from '@/vue-apollo';
import eventsTypeByIMEI from '@/graphql/eventsTypeByIMEI.gql';
import endpoints from '../../config/config';
import moment from '@/lib/time';
import AxiosService from '@/plugins/axios';
import Excel from 'exceljs';
import {
  fontNormal,
  sheetMargin,
  getStatusColor,
  hightlightTableHead,
  blobToFile,
  imeiToPlateNumber,
  formatDate,
  formatDuration,
  hightlightRow,
  geofenceStartEndFromLocation,
  imeiToVehicleName,
  getMCRDriverName,
  generateLogoHeader,
  addLogoCompany,
} from './config';
import * as Sentry from '@sentry/vue';
import store from '@/store';

export const getIdlingReport = async (
  eventTypes,
  vehicles,
  imeis,
  start,
  stop,
  duration,
  geofences,
  dictOfDriver,
  reportType,
  logo
) => {
  let dataResult = null;
  if (reportType === 'idlingOpenPTO') {
    let eventData = await AxiosService.post(
      `${endpoints.coreApi}/report-event`,
      {
        imeis: imeis,
        start: start,
        stop: stop,
        eventType: 'ENGINE_IDLE_END',
        pto: true,
      }
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        store.dispatch('loading/setReportLoading', false);
        return;
      });
    dataResult = eventData.data;
  } else {
    await apolloClient.resetStore();
    let eventData = await apolloClient.query({
      // Query
      query: eventsTypeByIMEI,
      variables: {
        eventTypes,
        imeis,
        start,
        stop,
      },
    });
    // console.log('Before', eventData);
    dataResult = eventData.data.eventsTypeByIMEI;
  }

  const reportByVehicle = splitIdlingReportByVehicle(
    vehicles,
    dataResult,
    duration,
    geofences,
    dictOfDriver,
    reportType
  );

  const strTimerange =
    moment.unix(start).format('D_MMM_YY') +
    '-ถึง-' +
    moment.unix(stop).format('D_MMM_YY');
  let fileName = '';
  if (reportType == 'idlingSummary') {
    const summaryData = await AxiosService.post(
      `${endpoints.coreApi}/report-summary-event`,
      {
        eventType: 'END_TRIP',
        imeis: imeis,
        start: start,
        stop: stop,
      }
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
        Sentry.captureException(err);
        store.dispatch('loading/setReportLoading', false);
        return;
      });
    //separate pto, idling    
    let reportByVehicleForSummary = {};
    for (const imei in reportByVehicle) {
      reportByVehicleForSummary[imei] = {};
      let lastAccSec = 0;
      reportByVehicleForSummary[imei]['IDLE'] = [];
      reportByVehicleForSummary[imei]['IDLE'] = reportByVehicle[imei]
        .filter((data) => data[4] === 'IDLE')
        .map(function (data) {
          lastAccSec = lastAccSec + (data[11] - data[10]);
          data[12] = lastAccSec;
          data.splice(4, 1);
          data.splice(5, 0, '');
          return data;
        });
      lastAccSec = 0;
      reportByVehicleForSummary[imei]['PTO'] = [];
      reportByVehicleForSummary[imei]['PTO'] = reportByVehicle[imei]
        .filter((data) => data[4] === 'PTO')
        .map(function (data) {
          lastAccSec = lastAccSec + (data[11] - data[10]);
          data[12] = lastAccSec;
          data.splice(4, 1);
          data.splice(6, 0, '');
          return data;
        });

      for (const idx1 in reportByVehicleForSummary[imei]['IDLE']) {
        const fromTs = reportByVehicleForSummary[imei]['IDLE'][idx1][10];
        const toTs = reportByVehicleForSummary[imei]['IDLE'][idx1][11];

        for (const idx2 in reportByVehicleForSummary[imei]['PTO']) {
          if (
            fromTs <= reportByVehicleForSummary[imei]['PTO'][idx2][10] &&
            toTs >= reportByVehicleForSummary[imei]['PTO'][idx2][11]
          ) {
            // increment PTO count
            reportByVehicleForSummary[imei]['IDLE'][idx1][4] =
              reportByVehicleForSummary[imei]['IDLE'][idx1][4] === '' ? 0 : '';
            reportByVehicleForSummary[imei]['IDLE'][idx1][5] =
              reportByVehicleForSummary[imei]['IDLE'][idx1][5] === '' ? 0 : '';

            reportByVehicleForSummary[imei]['IDLE'][idx1][4] = +1;
            reportByVehicleForSummary[imei]['IDLE'][idx1][5] =
              +reportByVehicleForSummary[imei]['PTO'][idx2][11] -
              reportByVehicleForSummary[imei]['PTO'][idx2][10];
          }
        }
      }
      const lenOfIdle = reportByVehicleForSummary[imei]['IDLE'].length;
      if (lenOfIdle > 0) {
        let totalPTOCount = 0;
        let sumDurationIdling =
          reportByVehicleForSummary[imei]['IDLE'][lenOfIdle - 1][12];
        let sumDurationPTO = 0;
        for (const idx1 in reportByVehicleForSummary[imei]['IDLE']) {
          if (reportByVehicleForSummary[imei]['IDLE'][idx1][4] !== '') {
            totalPTOCount =
              parseInt(reportByVehicleForSummary[imei]['IDLE'][idx1][4]) +
              totalPTOCount;
          }
          if (reportByVehicleForSummary[imei]['IDLE'][idx1][5] !== '') {
            sumDurationPTO =
              Number(reportByVehicleForSummary[imei]['IDLE'][idx1][5]) +
              sumDurationPTO;
            reportByVehicleForSummary[imei]['IDLE'][idx1][5] = formatDuration(
              0,
              reportByVehicleForSummary[imei]['IDLE'][idx1][5]
            );
          }
        }
        const sumDurationPTOTxt = formatDuration(0, sumDurationPTO);
        const sumDurationIdlingTxt = formatDuration(0, sumDurationIdling);
        reportByVehicleForSummary[imei]['IDLE'].push([
          '',
          '',
          '',
          '',
          totalPTOCount,
          sumDurationPTOTxt,
          sumDurationIdlingTxt,
          '',
          '',
          '',
          '',
          '',
          '',
        ]);
      }
    }

    fileName = 'IdlingPTO_Report_Summary_' + strTimerange;
    const tableHeadIdling = [
      'ทะเบียนรถ',
      'ชื่อ',
      'วัน เวลา เริ่มต้น',
      'วัน เวลา สิ้นสุด',
      'เปิด PTO(ครั้ง)',
      'รวมเวลา PTO',
      'รวมเวลา จอดรถติดเครื่อง',
      'ตำแหน่ง',
      'ละติจูด ลองจิจูด',
      'ผู้ใช้รถ',
      'timestamp เริ่มต้น',
      'timestamp สิ้นสุด',
    ];
    const tableHeadPTO = [
      'ทะเบียนรถ',
      'ชื่อ',
      'วัน เวลา เริ่มต้น',
      'วัน เวลา สิ้นสุด',
      'PTO ที่',
      'รวมเวลา',
      '-',
      'ตำแหน่ง',
      'ละติจูด ลองจิจูด',
      'ผู้ใช้รถ',
      'timestamp เริ่มต้น',
      'timestamp สิ้นสุด',
    ];
    const columnsSpec = [
      {
        key: 'plate_number',
        width: 10,
        style: {
          font: fontNormal,
        },
      },
      {
        key: 'name',
        width: 28,
        style: {
          numFmt: '0',
        },
      },
      {
        key: 'start',
        width: 20,
        alignment: {
          vertical: 'middle',
          horizontal: 'right',
        },
      },
      {
        key: 'stop',
        width: 20,
        alignment: {
          vertical: 'middle',
          horizontal: 'right',
        },
      },
      {
        key: 'pto',
        width: 20,
      },
      {
        key: 'totalTimePTO',
        width: 20,
      },
      {
        key: 'totalTime',
        width: 20,
      },
      {
        key: 'reversegeolocationend',
        width: 40,
      },
      {
        key: 'geolocation',
        width: 30,
      },
      {
        key: 'driver',
        width: 15,
      },
      {
        key: 'timeStampStart',
        width: 15,
      },
      {
        key: 'timeStampStop',
        width: 15,
      },
    ];
    genIdlingSummaryExcel({
      fileName,
      reportByVehicleForSummary,
      tableHeadIdling,
      tableHeadPTO,
      columnsSpec,
      statusColumn: null,
      summaryData: summaryData.data,
      start,
      stop,
      logo,
    });
  } else if (reportType == 'idlingOpenPTO') {
    fileName = 'Idling_Open_PTO_Report_' + strTimerange;
    const tableHead = [
      'ทะเบียนรถ',
      'ชื่อ',
      'วัน เวลา เริ่มต้น',
      'วัน เวลา สิ้นสุด',
      'รวมเวลา',
      'ตำแหน่ง',
      'ละติจูด ลองจิจูด',
      'PTO1',
      'PTO2',
      'PTO3',
      'ผู้ใช้รถ',
      'timestamp เริ่มต้น',
      'timestamp สิ้นสุด',
    ];
    const columnsSpec = [
      {
        key: 'plate_number',
        width: 10,
        style: {
          font: fontNormal,
        },
      },
      {
        key: 'name',
        width: 22,
        style: {
          numFmt: '0',
        },
      },
      {
        key: 'start',
        width: 20,
      },
      {
        key: 'stop',
        width: 20,
      },
      {
        key: 'totalTime',
        width: 8,
      },
      {
        key: 'reversegeolocationend',
        width: 40,
      },
      {
        key: 'geolocation',
        width: 30,
      },
      {
        key: 'PTO1',
        width: 15,
      },
      {
        key: 'PTO2',
        width: 15,
      },
      {
        key: 'PTO3',
        width: 15,
      },
      {
        key: 'driver',
        width: 15,
      },
      {
        key: 'timeStampStart',
        width: 15,
      },
      {
        key: 'timeStampStop',
        width: 15,
      },
    ];
    
    genIdlingOpenPTOExcel({
      fileName,
      reportByVehicle,
      tableHead,
      columnsSpec,
      statusColumn: 8,
      logo,
    });
  } else {
    fileName = 'Idling_Report_' + strTimerange;
    const tableHead = [
      'ทะเบียนรถ',
      'ชื่อ',
      'วัน เวลา เริ่มต้น',
      'วัน เวลา สิ้นสุด',
      'รวมเวลา',
      'ตำแหน่ง',
      'ละติจูด ลองจิจูด',
      'ผู้ใช้รถ',
      'timestamp เริ่มต้น',
      'timestamp สิ้นสุด',
    ];
    const columnsSpec = [
      {
        key: 'plate_number',
        width: 10,
        style: {
          font: fontNormal,
        },
      },
      {
        key: 'name',
        width: 22,
        style: {
          numFmt: '0',
        },
      },
      {
        key: 'start',
        width: 20,
      },
      {
        key: 'stop',
        width: 20,
      },
      {
        key: 'totalTime',
        width: 8,
      },
      {
        key: 'reversegeolocationend',
        width: 40,
      },
      {
        key: 'geolocation',
        width: 30,
      },
      {
        key: 'driver',
        width: 15,
      },
      {
        key: 'timeStampStart',
        width: 15,
      },
      {
        key: 'timeStampStop',
        width: 15,
      },
    ];
    genIdlingExcel({
      fileName,
      reportByVehicle,
      tableHead,
      columnsSpec,
      statusColumn: null,
      logo,
    });
  }
};

export const genIdlingExcel = async ({
  fileName,
  reportByVehicle,
  tableHead,
  columnsSpec,
  statusColumn,
  logo,
}) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: 'FF41C173',
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
    },
  };

  let imageLogoId;

  if (logo && logo.url) {
    const ext = logo.ext.substring(1);
    const myBase64Image = await generateLogoHeader(logo);
    imageLogoId = workbook.addImage({
      base64: myBase64Image,
      extension: ext,
    });
  }

  if (Object.keys(reportByVehicle).length > 0)
    Object.keys(reportByVehicle).forEach((imei) => {
      if (reportByVehicle[imei].length > 0) {
        let startingRow = 1;
        const plateNo = reportByVehicle[imei][0][0];
        const sheet = workbook.addWorksheet(plateNo, pageProperties);
        sheet.pageSetup.margins = sheetMargin;
        sheet.columns = columnsSpec;
        const totalTimeSec =
          reportByVehicle[imei][reportByVehicle[imei].length - 1][10];
        const totalTimeStr = moment('1970-01-01')
          .startOf('day')
          .seconds(totalTimeSec)
          .format('HH:mm:ss');
        const metaRow1 = `รายงานจอดรถติดเครื่อง ของ รถทะเบียน ${plateNo} เวลาเริ่ม ${
          reportByVehicle[imei][0][2]
        } ถึง ${reportByVehicle[imei][reportByVehicle[imei].length - 1][3]}`;
        const metaRow2 = `รวมเวลา ${totalTimeStr} ชม.:นาที:วินาที`;

        if (logo && logo.url) {
          startingRow = 2;
          addLogoCompany(sheet, imageLogoId);
        }

        sheet.addRow([metaRow1]);
        sheet.addRow([metaRow2]);

        sheet.addRow([]);

        sheet.addRow(tableHead);
        sheet.addRows(reportByVehicle[imei]);
        if (statusColumn) {
          sheet.getColumn(statusColumn).eachCell((cell) => {
            cell.font = {
              color: {
                argb: 'FFF5223E',
              },
            };
          });
        }
        hightlightTableHead(sheet, startingRow + 3);
        hightlightRow(sheet, startingRow);
        hightlightRow(sheet, startingRow + 1);
      }
    });
  else {
    workbook.addWorksheet('ไม่พบข้อมูล', pageProperties);
  }

  blobToFile(workbook, fileName);
};

export const genIdlingSummaryExcel = async ({
  fileName,
  reportByVehicleForSummary,
  tableHeadIdling,
  tableHeadPTO,
  columnsSpec,
  statusColumn,
  summaryData,
  start,
  stop,
  logo,
}) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: 'FF41C173',
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
    },
    headerFooter: {
      differentFirst: false,
      firstFooter:
        'โดย บริษัทเฮลลิออท จำกัด https://www.heliot.co.th อีเมล์ contact@heliot.co.th',
    },
  };

  let imageLogoId;

  if (logo && logo.url) {
    const ext = logo.ext.substring(1);
    const myBase64Image = await generateLogoHeader(logo);
    imageLogoId = workbook.addImage({
      base64: myBase64Image,
      extension: ext,
    });
  }

  if (Object.keys(reportByVehicleForSummary).length > 0)
    Object.keys(reportByVehicleForSummary).forEach((imei) => {
      if (
        reportByVehicleForSummary[imei]['PTO'].length > 0 ||
        reportByVehicleForSummary[imei]['IDLE'].length > 0
      ) {
        let startingRow = 1;
        let cntRow = 1;
        let lastRow = 0;

        const plateNo =
          reportByVehicleForSummary[imei]['IDLE'][0][0] ||
          reportByVehicleForSummary[imei]['PTO'][0][0];
        const sheet = workbook.addWorksheet(plateNo, pageProperties);
        sheet.pageSetup.margins = sheetMargin;
        sheet.columns = columnsSpec;
        ///// SUMMARY /////////////////////////
        const lenIdleData = reportByVehicleForSummary[imei]['IDLE'].length
          ? reportByVehicleForSummary[imei]['IDLE'].length - 1
          : 0;
        const lenPTOData =
          reportByVehicleForSummary[imei]['PTO'].length > 0
            ? reportByVehicleForSummary[imei]['PTO'].length - 1
            : 0;
        const totalTimePTOSec =
          lenPTOData > 0
            ? reportByVehicleForSummary[imei]['PTO'][lenPTOData][12]
            : 0;
        const totalTimePTOStr = moment('1970-01-01')
          .startOf('day')
          .seconds(totalTimePTOSec)
          .format('HH:mm:ss');
        const dataEachVehicle = summaryData[imei];
        const metaRow0 = `รายงานสรุป ของ พาหนะทะเบียน ${plateNo} เวลาเริ่ม ${formatDate(
          start
        )} ถึง ${formatDate(stop)}`;

        if (logo && logo.url) {
          cntRow = 2;
          startingRow = 2;
          addLogoCompany(sheet, imageLogoId);
        }

        sheet.addRow([metaRow0]);
        sheet.addRow([]);
        sheet.addRow(['ที่', 'เหตุการณ์', 'ค่ารายงาน', 'หน่วย']);
        for (const k in dataEachVehicle) {
          sheet.addRow([
            cntRow,
            dataEachVehicle[k].title,
            dataEachVehicle[k].value,
            dataEachVehicle[k].unit,
          ]);
          cntRow++;
        }
        sheet.addRow([]);
        sheet.addRow([]);

        hightlightRow(sheet, startingRow);
        hightlightTableHead(sheet, startingRow + 2 + lastRow);

        sheet.getColumn(2).eachCell((cell, rowNum) => {
          if (rowNum > startingRow + 2) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right',
            };
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'FFF0EFFC',
              },
              bgColor: {
                argb: 'FF0000FF',
              },
            };
            cell.border = {
              top: {
                style: 'thin',
              },
              left: {
                style: 'thin',
              },
              bottom: {
                style: 'thin',
              },
              right: {
                style: 'thin',
              },
            };
          }
        });

        ///// IDLING /////////////////////////

        const metaRow1 = `รายงานจอดรถติดเครื่อง ของ รถทะเบียน ${plateNo}`;
        sheet.addRow([metaRow1]);
        sheet.addRow([]);

        sheet.addRow(tableHeadIdling);
        if (lenIdleData > 0) {
          sheet.addRows(reportByVehicleForSummary[imei]['IDLE']);
        }
        if (statusColumn) {
          sheet.getColumn(statusColumn).eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: getStatusColor(cell.value),
            };
          });
        }
        sheet.addRow([]);
        hightlightRow(sheet, cntRow + 5);
        hightlightTableHead(sheet, cntRow + 7);
        sheet.addRow([]);
        sheet.addRow([]);
        ///// PTO /////////////////////////
        if (lenPTOData > 0) {
          const metaRow3 = `รายงาน PTO ของ รถทะเบียน ${plateNo}`;
          const metaRow4 = `รวมเวลา ${totalTimePTOStr} ชม.:นาที:วินาที`;

          sheet.addRow([metaRow3]);
          sheet.addRow([]);

          sheet.addRow(tableHeadPTO);
          sheet.addRows(reportByVehicleForSummary[imei]['PTO']);
          if (statusColumn) {
            sheet.getColumn(statusColumn).eachCell((cell) => {
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: getStatusColor(cell.value),
              };
            });
          }
          sheet.addRow([metaRow4]);
          //
          hightlightRow(
            sheet,
            cntRow + 11 + reportByVehicleForSummary[imei]['IDLE'].length
          );
          hightlightTableHead(
            sheet,
            cntRow + 13 + reportByVehicleForSummary[imei]['IDLE'].length
          );
        }
      }
    });
  else {
    workbook.addWorksheet('ไม่พบข้อมูล', pageProperties);
  }

  blobToFile(workbook, fileName);
};

export const genIdlingOpenPTOExcel = async ({
  fileName,
  reportByVehicle,
  tableHead,
  columnsSpec,
  statusColumn,
  logo,
}) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: 'FF41C173',
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
    },
  };

  let imageLogoId;

  if (logo && logo.url) {
    const ext = logo.ext.substring(1);
    const myBase64Image = await generateLogoHeader(logo);
    imageLogoId = workbook.addImage({
      base64: myBase64Image,
      extension: ext,
    });
  }

  if (Object.keys(reportByVehicle).length > 0)
    Object.keys(reportByVehicle).forEach((imei) => {
      if (reportByVehicle[imei].length > 0) {
        let startingRow = 1;

        const plateNo = reportByVehicle[imei][0][0];
        const sheet = workbook.addWorksheet(plateNo, pageProperties);
        sheet.pageSetup.margins = sheetMargin;

        sheet.columns = columnsSpec;
        const totalTimeSec =
          reportByVehicle[imei][reportByVehicle[imei].length - 1][10];
        const totalTimeStr = moment('1970-01-01')
          .startOf('day')
          .seconds(totalTimeSec)
          .format('HH:mm:ss');

        const metaRow1 = `รายงานจอดรถติดเครื่อง ของ รถทะเบียน ${plateNo} เวลาเริ่ม ${
          reportByVehicle[imei][0][2]
        } ถึง ${reportByVehicle[imei][reportByVehicle[imei].length - 1][3]}`;
        const metaRow2 = `รวมเวลา ${totalTimeStr} ชม.:นาที:วินาที`;

        if (logo && logo.url) {
          startingRow = 2;
          addLogoCompany(sheet, imageLogoId);
        }

        sheet.addRow([metaRow1]);
        sheet.addRow([metaRow2]);

        sheet.addRow([]);

        sheet.addRow(tableHead);
        sheet.addRows(reportByVehicle[imei]);

        sheet.addRow([]);
        sheet.addRow([]);
        sheet.addRow([]);
        sheet.addRow([
          '',
          '',
          '',
          '',
          '',
          '',
          'รวมเวลาเปิด PTO1',
          moment('1970-01-01')
            .startOf('day')
            .seconds(
              reportByVehicle[imei][reportByVehicle[imei].length - 1][14]
            )
            .format('HH:mm:ss'),
        ]);
        sheet.addRow([
          '',
          '',
          '',
          '',
          '',
          '',
          'รวมเวลาเปิด PTO2',
          moment('1970-01-01')
            .startOf('day')
            .seconds(
              reportByVehicle[imei][reportByVehicle[imei].length - 1][15]
            )
            .format('HH:mm:ss'),
        ]);
        sheet.addRow([
          '',
          '',
          '',
          '',
          '',
          '',
          'รวมเวลาเปิด PTO3',
          moment('1970-01-01')
            .startOf('day')
            .seconds(
              reportByVehicle[imei][reportByVehicle[imei].length - 1][16]
            )
            .format('HH:mm:ss'),
        ]);
        if (statusColumn) {
          sheet.getColumn(statusColumn).eachCell((cell) => {
            cell.font = {
              color: {
                argb: 'FFF5223E',
              },
            };
          });
        }
        hightlightTableHead(sheet, startingRow + 3);
        hightlightRow(sheet, startingRow);
        hightlightRow(sheet, startingRow + 1);
      }
    });
  else {
    workbook.addWorksheet('ไม่พบข้อมูล', pageProperties);
  }

  blobToFile(workbook, fileName);
};

const splitIdlingReportByVehicle = (
  vehicles,
  report,
  duration,
  geofences,
  dictOfDriver,
  reportType
) => {
  let result = {};
  let accumulateDuration = {};
  let accumulateDurationPTO1 = {};
  let accumulateDurationPTO2 = {};
  let accumulateDurationPTO3 = {};
  report.forEach((row) => {
    result[row.imei] = result[row.imei] || [];
    accumulateDuration[row.imei] = accumulateDuration[row.imei] || 0;
    accumulateDurationPTO1[row.imei] = accumulateDurationPTO1[row.imei] || 0;
    accumulateDurationPTO2[row.imei] = accumulateDurationPTO2[row.imei] || 0;
    accumulateDurationPTO3[row.imei] = accumulateDurationPTO3[row.imei] || 0;
    const resLoc = geofenceStartEndFromLocation(geofences, row);
    const mcr_license_no =
      typeof row.positionDataStart.mcr_license_no != 'undefined'
        ? row.positionDataStart.mcr_license_no
        : '';
    const mcr_driver_name = row.positionDataStart.mcr_driver_name || '';
    if (row.stopTime - row.startTime >= duration) {
      accumulateDuration[row.imei] =
        parseInt(accumulateDuration[row.imei]) +
        parseInt(row.stopTime - row.startTime);

      accumulateDurationPTO1[row.imei] =
        parseInt(accumulateDurationPTO1[row.imei]) +
        parseInt(row.pto1spendingTimeTotal);
      accumulateDurationPTO2[row.imei] =
        parseInt(accumulateDurationPTO2[row.imei]) +
        parseInt(row.pto2spendingTimeTotal);
      accumulateDurationPTO3[row.imei] =
        parseInt(accumulateDurationPTO3[row.imei]) +
        parseInt(row.pto3spendingTimeTotal);

      if (reportType == 'idlingSummary') {
        result[row.imei].push([
          imeiToPlateNumber(vehicles, row.imei),
          imeiToVehicleName(vehicles, row.imei),
          formatDate(row.startTime),
          formatDate(row.stopTime),
          row.eventType == 'PTO_STOP' ? 'PTO' : 'IDLE',
          row.pto ? row.pto : '',
          formatDuration(row.startTime, row.stopTime),
          resLoc.end,
          `${row.lat / 10000000}, ${row.lng / 10000000}`,
          typeof dictOfDriver != 'undefined' && mcr_license_no in dictOfDriver
            ? dictOfDriver[mcr_license_no].name
            : getMCRDriverName(mcr_license_no, mcr_driver_name), //10
          row.startTime,
          row.stopTime,
          accumulateDuration[row.imei],
        ]);
      } else if (reportType == 'idlingOpenPTO') {
        let latPos = 0
        let lngPos = 0        
        if ('positionDataStart' in row) {
          latPos = row.positionDataStart.latitude
          lngPos = row.positionDataStart.longitude
        } else {
          latPos = row.lat
          lngPos = row.lng
        }
        result[row.imei].push([
          imeiToPlateNumber(vehicles, row.imei),
          imeiToVehicleName(vehicles, row.imei),
          formatDate(row.startTime),
          formatDate(row.stopTime),
          formatDuration(row.startTime, row.stopTime),
          resLoc.end,
          `${latPos / 10000000}, ${lngPos / 10000000}`,
          row.pto1 ? 'เปิดใช้งาน' : '-',
          row.pto2 ? 'เปิดใช้งาน' : '-',
          row.pto3 ? 'เปิดใช้งาน' : '-',
          typeof dictOfDriver != 'undefined' && mcr_license_no in dictOfDriver
            ? dictOfDriver[mcr_license_no].name
            : getMCRDriverName(mcr_license_no, mcr_driver_name), //10
          row.startTime,
          row.stopTime,
          accumulateDuration[row.imei],
          accumulateDurationPTO1[row.imei],
          accumulateDurationPTO2[row.imei],
          accumulateDurationPTO3[row.imei],
        ]);
      } else {
        result[row.imei].push([
          imeiToPlateNumber(vehicles, row.imei),
          imeiToVehicleName(vehicles, row.imei),
          formatDate(row.startTime),
          formatDate(row.stopTime),
          formatDuration(row.startTime, row.stopTime),
          resLoc.end,
          `${row.lat / 10000000}, ${row.lng / 10000000}`,
          typeof dictOfDriver != 'undefined' && mcr_license_no in dictOfDriver
            ? dictOfDriver[mcr_license_no].name
            : getMCRDriverName(mcr_license_no, mcr_driver_name), //10
          row.startTime,
          row.stopTime,
          accumulateDuration[row.imei],
        ]);
      }
    }
  });
  return result;
};

export default getIdlingReport;
