// import {
//   apolloClient
// } from "@/vue-apollo";
// import eventsTypeByIMEI from "@/graphql/eventsTypeByIMEI.gql";
import moment from '@/lib/time';
import Excel from 'exceljs';
import AxiosService from '@/plugins/axios';
import endpoints from '../../config/config';
import { displayTempSensorData } from '@/lib/device';

import {
  sheetMargin,
  getStatusColor,
  hightlightTableHead,
  blobToFile,
  imeiToPlateNumber,
  formatDate,
  fontNormal,
  formatDuration,
  hightlightRow,
  getOdoData,
  geofenceStartEndFromLocation,
  imeiToVehicleName,
  getMCRDriverName,
  generateLogoHeader,
  addLogoCompany,  
} from './config';
import * as Sentry from '@sentry/vue';
import store from '@/store';

const splitReportByVehicleOfDay = (
  vehicles,
  report,
  geofences,
  reportType,
  dictOfDriver
) => {
  let result = {};
  let sumDistance = {};
  let sumDuration = {};

  report.forEach((row) => {
    result[row.imei] = result[row.imei] || [];
    const resLoc = geofenceStartEndFromLocation(geofences, row);
    const dayOfEvent = moment.unix(row.startTime).format('YYYY-MM-DD');
    const keyOfDate = row.imei + '_' + dayOfEvent;
    sumDistance[keyOfDate] = sumDistance[keyOfDate] || 0;
    sumDuration[keyOfDate] = sumDuration[keyOfDate] || 0;
    result[row.imei][dayOfEvent] = result[row.imei][dayOfEvent] || [];
    const distance = parseFloat(
      getOdoData(vehicles, row.imei, row.positionDataEnd).toFixed(2) - getOdoData(vehicles, row.imei, row.positionDataStart).toFixed(2)
    );

    sumDistance[keyOfDate] = parseFloat(
      (distance + sumDistance[keyOfDate]).toFixed(2)
    );
    sumDuration[keyOfDate] =
      row.stopTime - row.startTime + sumDuration[keyOfDate];
    let dataRow;
    let mcr_license_no;
    mcr_license_no =
      typeof row.mcr_license_no != 'undefined' ? row.mcr_license_no : '';
    let mcr_driver_name = row.mcr_driver_name || '';
    if (reportType == 'over4hour') {
      dataRow = [
        imeiToPlateNumber(vehicles, row.imei),
        imeiToVehicleName(vehicles, row.imei),
        formatDate(row.startTime),
        formatDate(row.stopTime),
        formatDuration(row.startTime, row.stopTime),
        row.totolStopTime,
        row.totolMovingTime,
        row.totolIdlingTime,
        parseFloat(
          getOdoData(vehicles, row.imei, row.positionDataEnd).toFixed(2)
        ),
        distance,
        resLoc.start,
        resLoc.end,
        typeof dictOfDriver != 'undefined' && mcr_license_no in dictOfDriver
          ? dictOfDriver[mcr_license_no].name
          : getMCRDriverName(mcr_license_no, mcr_driver_name), //10
        row.maxSpeed ? parseFloat(row.maxSpeed).toFixed(1) : '',
        row.avgSpeed ? parseFloat(row.avgSpeed).toFixed(1) : '',
        displayTempSensorData(row.positionDataStart.io),
        displayTempSensorData(row.positionDataEnd.io),
        row.startTime,
        row.stopTime,
        dayOfEvent,
        sumDistance[keyOfDate],
        sumDuration[keyOfDate],
      ];
    } else {
      dataRow = [
        imeiToPlateNumber(vehicles, row.imei),
        imeiToVehicleName(vehicles, row.imei),
        formatDate(row.startTime),
        formatDate(row.stopTime),
        formatDuration(row.startTime, row.stopTime),
        parseFloat(
          getOdoData(vehicles, row.imei, row.positionDataEnd).toFixed(2)
        ),
        distance,
        resLoc.start,
        resLoc.end,
        typeof dictOfDriver != 'undefined' && mcr_license_no in dictOfDriver
          ? dictOfDriver[mcr_license_no].name
          : getMCRDriverName(mcr_license_no, mcr_driver_name), //10
        row.maxSpeed ? parseFloat(row.maxSpeed).toFixed(1) : '',
        row.avgSpeed ? parseFloat(row.avgSpeed).toFixed(1) : '',
        displayTempSensorData(row.positionDataStart.io),
        displayTempSensorData(row.positionDataEnd.io),
        row.startTime,
        row.stopTime,
        dayOfEvent,
        sumDistance[keyOfDate],
        sumDuration[keyOfDate],
      ];
    }

    result[row.imei][dayOfEvent].push(dataRow);
  });
  return result;
};

const prepareDataForExcel = (
  fileName,
  vehicles,
  reportData,
  geofences,
  reportType,
  dictOfDriver,
  logo
) => {
  const reportByVehicle = splitReportByVehicleOfDay(
    vehicles,
    reportData,
    geofences,
    reportType,
    dictOfDriver
  );
  let tableHead = [];
  if (reportType == 'over4hour') {
    tableHead = [
      'ทะเบียนรถ',
      'ชื่อ',
      'วัน เวลา เริ่มต้น',
      'วัน เวลา สิ้นสุด',
      'รวมเวลา',
      'รวมจอดเวลา',
      'รวมวิ่่งเวลา',
      'รวมจอดไม่ดับเครื่องเวลา',
      'เลขกม.สะสม',
      'รวมระยะทาง (กม.)',
      'ตำแหน่งเริ่มต้น',
      'ตำแหน่งสิ้นสุด',
      'ผู้ใช้รถ',
      'ความเร็วสูงสุด',
      'ความเร็วเฉลี่ย',
      'อุณหภูมิเริ่มต้น',
      'อุณหภูมิสิ้นสุด',
      'timestamp เริ่มต้น',
      'timestamp สิ้นสุด',
    ];
  } else {
    tableHead = [
      'ทะเบียนรถ',
      'ชื่อ',
      'วัน เวลา เริ่มต้น',
      'วัน เวลา สิ้นสุด',
      'รวมเวลา',
      'เลขกม.สะสม',
      'รวมระยะทาง (กม.)',
      'ตำแหน่งเริ่มต้น',
      'ตำแหน่งสิ้นสุด',
      'ผู้ใช้รถ',
      'ความเร็วสูงสุด',
      'ความเร็วเฉลี่ย',
      'อุณหภูมิเริ่มต้น',
      'อุณหภูมิสิ้นสุด',
      'timestamp เริ่มต้น',
      'timestamp สิ้นสุด',
    ];
  }
  const columnsSpec = [
    {
      key: 'plate_number',
      width: 10,
      style: {
        font: fontNormal,
      },
    },
    {
      key: 'name',
      width: 22,
      style: {
        numFmt: '0',
      },
    },
    {
      key: 'start',
      width: 20,
    },
    {
      key: 'stop',
      width: 20,
    },
    {
      key: 'totalTime',
      width: 8,
    },
    {
      key: 'totolStopTime',
      width: 14,
    },
    {
      key: 'totolMovingTime',
      width: 14,
    },
    {
      key: 'totolIdlingTime',
      width: 14,
    },
    {
      key: 'cumOdometer',
      width: 12,
    },
    {
      key: 'totalDistance',
      width: 16,
    },
    {
      key: 'geolocationstart',
      width: 40,
    },
    {
      key: 'geolocationend',
      width: 40,
    },
    {
      key: 'driver',
      width: 15,
    },
    {
      key: 'maxSpeed',
      width: 8,
    },
    {
      key: 'avgSpeed',
      width: 8,
    },
    {
      key: 'startTemp',
      width: 12,
    },
    {
      key: 'stopTemp',
      width: 12,
    },
    {
      key: 'timeStampStart',
      width: 20,
    },
    {
      key: 'timeStampStop',
      width: 20,
    },
  ];
  genTripExcel({
    fileName,
    reportByVehicle,
    tableHead,
    columnsSpec,
    statusColumn: null,
    reportType,
    logo,
  });
};

export const getTripReport = async (
  eventTypes,
  vehicles,
  imeis,
  start,
  stop,
  geofences,
  reportType = '',
  dictOfDriver,
  logo
) => {
  const isTrip24hr = reportType == '24hr' ? true : false;
  const isTripOver4hr = reportType == 'over4hour' ? true : false;
  const strTimerange =
    moment.unix(start).format('D_MMM_YY') +
    '-ถึง-' +
    moment.unix(stop).format('D_MMM_YY');
  if (isTrip24hr) {
    await AxiosService.post(`${endpoints.coreApi}/report-trip-24-hr`, {
      eventType: 'END_TRIP',
      imeis: imeis,
      start: start,
      stop: stop,
    })
      .then(async (response) => {
        const fileName = 'Trip24hr_Report_' + strTimerange;
        prepareDataForExcel(
          fileName,
          vehicles,
          response.data,
          geofences,
          reportType,
          dictOfDriver,
          logo
        );
      })
      .catch((error) => {
        console.log('An error occurred:', error);
        Sentry.captureException(error);
        store.dispatch('loading/setReportLoading', false);
        return;
      });
  } else if (isTripOver4hr) {
    await AxiosService.post(
      `${endpoints.coreApi}/report-nonstop-4-hour-drive`,
      {
        eventType: 'END_TRIP',
        imeis: imeis,
        start: start,
        stop: stop,
      }
    )
      .then(async (response) => {
        const fileName = 'DriveOver4hr_Report_' + strTimerange;
        prepareDataForExcel(
          fileName,
          vehicles,
          response.data,
          geofences,
          reportType,
          dictOfDriver,
          logo
        );
      })
      .catch((error) => {
        console.log('An error occurred:', error);
        Sentry.captureException(error);
        store.dispatch('loading/setReportLoading', false);
        return;
      });
  }
};

export const genTripExcel = async ({
  fileName,
  reportByVehicle,
  tableHead,
  columnsSpec,
  statusColumn,
  reportType,
  logo,
}) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: 'FF41C173',
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
    },
  };
  let imageLogoId;

  if (logo && logo.url) {
    const ext = logo.ext.substring(1);
    const myBase64Image = await generateLogoHeader(logo);
    imageLogoId = workbook.addImage({
      base64: myBase64Image,
      extension: ext,
    });
  }

  if (Object.keys(reportByVehicle).length > 0) {
    Object.keys(reportByVehicle).forEach((imei) => {
      let cnt = 0;
      let sheet = null;
      let plateNo = '';
      let lastRow = 0;
      let sumTotalDistance = 0;
      let sumTotalTime = 0;
      let metaRow1 = '';
      let metaRow2 = '';
      let startingRow = 1;

      Object.keys(reportByVehicle[imei]).forEach((dateStr, index) => {
        const dataEachDay = reportByVehicle[imei][dateStr];
        if (cnt == 0) {
          plateNo = dataEachDay[0][0];
          sheet = workbook.addWorksheet(plateNo, pageProperties);
          sheet.pageSetup.margins = sheetMargin;
          sheet.columns = columnsSpec;
          if (logo && logo.url && index == 0) {
            startingRow = 2;
            addLogoCompany(sheet, imageLogoId);
          }
        }

        let totalDistance = dataEachDay[dataEachDay.length - 1][17]; // accumulate data
        if (reportType == 'over4hour') {
          totalDistance = dataEachDay[dataEachDay.length - 1][20]; // accumulate data
        }
        const totalTime = moment('1970-01-01')
          .startOf('day')
          .seconds(dataEachDay[dataEachDay.length - 1][18])
          .format('HH:mm:ss'); // accumulate data

        if (reportType == '24hr')
          metaRow1 = `รายงานการเดินทาง (ตัดเที่ยงคืน) ของ พาหนะทะเบียน ${plateNo} เวลาเริ่ม ${
            dataEachDay[0][2]
          } ถึง ${dataEachDay[dataEachDay.length - 1][3]}`;
        else if (reportType == 'over4hour')
          metaRow1 = `รายงานขับพาหนะต่อเนื่องเกิน 4 ชั่วโมง ของ พาหนะทะเบียน ${plateNo} เวลาเริ่ม ${
            dataEachDay[0][2]
          } ถึง ${dataEachDay[dataEachDay.length - 1][3]}`;
        else
          metaRow1 = `รายงานการเดินทาง ของ พาหนะทะเบียน ${plateNo} เวลาเริ่ม ${
            dataEachDay[0][2]
          } ถึง ${dataEachDay[dataEachDay.length - 1][3]}`;
        metaRow2 = `รวมระยะทางทั้งสิ้น ${totalDistance} กม. รวมเวลา ${totalTime} ชม.:นาที:วินาที`;

        sumTotalDistance = sumTotalDistance + totalDistance;
        sumTotalTime = sumTotalTime + dataEachDay[dataEachDay.length - 1][18];
        sheet.addRow([metaRow1]);
        sheet.addRow([metaRow2]);

        sheet.addRow([]);
        sheet.addRow(tableHead);
        sheet.addRows(dataEachDay);
        sheet.addRow([]);
        sheet.addRow([]);
        if (statusColumn) {
          sheet.getColumn(statusColumn).eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: getStatusColor(cell.value),
            };
          });
        }

        hightlightTableHead(sheet, startingRow + 3 + lastRow);
        hightlightRow(sheet, startingRow + lastRow);
        hightlightRow(sheet, startingRow + 1 + lastRow, 'FFA29BFC');
        lastRow = lastRow + dataEachDay.length + 6;

        cnt++;
      });

      const sumTotalTimeTxt = moment('1970-01-01')
        .startOf('day')
        .seconds(sumTotalTime)
        .format('HH:mm:ss');

      sheet.addRow([
        `รวมระทางทั้งสิ้น ${sumTotalDistance.toFixed(
          2
        )} กม. รวมเวลา ${sumTotalTimeTxt} ชม.:นาที:วินาที`,
      ]);
      hightlightRow(sheet, startingRow + lastRow, 'FFE821AA');
    });
  } else {
    workbook.addWorksheet('ไม่พบข้อมูล', pageProperties);
  }

  blobToFile(workbook, fileName);
};

export default getTripReport;
