import { apolloClient } from "../../strapi-apollo";
const state = () => ({
  drivers: [],
  byAPIdrivers: [],
});

import drivers from "@/graphql/drivers.gql";

import AxiosService from "@/plugins/axios";
import endpoints from "../../config/config";

// getters
const getters = {
  drivers: (state) => state.drivers,
  driverByLicenseNo: (state) => (licenseNo) => {
    // console.log('driverByLicenseNo',state.drivers.length);
    if (state.drivers.length > 0) {
      const resultDrivers = state.drivers.find((driver) => {
        // console.log("No.All",driver.license_no , licenseNo);
        return driver.license_no == licenseNo;
      });
      // console.log('found driver',licenseNo,resultDrivers)
      return resultDrivers;
    } else return "";
  },
};

// actions
const actions = {
  async getAllDrivers({ commit }, orgs) {
    await apolloClient.resetStore();
    const allDrivers = await apolloClient
      .query({
        // Query
        query: drivers,
        variables: {
          orgs,
        },
      })
      .then(async (eventData) => {
        //console.log(eventData)
        return eventData;
      })
      .catch((err) => {
        console.log(err);
        return;
      });
    if (allDrivers && allDrivers.data)
      commit("ALL_DRIVERS", allDrivers.data.drivers);
  },
  async getDriversbyAPI({ commit }, customer_groups_id) {
    await AxiosService.get(
      `${endpoints.strapiAPI}/drivers?org=${customer_groups_id}`
    )
      .then((response) => {
        // console.log(response.data)
        commit("API_DRIVERS", response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  },
  resetState({ commit }) {
    commit("RESET_STATE");
  },
};
// mutations
const mutations = {
  ALL_DRIVERS(state, payload) {
    state.drivers = payload;
  },
  API_DRIVERS(state, payload) {
    state.byAPIdrivers = payload;
  },
  RESET_STATE(state) {
    state.drivers = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
