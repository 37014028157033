<template>
  <div id="report-drawer__container">
    <div class="container__title">สร้างรายงาน</div>
    <div class="container__content">
      <div class="content__row">
        <div class="col-left">ประเภทรายงาน:</div>
        <div class="col-right">
          <report-type-selector
            :value="selectedReportType"
            @onChange="onChangeReportType"
          />
        </div>
      </div>
      <div v-if="isShowDateSelector" class="content__row">
        <div class="col-left">ช่วงเวลา:</div>
        <div class="col-right">
          <a-range-picker
            size="large"
            format="D MMM YYYY HH:mm"
            :placeholder="['เริ่มต้น', 'สิ้นสุด']"
            v-model="selectedDateRange"
            :allowClear="true"
            :locale="locale"
            :showTime="{
              defaultValue: [
                moment().hour(0).minute(0).second(0).millisecond(0),
                moment().hour(23).minute(59).second(59).millisecond(999),
              ],
            }"
          />
        </div>
      </div>
      <div class="content__row">
        <div class="col-left">กลุ่มพาหนะ:</div>
        <div class="col-right">
          <a-select
            :defaultActiveFirstOption="true"
            placeholder="เลือกกลุ่มพาหนะ"
            size="large"
            @change="onChangeVehicleGroup"
            :value="selectedVehicleGroup"
          >
            <a-select-option
              v-for="vehicleGroup in vehicleGroups"
              :key="vehicleGroup.id"
              :value="vehicleGroup.id"
            >
              <div class="select-menu-item">{{ vehicleGroup.label }}</div>
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="content__row">
        <div class="col-left">พาหนะ:</div>
        <div class="col-right">
          <a-select
            placeholder="เลือกพาหนะ"
            size="large"
            mode="multiple"
            option-filter-prop="children"
            :filter-option="filterOption"
            :defaultActiveFirstOption="true"
            :value="selectedVehicles"
            @select="onSelectVehicle"
            @deselect="onDeselectVehicle"
          >
            <a-select-option value="selectAll">
              <div class="select-menu-item" color="blue">เลือกทั้งหมด</div>
            </a-select-option>
            <a-select-option
              v-for="option in vehicleSelectorOptions"
              :key="option.id"
              :value="option.id"
            >
              <div class="select-menu-item">{{ option.label }}</div>
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div v-if="isShowMinimumSecSelector" class="content__row">
        <div class="col-left">ข้อมูลทุกๆเวลา:</div>
        <div class="col-right">
          <a-select
            size="large"
            :defaultActiveFirstOption="true"
            v-model="selectedMinimumSec"
            suffix="วินาที/นาที"
          >
            <a-select-option
              v-for="option in minimumSecOptions"
              :key="`minimumSec_${option.value}`"
              :value="option.value"
            >
              <div class="select-menu-item">{{ option.label }}</div>
            </a-select-option>
          </a-select>
        </div>
      </div>

      <div class="content__row">
        <div class="content__actions">
          <custom-button
            type="main"
            :label="'ดาวน์โหลดรายงาน'"
            @onClick="onClickDownloadButton"
            :disabled="disabledDownloadButton"
          />
          <custom-button :label="'เริ่มใหม่'" @onClick="onClickResetButton" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from '@/lib/time';
import { getVehicles } from '@/lib/vehicle';
import locale from 'ant-design-vue/es/date-picker/locale/th_TH';
import { mapActions, mapState, mapGetters } from 'vuex';
import reportTypeSelector from './reportTypeSelector.vue';
import customButton from '../customButton.vue';
import { reportTypesEnum } from '@/lib/report';
export default {
  name: 'ReportDrawer',
  components: {
    customButton,
    reportTypeSelector,
  },
  emits: ['onClickDownload'],
  data() {
    return {
      locale,
      vehicleSelectorOptions: [],
      minimumSecOptions: [
        { label: 'ทั้งหมด', value: 0 },
        { label: '30 วินาที', value: 30 },
        { label: '1 นาที', value: 60 },
        { label: '3 นาที', value: 180 },
        { label: '5 นาที', value: 300 },
        { label: '10 นาที', value: 600 },
      ],
      selectedMinimumSec: 0,
      selectedVehicleGroup: '',
      selectedVehicles: [],
      selectedReportType: reportTypesEnum.N01Daily,
      selectedDateRange: [
        moment().hour(0).minute(0).second(0).millisecond(0),
        moment().hour(23).minute(59).second(59).millisecond(999),
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.authen.user,
      selectedGroup: (state) => state.authen.selectedGroup,
      vehicleGroups: (state) => state.vehicle.vehicleGroups,
      drivers: (state) => state.driver.drivers,
    }),
    ...mapGetters({
      vehicles: 'vehicle/vehicles',
    }),
    disabledDownloadButton() {
      return this.selectedVehicles.length <= 0;
    },
    isShowMinimumSecSelector() {
      return [
        reportTypesEnum.N06Temperature,
        reportTypesEnum.S04TempWithChart,
        reportTypesEnum.S05TempPdf,
      ].includes(this.selectedReportType);
    },
    isShowDateSelector() {
      return this.selectedReportType === reportTypesEnum.S01GeofenceCurrent
        ? false
        : true;
    },
    dictOfDriver() {
      let dictOfDriver = {};
      for (const k in this.drivers) {
        dictOfDriver[this.drivers[k].license_no] = this.drivers[k];
      }
      return dictOfDriver;
    },
  },
  async mounted() {
    if (this.vehicles.length <= 0)
      await getVehicles(
        this.user.customer_groups.map((group) => {
          return group._id;
        })
      );

    if (this.vehicleGroups.length > 0) {
      this.onChangeVehicleGroup(this.vehicleGroups[0].id);
    }

    if (this.drivers.length <= 0) {
      await this.getAllDrivers([this.selectedGroup.id]);
    }
  },
  methods: {
    ...mapActions({
      setReportLoading: 'loading/setReportLoading',
      resetVehicleState: 'vehicle/resetState',
      resetPositionState: 'position/resetState',
      getAllGeofences: 'geofence/getAllGeofences',
      getAllDrivers: 'driver/getAllDrivers',
    }),
    moment,
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async onClickDownloadButton() {
      const form = {
        reportType: this.selectedReportType,
        vehicleGroup: this.selectedVehicleGroup,
        vehicles: this.selectedVehicles.includes('selectAll')
          ? this.vehicles.map((v) => v.id)
          : this.selectedVehicles,
        dateRange: this.selectedDateRange,
        additional: {},
      };

      switch (this.selectedReportType) {
        case reportTypesEnum.N06Temperature:
        case reportTypesEnum.S04TempWithChart:
        case reportTypesEnum.S05TempPdf:
          form['additional']['sec'] = this.selectedMinimumSec;
          form['additional']['dictOfDriver'] = this.dictOfDriver;
          form['additional']['vehiclesData'] = this.vehicles.map((vehicle) => {
            return {
              plate: vehicle.plate_number,
              name: vehicle.name,
              odo_canbus_offset: vehicle.odo_canbus_offset,
              canbus_fuel_enable: vehicle.canbus_fuel_enable,
              odo_canbus_enable: vehicle.odo_canbus_enable,
              canbus_truck_enabled: vehicle.canbus_truck_enabled,
              overspeed_limit: vehicle.overspeed_limit,
              imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
            };
          });
          break;
        case reportTypesEnum.N20VehicleUsageSummary:
          form['additional']['vehiclesData'] = this.vehicles.map((vehicle) => {
            return {
              plate: vehicle.plate_number,
              imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
            };
          });
          break;
        default:
          break;
      }

      this.$emit('onClickDownload', form);
    },
    onClickResetButton() {
      this.resetReportFormState();
    },
    onChangeVehicleGroup(id) {
      const vehicleGroup = this.vehicleGroups.find(
        (vehicleGroup) => vehicleGroup.id === id
      );
      this.selectedVehicleGroup = vehicleGroup.id;
      this.vehicleSelectorOptions = vehicleGroup.vehicles.map((vehicle) => {
        return {
          id: vehicle.id,
          label: vehicle.name + ' ( ' + vehicle.plate_number + ' ) ',
        };
      });
    },
    onSelectVehicle(id) {
      if (id === 'selectAll') {
        this.selectedVehicles = [id];
      } else {
        if (this.selectedVehicles.includes('selectAll')) {
          this.selectedVehicles = [id];
        } else {
          this.selectedVehicles.push(id);
        }
      }
    },
    onDeselectVehicle(id) {
      const index = this.selectedVehicles.findIndex(
        (vehicleId) => vehicleId === id
      );
      if (index > -1) {
        this.selectedVehicles.splice(index, 1);
      }
    },
    onChangeReportType(type) {
      this.selectedReportType = type;
    },
    resetReportFormState() {
      this.onChangeVehicleGroup(this.vehicleGroups[0].id);
      this.selectedVehicles = [];
      this.selectedReportType = reportTypesEnum.N01Daily;
      this.selectedDateRange = [
        moment().hour(0).minute(0).second(0).millisecond(0),
        moment().hour(23).minute(59).second(59).millisecond(999),
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.select-menu-item {
  font-size: 1rem;
}
#report-drawer__container {
  * {
    font-size: 16px;
  }

  margin-block-start: 32px;
  border: 1px solid #c9c9c9;
  border-radius: 8px;

  .container__title {
    padding: 12px 16px;
    border-block-end: 1px solid #c9c9c9;
    line-height: 100%;
    color: #000000;
  }
  .container__content {
    position: relative;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    > .content__row {
      display: grid;
      grid-template-columns: 30% 65%;
      align-items: center;
      gap: 16px;

      > .col-left {
        justify-self: end;
      }
      > .col-right {
        max-width: 480px;
        > * {
          width: 100% !important;
        }
      }
    }
    > :has(.content__actions) {
      &::before {
        content: '';
      }
    }

    .content__actions {
      margin-block-start: 8px;
      display: flex;
      gap: 24px;
    }
  }
}
</style>
