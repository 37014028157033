import AxiosService from '@/plugins/axios';
import endpoints from '../../config/config';
import moment from '@/lib/time';
import Excel from 'exceljs';
import {
  fontNormal,
  sheetMargin,
  getStatusColor,
  hightlightTableHead,
  blobToFile,
  imeiToPlateNumber,
  formatDate,
  formatDuration,
  hightlightRow,
  getOdoData,
  geofenceStartEndFromLocation,
  imeiToVehicleName,
  getMCRDriverName,
  generateLogoHeader,
  addLogoCompany,
} from './config';

import * as Sentry from '@sentry/vue';
import store from '@/store';

export const getOverSpeedReport = async (
  eventTypes,
  vehicles,
  imeis,
  start,
  stop,
  duration,
  geofences,
  dictOfDriver,
  logo
) => {
  await AxiosService.post(`${endpoints.coreApi}/report-event`, {
    imeis: imeis,
    start: start,
    stop: stop,
    eventType: 'OVER_SPEED_END',
  })
    .then(async (eventData) => {
      const reportByVehicle = splitSpeedReportByVehicle(
        vehicles,
        eventData.data,
        duration,
        geofences,
        dictOfDriver
      );
      const strTimerange =
        moment.unix(start).format('D_MMM_YY') +
        '-ถึง-' +
        moment.unix(stop).format('D_MMM_YY');
      const fileName = 'OverSpeed_Report_' + strTimerange;
      const tableHead = [
        'ทะเบียนรถ',
        'ชื่อ',
        'วัน เวลา เริ่มต้น',
        'วัน เวลา สิ้นสุด',
        'รวมเวลา',
        'ความเร็วสูงสุด (กม./ชม.)',
        'เลขกม.สะสม',
        'รวมระยะทาง (กม.)',
        'ตำแหน่งเริ่มต้น',
        'ตำแหน่งสิ้นสุด',
        'ละติจูด ลองจิจูด',
        'ผู้ใช้รถ',
        'timestamp เริ่มต้น',
        'timestamp สิ้นสุด',
      ];
      const columnsSpec = [
        {
          key: 'plate_number',
          width: 10,
          style: {
            font: fontNormal,
          },
        },
        {
          key: 'name',
          width: 22,
          style: {
            numFmt: '0',
          },
        },
        {
          key: 'start',
          width: 20,
        },
        {
          key: 'stop',
          width: 20,
        },
        {
          key: 'totalTime',
          width: 8,
        },
        {
          key: 'maxSpeed',
          width: 20,
        },
        {
          key: 'cumOdometer',
          width: 12,
        },
        {
          key: 'totalDistance',
          width: 16,
        },
        {
          key: 'reversegeolocationstart',
          width: 40,
        },
        {
          key: 'reversegeolocationend',
          width: 40,
        },
        {
          key: 'geolocation',
          width: 30,
        },
        {
          key: 'driver',
          width: 15,
        },
        {
          key: 'timeStampStart',
          width: 15,
        },
        {
          key: 'timeStampStop',
          width: 15,
        },
      ];
      genOverSpeedExcel({
        fileName,
        reportByVehicle,
        tableHead,
        columnsSpec,
        statusColumn: null,
        logo,
      });
    })
    .catch((err) => {
      console.log(err);
      Sentry.captureException(err);
      store.dispatch('loading/setReportLoading', false);
      return;
    });
};

export const genOverSpeedExcel = async ({
  fileName,
  reportByVehicle,
  tableHead,
  columnsSpec,
  statusColumn,
  logo,
}) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: 'FF41C173',
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
    },
  };

  let imageLogoId;

  if (logo && logo.url) {
    const ext = logo.ext.substring(1);
    const myBase64Image = await generateLogoHeader(logo);
    imageLogoId = workbook.addImage({
      base64: myBase64Image,
      extension: ext,
    });
  }

  if (Object.keys(reportByVehicle).length > 0)
    Object.keys(reportByVehicle).forEach((imei) => {
      if (reportByVehicle[imei].length > 0) {
        const plateNo = reportByVehicle[imei][0][0];
        const sheet = workbook.addWorksheet(plateNo, pageProperties);
        sheet.pageSetup.margins = sheetMargin;
        sheet.columns = columnsSpec;

        const totalDistance = parseFloat(
          reportByVehicle[imei][reportByVehicle[imei].length - 1][14]
        );
        const totalTimeSec =
          reportByVehicle[imei][reportByVehicle[imei].length - 1][15];
        const totalTimeStr = moment('1970-01-01')
          .startOf('day')
          .seconds(totalTimeSec)
          .format('HH:mm:ss');

        const metaRow1 = `รายงานความเร็วเกิน ของ รถทะเบียน ${plateNo} เวลาเริ่ม ${
          reportByVehicle[imei][0][2]
        } ถึง ${reportByVehicle[imei][reportByVehicle[imei].length - 1][3]}`;

        const metaRow2 = `รวมระยะทางทั้งสิ้น ${totalDistance.toFixed(
          1
        )} กม. รวมเวลา ${totalTimeStr} ชม.:นาที:วินาที`;

        let startingRow = 1;

        if (logo && logo.url) {
          startingRow = 2;
          addLogoCompany(sheet, imageLogoId);
        }

        sheet.addRow([metaRow1]);
        sheet.addRow([metaRow2]);
        sheet.addRow([]);

        sheet.addRow(tableHead);

        sheet.addRows(reportByVehicle[imei]);
        if (statusColumn) {
          sheet.getColumn(statusColumn).eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: getStatusColor(cell.value),
            };
          });
        }
        hightlightTableHead(sheet, startingRow + 3);
        hightlightRow(sheet, startingRow);
        hightlightRow(sheet, startingRow + 1);
      }
    });
  else {
    workbook.addWorksheet('ไม่พบข้อมูล', pageProperties);
  }

  blobToFile(workbook, fileName);
};

const splitSpeedReportByVehicle = (
  vehicles,
  report,
  duration,
  geofences,
  dictOfDriver
) => {
  let result = {};
  let accumulateDistance = {};
  let accumulateDuration = {};
  report.forEach((row) => {
    result[row.imei] = result[row.imei] || [];
    accumulateDistance[row.imei] = accumulateDistance[row.imei] || 0;
    accumulateDuration[row.imei] = accumulateDuration[row.imei] || 0;
    const resLoc = geofenceStartEndFromLocation(geofences, row);
    
    if (row.stopTime - row.startTime >= duration) {
      const distance = parseFloat(
        (row.positionDataEnd.io[65] - row.positionDataStart.io[65]) / 1000
      );
      accumulateDistance[row.imei] =
        parseFloat(accumulateDistance[row.imei]) + distance;
      accumulateDuration[row.imei] =
        parseInt(accumulateDuration[row.imei]) +
        parseInt(row.stopTime - row.startTime);
      result[row.imei].push([
        imeiToPlateNumber(vehicles, row.imei),
        imeiToVehicleName(vehicles, row.imei),
        formatDate(row.startTime),
        formatDate(row.stopTime),
        formatDuration(row.startTime, row.stopTime),
        parseFloat(row.maxSpeed),
        parseFloat(
          getOdoData(vehicles, row.imei, row.positionDataEnd).toFixed(2)
        ),
        parseFloat(distance.toFixed()),
        resLoc.start,
        resLoc.end,
        `${row.positionDataStart.latitude / 10000000}, ${row.positionDataStart.longitude / 10000000}`,
        typeof dictOfDriver != 'undefined' && row.mcr_license_no in dictOfDriver
          ? dictOfDriver[row.mcr_license_no].name
          : getMCRDriverName(row.mcr_license_no, row.mcr_driver_name),
        row.startTime,
        row.stopTime,
        accumulateDistance[row.imei],
        accumulateDuration[row.imei],
      ]);
    }
  });
  return result;
};

export default getOverSpeedReport;
