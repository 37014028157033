<template>
  <defaultLayout>
    <div>
      <a-row>
        <a-col :span="12">
          <div class="sorry-message-container">
            <img
              class="login-image"
              :src="require('/src/assets/virtual-assistant.svg')"
            />
          </div>
        </a-col>
        <a-col :span="12">
          <div class="sorry-message-container">
            <div class="sorry-message">
              <h1>{{ title }}</h1>
              <p>
                <a-icon
                  type="exclamation-circle"
                  theme="filled"
                  style="color: #d31e9b"
                />
                {{ content }}
              </p>
              <div style="display: flex; column-gap: 27px">
                <a href="tel:020106990">
                  <button :class="['button', 'tel']">
                    <a-icon type="phone"></a-icon> ติดต่อพนักงานขาย
                  </button>
                </a>

                <button :class="['button', 'line']" @click="openLineHeliot">
                  <span style="height: 24px">
                    <img
                      style="width: 100%; height: 100%"
                      :src="require('/src/assets/line_icon.svg')"
                    />
                  </span>

                  แอดไลน์ Heliot
                </button>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </defaultLayout>
</template>

<script>
// @ is an alias to /src
import defaultLayout from '@/views/layouts/defaultLayout.vue';
import { mapState } from 'vuex';
export default {
  name: 'home',
  components: {
    defaultLayout,
  },
  data() {
    return {
      title: `ไม่สามารถเข้าถึงฟีทเจอร์ที่ต้องการ`,
      content: 'พบปัญหาการใช้งาน กรุณาติดต่อพนักงานขาย',
    };
  },
  computed: mapState({
    vehicles: (state) => state.vehicle.vehicles,
    selectedVehicle: (state) => state.vehicle.selectedVehicle,
  }),
  methods: {
    openLineHeliot() {
      window.open('https://lin.ee/XbYL41c', '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  background-color: #fff;
  color: #fff;
  border: 1px solid #e4e4e4;
  padding: 0.5rem 1.625rem;
  font-size: 1rem;
  border-radius: 0.5rem !important;
  cursor: pointer;

  &.line {
    background-color: #08b63c;
  }

  &.tel {
    background-color: #4a37de;
  }
}
.sorry-message-container {
  .sorry-message {
    padding: 0 80px 0 0;
    margin-top: 40px;

    h1 {
      font-size: 2rem;
      // margin-bottom: 8px;
    }
    p {
      font-size: 1rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 24px;
    }
  }
  padding-top: 200px;
  img {
    display: block;
    margin: 0 auto 0 auto;
    width: 300px;
    max-width: 100%;
  }
}
</style>
