var render = function render(){var _vm=this,_c=_vm._self._c;return _c('defaultLayout',[_c('div',{staticClass:"home"},[_c('deviceDrawer',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],attrs:{"clickVehicle":_vm.clickVehicle,"googleMap":this.$refs.googleMap}}),_c('div',{staticClass:"google-map"},[_c('div',{staticClass:"google-map-toolbar"},[_c('div',{staticClass:"toggle-geofence-button",on:{"click":_vm.toggleGeofence}},[_c('a-checkbox',{attrs:{"checked":_vm.showGeofence}}),_vm._v(" แสดงสถานี ")],1),_c('div',{staticClass:"toggle-traffic-button",on:{"click":_vm.toggleTraffic}},[_c('a-checkbox',{attrs:{"checked":_vm.showTraffic}}),_vm._v(" Traffic ")],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedVehicle.last_position_json),expression:"selectedVehicle.last_position_json"}],staticClass:"google-map-button",on:{"click":_vm.showGeofenceModal}},[_vm._v(" สร้างสถานี ")])]),_c('div',{staticClass:"custom-map-toolbar"},[_c('a-button',{staticStyle:{"margin-right":"0px","width":"26px"},attrs:{"icon":"heat-map"},on:{"click":_vm.getCurrentLocation}})],1),_c('GmapMap',{ref:"googleMap",staticStyle:{"width":"100%","height":"100%"},attrs:{"center":{ lat: 13.7460067, lng: 100.5440535 },"zoom":18,"map-type-id":"roadmap","options":{
          // minZoom: 6,
          //maxZoom: 17,
          controlSize: 26,
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: false,
          streetViewControl: true,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: true,
          zoomControlOptions: {
            position: 3,
          },
          streetViewControlOptions: {
            position: 3,
          },
          clickableIcons: true,
        }},on:{"drag":_vm.startDragMap,"bounds_changed":_vm.startDragMap,"idle":_vm.endDragMap,"zoom_changed":_vm.zoomLevelChange}},[(_vm.showMyLocationMarker)?_c('gmap-custom-marker',{ref:"myLocation",attrs:{"position":_vm.google &&
            new _vm.google.maps.LatLng(_vm.myCurrentLocationLat, _vm.myCurrentLocationLng),"clickable":true,"draggable":true},on:{"click":function($event){return _vm.showGeofenceModal()},"dragend":_vm.updateMyCoordinates}}):_vm._e(),[_c('cluster',{attrs:{"minimumClusterSize":'displayVehicleCluster' in _vm.setting &&
              _vm.setting.displayVehicleCluster === true
                ? 999
                : 2,"position":"center","zoomOnClick":true,"maxZoom":12,"clickable":"true","animation":"0"}},[_vm._l((_vm.allVehicles),function(vehicle,index){return [(
                  vehicle.device &&
                  vehicle.device.position &&
                  'latitude' in vehicle.device.position
                )?_c('CustomMarker',{key:`${index}-${vehicle.id}`,attrs:{"zIndex":_vm.selectedVehicle && _vm.selectedVehicle.id === vehicle.id
                    ? 55
                    : 50,"marker":{
                  lat:
                    vehicle.device && vehicle.device.position
                      ? vehicle.device.position.latitude / 10000000
                      : 0,
                  lng:
                    vehicle.device && vehicle.device.position
                      ? vehicle.device.position.longitude / 10000000
                      : 0,
                },"alignment":"center","mapZoom":_vm.mapZoom},nativeOn:{"click":function($event){return _vm.clickVehicle(vehicle)}}},[_c('div',{},[_c('img',{class:[
                      ['device-marker', _vm.ImgSizeChange(vehicle.vehicle_icon)],
                      _vm.selectedVehicle && _vm.selectedVehicle.id === vehicle.id
                        ? 'active-vehicle'
                        : '',
                      _vm.getFilterClass(
                        vehicle.device && vehicle.device.position
                          ? _vm.checkOverSpeed(
                              vehicle.device.position.engineStatus,
                              vehicle.device.position.speed,
                              vehicle.overspeed_limit
                            )
                          : 'ENGINE_STOP'
                      ),
                    ],style:({
                      transform: _vm.getDirectionClass(
                        vehicle.device && vehicle.device.position
                          ? vehicle.device.position.angle
                          : 0
                      ),
                    }),attrs:{"src":_vm.carIcon(vehicle.vehicle_icon)}})]),_c('div',{key:`pulse${vehicle.id}`,class:[
                    'pulse',
                    _vm.selectedVehicle && _vm.selectedVehicle.id === vehicle.id
                      ? 'active-vehicle-pulse'
                      : 'inactive-vehicle-pulse',
                  ]}),_c('div',{staticClass:"device-summary"},[_c('h3',[_vm._v(_vm._s(vehicle.name))]),(
                      vehicle.device &&
                      vehicle.device.position &&
                      vehicle.device.position.speed > 0
                    )?_c('p',[_vm._v(" "+_vm._s(vehicle.device && vehicle.device.position ? vehicle.device.position.speed : 0)+" Km/Hr ")]):_vm._e(),(
                      vehicle.device.position.mcr_license_no &&
                      vehicle.device.position.mcr_license_no.length > 0 &&
                      _vm.getDriverNameByLicenseNo(
                        vehicle.device.position.mcr_license_no
                      ) &&
                      _vm.getDriverNameByLicenseNo(
                        vehicle.device.position.mcr_license_no
                      ).name
                    )?_c('div',{staticClass:"driver-name",staticStyle:{"display":"flex"}},[(
                        vehicle.device.position.mcr_license_no &&
                        vehicle.device.position.mcr_license_no.length > 0 &&
                        _vm.getDriverNameByLicenseNo(
                          vehicle.device.position.mcr_license_no
                        ) &&
                        _vm.getDriverNameByLicenseNo(
                          vehicle.device.position.mcr_license_no
                        ).photo &&
                        _vm.getDriverNameByLicenseNo(
                          vehicle.device.position.mcr_license_no
                        ).photo.url.length > 0
                      )?_c('div',[_c('img',{staticStyle:{"margin":"4px 5px 4px 5px","border":"1px solid #513fe8","border-radius":"12px"},attrs:{"src":`${
                          _vm.getDriverNameByLicenseNo(
                            vehicle.device.position.mcr_license_no
                          ).photo.url
                        }`,"width":"24px","height":"24px"}})]):_c('div',[_c('a-avatar',{staticStyle:{"margin":"4px 5px 4px 5px","border-radius":"12px"},attrs:{"size":24,"icon":"user"}})],1),_c('p',{staticStyle:{"text-align":"left","letter-spacing":"0px","color":"#000000","opacity":"1","margin":"4px 15px 8px 0px"}},[_vm._v(" "+_vm._s(vehicle.device.position.mcr_license_no && vehicle.device.position.mcr_license_no.length > 0 && _vm.getDriverNameByLicenseNo( vehicle.device.position.mcr_license_no ) ? _vm.getDriverNameByLicenseNo( vehicle.device.position.mcr_license_no ).name : '-')+" ")])]):_vm._e()])]):_vm._e()]})],2)],(
            _vm.mapMoving === false &&
            _vm.mapZoom < 17 &&
            _vm.showBoundryGeofence === false &&
            _vm.showGeofence === true
          )?_vm._l((_vm.geofencesList),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":{
              lat: parseFloat(m.Positions[0].lat),
              lng: parseFloat(m.Positions[0].lng),
            },"clickable":true,"draggable":false},on:{"mouseover":function($event){_vm.showByIndex = index},"mouseout":function($event){_vm.showByIndex = null},"click":function($event){return _vm.setCenterPath(m)}}},[_c('gmap-info-window',{attrs:{"opened":_vm.showByIndex === index}},[_vm._v(" สถานี "+_vm._s(m.name)+" ")])],1)}):_vm._e(),(
            _vm.mapMoving === false &&
            _vm.showGeofence === true &&
            _vm.showBoundryGeofence === true
          )?[_vm._l((_vm.geofencesCircle),function(geofence,index){return _c('GmapCircle',{key:`geofence-circle-${index}`,attrs:{"center":geofence.Positions[0],"radius":geofence.radius,"options":{
              fillColor: '#00c246',
              fillOpacity: 0.3,
              strokeColor: '#00c246',
              strokeOpacity: 0.8,
            }}})}),_vm._l((_vm.geofencesPolygon),function(geofence,index){return _c('GmapPolygon',{key:`geofence-polygon-${index}`,attrs:{"path":geofence.Positions,"visible":_vm.showGeofence,"options":{
              fillColor: '#00c246',
              fillOpacity: 0.3,
              strokeColor: '#00c246',
              strokeOpacity: 0.8,
            }}})}),(_vm.mapZoom >= 17)?_vm._l((_vm.geofencesList),function(geofence,index){return _c('gmap-custom-marker',{key:`geofence-name-${index}`,attrs:{"marker":geofence.Positions[0],"alignment":"center"}},[_c('div',{staticClass:"geofence-name"},[_vm._v(" สถานี "),_c('br'),_vm._v(" "+_vm._s(geofence.name)+" ")])])}):_vm._e()]:_vm._e()],2),_c('deviceInfo',{attrs:{"selectedVehicle":_vm.selectedVehicle}})],1)],1),_c('a-modal',{attrs:{"visible":_vm.createGeofenceModalVisible,"confirm-loading":_vm.saveGeofenceLoading},on:{"ok":_vm.createGeofenceSubmit,"cancel":_vm.cancelCreateGeofence}},[_c('h2',[_vm._v("สร้างสถานีจากตำแหน่ง "+_vm._s(_vm.selectedVehicle.plate_number))]),_c('a-form',{attrs:{"layout":"inline","form":_vm.form}},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-input',{attrs:{"placeholder":"ชื่อสถานี","maxLength":30},model:{value:(_vm.geofenceName),callback:function ($$v) {_vm.geofenceName=$$v},expression:"geofenceName"}})],1)],1),_c('a-row',{staticStyle:{"margin-top":"10px"}},[_c('a-col',{attrs:{"span":24}},[_c('a-input',{attrs:{"type":"number","addon-before":"รัศมี","addon-after":"เมตร","min":10,"max":500.0,"step":10},model:{value:(_vm.geofenceRadius),callback:function ($$v) {_vm.geofenceRadius=$$v},expression:"geofenceRadius"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }