<template>
  <div id="report-queue__container">
    <a-table
      id="report-table"
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      @change="onChangeDataTable"
      :loading="isSync"
    >
      <template slot="index" slot-scope="text, record, index">
        <div class="row__no">
          <div>
            {{ displayRowNo(index) }}
            <div v-if="record.isJustFinishing" class="new">new</div>
          </div>
        </div>
      </template>
      <template slot="vehicles" slot-scope="vehicles">
        <div class="vehicle__label">
          <div class="platenumber-list">
            {{ displayVehicleLabel(vehicles) }}
            <span v-if="vehicles.length > 4">
              <a-dropdown :trigger="['hover']">
                <span style="cursor: pointer; position: relative">
                  , ...
                  <img
                    style="position: absolute"
                    src="@/assets/icons/circle_info.svg"
                    alt="circle_info"
                  />
                </span>
                <a-menu
                  slot="overlay"
                  style="height: 120px; overflow: auto; scrollbar-width: none"
                >
                  <a-menu-item
                    v-for="(vehicle, index) in vehicles.slice(4)"
                    :key="vehicle"
                  >
                    {{ vehicle }}
                    <div
                      v-if="index !== vehicles.slice(4).length - 1"
                      style="
                        border-top: 1px solid #eaeaea;
                        margin-top: 4px;
                        margin-bottom: -4px;
                      "
                    ></div>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </span>
          </div>
        </div>
      </template>
      <template slot="action" slot-scope="text, record">
        <div class="table__action">
          <div v-if="isDisplayStatusLabel(record.status)" class="status__label">
            {{ displayStatusLabel(record.status) }}
          </div>
          <div v-if="isDisplayLink(record.status)">
            <div
              v-if="isLinkValid(record) !== ''"
              class="download__button"
              @click="onClickLink(record.url)"
            >
              รับไฟล์
            </div>
            <div v-else style="color: #e821aa">เกิดข้อผิดพลาด</div>
          </div>
          <icon-font
            v-if="isDisplayDeleteButton(record.status)"
            style="font-size: 1.5rem"
            type="icon-delete"
            class="delete__button"
            @click="onClickDeleteButton(record._id)"
          />
          <a-dropdown
            v-if="isDisplayMoreButton(record.status)"
            :trigger="['click']"
          >
            <a-icon
              style="font-size: 1.5rem"
              type="more"
              class="more__button"
            />
            <a-menu slot="overlay">
              <a-menu-item @click="onClickNextInqueue(record._id)">
                <div>เลื่อนไปคิวบนสุด</div>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </template>
    </a-table>
  </div>
</template>
<script>
import { IconFont } from '@/config/config';
import { specialReportTypeList, normalReportTypeList } from '@/lib/report';
export default {
  name: 'ReportDataTable',
  components: { IconFont },
  props: {
    dataList: { type: Array },
    isSync: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      pagination: { pageSize: 10, total: 0, current: 1 },
      columns: [
        {
          dataIndex: '',
          key: 'index',
          title: 'ลำดับ',
          align: 'center',
          width: '8%',
          scopedSlots: { customRender: 'index' },
          // customRender: (text, record, index) => {
          //   if (this.pagination.current > 1) {
          //     return (this.pagination.current - 1) * 10 + index + 1;
          //   } else {
          //     return index + 1;
          //   }
          // },
        },
        {
          dataIndex: 'type',
          key: 'type',
          title: 'ประเภทรายงาน',
          align: 'center',
          width: '15%',
          customRender: (text) => {
            return this.renderReportTitle(text);
          },
        },
        {
          dataIndex: 'dates',
          key: 'dates',
          title: 'ช่วงเวลา',
          align: 'center',
          width: '15%',
        },
        {
          dataIndex: 'vehiclegroup',
          key: 'vehiclegroup',
          title: 'กลุ่มพาหนะ',
          align: 'center',
          width: '15%',
        },
        {
          dataIndex: 'vehicles',
          key: 'vehicles',
          title: 'พาหนะ',
          align: 'center',
          scopedSlots: { customRender: 'vehicles' },
        },
        {
          key: 'action',
          title: 'การดำเนินการ',
          align: 'center',
          width: '15%',
          scopedSlots: { customRender: 'action' },
        },
      ],
      tableData: [],
    };
  },
  watch: {
    dataList(value) {
      this.setDataTable(value);
    },
  },
  mounted() {
    if (this.dataList.length > 0) {
      this.setDataTable(this.dataList);
    }
  },
  methods: {
    isLinkValid(rowDetail) {
      if (rowDetail.url) {
        if (!rowDetail.isLinkExpired) {
          return rowDetail.url;
        }
      }
      return '';
    },
    displayStatusLabel(status) {
      if (status === 'inqueue') {
        return 'คิวถัดไป';
      }
      return 'กำลังดำเนินการ';
    },
    isDisplayStatusLabel(status) {
      return status === 'running' || status === 'inqueue';
    },
    isDisplayDeleteButton(status) {
      return status !== 'running';
    },
    isDisplayLink(status) {
      return status === 'finish';
    },
    isDisplayMoreButton(status) {
      return status === 'waiting';
    },
    onChangeDataTable(pagination) {
      this.pagination.current = pagination.current;
    },
    onClickDeleteButton(id) {
      this.$emit('onClickDeleteButton', id);
    },
    onClickNextInqueue(id) {
      this.$emit('onClickNextInqueue', id);
    },
    onClickLink(url) {
      window.open(url, '_blank');
    },
    renderReportTitle(type) {
      const specialType = specialReportTypeList.find((st) => st.value === type);
      const normalType = normalReportTypeList.find((nt) => nt.value === type);

      if (specialType) {
        return specialType.label;
      } else if (normalType) {
        return normalType.label;
      } else {
        return '-';
      }
    },
    setDataTable(dataList) {
      this.tableData = dataList;
      this.pagination.total = dataList.length;
      this.pagination.current = 1;
    },
    displayVehicleLabel(vehicleList) {
      if (vehicleList.length > 4) {
        return vehicleList.slice(0, 4).join(', ');
      } else {
        return vehicleList.join(', ');
      }
    },
    displayRowNo(index) {
      if (this.pagination.current > 1) {
        return (this.pagination.current - 1) * 10 + index + 1;
      } else {
        return index + 1;
      }
    },
  },
};
</script>
<style lang="scss">
$text-primary: #5e5e5e;

#report-queue__container {
  margin-block-start: 66px;
  box-sizing: border-box;

  #report-table {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      font-size: 16px;
    }
  }
}
</style>
<style lang="scss" scoped>
.row__no {
  display: flex;
  justify-content: center;
  > div {
    position: relative;
    width: fit-content;
    .new {
      position: absolute;
      color: #00c246;
      width: max-content;
      top: -8px;
      right: -25px;
    }
  }
}
.table__action {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .download__button {
    color: #00c246;
    text-decoration: underline;
  }

  .delete__button {
    color: #e821aa;

    &:hover {
      cursor: pointer;
    }
  }

  .status__label {
    color: #00c246;
  }
  .more__button,
  .download__button {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
