<template>
  <div>
    <a-select
      :class="['select-input', 'input-box']"
      style="margin-top: 8px"
      option-filter-prop="children"
      show-search
      showArrow
      placeholder="เลือกสถานี"
      :filter-option="filterOption"
      @change="onChangeGeofence"
      :default-value="defaultValue === '' ? undefined : defaultValue"
    >
      <a-select-option
        v-for="geofence in geofenceOptions"
        :key="geofence.id"
        :value="geofence.id"
      >
        {{ geofence.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'GeofenceSelect',
  props: ['allSelectOptionEnabled', 'defaultValue'],
  emits: ['onSelect'],

  data() {
    return {
      geofenceOptions: [],
    };
  },
  watch: {
    allSelectOptionEnabled() {
      this.initGeofenceOption();
    },
  },
  mounted() {
    this.initGeofenceOption();
  },
  computed: {
    ...mapState({
      geofences: (state) => state.geofence.geofences,
    }),
  },

  methods: {
    onChangeGeofence(id) {
      this.$emit('onSelect', id);
    },
    initGeofenceOption() {
      const geofenceCopied = [...this.geofences];

      if (this.allSelectOptionEnabled) {
        geofenceCopied.unshift({
          id: 'all_geofences_001',
          name: 'ทั้งหมด',
        });
      }

      this.geofenceOptions = geofenceCopied;
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>

<style lang="scss"></style>
