const state = () => ({
  locales: ["en", "th"],
  locale: "en",
  user: null,
  plan: null,
  registerUser: null,
  registerModal: false,
  selectedGroup: null,
  customerGroups: [],
  token: "",
  selectedPage: "",
  alerts: [],
  setting: {},
  showNotification: false,
});

// getters
const getters = {
  registerModal: (state) => state.registerModal,
  user: (state) => state.user,
  groupStr: (state) => state.customerGroups.map((group) => group.id).join(","),
  registerUser: (state) => state.registerUser,
  getToken: (state) => state.token,
  isAuthenticated: (state) => {
    return state.user && state.user.email !== null;
  },
  featurePermissions: (state) => state.user.feature_permissions
};

// actions
const actions = {
  autoSignIn({ commit }, payload) {
    commit("setUser", payload);
  },
  setUser({ commit }, payload) {
    commit("setUser", payload);
  },
  setToken({ commit }, payload) {
    commit("SET_TOKEN", payload);
  },
  setRegisterData({ commit }, payload) {
    commit("setRegisterUser", payload);
  },
  selectGroup({ commit }, payload) {
    commit("SELECT_GROUP", payload);
  },
  showRegisterModal({ commit }, payload) {
    commit("SHOW_MODAL", payload);
  },
  setSelectedPage({ commit }, payload) {
    commit("SET_PAGE", payload);
  },
  setCustomerGroups({ commit }, payload) {
    commit("SET_CUSTOMER_GROUPS", payload);
  },
  setCustomerPlan({ commit }, payload) {
    commit("SET_PLAN", payload)
  },
  addAlert({ commit }, payload) {
    commit("ADD_ALERT", payload);
  },
  setSetting({ commit }, payload) {
    // console.log(payload);
    commit("SET_SETTING", payload);
  },
  toggleShowNotification({ commit }) {
    commit("SHOW_NOTI");
  },
  setShowNotification({ commit }, payload) {
    // console.log(payload);
    commit("SHOW_NOTI", payload);
  },
};

// mutations
const mutations = {
  SET_LANG(state, locale) {
    if (state.locales.includes(locale)) {
      state.locale = locale;
    }
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setRegisterUser(state, payload) {
    state.registerUser = payload;
  },
  updateUser(state, payload) {
    state.user = payload;
  },
  SHOW_MODAL(state, payload) {
    state.registerModal = payload;
  },
  SET_PLAN(state, payload) {
    state.plan = payload
  },
  SELECT_GROUP(state, payload) {
    state.selectedGroup = payload;
  },
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  SET_CUSTOMER_GROUPS(state, payload) {
    state.customerGroups = payload;
  },
  SET_PAGE(state, payload) {
    state.selectedPage = payload;
  },
  ADD_ALERT(state, payload) {
    state.alerts.push(payload);
  },
  SET_SETTING(state, payload) {
    state.setting = payload;
  },
  SHOW_NOTI(state) {
    state.showNotification = !state.showNotification;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
