<template>
  <defaultLayout>
    <div class="setting">
      <a-row :gutter="32">
        <a-col :span="12" :xs="{ span: 24 }" class="display">
          <a-tabs default-active-key="1" @tabClick="onChangeTab">
            <a-tab-pane key="1" tab="หน้าตำแหน่ง">
              <h2>การแสดงผลหน้าตำแหน่ง</h2>
              <p>
                <a-switch
                  @change="onChangePTO"
                  :checked="setting.positionPTO"
                  :loading="saveSettingLoading"
                />
                การเชื่อมต่อ PTO
              </p>
              <p>
                <a-switch
                  @change="onChangeTemp"
                  :checked="setting.positionTemp"
                  :loading="saveSettingLoading"
                />
                อุณหภูมิ
              </p>
              <p>
                <a-switch
                  @change="onChangeFuel"
                  :checked="setting.positionFuel"
                  :loading="saveSettingLoading"
                />
                ระดับเชื้อเพลิง
              </p>
              <p>
                <a-switch
                  @change="onChangeActivedCard"
                  :checked="setting.positionActivedCard"
                  :loading="saveSettingLoading"
                />
                ข้อมูลรูดบัตร
              </p>
              <p>
                <a-switch
                  @change="onVehicleCluster"
                  :checked="setting.displayVehicleCluster"
                  :loading="saveSettingLoading"
                />
                ไม่แสดงรถแบบกลุ่มในแผนที่
              </p>
            </a-tab-pane>
            <a-tab-pane key="2" tab="แจ้งเตือน" force-render>
              <h2>การแจ้งเตือน</h2>
              <p>
                <a-switch
                  @change="onChangeNoti"
                  :checked="setting.notiEnable"
                  :loading="saveSettingLoading"
                />
                เปิดการแจ้งเตือน
              </p>
            </a-tab-pane>
            <a-tab-pane key="3" tab="หน้าประวัติและทริป">
              <h2>การแสดงผลของคอลัมน์</h2>
              <p
                v-for="(column, column_key) in columnDisplayList"
                :key="column_key"
              >
                <a-switch
                  @click="onChangeColumnDisplay(column.name, column.checked)"
                  v-model="column.checked"
                  :checked="column.checked"
                  :loading="saveSettingLoading"
                />
                {{ column.title }}
              </p>
            </a-tab-pane>
          </a-tabs>
        </a-col>
        <a-col :span="12"> </a-col>
      </a-row>
    </div>
  </defaultLayout>
</template>

<script>
// @ is an alias to /src
import defaultLayout from '@/views/layouts/defaultLayout.vue';
import { mapActions, mapState } from 'vuex';
import { saveSetting } from '@/lib/authen';

export default {
  name: 'home',
  components: {
    defaultLayout,
  },
  data() {
    return {
      columnDisplayList: [
        { name: 'columnPTODisplay', title: 'PTO', checked: true },
        { name: 'columnTempDisplay', title: 'Temp', checked: true },
        { name: 'columnTemp2Display', title: 'Temp2', checked: true },
        { name: 'columnDriverDisplay', title: 'คนขับ', checked: false },
        { name: 'columnCardStatusDisplay', title: 'รูดบัตร', checked: false },
        { name: 'columnCanbusDisplay', title: 'Canbus', checked: false },
        { name: "columnDetailedLocationDisplay", title: "ตำแหน่งแบบละเอียด", checked: false },
      ],
    };
  },
  computed: mapState({
    vehicles: (state) => state.vehicle.vehicles,
    selectedVehicle: (state) => state.vehicle.selectedVehicle,
    setting: (state) => state.authen.setting,
    user: (state) => state.authen.user,
    saveSettingLoading: (state) => state.loading.saveSettingLoading,
  }),
  mounted() {
    this.columnDisplayList.forEach((column) => {
      if (Object.hasOwn(this.setting, column.name)) {
        column.checked = this.setting[column.name];
      }
    });
    //////// GA /////////
    this.$gtag.event('setting_position-tab_clicked');
    ////////////////////
  },
  methods: {
    ...mapActions({
      setSetting: 'authen/setSetting',
    }),
    onChangeTab(tab) {
      //////// GA /////////
      this.$gtag.event(
        tab == 1
          ? 'setting_position-tab_clicked'
          : 'setting_notification-tab_clicked'
      );
      ////////////////////
    },
    onChangeColumnDisplay(column, checked) {
      this.changeSetting(column, checked);
    },
    onChangePTO(checked) {
      this.changeSetting('positionPTO', checked);
    },
    onChangeTemp(checked) {
      this.changeSetting('positionTemp', checked);
    },
    onChangeFuel(checked) {
      this.changeSetting('positionFuel', checked);
    },
    onChangeNoti(checked) {
      this.changeSetting('notiEnable', checked);
    },
    onChangeActivedCard(checked) {
      this.changeSetting('positionActivedCard', checked);
    },
    onVehicleCluster(checked) {
      this.changeSetting('displayVehicleCluster', checked);
    },
    async changeSetting(settingName, checked) {
      this.setting[settingName] = checked;
      // console.log(this.setting);
      let saveResult = await saveSetting({
        userID: this.user.id,
        setting: this.setting,
      });
      if (saveResult.data.updateUser.user) {
        this.setSetting(this.setting);
        this.$message.success('บันทึกการตั้งค่าแล้ว');
      } else {
        this.$message.error('ไม่สามารถบันทึกค่าได้');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$md: 768px;
$xs: 414px;

.setting {
  padding: 50px 100px;
  @media screen and (max-width: $md) {
    padding: 20px 30px;
  }

  h1 {
    font-size: 1.6rem;
    font-weight: bold;
  }
  .display {
    background-color: #f6f6f6;
    border-radius: 7px;
    padding: 20px 30px !important;
    font-weight: 200 !important;
  }
}
</style>
