<template>
  <defaultLayout>
    <div class="home">
      <deviceDrawer
        :clickVehicle="clickVehicle"
        v-show="true"
        :googleMap="this.$refs.googleMap"
      />
      <div class="google-map">
        <div class="google-map-toolbar">
          <div class="toggle-geofence-button" @click="toggleGeofence">
            <a-checkbox :checked="showGeofence" />&nbsp;แสดงสถานี
          </div>
          <div class="toggle-traffic-button" @click="toggleTraffic">
            <a-checkbox :checked="showTraffic" />&nbsp;Traffic
          </div>
          <div
            class="google-map-button"
            @click="showGeofenceModal"
            v-show="selectedVehicle.last_position_json"
          >
            สร้างสถานี
          </div>
        </div>
        <div class="custom-map-toolbar">
          <a-button
            @click="getCurrentLocation"
            style="margin-right: 0px; width: 26px"
            icon="heat-map"
          ></a-button>
        </div>

        <GmapMap
          :center="{ lat: 13.7460067, lng: 100.5440535 }"
          :zoom="18"
          map-type-id="roadmap"
          style="width: 100%; height: 100%"
          ref="googleMap"
          :options="{
            // minZoom: 6,
            //maxZoom: 17,
            controlSize: 26,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: false,
            streetViewControl: true,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: true,
            zoomControlOptions: {
              position: 3,
            },
            streetViewControlOptions: {
              position: 3,
            },
            clickableIcons: true,
          }"
          @drag="startDragMap"
          @bounds_changed="startDragMap"
          @idle="endDragMap"
          @zoom_changed="zoomLevelChange"
        >
          <gmap-custom-marker
            v-if="showMyLocationMarker"
            ref="myLocation"
            :position="
              google &&
              new google.maps.LatLng(myCurrentLocationLat, myCurrentLocationLng)
            "
            :clickable="true"
            :draggable="true"
            @click="showGeofenceModal()"
            @dragend="updateMyCoordinates"
          />
          <template>
            <cluster
              :minimumClusterSize="
                'displayVehicleCluster' in setting &&
                setting.displayVehicleCluster === true
                  ? 999
                  : 2
              "
              position="center"
              :zoomOnClick="true"
              :maxZoom="12"
              clickable="true"
              animation="0"
            >
              <template v-for="(vehicle, index) in allVehicles">
                <CustomMarker
                  :zIndex="
                    selectedVehicle && selectedVehicle.id === vehicle.id
                      ? 55
                      : 50
                  "
                  :key="`${index}-${vehicle.id}`"
                  v-if="
                    vehicle.device &&
                    vehicle.device.position &&
                    'latitude' in vehicle.device.position
                  "
                  :marker="{
                    lat:
                      vehicle.device && vehicle.device.position
                        ? vehicle.device.position.latitude / 10000000
                        : 0,
                    lng:
                      vehicle.device && vehicle.device.position
                        ? vehicle.device.position.longitude / 10000000
                        : 0,
                  }"
                  alignment="center"
                  @click.native="clickVehicle(vehicle)"
                  :mapZoom="mapZoom"
                >
                  <div style="">
                    <img
                      :class="[
                        ['device-marker', ImgSizeChange(vehicle.vehicle_icon)],
                        selectedVehicle && selectedVehicle.id === vehicle.id
                          ? 'active-vehicle'
                          : '',
                        getFilterClass(
                          vehicle.device && vehicle.device.position
                            ? checkOverSpeed(
                                vehicle.device.position.engineStatus,
                                vehicle.device.position.speed,
                                vehicle.overspeed_limit
                              )
                            : 'ENGINE_STOP'
                        ),
                      ]"
                      :src="carIcon(vehicle.vehicle_icon)"
                      v-bind:style="{
                        transform: getDirectionClass(
                          vehicle.device && vehicle.device.position
                            ? vehicle.device.position.angle
                            : 0
                        ),
                      }"
                    />
                  </div>
                  <div
                    :key="`pulse${vehicle.id}`"
                    :class="[
                      'pulse',
                      selectedVehicle && selectedVehicle.id === vehicle.id
                        ? 'active-vehicle-pulse'
                        : 'inactive-vehicle-pulse',
                    ]"
                  ></div>

                  <div class="device-summary">
                    <h3>{{ vehicle.name }}</h3>
                    <p
                      v-if="
                        vehicle.device &&
                        vehicle.device.position &&
                        vehicle.device.position.speed > 0
                      "
                    >
                      {{
                        vehicle.device && vehicle.device.position
                          ? vehicle.device.position.speed
                          : 0
                      }}
                      Km/Hr
                    </p>
                    <!-- <p class="vehicle-geolocation" v-if="vehicle.lastLocation">
                      {{
                        `${vehicle.lastLocation.district} ${vehicle.lastLocation.province}`
                      }}
                    </p> -->
                    <div
                      v-if="
                        vehicle.device.position.mcr_license_no &&
                        vehicle.device.position.mcr_license_no.length > 0 &&
                        getDriverNameByLicenseNo(
                          vehicle.device.position.mcr_license_no
                        ) &&
                        getDriverNameByLicenseNo(
                          vehicle.device.position.mcr_license_no
                        ).name
                      "
                      class="driver-name"
                      style="display: flex"
                    >
                      <div
                        v-if="
                          vehicle.device.position.mcr_license_no &&
                          vehicle.device.position.mcr_license_no.length > 0 &&
                          getDriverNameByLicenseNo(
                            vehicle.device.position.mcr_license_no
                          ) &&
                          getDriverNameByLicenseNo(
                            vehicle.device.position.mcr_license_no
                          ).photo &&
                          getDriverNameByLicenseNo(
                            vehicle.device.position.mcr_license_no
                          ).photo.url.length > 0
                        "
                      >
                        <img
                          :src="`${
                            getDriverNameByLicenseNo(
                              vehicle.device.position.mcr_license_no
                            ).photo.url
                          }`"
                          style="
                            margin: 4px 5px 4px 5px;
                            border: 1px solid #513fe8;
                            border-radius: 12px;
                          "
                          width="24px"
                          height="24px"
                        />
                      </div>
                      <div v-else>
                        <a-avatar
                          :size="24"
                          icon="user"
                          style="margin: 4px 5px 4px 5px; border-radius: 12px"
                        />
                      </div>
                      <p
                        style="
                          text-align: left;

                          letter-spacing: 0px;
                          color: #000000;
                          opacity: 1;
                          margin: 4px 15px 8px 0px;
                        "
                      >
                        {{
                          vehicle.device.position.mcr_license_no &&
                          vehicle.device.position.mcr_license_no.length > 0 &&
                          getDriverNameByLicenseNo(
                            vehicle.device.position.mcr_license_no
                          )
                            ? getDriverNameByLicenseNo(
                                vehicle.device.position.mcr_license_no
                              ).name
                            : '-'
                        }}
                      </p>
                    </div>
                  </div>
                </CustomMarker>
              </template>
            </cluster>
          </template>
          <template
            v-if="
              mapMoving === false &&
              mapZoom < 17 &&
              showBoundryGeofence === false &&
              showGeofence === true
            "
          >
            <gmap-marker
              v-for="(m, index) in geofencesList"
              v-bind:key="index"
              :position="{
                lat: parseFloat(m.Positions[0].lat),
                lng: parseFloat(m.Positions[0].lng),
              }"
              :clickable="true"
              :draggable="false"
              @mouseover="showByIndex = index"
              @mouseout="showByIndex = null"
              @click="setCenterPath(m)"
            >
              <gmap-info-window :opened="showByIndex === index">
                สถานี {{ m.name }}
              </gmap-info-window>
            </gmap-marker>
          </template>
          <template
            v-if="
              mapMoving === false &&
              showGeofence === true &&
              showBoundryGeofence === true
            "
          >
            <GmapCircle
              v-for="(geofence, index) in geofencesCircle"
              :key="`geofence-circle-${index}`"
              :center="geofence.Positions[0]"
              :radius="geofence.radius"
              :options="{
                fillColor: '#00c246',
                fillOpacity: 0.3,
                strokeColor: '#00c246',
                strokeOpacity: 0.8,
              }"
            ></GmapCircle>
            <GmapPolygon
              v-for="(geofence, index) in geofencesPolygon"
              :key="`geofence-polygon-${index}`"
              :path="geofence.Positions"
              :visible="showGeofence"
              :options="{
                fillColor: '#00c246',
                fillOpacity: 0.3,
                strokeColor: '#00c246',
                strokeOpacity: 0.8,
              }"
            ></GmapPolygon>
            <template v-if="mapZoom >= 17">
              <gmap-custom-marker
                v-for="(geofence, index) in geofencesList"
                :key="`geofence-name-${index}`"
                :marker="geofence.Positions[0]"
                alignment="center"
              >
                <div class="geofence-name">
                  สถานี
                  <br />
                  {{ geofence.name }}
                </div>
              </gmap-custom-marker>
            </template>
          </template>
        </GmapMap>
        <deviceInfo :selectedVehicle="selectedVehicle" />
      </div>
    </div>
    <a-modal
      :visible="createGeofenceModalVisible"
      :confirm-loading="saveGeofenceLoading"
      @ok="createGeofenceSubmit"
      @cancel="cancelCreateGeofence"
    >
      <h2>สร้างสถานีจากตำแหน่ง {{ selectedVehicle.plate_number }}</h2>
      <a-form layout="inline" :form="form">
        <a-row>
          <a-col :span="24">
            <a-input
              placeholder="ชื่อสถานี"
              :maxLength="30"
              v-model="geofenceName"
            />
          </a-col>
        </a-row>
        <a-row style="margin-top: 10px">
          <a-col :span="24">
            <a-input
              type="number"
              addon-before="รัศมี"
              addon-after="เมตร"
              v-model="geofenceRadius"
              :min="10"
              :max="500.0"
              :step="10"
            />
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </defaultLayout>
</template>

<script>
// @ is an alias to /src
import defaultLayout from '@/views/layouts/defaultLayout.vue';
import { getGoogleMapsAPI } from 'vue2-google-maps';
import { mapActions, mapState, mapGetters } from 'vuex';
import CustomMarker from '@/views/components/customMarker';
import deviceDrawer from '@/views/components/deviceDrawer';
import deviceInfo from '@/views/components/deviceInfo';
import { getVehicles } from '@/lib/vehicle';
import { connectPositionSocket } from '@/vue-socket';
import moment from '@/lib/time';
import MeasureTool from 'measuretool-googlemaps-v3';
const geolib = require('geolib');
let trafficLayer = null;
export default {
  name: 'home',
  components: {
    defaultLayout,
    deviceDrawer,
    deviceInfo,
    CustomMarker,
    'gmap-custom-marker': CustomMarker,
  },
  data() {
    return {
      showByIndex: null,
      showMyLocationMarker: false,
      boundRect: [],
      showBoundryGeofence: false,
      mapMoving: false,
      mapZoom: 12,
      googleMap: {},
      createGeofenceModalVisible: false,
      geofenceRadius: 0.0,
      geofenceName: '',
      form: this.$form.createForm(this, { name: 'createGeofenceForm' }),
      showGeofence: false,
      showTraffic: false,
      geofencesCircle: [],
      geofencesPolygon: [],
      geofencesList: [],
      myCurrentLocationLat: 0,
      myCurrentLocationLng: 0,
      // DisplayInitTime: ""

      //MD
      measureTool: null,
    };
  },
  beforeDestroy() {
    this.resetVehicleState();
    this.resetPositionState();
    this.resetGeofenceState();
  },

  computed: mapState({
    google: getGoogleMapsAPI,
    selectedGroup: (state) => state.authen.selectedGroup,
    user: (state) => state.authen.user,
    vehicleGroups: (state) => state.vehicle.vehicleGroups,
    selectedVehicle: (state) => state.vehicle.selectedVehicle,
    setting: (state) => state.authen.setting,
    alerts: (state) => state.authen.alerts,
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      vehicles: 'vehicle/vehicles',
      vehicle: 'vehicle/getSelectedVehicle',
      driverByLicenseNo: 'driver/driverByLicenseNo',
    }),
    saveGeofenceLoading: (state) => state.loading.saveGeofenceLoading,
    geofenceData: (state) => state.geofence.geofences,
    drivers: (state) => state.driver.drivers,
    licensetypes: (state) => state.licensetype.licensetypes,
    allVehicles: (state) => {
      let allV = state.vehicle.vehicleGroups.map((group) => {
        return group.vehicles.filter((vehicle) => {
          return (
            vehicle.device &&
            vehicle.device.imei &&
            vehicle.device.position &&
            vehicle.device.position.latitude
          );
        });
      });
      let flat = [];
      if (allV.length > 0) {
        flat = allV.reduce((a, b) => {
          return a.concat(b);
        }, []);
      }
      return flat;
    },
    isTracking: (state) => state.vehicle.isTracking,
  }),
  sockets: {
    connect: function () {
      console.log('socket connected');
    },
    customEmit: function (data) {
      console.log(
        'this method was fired by the socket server. eg: io.emit("customEmit", data)',
        data
      );
    },
  },
  async mounted() {
    if (this.vehicles.length <= 0) {
      if (this.user.customer_groups) {
        await getVehicles(
          this.user.customer_groups.map((group) => {
            return group._id;
          })
        );
      }
    }

    //get all drivers
    if (this.drivers.length <= 0) this.getAllDrivers([this.selectedGroup.id]);

    //get all license types
    if (this.licensetypes.length <= 0) this.getAlllicensetypes();

    // console.log("connectPositionSocket mounted")
    connectPositionSocket();
    this.setCenterAll();

    //Measure distance
    this.$refs.googleMap.$mapPromise.then((map) => {
      this.measureTool = new MeasureTool(map);
    });
  },

  watch: {
    vehicle: function (newVehicle) {
      const map = this.$refs.googleMap;
      if (map && this.isTracking) {
        map.$mapPromise.then((map) => {
          if (
            map &&
            newVehicle &&
            newVehicle.device &&
            newVehicle.device.position &&
            newVehicle.device.position.latitude &&
            newVehicle.device.position.longitude
          ) {
            // console.log(newVehicle.device.position);
            map.setCenter(
              new window.google.maps.LatLng(
                newVehicle.device.position.latitude / 10000000,
                newVehicle.device.position.longitude / 10000000
              )
            );
          }
        });
      }
    },
    vehicleGroups: function (newVehicleGroup, oldVehicleGroup) {
      if (
        this.$refs.googleMap &&
        this.$refs.googleMap.$mapPromise &&
        newVehicleGroup[0] &&
        oldVehicleGroup[0] &&
        'vehicles' in newVehicleGroup[0] &&
        'vehicles' in oldVehicleGroup[0] &&
        newVehicleGroup[0].vehicles[0].id != oldVehicleGroup[0].vehicles[0].id
      ) {
        this.$refs.googleMap.$mapPromise.then((map) => {
          let bounds = new window.google.maps.LatLngBounds();
          newVehicleGroup[0] &&
            newVehicleGroup[0].vehicles.forEach((vehicle) => {
              // console.log(vehicle.last_position_json.latitude);
              if (
                vehicle.last_position_json &&
                vehicle.last_position_json.latitude
              ) {
                bounds.extend({
                  lat: vehicle.last_position_json.latitude / 10000000,
                  lng: vehicle.last_position_json.longitude / 10000000,
                });
              }
            });
          map.fitBounds(bounds);
          map.panTo(bounds.getCenter());
        });
      }
    },
  },

  methods: {
    ...mapActions({
      selectVehicle: 'vehicle/selectVehicle',
      setLoading: 'loading/setLoading',
      showVehicleInfo: 'vehicle/showVehicleBottomInfo',
      resetVehicleState: 'vehicle/resetState',
      resetPositionState: 'position/resetState',
      //geofence Actions
      createGeofence: 'geofence/createGeofence',
      getAllGeofences: 'geofence/getAllGeofences',
      getAllDrivers: 'driver/getAllDrivers',
      getAlllicensetypes: 'licensetype/getAlllicensetypes',
      setSaveGeofenceLoading: 'loading/setSaveGeofenceLoading',
      resetGeofenceState: 'geofence/resetState',
      addAlert: 'authen/addAlert',
    }),
    moment,
    sleep(m) {
      return new Promise((r) => setTimeout(r, m));
    },

    clickVehicle(vehicle) {
      if (!vehicle.device) return;
      if (this.$refs.googleMap && this.$refs.googleMap.$mapPromise) {
        this.$refs.googleMap.$mapPromise.then((map) => {
          if (
            vehicle.device.position &&
            'latitude' in vehicle.device.position
          ) {
            map.panTo({
              lat: vehicle.device.position.latitude / 10000000,
              lng: vehicle.device.position.longitude / 10000000,
            });
            map.setZoom(17);
          }
        });
      }

      this.selectVehicle(vehicle);
      if (this.selectedVehicle && this.selectedVehicle.last_position_json) {
        this.showVehicleInfo(true);
      } else {
        this.showVehicleInfo(false);
      }
      setTimeout(() => {
        let el = document.getElementsByClassName('heliot-info-car active');
        if (el && el.length > 0) {
          el[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
      }, 500);
    },
    carIcon(type) {
      let file = 'top-car4';
      if (type == 'truck') {
        file = 'truck';
      }
      if (type == 'sedan') {
        file = 'sedan_new';
      }
      if (type == 'bigTruck') {
        file = 'bigTruck';
      }
      if (type == 'machine') {
        file = 'machine';
      }
      if (type == 'concreteTruck') {
        file = 'concreteTruck';
      }
      if (type == 'trailer') {
        file = 'trailer';
      }
      // console.log(file);
      return require(`../assets/${file}.png`);
    },
    ImgSizeChange(type) {
      // const ChangeSizeType = [null];
      if (type === null) {
        return 'normal-size';
      }
      if (type === 'sedan' || type === 'truck') {
        return 'sedan-truck-size';
      }
      return '';
    },
    getDriverNameByLicenseNo(licenseNo) {
      return this.driverByLicenseNo(licenseNo);
    },
    checkOverSpeed(enginestatus, speed, overspeed) {
      if (speed >= overspeed) {
        return 'OVER_SPEED';
      } else {
        return enginestatus;
      }
    },
    getFilterClass(status) {
      if (status == 'VEHICLE_MOVING') return 'running';
      else if (status == 'ENGINE_IDLE') return 'idle';
      else if (status == 'OVER_SPEED') return 'overspeed';
      else return 'stop';
    },
    getDirectionClass(degree) {
      const direction = degree / 100;
      return `rotate(${direction}deg)`;
    },

    onCloseDrawer() {
      this.bottomDrawer = false;
    },
    async toggleGeofence() {
      //////// GA /////////
      this.$gtag.event('track_google-map-geofences_displayed');
      ////////////////////
      this.showGeofence = !this.showGeofence;
      if (this.showGeofence) {
        if (this.geofenceData.length <= 0) {
          await this.getAllGeofences([this.selectedGroup.id]);
        }
        this.setGeofenceData();
      }
    },

    setCenterPath(geofence) {
      if (this.$refs.googleMap && this.$refs.googleMap.$mapPromise) {
        if (geofence.type == 'circle') {
          this.$refs.googleMap.$mapPromise.then((map) => {
            const circle = new window.google.maps.Circle({
              map: map,
              radius: geofence.radius, // 3000 km
              center: {
                lat: parseFloat(geofence.Positions[0].lat),
                lng: parseFloat(geofence.Positions[0].lng),
              },
              fillColor: '#fff',
              fillOpacity: 0.0,
              strokeColor: '#fff',
              strokeOpacity: 0.0,
            });

            var bounds = new window.google.maps.LatLngBounds();
            bounds.union(circle.getBounds());
            map.fitBounds(bounds);
            map.panTo(circle.getBounds().getCenter());
          });
        } else {
          this.$refs.googleMap.$mapPromise.then((map) => {
            let bounds = new window.google.maps.LatLngBounds();
            for (let i = 0; i < geofence.Positions.length; i++) {
              bounds.extend(geofence.Positions[i]);
            }
            map.fitBounds(bounds);
            map.panTo(bounds.getCenter());
            // map.setZoom(16);
          });
        }
      }
    },

    toggleTraffic() {
      //////// GA /////////
      this.$gtag.event('track_google-map-traffic_displayed');
      ////////////////////
      if (this.$refs.googleMap && this.$refs.googleMap.$mapPromise) {
        this.showTraffic = !this.showTraffic;
        if (this.showTraffic === true) {
          this.$refs.googleMap.$mapPromise.then((map) => {
            trafficLayer = new window.google.maps.TrafficLayer();
            trafficLayer.setMap(map);
          });
        } else {
          trafficLayer.setMap(null);
        }
      }
    },

    setCenterAll() {
      // console.log("setCenterAll");
      if (this.$refs.googleMap && this.$refs.googleMap.$mapPromise) {
        this.$refs.googleMap.$mapPromise.then((map) => {
          let bounds = new window.google.maps.LatLngBounds();
          this.allVehicles.forEach((vehicle) => {
            if (
              vehicle.device &&
              vehicle.device.position &&
              vehicle.device.position.latitude &&
              vehicle.device.position.longitude
            ) {
              bounds.extend({
                lat: vehicle.device.position.latitude / 10000000,

                lng: vehicle.device.position.longitude / 10000000,
              });
            }
          });

          map.fitBounds(bounds);
          map.panTo(bounds.getCenter());
        });
      }
    },
    updateMyCoordinates(location) {
      this.myCurrentLocationLat = location.latLng.lat();
      this.myCurrentLocationLng = location.latLng.lng();
    },
    getCurrentLocation() {
      if (this.showMyLocationMarker) {
        this.showMyLocationMarker = false;
        this.myCurrentLocationLat = 0;
        this.myCurrentLocationLng = 0;
      } else {
        if (!('geolocation' in navigator)) {
          this.showMyLocationMarker = false;
          alert('ไม่สามารถดึงข้อมูลที่อยู่ได้');
          return;
        }
        // get position
        navigator.geolocation.getCurrentPosition(
          (pos) => {
            // console.log(pos);
            if (
              pos &&
              pos.coords &&
              pos.coords.latitude &&
              pos.coords.longitude
            ) {
              this.myCurrentLocationLat = Number(
                pos.coords.latitude.toFixed(6)
              );
              this.myCurrentLocationLng = Number(
                pos.coords.longitude.toFixed(6)
              );
              // console.log(this.myCurrentLocationLat, this.myCurrentLocationLng);
              this.showMyLocationMarker = true;
              this.$refs.googleMap.$mapPromise.then((map) => {
                const circle = new window.google.maps.Circle({
                  map: map,
                  radius: 50,
                  center: {
                    lat: this.myCurrentLocationLat,
                    lng: this.myCurrentLocationLng,
                  },
                  fillColor: '#fff',
                  fillOpacity: 0.0,
                  strokeColor: '#fff',
                  strokeOpacity: 0.0,
                });

                var bounds = new window.google.maps.LatLngBounds();
                bounds.union(circle.getBounds());
                map.fitBounds(bounds);
                map.panTo(circle.getBounds().getCenter());
                // map.setZoom(16);
              });
            }
          },
          (err) => {
            this.showMyLocationMarker = false;
            alert('เกิดข้อผิดพลาด ไม่สามารถดึงข้อมูลที่อยู่ได้');
            console.log(err);
          }
        );
      }
    },
    showGeofenceModal() {
      //////// GA /////////
      this.$gtag.event('track_g-map-geofences-creating_clicked');
      ////////////////////
      this.createGeofenceModalVisible = true;
    },
    async createGeofenceSubmit() {
      // console.log(this.selectedVehicle, this.geofenceName, this.geofenceRadius);
      let latitude = 0;
      let longitude = 0;
      if (
        this.selectedVehicle.last_position_json &&
        this.selectedVehicle.last_position_json.latitude &&
        this.geofenceName.length > 0 &&
        this.geofenceRadius > 0
      ) {
        latitude = this.selectedVehicle.last_position_json.latitude / 10000000;
        longitude =
          this.selectedVehicle.last_position_json.longitude / 10000000;
      } else if (
        this.showMyLocationMarker &&
        this.myCurrentLocationLng > 0 &&
        this.myCurrentLocationLat > 0
      ) {
        latitude = this.myCurrentLocationLat;
        longitude = this.myCurrentLocationLng;
      } else {
        this.$notification['error']({
          message: 'ข้อมูลสถานีไม่ถูกต้อง',
          description: 'กรุณาตรวจสอบข้อมูลสถานี และมีพาหนะที่กำลังเลือกอยู่',
        });
        return;
      }

      this.setSaveGeofenceLoading(true);
      let geojson = {
        type: 'Point',
        coordinates: [longitude, latitude],
      };
      await this.createGeofence({
        name: this.geofenceName,
        type: 'circle',
        radius: parseFloat(this.geofenceRadius),
        org: this.selectedGroup.id,
        positions: [
          {
            lat: latitude,
            lng: longitude,
          },
        ],
        geojson,
      })
        .then(async (saveResult) => {
          if (saveResult) {
            this.showMyLocationMarker = false;
            this.myCurrentLocationLat = 0;
            this.myCurrentLocationLng = 0;
            this.setSaveGeofenceLoading(false);
            this.geofenceName = '';
            this.geofenceRadius = 0.0;
            this.createGeofenceModalVisible = false;

            await this.getAllGeofences([this.selectedGroup.id]);

            this.$notification['success']({
              message: 'บันทึกข้อมูลเรียบร้อยแล้ว',
              description:
                'ข้อมูสถานีถูกบันทึกเรียบร้อยแล้ว การประมวลผลการเข้าออกสถานีจะใช้เวลาหลังจากสร้างสถานี 5-10 นาที',
            });
          } else {
            this.$notification['error']({
              message: 'เกิดข้อผิดพลาด',
              description: 'ไม่สามารถบันทึกข้อมูลสถานีได้ กรุณาลองอีกครั้ง',
            });
            this.setSaveGeofenceLoading(false);
          }
        })
        .catch((err) => {
          this.showMyLocationMarker = false;
          this.myCurrentLocationLat = 0;
          this.myCurrentLocationLng = 0;
          console.log(err);
          this.$notification['error']({
            message: 'เกิดข้อผิดพลาด',
            description: 'ไม่สามารถบันทึกข้อมูลสถานีได้ กรุณาลองอีกครั้ง',
          });
          this.setSaveGeofenceLoading(false);
        });
    },
    cancelCreateGeofence() {
      this.createGeofenceModalVisible = false;
    },
    checkIsShowOnNearby(vehicle) {
      if (this.isTracking) {
        return true;
      } else {
        console.log(
          this.selectedVehicle.id,
          vehicle.id,
          this.selectedVehicle && this.selectedVehicle.id === vehicle.id
        );
        if (this.selectedVehicle && this.selectedVehicle.id === vehicle.id) {
          return true;
        } else {
          return false;
        }
      }
    },
    setGeofenceData() {
      this.geofencesCircle = [];
      this.geofencesPolygon = [];
      this.geofencesList = [];
      this.$nextTick(() => {
        for (const k in this.geofenceData) {
          const geofence = this.geofenceData[k];
          let isIn = false;
          if (this.boundRect.length > 0) {
            if (geofence.type == 'polygon') {
              let centerPolygon = geolib.getCenterOfBounds(geofence.Positions);
              isIn = geolib.isPointInPolygon(
                {
                  latitude: centerPolygon.latitude,
                  longitude: centerPolygon.longitude,
                },
                this.boundRect
              );
            } else {
              isIn = geolib.isPointInPolygon(
                {
                  latitude: geofence.Positions[0].lat,
                  longitude: geofence.Positions[0].lng,
                },
                this.boundRect
              );
            }
          } else {
            isIn = true;
          }
          if (isIn) {
            if (geofence.type == 'circle') {
              this.geofencesCircle.push(geofence);
            } else if (geofence.type == 'polygon') {
              this.geofencesPolygon.push(geofence);
            }
            this.geofencesList.push(geofence);
          }
        }
        return this.geofencesList;
      });
    },
    startDragMap() {
      this.mapMoving = true;
    },
    endDragMap() {
      setTimeout(() => {
        if (this.$refs.googleMap && this.$refs.googleMap.$mapObject) {
          const neBound = this.$refs.googleMap.$mapObject
            .getBounds()
            .getNorthEast();
          const swBound = this.$refs.googleMap.$mapObject
            .getBounds()
            .getSouthWest();

          const lat0 = neBound.lat();
          const lng0 = neBound.lng();
          const lat1 = swBound.lat();
          const lng1 = swBound.lng();
          const boundRect = [
            { latitude: lat0, longitude: lng0 },
            { latitude: lat0, longitude: lng1 },
            { latitude: lat1, longitude: lng1 },
            { latitude: lat1, longitude: lng0 },
          ];
          if (this.mapMoving === true) {
            this.mapMoving = false;
            this.setGeofenceData();
            this.boundRect = boundRect;
          } else {
            this.boundRect = [];
          }
        }
        // console.log(this.boundRect);
      }, 800);
    },
    zoomLevelChange(zoomLevel) {
      this.mapZoom = zoomLevel;
      let obj = this;
      setTimeout(() => {
        if (zoomLevel >= 17) {
          obj.showBoundryGeofence = true;
        } else {
          obj.showBoundryGeofence = false;
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
$md: 768px;
$xs: 414px;
.driver-name {
  margin-left: 5px;
  left: -7px;
  position: relative;
  width: 100px;
  height: 32px;
  border-radius: 7px;
  opacity: 1;
}

.google-map {
  @media screen and (max-width: $md) {
    width: calc(100vw - 120px);
  }
  width: calc(100vw - 370px);
  height: 100vh;
  display: inline-block;
  position: absolute;
}
.device-marker {
  &.normal-size {
    width: 45px;
    top: -20px;
  }
  &.sedan-truck-size {
    width: 31px;
    top: -20px;
  }
  width: 25px;
  position: absolute;
  z-index: 99;
  left: -20px;
  top: -29px;
}

.active-vehicle-pulse {
  // margin-left: -30px;
  // margin-top: -30px;
  border: 50px solid #00c246;
  background: transparent;
  border-radius: 120px;
  height: 80px;
  width: 80px;
  -webkit-animation: pulse 1s linear;
  -moz-animation: pulse 1s linear;
  animation: pulse 1s linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  z-index: 1;
  opacity: 0;
  // display: block;
  position: absolute;
  top: -50px;
  left: -50px;
}
.inactive-vehicle-pulse {
  display: none;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(0.75);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.device-summary {
  border: 1px solid #513fe8;
  background: #dbdbdb 0% 0% no-repeat padding-box;
  //background-color: rgba(0, 0, 0, 0.6);
  opacity: 0.8;
  padding: 5px;
  z-index: 100;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  line-height: 14px;
  display: inline-block;
  position: absolute;
  top: 20px;
  left: -50px;
  margin: 0px;
  white-space: nowrap;
  h3,
  p {
    font-size: 0.9rem;
    color: #000000;
    margin: 0px;
    &.vehicle-geolocation {
      margin-top: 3px;
    }
  }
}

.running {
  // filter: invert(41%) sepia(72%) saturate(1296%) hue-rotate(87deg)
  //   brightness(102%) contrast(111%);
  filter: grayscale(50%) hue-rotate(240deg) saturate(3.3);
}
.idle {
  filter: grayscale(32%) hue-rotate(210deg) saturate(3.8);
}
.stop {
  // filter: invert(56%) sepia(5%) saturate(16%) hue-rotate(136deg) brightness(95%)
  //   contrast(88%);
  filter: grayscale(30%) hue-rotate(153deg) saturate(3.3);
}

.overspeed {
  //filter: invert(24%) sepia(90%) saturate(3678%) hue-rotate(258deg) brightness(65%) contrast(90%);
  filter: grayscale(58%) hue-rotate(68deg) saturate(2.5);
}

.custom-map-toolbar {
  float: right;
  position: relative;
  @media screen and (max-width: $xs) {
    right: 10px;
    top: 120px;
  }
  right: 5px;
  top: 130px;
  z-index: 100;
}

.google-map-toolbar {
  direction: ltr;
  overflow: hidden;
  text-align: center;
  // height: 40px;
  display: table-cell;
  vertical-align: middle;
  user-select: none;
  background-color: rgb(255, 255, 255);
  padding: 0px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  min-width: 55px;
  border-left: 0px;
  z-index: 100;
  position: absolute;
  @media screen and (max-width: $xs) {
    left: 8px;
    top: 35px;
  }
  left: 176px;
  top: 6px;

  .google-map-button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    height: 26px;
    padding: 3px 5px;
    font-size: 0.9rem;
    color: rgb(86, 86, 86);
    font-family: 'Prompt', Arial, sans-serif;
    font-weight: bold;
    &:hover {
      background-color: #ddd;
    }
  }

  .toggle-geofence-button {
    position: relative;
    display: inline-block;
    height: 26px;
    cursor: pointer;
    padding: 3px 5px;
    font-size: 0.9rem;
    color: rgb(86, 86, 86);
    font-family: 'Prompt', Arial, sans-serif;
    font-weight: bold;
    &:hover {
      background-color: #ddd;
    }
  }
  .toggle-traffic-button {
    position: relative;
    display: inline-block;
    height: 26px;
    cursor: pointer;
    padding: 3px 5px;
    font-size: 0.9rem;
    color: rgb(86, 86, 86);
    font-family: 'Prompt', Arial, sans-serif;
    font-weight: bold;
    &:hover {
      background-color: #ddd;
    }
  }
}

//Measure Distance
.segment-measure-text {
  font-size: 1rem;
  pointer-events: none;
  text-shadow: none;

  /* &.inverted {
    stroke: white;
    text-shadow: -2.4px -2.4px $alpha-black, -2.4px 2.4px $alpha-black,
      2.4px 2.4px $alpha-black, 2.4px -2.4px $alpha-black, -2.4px 0 $alpha-black,
      0 2.4px $alpha-black, 2.4px 0 $alpha-black, 0 -2.4px $alpha-black;
  } */
}

.node-measure-text {
  font-size: 1rem;
  pointer-events: none;
  text-shadow: none;

  /* &.inverted {
    stroke: white;
    text-shadow: -2.4px -2.4px $alpha-black, -2.4px 2.4px $alpha-black,
      2.4px 2.4px $alpha-black, 2.4px -2.4px $alpha-black, -2.4px 0 $alpha-black,
      0 2.4px $alpha-black, 2.4px 0 $alpha-black, 0 -2.4px $alpha-black;
  } */

  &.head-text {
    visibility: hidden;
  }
}
</style>
