import gql from "graphql-tag";
import { AUTH_TOKEN, apolloClient } from "../vue-apollo";
import { restartWebsockets } from "vue-cli-plugin-apollo/graphql-client";
import store from "../store";
import router from "../router";
import { positionSocketConnect } from "../vue-socket";
import axios from "axios";
import updateUserSetting from "@/graphql/updateUserSetting.gql";
import getLastReadGQL from "@/graphql/getLastRead.gql";
import { apolloClient as strapiApolloClient } from "../strapi-apollo";
import moment from "@/lib/time";

import endpoints from "../config/config";

export const socialGQLSignIn = async (firebaseToken) => {
  store.dispatch("loading/setLoading", true);
  const result = await apolloClient
    .mutate({
      // Query
      mutation: gql`
        mutation($firebaseToken: String!) {
          signIn(firebaseToken: $firebaseToken) {
            id
            uid
            name
            display_name
            email
            email_verified
            referer
            utm_source
            utm_medium
            utm_campaign
            phone_number
            photo_url
            privider_uid
            group
            role {
              name
              label
            }

            token
          }
        }
      `,
      variables: {
        firebaseToken: firebaseToken,
      },
    })
    .catch((err) => {
      console.log(err);
      return;
    });

  if (result && result.data.signIn.token) {
    localStorage.setItem(AUTH_TOKEN, result.data.signIn.token);

    await store.dispatch("authen/setUser", result.data.signIn);
    await store.dispatch("loading/setLoading", false);
    return true;
  } else {
    await store.dispatch("loading/setLoading", false);
    signOut("กรุณาลงทะเบียนเพื่อทดลองใช้บริการ");
  }

  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log("%cError on cache reset (login)", "color: orange;", e.message);
  }
};

export const signOut = async ({
  topic = "ขออภัย",
  message = null,
  type = "error",
}) => {
  store.dispatch("loading/setLoading", true);

  if (typeof localStorage !== "undefined") {
    await localStorage.removeItem(AUTH_TOKEN);
    await localStorage.removeItem("token");
  }
  store.dispatch("authen/showRegisterModal", false);
  store.dispatch("fcm/resetState");
  store.dispatch("geofence/resetState");
  if (typeof positionSocketConnect !== "undefined") {
    await positionSocketConnect.disconnect();
  }
  await store.dispatch("authen/setUser", null);
  await store.dispatch("authen/setCustomerPlan", null);
  await store.dispatch("authen/setToken", "");
  await store.dispatch("loading/setLoading", false);
  await store.dispatch("vehicle/updateVehicle", {
    groups: null,
    groupID: null,
  });
  router.push({
    path: "login",
    query: {
      message,
      topic,
      type,
      time: Date.now(),
    },
  });
};

export const EmailGQLSignUp = async ({ firebaseToken, name, tel, address }) => {
  store.dispatch("loading/setLoading", true);
  const result = await apolloClient
    .mutate({
      // Query
      mutation: gql`
        mutation(
          $firebaseToken: String!
          $name: String
          $tel: String
          $address: String
        ) {
          emailSignUp(
            firebaseToken: $firebaseToken
            name: $name
            tel: $tel
            address: $address
          ) {
            uid
            name
            display_name
            email
            email_verified
            referer
            utm_source
            utm_medium
            utm_campaign
            phone_number
            photo_url
            privider_uid
            group
            role {
              name
              label
            }
            token
          }
        }
      `,
      variables: {
        firebaseToken: firebaseToken,
        name: name,
        tel: tel,
        address: address,
      },
    })
    .catch((err) => {
      throw err;
    });
  if (result && result.data.errors) {
    throw result.data.errors[0].message;
  }
  store.dispatch("loading/setLoading", false);

  if (result && result.data.emailSignUp.email) {
    return true;
  } else {
    throw "ไม่สามารถลงทะเบียนได้ กรุณาลองใหม่อีกครั้ง";
  }
};

export const saveSetting = async ({ userID, setting, termsVersion }) => {
  store.dispatch("loading/setSaveSettingLoading", true);
  const result = await strapiApolloClient
    .mutate({
      // Query
      mutation: updateUserSetting,
      variables: {
        input: {
          where: {
            id: userID,
          },
          data: {
            setting: setting,
            termsVersion: termsVersion
          },
        },
      },
    })
    .catch((err) => {
      console.log(err);
      return;
    });
  store.dispatch("loading/setSaveSettingLoading", false);
  return result;
};

export const pushLastRead = async () => {
  store.dispatch("loading/setSaveSettingLoading", true);
  const result = await strapiApolloClient
    .mutate({
      // Query
      mutation: updateUserSetting,
      variables: {
        input: {
          where: {
            id: store.state.authen.user.id,
          },
          data: {
            lastRead: moment().unix(),
          },
        },
      },
    })
    .catch((err) => {
      console.log(err);
      return;
    });
  store.dispatch("loading/setSaveSettingLoading", false);
  return result;
};

export const getLastRead = async ({ userID }) => {
  const result = await strapiApolloClient
    .query({
      // Query
      query: getLastReadGQL,
      variables: {
        id: userID,
      },
    })
    .then(async (eventData) => {
      store.dispatch(
        "fcm/setLastRead",
        eventData.data.user.lastRead ? eventData.data.user.lastRead : 0
      );
      return eventData.data.user.lastRead ? eventData.data.user.lastRead : 0;
    })
    .catch((err) => {
      console.log(err);
      return;
    });

  return result;
};

export const localLogin = async (username, password) => {
  store.dispatch("loading/setLoading", true);
  await axios
    .post(`${endpoints.strapiAPI}/auth/local`, {
      identifier: username,
      password,
    })
    .then(async (response) => {
      const customer_groups = response.data.user.customer_groups;
      if (customer_groups.length <= 0) {
        signOut({
          topic: "ขออภัย",
          message: "ไม่พบข้อมูลเกี่ยวกับกลุ่มลูกค้า",
          type: "error",
        });
        return;
      }
      // Handle success.
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("api-token", `Bearer ${response.data.jwt}`);
      }
      await store.dispatch("authen/setToken", `Bearer ${response.data.jwt}`);
      await store.dispatch("authen/setUser", response.data.user);
      if (!response.data.user.setting) {
        await store.dispatch("authen/setSetting", {
          positionPTO: true,
          positionTemp: true,
          positionFuel: true,
          positionActivedCard: true,
          notiEnable: true,
        });
        await saveSetting({
          userID: response.data.user.id,
          setting: {
            positionPTO: true,
            positionTemp: true,
            positionFuel: true,
            positionActivedCard: true,
            notiEnable: true,
          },
        });
      } else {
        await store.dispatch("authen/setSetting", response.data.user.setting);
      }
      await store.dispatch("loading/setLoading", false);
    })
    .catch((error) => {
      signOut({
        topic: "ขออภัย",
        message: "ชื่อผู้ใช้งานหรืออีเมล์ และรหัสผ่านไม่ถูกต้อง",
        type: "error",
      });
      // Handle error.

      console.log("An error occurred:", error);
    });
};

export const firebaseLogin = async (token) => {
  store.dispatch("loading/setLoading", true);
  await axios
    .post(`${endpoints.strapiAPI}/firebase/auth`, {
      token,
    })
    .then(async (response) => {
      // Handle success.
      if (typeof localStorage !== "undefined") {
        localStorage.setItem("api-token", `Bearer ${response.data.jwt}`);
      }
      await store.dispatch("authen/setToken", `Bearer ${response.data.jwt}`);
      await store.commit("authen/setUser", response.data.user);
      if (!response.data.user.setting) {
        await store.dispatch("authen/setSetting", {
          positionPTO: true,
          positionTemp: true,
          positionFuel: true,
          positionActivedCard: true,
          notiEnable: true,
        });
        await saveSetting({
          userID: response.data.user.id,
          setting: {
            positionPTO: true,
            positionTemp: true,
            positionFuel: true,
            positionActivedCard: true,
            notiEnable: true,
          },
        });
      } else {
        await store.dispatch("authen/setSetting", response.data.user.setting);
      }
      await store.dispatch("loading/setLoading", false);
    })
    .catch((error) => {
      signOut({
        topic: "ขออภัย",
        message: "ยังไม่พบการลงทะเบียน สำหรับอีเมล์นี้",
        type: "error",
      });
      // Handle error.

      console.log("An error occurred:", error);
    });
};

export const localRegister = ({
  display_name,
  username,
  email,
  password,
  address,
  phone_number,
}) => {
  store.dispatch("loading/setLoading", true);

  return axios.post(`${endpoints.strapiAPI}/auth/local/register`, {
    display_name,
    username,
    email,
    password,
    address,
    phone_number,
  });
};

export const forgetPassword = (email) => {
  return axios.post(`${endpoints.strapiAPI}/auth/forgot-password`, {
    email,
  });
};

export const providerRegister = (provider) => {
  window.location.href = `${endpoints.strapiAPI}/connect/${provider}`;
};
