import {
    apolloClient
} from "../../vue-apollo";
import gql from "graphql-tag";
import _ from 'lodash'
const state = () => ({
    trips: [],
    selectedTrip: {},
    selectedVehicleTrip: {},
    showTripInfo: false,
    selectedPosition: null
});

// getters
const getters = {
    trips: state => state.trips,
    tripsEnd: state =>
        state.trips.filter(trip => {
            return trip.eventType == "END_TRIP";
        }),
    tripsEngine: state => {

        const selectedTrip = state.selectedTrip;
        // console.log("selectedTrip", selectedTrip);
        if (state.trips.length > 0 && !_.isEmpty(selectedTrip)) {


            let idleSecs = 0;
            let movingSecs = 0;
            const tripsInSelectedEvent = state.trips.filter((trip) => {
                return parseFloat(trip.startTime) >= parseFloat(selectedTrip.startTime) && parseFloat(trip.stopTime) <= parseFloat(selectedTrip.stopTime)
            })

            const allSecs = parseFloat(state.selectedTrip.stopTime) - parseFloat(state.selectedTrip.startTime);

            // console.log("tripsInSelectedEvent", tripsInSelectedEvent);

            tripsInSelectedEvent.forEach((trip) => {
                const tripInterval = parseFloat(trip.stopTime) - parseFloat(trip.startTime);
                if (trip.eventType == "ENGINE_IDLE_END")
                    idleSecs = idleSecs + tripInterval;
                else if (trip.eventType == "VEHICLE_MOVING_END") movingSecs = movingSecs + tripInterval;

            })

            // console.log(allSecs);
            return {
                color: ["#00c246", "#F5223E", "#FAAD14"],
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : ({d}%)"
                },
                legend: {
                    left: "center",
                    top: "bottom",
                    data: ["rose1", "rose2", "rose3"]
                },

                series: [{
                    labelLine: {
                        smooth: 0.2,
                        length: 7,
                        length2: 8
                    },
                    label: {
                        show: false
                    },
                    emphasis: {
                        label: {
                            show: false
                        }
                    },
                    name: "เครื่องยนต์",
                    type: "pie",
                    // roseType: "area",
                    radius: [25, 40],
                    center: ["50%", "50%"],
                    data: [{
                            value: movingSecs,
                            name: "รถวิ่ง"
                        },
                        {
                            value: allSecs - (movingSecs + idleSecs),
                            name: "รถหยุด"
                        },
                        {
                            value: idleSecs,
                            name: "จอดติดเครื่อง"
                        }
                    ]
                }]
            };
        } else return {};
    },

    // distance:state => {

    //     // const  = state.positions.map((position) => {
    //     //     return {
    //     //         lat: parseInt(position.latitude) / 10000000,
    //     //         lng: parseInt(position.longitude) / 10000000
    //     //     }
    //     // })
    //     // return path
    // },
};

// actions
const actions = {
    async searchTrip({
        commit
    }, {
        imei,
        start,
        stop,
        limit = 10000
    }) {
        await apolloClient.resetStore();
        const trips = await apolloClient
            .query({
                // Query
                query: gql `
          query events($imei: Float!, $start: Int!, $stop: Int!, $limit: Int) {
            events(imei: $imei, start: $start, stop: $stop, limit: $limit) {
              id
              eventType
              imei
              startTime
              stopTime
              positionDataStart {
                timestamp
                longitude
                latitude
                event_id
                imei
                io
              }
              positionDataEnd {
                timestamp
                longitude
                latitude
                event_id
                imei
                io
              }
            }
          }
        `,
                variables: {
                    imei: parseFloat(imei),
                    start,
                    stop,
                    limit
                }
            })
            .then(async eventData => {
                // console.log("trips", eventData);
                return eventData;
            })
            .catch(err => {
                console.log(err);
                return;
            });
        commit("ADD_TRIPS", trips.data.events);
    },
    selectTrip({
        commit
    }, payload) {
        // console.log('selecttrip', payload);
        commit("SELECT_TRIP", payload);
    },
    selectVehicleTrip({
        commit
    }, payload) {
        commit("SELECT_VEHICLE_TRIP", payload);
    },
    showTripSecondInfo({
        commit
    }, payload) {
        commit("SHOW_TRIP_INFO", payload);
    },
    resetState({
        commit
    }) {
        commit("RESET_STATE");
    },
    selectPosition({
        commit
    }, payload) {
        // console.log('selecttrip', payload);
        commit("SELECT_POSITION", payload);
    },
};
// mutations
const mutations = {
    ADD_TRIPS(state, payload) {
        // if (payload.imei && state.devices.find(device => device.imei === payload.imei)) {
        //     state.devices[]
        // }
        // let newDevice = {
        //   ...state.devices
        // };
        // newDevice[payload.imei] = payload;
        state.trips = payload;
    },
    SELECT_TRIP(state, payload) {
        state.selectedTrip = payload;
    },
    SHOW_TRIP_INFO(state, payload) {
        state.showTripInfo = payload;
    },
    SELECT_VEHICLE_TRIP(state, payload) {
        state.selectedVehicleTrip = payload;
    },
    RESET_STATE(state) {
        state.showTripInfo = false;
    },
    SELECT_POSITION(state, payload) {
        state.selectedPosition = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};