<template>
  <a-drawer
    placement="left"
    :visible="visible"
    :width="640"
    :closable="false"
    :drawerStyle="drawerStyles"
    :bodyStyle="{ padding: 0 }"
  >
    <div class="title">{{ getTitleText }}</div>

    <!-- If edit mode isn't enabled -->
    <div v-if="managementMode === 'create'" class="navigation-container">
      <div
        @click="onChangeTab(true)"
        :class="['navigation-button', isEventTab && 'active']"
      >
        Event
      </div>
      <div
        @click="onChangeTab(false)"
        :class="['navigation-button', !isEventTab && 'active']"
      >
        Maintenance
      </div>
    </div>

    <!-- If edit mode is enabled -->
    <div v-else class="navigation-container">
      <div :class="['navigation-button', isMainEventTabActived && 'active']">
        Event
      </div>
      <div :class="['navigation-button', !isMainEventTabActived && 'active']">
        Maintenance
      </div>
    </div>

    <!-- Content sections -->
    <div class="content-container">
      <div class="info-section">
        <a-row type="flex" align="middle" :gutter="[0, 32]">
          <a-col :span="8">
            <div class="form-label">
              หัวข้อ<span style="color: red">*</span>
            </div>
          </a-col>
          <a-col :span="16">
            <a-input
              placeholder="ชื่อแจ้งเตือน"
              class="input-box"
              v-model="name"
              required
            />
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" :gutter="[0, 32]">
          <a-col :span="8">
            <div class="form-label">
              ทะเบียนรถ<span style="color: red">*</span>
            </div>
          </a-col>
          <a-col :span="16">
            <!-- For geo activity alert -->
            <VehicleMultiSelect
              v-if="
                tableType === 'geo_activity_alert' &&
                (selectedGeoAction === geofenceTypes.GEOFENCE_ENTER ||
                  selectedGeoAction === geofenceTypes.GEOFENCE_EXIT)
              "
              :multipleVehicleValues="multipleVehicleValues"
              @onMultiVehiclesSelect="onMultipleVehiclesSelect"
              @onUnselect="onDeselect"
              />
            <VehicleSingleSelect
              v-else
              :defaultValue="selectedVehicle"
              @onSelect="onSelectVehicle"
            />
          </a-col>
        </a-row>
      </div>
      <div class="noti-info-section">
        <div class="title-section">ประเภทการแจ้งเตือน</div>

        <!-- Notification buttons group -->
        <!-- If event is actived -->
        <div v-if="isEventTab" style="display: flex; column-gap: 18px">
          <button
            v-for="(event, key) in eventTypes"
            :key="key"
            :class="[
              managementMode === 'edit' && selectedType !== event.type
                ? 'disabled-action-button'
                : 'action-button',
              'noti',
              selectedType === event.type && 'active',
            ]"
            @click="onSelectType(event.type)"
            :disabled="
              managementMode === 'edit' && selectedType !== event.type
                ? true
                : false
            "
          >
            {{ event.label }}
          </button>
        </div>

        <!-- If maintenance is actived -->
        <div v-else style="display: flex; column-gap: 24px">
          <button
            v-for="(maintenance, key) in maintenanceTypes"
            :key="key"
            :class="[
              managementMode === 'edit' && selectedType !== maintenance.type
                ? 'disabled-action-button'
                : 'action-button',
              'noti',
              selectedType === maintenance.type && 'active',
            ]"
            @click="onSelectType(maintenance.type)"
            :disabled="
              managementMode === 'edit' && selectedType !== maintenance.type
                ? true
                : false
            "
          >
            {{ maintenance.label }}
          </button>
        </div>

        <!-- Notification conditions -->
        <div style="margin-top: 24px">
          <!-- Event -->
          <div v-if="isEventTab">
            <!-- No update -->
            <a-row
              v-if="selectedType === propTypes.NOUPDATE"
              style="font-weight: 700"
            >
              เงื่อนไข:
              <span style="font-weight: 400; color: #515151">20 นาที</span>
            </a-row>

            <a-row
              v-if="selectedType === propTypes.NO_SWIPE_CARD_5MINS"
              style="font-weight: 700"
            >
              เงื่อนไข:
              <span style="font-weight: 400; color: #515151">5 นาที</span>
            </a-row>

            <!-- Temperature -->
            <a-row
              v-if="selectedType === propTypes.TEMP"
              type="flex"
              justify="space-between"
              align="middle"
            >
              <a-col :span="6">
                <div class="form-label">อุปกรณ์</div>
                <a-select
                  :default-value="eventTypes[1].typeTopics[0].label"
                  class="select-input"
                  style="width: 120px; margin-top: 8px"
                  v-model="selectedTempType"
                  @change="onSelectTempTypeChange"
                >
                  <a-select-option
                    v-for="(temp, key) in eventTypes[1].typeTopics"
                    :key="key"
                    :value="temp.topic"
                  >
                    {{ temp.label }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="18" style="padding-left: 32px">
                <a-slider
                  id="temp_range"
                  range
                  :step="1"
                  :min="-50"
                  :max="50"
                  :value="[Number(tempMin), Number(tempMax)]"
                  :v-model="[Number(tempMin), Number(tempMax)]"
                  @change="onTempRangeChange"
                  style="margin: 0px"
                />
                <div
                  style="
                    margin-top: 17px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <a-input
                    type="number"
                    placeholder="อุณหภูมิ"
                    suffix="°C"
                    :max="50"
                    :min="-50"
                    :value="tempMin"
                    @change="onTempMinInputChange"
                    class="input-box"
                    style="width: 84px; height: auto; align-items: center"
                  />
                  <a-input
                    type="number"
                    placeholder="อุณหภูมิ"
                    suffix="°C"
                    :max="50"
                    :min="-50"
                    :value="tempMax"
                    @change="onTempMaxInputChange"
                    class="input-box"
                    style="width: 84px; height: auto"
                  />
                </div>
              </a-col>
            </a-row>

            <!-- Overspeed -->
            <a-row
              v-if="selectedType === propTypes.OVERSPEED"
              style="font-weight: 700"
            >
              เงื่อนไข:
              <span
                v-if="selectedVehicle !== ''"
                style="font-weight: 400; color: #515151"
                >มากกว่า {{ computedOverspeedLimitText }} กม./ชม.</span
              >
            </a-row>

            <!-- Geofence -->
            <a-row v-if="selectedType === propTypes.GEOFENCE">
              <a-col :span="8">
                <div class="form-label">เงื่อนไข</div>
                <a-select
                  :class="['select-input', 'input-box']"
                  style="width: 120px; margin-top: 8px; border-radius: 6px"
                  :default-value="
                    selectedGeoAction === '' ? undefined : selectedGeoAction
                  "
                  @change="onSelectGeoActionChange"
                  :disabled="managementMode === 'edit' ? true : false"
                >
                  <a-select-option
                    v-for="(geoAlertItem, key) in geofenceInputTypes"
                    :key="key"
                    :value="geoAlertItem.type"
                  >
                    {{ geoAlertItem.label }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="16">
                <div class="form-label">
                  เวลาที่คาดว่าจะมาถึง<span style="color: red">*</span>
                </div>
                <a-date-picker
                  class="input-box"
                  style="margin-top: 8px"
                  placeholder="เลือกวันที่และเวลาที่คาดว่าจะมาถึง"
                  :show-time="{ format: 'HH:mm' }"
                  format="DD MMM YYYY HH:mm"
                  v-model="expectedArrivalTime"
                  @change="onExpectedArrivalTimeChange"
                  :disabled="
                    selectedGeoAction === geofenceTypes.FIXED30MINS
                      ? false
                      : true
                  "
                />
              </a-col>
              <a-col :span="24" style="margin-top: 16px">
                <div class="form-label">
                  ชื่อสถานี<span style="color: red">*</span>
                </div>
                <GeofenceSelect
                  :defaultValue="selectedGeofence"
                  :allSelectOptionEnabled="
                    selectedGeoAction === 'fixed30mins' ? false : true
                  "
                  @onSelect="onSelectGeofenceChange"
                />
              </a-col>
            </a-row>
          </div>

          <!-- Maintenances -->
          <div v-else>
            <div
              v-if="[propTypes.MILEAGE, propTypes.PTO].includes(selectedType)"
            >
              <div
                v-if="selectedType === propTypes.PTO"
                style="margin-bottom: 16px"
              >
                <a-row type="flex" align="middle" :gutter="[0, 32]">
                  <a-col :span="10">
                    <div class="form-label">Input</div>
                  </a-col>
                  <a-col :span="14">
                    <a-select
                      :class="['select-input', 'input-box']"
                      @change="onSelectPTOTypeChange"
                      :default-value="getPtoText"
                      v-model="selectedPTOType"
                    >
                      <a-select-option
                        v-for="(input, key) in ptoInputTypes"
                        :key="key"
                        :value="input.type"
                      >
                        {{ input.label }}
                      </a-select-option>
                    </a-select>
                  </a-col>
                </a-row>
              </div>
              <div style="display: flex; column-gap: 16px; position: relative">
                <div style="width: 50%">
                  <div class="form-label">
                    ครั้งล่าสุด<span style="color: red">*</span>
                  </div>
                  <a-date-picker
                    class="input-box"
                    placeholder="เลือกวันที่ครั้งล่่าสุด"
                    :show-time="{ format: 'HH:mm' }"
                    format="DD MMM YYYY HH:mm"
                    style="margin-top: 8px"
                    v-model="maintenanceInfo.lastNotify"
                  />
                </div>
                <div>
                  <div class="form-label">
                    แจ้งเตือนซ้ำทุกๆ<span style="color: red">*</span>
                  </div>
                  <a-input
                    class="input-box"
                    style="margin-top: 8px"
                    :suffix="renderInputOption(selectedType).suffix"
                    :max-length="renderInputOption(selectedType).maxLength"
                    placeholder="0"
                    v-model="maintenanceInfo.repeatTaskAfter"
                  />
                </div>
                <div>
                  <div class="form-label">แจ้งเตือนล่วงหน้า</div>
                  <a-input
                    class="input-box"
                    style="margin-top: 8px"
                    :suffix="renderInputOption(selectedType).suffix"
                    :max-length="renderInputOption(selectedType).maxLength"
                    placeholder="0"
                    v-model="maintenanceInfo.notifyBefore"
                  />
                </div>
              </div>
            </div>
            <div v-if="selectedType === propTypes.DATE">
              <div style="width: 50%">
                <div class="form-label">
                  วันที่แจ้งเตือน<span style="color: red">*</span>
                </div>
                <a-date-picker
                  class="input-box"
                  style="margin-top: 8px"
                  :disabledDate="disabledDate"
                  placeholder="เลือกวันที่แจ้งเตือน"
                  :show-time="{ format: 'HH:mm' }"
                  format="DD MMM YYYY HH:mm"
                  v-model="notifySchedule"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="noti-sending-section">
        <div class="title-section">การส่งแจ้งเตือน</div>
        <a-row v-if="!isEventTab" type="flex" align="middle" :gutter="[0, 32]">
          <a-col
            :span="4"
            :class="emailErrorMsg !== '' ? 'notify-sending-row-error' : ''"
          >
            <a-checkbox
              class="noti-checkbox"
              :checked="isEmailEnabled"
              @change="onEmailNotiEnabled"
              >Email</a-checkbox
            >
          </a-col>
          <a-col :span="20">
            <a-input
              placeholder="Email"
              :class="
                emailErrorMsg !== '' ? 'input-box-error input-box' : 'input-box'
              "
              :disabled="isEmailEnabled ? false : true"
              v-model="emailText"
            />
            <div v-if="emailErrorMsg !== ''" class="error-msg">
              {{ emailErrorMsg }}
            </div>
          </a-col>
        </a-row>
        <a-row type="flex" align="middle" :gutter="[0, 32]">
          <a-col
            :span="4"
            :class="lineErrorMsg !== '' ? 'notify-sending-row-error' : ''"
          >
            <a-checkbox
              class="noti-checkbox"
              :checked="isLineEnabled"
              @change="onLineNotiEnabled"
            >
              Line
            </a-checkbox>
          </a-col>
          <a-col :span="20">
            <a-input
              placeholder="Line Token"
              :class="
                lineErrorMsg !== '' ? 'input-box-error input-box' : 'input-box'
              "
              :disabled="isLineEnabled ? false : true"
              v-model="lineToken"
            />
            <div v-if="lineErrorMsg !== ''" class="error-msg">
              {{ lineErrorMsg }}
            </div>
          </a-col>
        </a-row>
      </div>
      <div class="action-container">
        <button
          type="submit"
          :class="
            validateEmptyInputForms
              ? 'action-button primary'
              : 'disabled-action-button'
          "
          :disabled="!validateEmptyInputForms"
          @click.prevent="onSubmit()"
        >
          บันทึก
        </button>
        <button :class="['action-button']" @click="onCancel()">ยกเลิก</button>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import moment from '@/lib/time';
import { mapActions, mapState } from 'vuex';
// import cronParser from 'cron-parser';
import { getVehicles } from '@/lib/vehicle';
import VehicleMultiSelect from './vehicle/vehicleMultiSelect.vue';
import VehicleSingleSelect from './vehicle/vehicleSingleSelect.vue';
import GeofenceSelect from './geofence/geofenceSelect.vue';

const propTypes = Object.freeze({
  NOUPDATE: 'NO_UPDATE',
  NO_SWIPE_CARD_5MINS: 'NO_SWIPE_CARD_5MINS',
  TEMP: 'TEMPERATURE',
  OVERSPEED: 'OVERSPEED',
  GEOFENCE: 'geofence',
  PTO: 'PTO',
  MILEAGE: 'Mileage',
  DATE: 'Date',
});

const ptoTypes = Object.freeze({
  PTO1: 'PTO1',
  PTO2: 'PTO2',
  PTO3: 'PTO3',
});

const tempTypes = Object.freeze({
  TEMPERATURE1: 'TEMPERATURE1',
  TEMPERATURE2: 'TEMPERATURE2',
});

const geofenceTypes = Object.freeze({
  GEOFENCE_ENTER: 'GEOFENCE_ENTER',
  GEOFENCE_EXIT: 'GEOFENCE_EXIT',
  FIXED30MINS: 'fixed30mins',
});

export default {
  name: 'maintenanceForm',
  components: {
    VehicleMultiSelect,
    VehicleSingleSelect,
    GeofenceSelect,
  },
  props: [
    'visible',
    'vehicles',
    'selectedGroup',
    'notiData',
    'notiType',
    'managementMode',
    'isMainEventTabActived',
  ],
  data() {
    return {
      // Styles
      drawerStyles: {
        margin: '3rem',
        color: '#000000',
        fontSize: '14px',
        height: 'auto',
      },

      // Data types
      eventTypes: [
        {
          type: propTypes.NOUPDATE,
          label: 'รถไม่อัพเดต',
        },
        {
          type: propTypes.TEMP,
          label: 'อุณหภูมิ',
          typeTopics: [
            {
              type: propTypes.TEMP,
              topic: tempTypes.TEMPERATURE1,
              label: 'Temp1',
            },
            {
              type: propTypes.TEMP,
              topic: tempTypes.TEMPERATURE2,
              label: 'Temp2',
            },
          ],
        },
        {
          type: propTypes.OVERSPEED,
          label: 'ความเร็ว',
        },
        {
          type: propTypes.GEOFENCE,
          label: 'สถานี',
        },
        {
          type: propTypes.NO_SWIPE_CARD_5MINS,
          label: 'ไม่รูดบัตร',
        },
      ],
      maintenanceTypes: [
        {
          type: propTypes.MILEAGE,
          label: 'Mileage',
        },
        {
          type: propTypes.PTO,
          label: 'ชั่วโมงการทำงาน',
        },
        {
          type: propTypes.DATE,
          label: 'วันที่',
        },
      ],
      ptoInputTypes: [
        {
          type: ptoTypes.PTO1,
          label: 'PTO1',
        },
        {
          type: ptoTypes.PTO2,
          label: 'PTO2',
        },
        {
          type: ptoTypes.PTO3,
          label: 'PTO3',
        },
      ],
      geofenceInputTypes: [
        {
          type: geofenceTypes.GEOFENCE_ENTER,
          label: 'เข้า',
        },
        // {
        //   type: geofenceTypes.GEOFENCE_EXIT,
        //   label: "ออก",
        // },
        {
          type: geofenceTypes.FIXED30MINS,
          label: 'ก่อนเข้า 30 นาที',
        },
      ],
      tableType: 'maintenance',

      // General variables
      isEventTab: true,
      propTypes,
      name: '',
      maintenanceInfo: {
        lastNotify: null,
        repeatTaskAfter: null,
        notifyBefore: null,
      },
      isNotiNameValid: false,
      expectedArrivalTime: null,
      notifySchedule: null,
      convertedDateTime: '',
      cronExpression: '',

      // Notification options
      isEmailEnabled: false,
      isLineEnabled: false,
      emailText: '',
      lineToken: '',
      emailErrorMsg: '',
      lineErrorMsg: '',

      // No update variables
      selectedType: propTypes.NOUPDATE,

      // PTO variables
      ptoTypes,
      selectedPTOType: ptoTypes.PTO1,

      // Temperature variables
      tempTypes,
      selectedTempType: tempTypes.TEMPERATURE1,
      tempMin: -50,
      tempMax: 50,

      // Geofence variables
      geofenceTypes,
      selectedGeofence: '',
      selectedGeoAction: geofenceTypes.GEOFENCE_ENTER,
      all_geofences_notify: '',

      // Vehicle variables
      selectedVehicle: '',
      multipleVehicleValues: [],
      vehicleData: {},
      vehicleOverSpeedLimit: '',
    };
  },

  watch: {
    visible: function () {


      if (this.managementMode === 'create') {
        this.selectedGeoAction = geofenceTypes.GEOFENCE_ENTER;
        this.tableType = 'maintenance';        
        if (this.isMainEventTabActived === false) {
          this.isEventTab = false
        } else {
          this.isEventTab = true
        }
      }

      if (this.notiData && this.visible && this.managementMode === 'edit') {
        const {
          name,
          notify_channel_email_enabled,
          notify_email_channel_enabled,
          email_list,
          notify_channel_line_enabled,
          notify_line_channel_enabled,
          line_token,
          line_notify_token,
          lineToken,
          last_notify,
          notify_before,
          repeat_task_after,
          geofence,
          date_type_schedule,
          type,
          notify_type,
          vehicle,
          expected_arrival_time,
          all_geofences_notify,
          data_source,
        } = this.notiData;

        this.all_geofences_notify = all_geofences_notify;
        this.tableType = data_source;
        this.name = name;
        this.isEmailEnabled =
          data_source === 'maintenance'
            ? notify_channel_email_enabled
            : notify_email_channel_enabled;
        this.emailText = !email_list ? '' : email_list[0];
        this.isLineEnabled =
          data_source === 'maintenance'
            ? notify_channel_line_enabled
            : notify_line_channel_enabled;
        this.lineToken = line_token || line_notify_token || lineToken;
        this.maintenanceInfo.lastNotify =
          last_notify !== null ? last_notify : null;
        this.maintenanceInfo.notifyBefore =
          notify_before !== null ? notify_before : null;
        this.maintenanceInfo.repeatTaskAfter =
          repeat_task_after !== null ? repeat_task_after : null;

        if (date_type_schedule) {
          this.notifySchedule = moment(date_type_schedule);
        }

        this.isEventTab =
          this.managementMode === 'edit' &&
          ![propTypes.MILEAGE, propTypes.PTO, propTypes.DATE].includes(
            this.notiType
          )
            ? true
            : false;
        
        this.selectedType = this.computedNotiTypeEditMode;
        this.selectedVehicle = vehicle.id;
        this.vehicleData = this.onVehicleSelectChange(vehicle.id);

        if (data_source === 'arrival_geo_alert') {
          this.selectedGeofence = geofence !== undefined ? geofence.id : '';
          this.expectedArrivalTime =
            expected_arrival_time !== null ? expected_arrival_time : null;
          this.selectedGeoAction = notify_type;
        }

        // Geo activity alert
        if (data_source === 'geo_activity_alert') {
          this.selectedGeofence = geofence ? geofence.id : 'all_geofences_001';

          if (
            notify_type === geofenceTypes.GEOFENCE_ENTER ||
            notify_type === geofenceTypes.GEOFENCE_EXIT
          ) {
            this.selectedGeoAction = notify_type;
            this.multipleVehicleValues = vehicle.map(({plate_number, name}) => this.mergedVehicleKey(plate_number, name));
          }
        }

        if (this.notiType === propTypes.PTO) {
          this.selectedPTOType === type;
        }

        if (this.notiType === propTypes.TEMP) {
          if (type === tempTypes.TEMPERATURE1) {
            this.selectedTempType = tempTypes.TEMPERATURE1;
            this.tempMin = vehicle.sensor1_temp_min;
            this.tempMax = vehicle.sensor1_temp_max;
          } else {
            this.selectedTempType = tempTypes.TEMPERATURE2;
            this.tempMin = vehicle.sensor2_temp_min;
            this.tempMax = vehicle.sensor2_temp_max;
          }
        }
      }
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.authen.user,
    }),

    getTitleText() {
      return this.managementMode === 'edit'
        ? 'การแจ้งเตือน'
        : 'เพิ่มการแจ้งเตือน';
    },

    getPtoText: {
      get() {
        return this.managementMode === 'create'
          ? this.selectedPTOType
          : this.ptoTypeGenerator();
      },
      set(newValue) {
        this.selectedPTOType = newValue;
      },
    },

    computedType() {
      if (this.selectedType === propTypes.PTO) {
        return this.selectedPTOType;
      } else if (this.selectedType === propTypes.TEMP) {
        return this.selectedTempType;
      } else {
        return this.selectedType;
      }
    },

    computedExpectedArrivalTime() {
      return moment(this.expectedArrivalTime?._d).format(
        'YYYY-MM-DDTHH:mm:ss.SSSZ'
      );
    },

    computedNotiTypeEditMode() {
      if (this.managementMode === 'edit' && !this.isEventTab) {
        switch (this.notiType) {
          case propTypes.MILEAGE:            
          case propTypes.PTO:
            return this.notiType;
          default:
            return propTypes.DATE;
        }
      } else {
        switch (this.notiType) {
          case propTypes.NOUPDATE:            
          case propTypes.NO_SWIPE_CARD_5MINS:            
          case propTypes.TEMP:            
          case propTypes.OVERSPEED:
            return this.notiType;
          default:
            return propTypes.GEOFENCE;
        }
      }
    },

    computedOverspeedLimitText() {
      return this.overspeedLimitConverter();
    },

    validateNotifyNameInput() {
      return this.name !== '';
    },

    validateSelectedVehicle() {
      return this.selectedVehicle !== '';
    },

    validateMultipleVehicle() {
      return (
        this.multipleVehicleValues.length > 0 || !this.multipleVehicleValues
      );
    },

    validateEmptyInputForms() {
      const type = this.selectedType;
      const mtInfo = this.maintenanceInfo;
      if (
        this.validateNotifyNameInput &&
        (this.validateSelectedVehicle || this.validateMultipleVehicle)
      ) {
        if (
          type === propTypes.NOUPDATE ||
          type === propTypes.TEMP ||
          type === propTypes.NO_SWIPE_CARD_5MINS ||
          type === propTypes.OVERSPEED
        ) {
          return true;
        } else if (
          type === propTypes.GEOFENCE &&
          this.expectedArrivalTime !== null &&
          this.selectedGeofence !== ''
        ) {
          return true;
        } else if (
          type === propTypes.GEOFENCE &&
          this.expectedArrivalTime !== null &&
          this.selectedGeoAction === geofenceTypes.FIXED30MINS
        ) {
          return true;
        } else if (
          type === propTypes.GEOFENCE &&
          this.selectedGeofence !== '' &&
          this.validateMultipleVehicle &&
          (this.selectedGeoAction === geofenceTypes.GEOFENCE_ENTER ||
            this.selectedGeoAction === geofenceTypes.GEOFENCE_EXIT)
        ) {
          return true;
        } else if (
          (type === propTypes.MILEAGE || type === propTypes.PTO) &&
          mtInfo.lastNotify !== null &&
          mtInfo.repeatTaskAfter !== null &&
          mtInfo.repeatTaskAfter !== ''
        ) {
          return true;
        } else if (type === propTypes.DATE && this.notifySchedule !== null) {
          return true;
        } else {
          return false;
        }
      }

      return false;
    },
  },
  methods: {
    ...mapActions({
      // Vehicles
      updateVehicleTempSetting: 'vehicle/updateVehicleTempSetting',
    }),

    disabledDate(current) {
      return current && current < moment().startOf('day');
    },
    onChangeTab(isEvent) {
      this.isEventTab = isEvent;
      this.setDefaultSelectedType();
    },

    onExpectedArrivalTimeChange() {
      this.expectedArrivalTime = this.computedExpectedArrivalTime;
    },

    ///////////// Input Validations //////////////
    validateEmailInfo() {
      this.emailErrorMsg =
        this.isEmailEnabled && this.emailText === ''
          ? 'กรุณากรอกข้อมูลให้ครบถ้วน'
          : '';
      return this.isEmailEnabled && this.emailText === '' ? false : true;
    },

    validateLineInfo() {
      this.lineErrorMsg =
        this.isLineEnabled && this.lineToken === ''
          ? 'กรุณากรอกข้อมูลให้ครบถ้วน'
          : '';
      return this.isLineEnabled && this.lineToken === '' ? false : true;
    },
    //////////////////////////////////

    async onSubmit() {
      const data = this.prepareData();

      if (
        this.validateEmailInfo() &&
        this.validateLineInfo() &&
        this.emailErrorMsg === '' &&
        this.lineErrorMsg === ''
      ) {
        if (this.selectedType === propTypes.TEMP) {
          let vehicle_temp_data = {
            vehicle: this.selectedVehicle,
            temp_notify_enabled: true,
          };

          if (this.selectedTempType === tempTypes.TEMPERATURE1) {
            vehicle_temp_data = {
              ...vehicle_temp_data,
              // temp1_enabled: true,
              sensor1_temp_min: Number(this.tempMin),
              sensor1_temp_max: Number(this.tempMax),
            };
          } else if (this.selectedTempType === tempTypes.TEMPERATURE2) {
            vehicle_temp_data = {
              ...vehicle_temp_data,
              // temp2_enabled: true,
              sensor2_temp_min: Number(this.tempMin),
              sensor2_temp_max: Number(this.tempMax),
            };
          }

          await this.updateVehicleTempSetting(vehicle_temp_data)
            .then(async (response) => {
              if (response) {
                await getVehicles(
                  this.user.customer_groups.map((group) => {
                    return group._id;
                  })
                );
              }
            })
            .catch((err) => {
              console.error('Maintenance saving error:', err);
            });
        }

        this.$emit(
          'onSubmit',
          data,
          this.notiData?.id,
          this.managementMode,
          this.notiData?.data_source,
          this.tableType
        );

        this.onCancel();
      }
    },

    prepareData() {
      let data = {
        name: this.name,
        type: this.computedType,
        status: 'Normal',
        notify_channel_email_enabled: this.isEmailEnabled,
        notify_channel_line_enabled: this.isLineEnabled,
        vehicle: this.selectedVehicle,
        email_list: this.emailText === '' ? null : [this.emailText],
        line_notify_token: this.lineToken,
        customer_group: this.selectedGroup.id,
      };

      const dt = new Date();

      // Extract date and time components
      const isoString = dt.toISOString();
      const timeOffset = '+07:00';

      const formattedDate = isoString + timeOffset;

      switch (this.selectedType) {
        case propTypes.NOUPDATE:
          data = {
            ...data,
            last_notify:
              this.maintenanceInfo.lastNotify === undefined ||
              this.maintenanceInfo.lastNotify === null
                ? moment(formattedDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
                : this.maintenanceInfo.lastNotify,
            notify_schedule:
              this.cronExpression === '' ? '0 * * * *' : this.cronExpression,
          };
          break;
        case propTypes.TEMP:
        case propTypes.NO_SWIPE_CARD_5MINS:
          data = {
            ...data,
            last_notify:
              this.maintenanceInfo.lastNotify === undefined ||
              this.maintenanceInfo.lastNotify === null
                ? moment(formattedDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
                : this.maintenanceInfo.lastNotify,
            notify_schedule:
              this.cronExpression === '' ? '* * * * *' : this.cronExpression,
          };
          break;
        case propTypes.OVERSPEED:
          data = {
            ...data,
            last_notify:
              this.maintenanceInfo.lastNotify === undefined ||
              this.maintenanceInfo.lastNotify === null
                ? moment(formattedDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ')
                : this.maintenanceInfo.lastNotify,
            notify_schedule:
              this.cronExpression === '' ? '* * * * *' : this.cronExpression,
          };
          break;
        case propTypes.GEOFENCE:
          data = {
            name: this.name,
            line_token: this.lineToken,
            customer_group: this.selectedGroup.id,
          };

          if (this.selectedGeoAction === geofenceTypes.FIXED30MINS) {
            Object.assign(data, {
              vehicle: this.selectedVehicle,
              geofence:
                this.selectedGeofence === ''
                  ? undefined
                  : this.selectedGeofence,
              notify_email_channel_enabled: this.isEmailEnabled,
              notify_line_channel_enabled: this.isLineEnabled,
              email_list: this.emailText === '' ? null : [this.emailText],
              notify_type: geofenceTypes.FIXED30MINS,
              status: 'new',
              expected_arrival_time: this.expectedArrivalTime,
            });
          } else {
            // For Geo activity alert
            const geofenceValue = this.selectedGeofence === "all_geofences_001" ? null : this.selectedGeofence;
            const extractedPlate = this.multipleVehicleValues.map(entry => {
                const match = entry.match(/^(.*?) \((.*?)\)$/); // Use regular expression to match the pattern
                if (match) {
                    const [, firstPart] = match;
                    return firstPart;
                } else {
                    return entry;
                }
            });

            const matchedVehiclesByID = extractedPlate
                .filter(plate => plate !== 'all_vehicles_001') // Filter out 'all_vehicles_001'
                .flatMap(plate => this.vehicles
                    .filter(vehicle => vehicle.plate_number === plate)
                    .map(vehicle => vehicle.id)
                );  // Flatmap to change plate number become id

            Object.assign(data, {
              vehicle: matchedVehiclesByID,
              geofence: geofenceValue,
              all_geofences_notify:
                this.selectedGeofence === 'all_geofences_001' ? true : false,
              notify_type: this.selectedGeoAction,
              lineToken: this.lineToken,
              notify_line_channel_enabled: this.isLineEnabled,
            });
          }

          break;
        case propTypes.MILEAGE:
        case propTypes.PTO:
          data = {
            ...data,
            notify_schedule:
              this.cronExpression === '' ? '0 17 * * *' : this.cronExpression,
            last_notify: this.maintenanceInfo.lastNotify,
            repeat_task_after: this.maintenanceInfo.repeatTaskAfter,
            notify_before: this.maintenanceInfo.notifyBefore,
          };
          break;
        case propTypes.DATE:
          data = {
            ...data,
            date_type_schedule: moment
              .utc(this.notifySchedule)
              .format('YYYY-MM-DDTHH:mm:ssZ'),
            notify_schedule: '-',
          };
          break;
        default:
          break;
      }

      return data;
    },

    onCancel() {
      this.$emit('onCancel', false);
      this.resetState();
    },

    // Vehicles selection
    onVehicleSelectChange(value) {
      if (value) {
        this.vehicleData = value;

      // Logic after change vehicle temp device and temp values will change too.
      this.selectedTempType = tempTypes.TEMPERATURE1;
      this.initTempSensorValues(this.selectedTempType);
      return this.vehicleData;
      }
    },

    onSelectVehicle(vehicle) {
      if(vehicle) {
        this.selectedVehicle = vehicle.id
        this.onVehicleSelectChange([vehicle])
      }
    },
  
    mergedVehicleKey(plate, name) {
       const mergedValue = `${plate} (${name})`
       return mergedValue
    },

    onMultipleVehiclesSelect(data) {
      this.multipleVehicleValues = data;
    },

    onDeselect(data){
      this.multipleVehicleValues = data;
    },

    addAllVehicles(vehicles_data) {
      const reformattedVehicles = vehicles_data.map(({id, name, plate_number}) => ({id, name, plate_number}));
      reformattedVehicles.unshift({ id: 'all_vehicles_001', plate_number: 'ทั้งหมด', name: 'ทั้งหมด'});
      return reformattedVehicles;
    },

    onSelectType(type) {
      this.selectedType = type;

      if (this.selectedType === propTypes.GEOFENCE) {
        if (this.selectedGeoAction === geofenceTypes.FIXED30MINS) {
          this.tableType = 'arrival_geo_alert';
          this.multipleVehicleValues = [];
        }

        if (this.selectedGeoAction === geofenceTypes.GEOFENCE_ENTER) {
          this.tableType = 'geo_activity_alert';
        }
      } else {
        this.tableType = 'maintenance';
        this.multipleVehicleValues = [];
      }
    },

    ///////// Temperature /////////////
    onTempRangeChange(value) {
      this.tempMin = value[0];
      this.tempMax = value[1];
    },

    onTempMinInputChange(temp_min_val) {
      let tempMinVal = temp_min_val.target.valueAsNumber;

      // if (isNaN(tempMinVal)) {
      //   this.tempMin = 0;
      // }

      // If temp value is out of min range
      if (tempMinVal < -50) {
        this.tempMin = -50;
      }

      // If temp value is out of max range
      if (tempMinVal > 50) {
        this.tempMax = 50;
      }

      // If temp value is between tempMax and -50°C
      if (tempMinVal < this.tempMax && tempMinVal >= -50) {
        this.tempMin = tempMinVal;
      } else if (tempMinVal >= this.tempMax) {
        this.tempMin = this.tempMax - 1;
      }
    },

    onTempMaxInputChange(temp_max_val) {
      let tempMaxVal = temp_max_val.target.valueAsNumber;

      // if (isNaN(tempMaxVal)) {
      //   this.tempMax = 0;
      // }

      // If temp value is out of min range
      if (tempMaxVal > 50) {
        this.tempMax = 50;
      }

      // If temp value is out of max range
      if (tempMaxVal < -50) {
        this.tempMin = -50;
      }

      // If temp value is between tempMin and 50°C
      if (tempMaxVal > this.tempMin && tempMaxVal <= 50) {
        this.tempMax = tempMaxVal;
      } else if (tempMaxVal <= this.tempMin) {
        this.tempMax = this.tempMin + 1;
      }
    },

    initTempSensorValues(temp_type) {
      // If there's data
      if (this.vehicleData.length > 0) {
        const data = this.vehicleData[0];
        this.tempMin =
          temp_type === tempTypes.TEMPERATURE1
            ? data.sensor1_temp_min ?? -50
            : data.sensor2_temp_min ?? -50;

        this.tempMax =
          temp_type === tempTypes.TEMPERATURE1
            ? data.sensor1_temp_max ?? 50
            : data.sensor2_temp_max ?? 50;
      }
    },

    onSelectTempTypeChange(temp_type) {
      if (temp_type === tempTypes.TEMPERATURE1) {
        this.initTempSensorValues(tempTypes.TEMPERATURE1);
      } else {
        this.initTempSensorValues(tempTypes.TEMPERATURE2);
      }
    },

    ///////////// Overspeed Limit /////////////
    overspeedLimitConverter() {
      const vehicleData = this.vehicles.find(
        (vehicle) => vehicle.id === this.selectedVehicle
      );
      return (this.vehicleOverSpeedLimit = vehicleData.overspeed_limit);
    },

    ///////////// Geofence ///////////////
    onSelectGeofenceChange(geo_id) {
      this.selectedGeofence = geo_id;
    },

    onSelectGeoActionChange(action_type) {
      this.selectedGeoAction = action_type;

      const actionTypeToTableType = {
        [geofenceTypes.GEOFENCE_ENTER]: 'geo_activity_alert',
        [geofenceTypes.GEOFENCE_EXIT]: 'geo_activity_alert',
        [geofenceTypes.FIXED30MINS]: 'arrival_geo_alert',
      };

      this.tableType = actionTypeToTableType[action_type] || '';
    },

    addAllGeofences(geofences_data) {
      const reformattedGeofences = geofences_data.map(({ id, name }) => ({
        id,
        name,
      }));
      reformattedGeofences.unshift({
        id: 'all_geofences_001',
        name: 'ทั้งหมด',
      });
      return reformattedGeofences;
    },

    ///////////////// PTO //////////////////
    onSelectPTOTypeChange(value) {
      this.selectedPTOType = value;
    },

    ptoTypeGenerator() {
      if (this.visible && this.managementMode === 'edit') {
        // use this.notiData?.type as the key to look up the corresponding ptoType in the ptoTypeMapping object.
        // If mappin is not one of them we'll assign this.selectedPTOType(Default from data)
        this.selectedPTOType = ptoTypes[this.notiData?.type] || undefined;
      }
    },

    ////////// Notification options /////////
    onEmailNotiEnabled(e) {
      this.isEmailEnabled = e.target.checked;
      this.clearNotiValue('email');
    },

    onLineNotiEnabled(e) {
      this.isLineEnabled = e.target.checked;
      this.clearNotiValue('line');
    },

    clearNotiValue(noti_type) {
      if (noti_type === 'email' && !this.isEmailEnabled) {
        this.emailErrorMsg = '';
        this.emailText = '';
      } else {
        this.lineErrorMsg = '';
        this.lineToken = '';
      }
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text?.toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
      );
    },

    renderInputOption(type) {
      if (type === propTypes.MILEAGE) {
        return {
          suffix: 'กม.',
          maxLength: 8,
        };
      }
      return {
        suffix: 'ชม.',
        maxLength: 3,
      };
    },

    setDefaultSelectedType() {
      if (this.isEventTab) {
        this.resetMaintenanceInfo();
      } else {
        this.resetEventInfo();
      }
    },

    resetTempInfo() {
      this.selectedTempType = tempTypes.TEMPERATURE1;
      this.tempMin = -50;
      this.tempMax = 50;
    },

    resetEventInfo() {
      this.selectedType = propTypes.MILEAGE;
      this.resetTempInfo();
      this.expectedArrivalTime = null;
      this.tableType = 'maintenance';
      this.multipleVehicleValues = [];
    },

    resetMaintenanceInfo() {
      this.selectedType = propTypes.NOUPDATE;
      this.selectedPTOType = ptoTypes.PTO1;
      this.convertedDateTime = '';
      this.notifySchedule = null;
      this.maintenanceInfo = {
        lastNotify: null,
        repeatTaskAfter: null,
        notifyBefore: null,
      };
    },
    resetState() {
      this.name = '';
      this.selectedVehicle = '';
      this.isEmailEnabled = false;
      this.isLineEnabled = false;
      this.emailText = '';
      this.lineToken = '';
      this.isEventTab = true;
      this.selectedGeofence = '';
      this.expectedArrivalTime = null;
      this.tableType = '';
      this.vehicleData = {};
      this.cronExpression = '';
      this.emailErrorMsg = '';
      this.lineErrorMsg = '';
      this.multipleVehicleValues = [];
      this.selectedGeoAction = '';
      this.resetTempInfo();
      this.setDefaultSelectedType();
    },
  },
};
</script>
<style lang="scss">
.select-input .ant-select-selection--single {
  height: 48px !important;
  &:hover {
    border-color: #523ee8;
  }

  .ant-select-selection__rendered {
    height: 48px !important;
  }
  .ant-select-selection-selected-value {
    padding-top: 8px;
  }
}

.select-input .ant-select-selection--multiple {
  height: 48px !important;
  &:hover {
    border-color: #523ee8;
  }

  .ant-select-selection__placeholder {
    margin-top: -1px;
  }

  .ant-select-selection__rendered > ul > li {
    height: 40px !important;
    padding: 10px 20px 10px 10px;
  }
  .ant-select-selection-selected-value {
    padding-top: 8px;
  }

  .ant-select-arrow {
    top: 24px;
    cursor: pointer;
  }
}

.input-box .ant-input {
  height: 48px !important;
  width: 100%;

  &:hover {
    border-color: #523ee8 !important;
  }
}

#temp_range {
  .ant-slider-rail {
    border: 1px solid #523ee8;
    border-radius: 6px;
    background-color: #fff;
    height: 8px;
  }
  .ant-slider-track {
    background-color: #523ee8;
    height: 8px;
  }
  .ant-slider-handle {
    border: 1px solid #523ee8;
    height: 12px;
    width: 12px;
    top: 60%;
  }
}

.noti-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #523ee8 !important;
  border-color: #523ee8 !important;
}

.notify-sending-row-error {
  margin-top: -12px !important;
  padding-top: 0px !important;
}

#vehicle-selection-item-list {
  .vehicle-item-container {
    margin: 2px 0px;

    .vehicle-checkbox input {
      height: 0;
      width: 0;
    }

    .vehicle-checkbox {
      display: block;
      position: relative;
      padding-left: 20px;
      margin-bottom: 4px;
      user-select: none;
    }

    .vehicle-checkbox:hover input ~ .checkmark {
      background-color: #523ee8;
      border: 1px solid #523ee8;
    }

    .vehicle-checkbox input:checked ~ .checkmark {
      background-color: #523ee8;
      border: 1px solid #523ee8;
    }

    .checkmark {
      position: absolute;
      top: 2px;
      left: 2px;
      height: 16px;
      width: 16px;
      border-radius: 4px;
      border: 1px solid #e4e4e4;
      background-color: white;
    }

    .vehicle-checkbox .checkmark:after {
      top: -2px;
      width: 0.5px;
      height: 0.5px;
      left: 0px;
      color: white;
    }

    .vehicle-checkbox input:checked ~ .checkmark:after {
      display: block;
    }

    //To hide the checkmark when not checked//
    .checkmark:after {
      content: '✓';
      transform-origin: bottom left;
      position: absolute;
      display: none;
      width: 2px;
      height: 2px;
    }

    label {
      margin-left: 10px;
    }
  }

  i {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.title {
  font-size: 24px;
  font-weight: normal;
}
.navigation-container {
  margin-top: 34px;
  font-size: 18px;
  display: inline-block;
  .navigation-button {
    height: 30px;
    padding: 0 25px;
    display: inline-block;
    text-align: center;
    color: #b5b5b5;
    border-bottom: 1px solid #b5b5b5;
    cursor: pointer;

    &.active {
      color: #362ba2;
      border-bottom: 2px solid #362ba2;
    }
  }
}

.content-container {
  margin-top: 48px;
  padding: 0 32px;

  .info-section {
    margin-bottom: 32px;
  }

  .noti-info-section {
    padding: 28px 0px;
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
  }

  .noti-sending-section {
    margin-top: 32px;
  }
}
.form-label {
  color: #515151;
}
.title-section {
  color: #515151;
  margin-bottom: 20px;
}
.input-box {
  height: 48px;
  width: 100%;

  &:hover {
    border-color: #523ee8 !important;
  }
}

.input-box-error {
  border-color: #e821aa !important;
}

.action-container {
  margin-top: 40px;
  display: flex;
  column-gap: 32px;
}
.action-button {
  background-color: #fff;
  color: #515151;
  border: 1px solid #e4e4e4;
  height: 44px;
  width: 92px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #eae7e7;
    color: #515151 !important;
    border: 1px solid #e4e4e4 !important;
  }
  &.noti {
    border-radius: 20px;
    display: inline-block;
    width: auto;
    padding: 0px 20px;
    &.active {
      background-color: #523ee8;
      color: #fff !important;
      border: none;

      &:hover {
        // background-color: #362ba2;
        border: none !important;
      }
    }
  }

  &.primary {
    background-color: #523ee8;
    color: #fff !important;
    border: none;

    &:hover {
      background-color: #362ba2;
    }
  }
}

.disabled-action-button {
  background-color: #eae7e7;
  border: 1px solid #e4e4e4;
  color: rgba(0, 0, 0, 0.25);
  height: 44px;
  width: 92px;
  border-radius: 6px;
  cursor: not-allowed;

  &:hover {
    background-color: #eae7e7;
    color: rgba(0, 0, 0, 0.25);
    border: 1px solid #e4e4e4;
  }
  &.noti {
    border-radius: 20px;
    display: inline-block;
    width: auto;
    padding: 0px 24px;
  }
}

.error-msg {
  position: relative;
  color: #e821aa;
  margin-top: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}
</style>
