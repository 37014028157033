import gql from 'graphql-tag';
import { apolloClient } from '../strapi-apollo';
// import {
//   apolloStrapiClient
// } from "../strapi-apollo";

import store from '../store';
// import router from "../router";

// import userGroups from "@/graphql/userGroups.gql"
export const displayAddress = (location) => {
  let addr = '';
  if (location) {
    if ('road' in location) {
      addr = addr + ' ' + location.road;
    }
    if ('subdistrict' in location) {
      addr = addr + ' ' + location.subdistrict;
    }
    if ('district' in location) {
      addr = addr + ' ' + location.district;
    }
    if ('province' in location) {
      addr = addr + ' ' + location.province;
    }
  }
  return addr;
};

export const getVehicles = async (IDs) => {
  await apolloClient.resetStore();
  await apolloClient
    .query({
      // Query
      query: gql`
        query customergroups($id_in: [ID]!) {
          customergroups(where: { id_in: $id_in }) {
            id
            name
            label
            plan
            Logo {
              id
              name
              url
              ext
            }
            theme
            theme_logo {
                id
                name
                url
            }
            vehicle_groups {
              id
              name
              label
              vehicles {
                id
                name
                unit_id
                chassis_no
                register_type
                display_order
                lastFuelLitre
                sim {
                  operator
                  name
                  package
                }
                plate_number
                model
                device {
                  id
                  name
                  serial_no
                  company
                  model
                  imei
                  purchase_date
                  warranty_begin
                }
                last_position_json
                lastLocation
                odo_canbus_offset
                odo_canbus_enable
                canbus_fuel_enable
                overspeed_limit
                block_ignition_enabled
                block_ignition_with_card_enabled
                block_ignition_status
                block_ignition_with_card_status
                canbus_truck_enabled
                oil_liters_max
                oil_liters_min
                vehicle_icon
                sensor1_temp_min
                sensor1_temp_max
                sensor2_temp_min
                sensor2_temp_max
                temp_notify_enabled
              }
            }
          }
        }
      `,
      variables: {
        id_in: IDs,
      },
    })
    .then(async (customerGroupsData) => {
      // console.log('all vehicles', customerGroupsData.data.customergroups);
      for (const groupKey in customerGroupsData.data.customergroups[0]
        .vehicle_groups) {
        const vehiclesData =
          customerGroupsData.data.customergroups[0].vehicle_groups[groupKey]
            .vehicles;

        for (const idx in vehiclesData) {
          if (
            vehiclesData[idx] &&
            'last_position_json' in vehiclesData[idx] &&
            vehiclesData[idx].device &&
            vehiclesData[idx]['last_position_json']
          ) {
            vehiclesData[idx].device.position =
              vehiclesData[idx].last_position_json;

            if (vehiclesData[idx].device.position === null) {
              vehiclesData[idx].device.position = {
                engineStatus: 'OTHERS',
              };
            }
          } else if (vehiclesData[idx].device) {
            vehiclesData[idx].device.position = {
              engineStatus: 'OTHERS',
            };
          }
        }
      }
      const firstCustomerGroup = customerGroupsData.data.customergroups[0];
      // console.log(customerGroupsData.data.customergroups)
      await store.dispatch(
        'authen/setCustomerGroups',
        customerGroupsData.data.customergroups
      );
      await store.dispatch('authen/setCustomerPlan', firstCustomerGroup.plan);
      await store.dispatch('authen/selectGroup', {
        id: firstCustomerGroup.id,
        label: firstCustomerGroup.label,
        logo: firstCustomerGroup.Logo,
        ext: firstCustomerGroup.ext,
        theme: firstCustomerGroup.theme,
        theme_logo: firstCustomerGroup.theme_logo
      });
      // console.log('first vehicle group', firstCustomerGroup.label);
      await store.dispatch('vehicle/updateVehicle', {
        groups: customerGroupsData.data.customergroups,
        groupID: firstCustomerGroup.id,
      });

      // console.log('update vehicle group', customerGroupsData.data.customergroups);
    })
    .catch((err) => {
      console.log(err);
      return;
    });

  // return allDeviceGQL;
};

// export const getVehicles2 = async () => {
//   await apolloClient.resetStore();
//   apolloClient
//     .query({
//       // Query
//       query: userGroups,
//       variables: {
//         id: store.state.authen.user.id
//       }
//     })
//     .then(async userData => {
//       // console.log('all vehicles', userData)
//       const firstVehicleGroup = userData.data.user.customer_groups[0];
//       await store.dispatch("authen/selectGroup", {
//         id: firstVehicleGroup.id,
//         label: firstVehicleGroup.label
//       });
//       await store.dispatch("vehicle/updateVehicle", {
//         userData: userData,
//         groupID: firstVehicleGroup.id
//       });
//       connectPositionSocket();
//     })
//     .catch(err => {
//       console.log(err);
//       return;
//     });

//   // return allDeviceGQL;
// };

export const getGeolocation = async (vehicleID) => {
  await apolloClient.resetStore();
  apolloClient
    .query({
      // Query
      query: gql`
        query vehicle($id: ID!) {
          vehicle(id: $id) {
            id
            name
            model
            lastFuelLitre
            lastLocation
            overspeed_limit
            block_ignition_enabled
            block_ignition_with_card_enabled
            block_ignition_status
            block_ignition_with_card_status
          }
        }
      `,
      variables: {
        id: vehicleID,
      },
    })
    .then(async (vehicle) => {
      await store.dispatch('vehicle/updateVehicleLocation', vehicle);
    })
    .catch((err) => {
      console.log(err);
      return;
    });

  // return allDeviceGQL;
};
