// const axios = require("axios");
// import endpoints from "../../config/config";
// import router from '../../router'
// initial state
// shape: [{ id, quantity }]
const state = () => ({
  loading: false,
  reportLoading: false,
  saveGeofenceLoading: false,
  saveRouteLoading: false,
  saveSettingLoading: false,
  nofificationLoading: false,
  changeStatusBlockIgnitionLoading: false,
  changeStatusBlockWithCardLoading: false,
});

// getters
const getters = {
  loading: (state) => state.loading,
};

// actions
const actions = {
  setLoading({
    commit
  }, payload) {
    commit("SET_LOADING", payload);
  },
  setReportLoading({
    commit
  }, payload) {
    commit("SET_REPORT_LOADING", payload);
  },
  setSaveGeofenceLoading({
    commit
  }, payload) {
    commit("SET_SAVE_GEOFENCE_LOADING", payload);
  },
  setSaveRouteLoading({
    commit
  }, payload) {
    commit("SET_SAVE_ROUTE_LOADING", payload);
  },
  setSaveSettingLoading({
    commit
  }, payload) {
    commit("SET_SAVE_SETTING_LOADING", payload);
  },
  setNotificationLoading({
    commit
  }, payload) {
    commit("SET_NOTIFICATION_LOADING", payload);
  },
  setChageStatusBlockIgnitionLoading({
    commit
  }, payload) {
    commit("SET_CHANGE_STATUS_BLOCK_IGNITION_LOADING", payload);
  },
  setChangeStatusBlockWithCardLoading({
    commit
  }, payload) {
    commit("SET_CHANGE_STATUS_BLOCK_WITH_CARD_LOADING", payload);
  },
  // getUserData({ commit }, payload) {
  //   // axios({
  //   //   method: "post",
  //   //   url: endpoints.graphAPI,
  //   //   data: payload,
  //   //   headers: { Authorization: payload.token }
  //   // }).then(res => {
  //   //   commit("setUser", res);
  //   // });
  // }
};

// mutations
const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload;
  },
  SET_REPORT_LOADING(state, payload) {
    state.reportLoading = payload;
  },
  SET_SAVE_GEOFENCE_LOADING(state, payload) {
    state.saveGeofenceLoading = payload;
  },
  SET_SAVE_ROUTE_LOADING(state, payload) {
    state.saveRouteLoading = payload;
  },
  SET_SAVE_SETTING_LOADING(state, payload) {
    state.saveRouteLoading = payload;
  },
  SET_NOTIFICATION_LOADING(state, payload) {
    state.nofificationLoading = payload;
  },
  SET_CHANGE_STATUS_BLOCK_IGNITION_LOADING(state, payload) {
    state.changeStatusBlockIgnitionLoading = payload;
  },
  SET_CHANGE_STATUS_BLOCK_WITH_CARD_LOADING(state, payload) {
    state.changeStatusBlockWithCardLoading = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};