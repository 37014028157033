import moment from '@/lib/time';
import Excel from 'exceljs';
import AxiosService from '@/plugins/axios';
import endpoints from '../../config/config';
import {
  blobToFile,
  sheetMargin,
  fontNormal,
  hightlightTableHead,
  hightlightRow,
  generateLogoHeader,
  addLogoCompany,
} from './config';
import * as Sentry from '@sentry/vue';
import store from '@/store';

const genFuelUsedRateSumReport = async (vehicles, imeis, start, stop, logo) => {
  const strTimerange =
    moment.unix(start).format('D_MMM_YY') +
    '-ถึง-' +
    moment.unix(stop).format('D_MMM_YY');
  await AxiosService.post(`${endpoints.coreApi}/report-summary-event`, {
    eventType: 'END_TRIP',
    imeis: imeis,
    start: start,
    stop: stop,
  })
    .then(async (response) => {
      const fileName = 'Summary_Report_' + strTimerange;
      genExcelFURSReport(fileName, vehicles, response.data, start, stop, logo);
    })
    .catch((error) => {
      console.log('An error occurred:', error);
      Sentry.captureException(error);
      store.dispatch('loading/setReportLoading', false);
      return;
    });
};

const genExcelFURSReport = async (
  fileName,
  vehicles,
  data,
  start,
  stop,
  logo
) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: 'FF41C173',
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
    },
  };

  let imageLogoId;
  if (logo && logo.url) {
    const ext = logo.ext.substring(1);
    const myBase64Image = await generateLogoHeader(logo);
    imageLogoId = workbook.addImage({
      base64: myBase64Image,
      extension: ext,
    });
  }

  const tableHead = [
    'ทะเบียนรถ',
    'Idling >5 min (ครั้ง)',
    'Idling >5 min (นาที)',
    'stop >10 min (ครั้ง)',
    'stop >10 min (นาที)',
    'overspeed (ครั้ง)',
    'distance (กม)',
    'จำนวนน้ำมัน',
    'สูตรอัตราสิ้นเปลือง',
  ];
  const columnsSpec = [
    {
      key: 'plate_no',
      width: 30,
      style: {
        font: fontNormal,
      },
    },
    {
      key: 'totalCountIdling',
      width: 25,
    },
    {
      key: 'idtotalTimeIdlingling',
      width: 25,
    },
    {
      key: 'totalCountstop',
      width: 25,
    },
    {
      key: 'totalTimeStop',
      width: 25,
    },
    {
      key: 'overspeed',
      width: 25,
    },
    {
      key: 'distance',
      width: 25,
    },
    {
      key: 'fuel',
      width: 25,
    },
    {
      key: 'fuelUsedRate',
      width: 25,
    },
  ];
  const strTimerange =
    moment.unix(start).format('D_MMM_YYYY') +
    '-ถึง-' +
    moment.unix(stop).format('D_MMM_YYYY');
  const metaRow1 = `รายงานสรุปพร้อมอัตราสิ้นเปลือง ประจำวันที่ ${strTimerange}`;

  const metaRow2 = ` `;
  const sheet = workbook.addWorksheet(`Summary`, pageProperties);

  let startingRow = 1;
  sheet.pageSetup.margins = sheetMargin;
  sheet.columns = columnsSpec;

  if (logo && logo.url) {
    startingRow = 2;
    addLogoCompany(sheet, imageLogoId);
  }

  sheet.addRow([metaRow1]);
  sheet.addRow([metaRow2]);

  sheet.addRow([]);
  sheet.addRow(tableHead);
  let n = 4;
  for (const i in vehicles) {
    const imei = vehicles[i].imei;
    if (imei in data) {
      n = n + 1;
      const totalCountIdling =
        'totalCountIdling' in data[imei]
          ? data[imei].totalCountIdling.value
          : 0;
      const totalTimeIdling =
        'totalTimeIdling' in data[imei] ? data[imei].totalTimeIdling.value : 0;
      const totalCountStop =
        'totalCountStop' in data[imei] ? data[imei].totalCountStop.value : 0;
      const totalTimeStop =
        'totalTimeStop' in data[imei] ? data[imei].totalTimeStop.value : 0;
      const overSpeedCount =
        'overSpeedCount' in data[imei] ? data[imei].overSpeedCount.value : 0;
      const totalDistance =
        'totalDistance' in data[imei] ? data[imei].totalDistance.value : 0;

      sheet.addRow([
        vehicles[i].plate,
        totalCountIdling,
        totalTimeIdling,
        totalCountStop,
        totalTimeStop,
        overSpeedCount,
        totalDistance,
        0,
      ]);
      sheet.getCell(`I${n}`).value = { formula: `G${n}/H${n}`, result: 0 };
    }
  }

  hightlightTableHead(sheet, startingRow + 3);
  hightlightRow(sheet, startingRow);

  blobToFile(workbook, fileName);
};

export default genFuelUsedRateSumReport;
