<template>
  <button
    id="custom-button"
    type="button"
    @click="$emit('onClick')"
    :disabled="disabled"
    :class="type"
  >
    <slot name="icon" class="icon"></slot>
    <div class="text">
      {{ label || 'กด' }}
    </div>
  </button>
</template>
<script>
export default {
  props: ['label', 'disabled', 'type'],
  emits: ['onClick'],
};
</script>
<style lang="scss" scoped>
$button-primary: #00c246;
$button-danger: #e821aa;
$text-primary: #5e5e5e;

button[id='custom-button'][type='button'] {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  outline: none;

  position: relative;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 0.5rem 1.75rem;
  overflow: hidden;
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 8px;

  > .icon {
    display: flex;
    align-items: center;
  }

  &.main {
    background-color: $button-primary;
    color: #ffffff;
  }

  &.danger {
    background-color: $button-danger;
    color: #ffffff;
  }

  &.special {
    background-image: linear-gradient(to right, #523ee8, #e821aa);
    color: #ffffff;
  }
}

button[id='custom-button'][type='button']:hover:not(:disabled) {
  opacity: 0.8;
}

button[id='custom-button'][type='button']:disabled {
  cursor: not-allowed;
  color: $text-primary;
  background-image: none;
}
// button[type='button']:disabled {
//   cursor: not-allowed;
//   background-color: lightgrey;
//   background-image: none;
// }
</style>
