<template lang="">
  <defaultLayout>
    <div
      :style="{
        position: isVideoListLoading ? 'relative' : 'static',
        display: 'flex',
        height: '100%',
      }"
    >
      <div v-if="isVideoListLoading" class="loading-mask">
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 56px"
            spin
          />
        </a-spin>
      </div>
      <div class="left-side-drawer">
        <div class="title">ค้นหาวิดีโอย้อนหลัง</div>
        <div class="form">
          <div>
            <a-select
              showSearch
              placeholder="เลือกพาหนะ"
              optionFilterProp="children"
              @change="onSelectVehicle"
              style="width: 100%; border-radius: 30px"
              :filterOption="filterOption"
            >
              <a-select-option
                v-for="vehicle in filterVideoDeviceList"
                :key="vehicle.id"
                :value="vehicle.unit_id"
                :disabled="disabledOption(vehicle)"
              >
                {{ vehicle.plate_number.trim() }} ( {{ vehicle.name.trim() }} )
              </a-select-option>
            </a-select>
          </div>
          <div style="position: relative">
            เวลาเริ่มต้น
            <a-date-picker
              v-model="startTime"
              @change="onChangeStartTime"
              :disabledDate="disabledDate"
              :locale="locale"
              format="DD MMM YYYY HH:mm"
              placeholder="เลือกเวลาเริ่มต้น"
              show-time
              style="width: 100%"
            />
          </div>
          <div>
            เวลาสิ้นสุด
            <a-date-picker
              v-model="endTime"
              @change="onChangeEndTime"
              :disabledDate="disabledDate"
              :disabled-time="disabledEndDateTime"
              :locale="locale"
              format="DD MMM YYYY HH:mm"
              placeholder="เลือกเวลาสิ้นสุด"
              show-time
              style="width: 100%"
            />
          </div>
          <div>
            แหล่งที่มา
            <div>
              <a-checkbox
                class="checkbox"
                v-model="isSDCardSourceType"
                @change="onChangeSourceType(0)"
              >
                SD Card
              </a-checkbox>
              <a-checkbox
                class="checkbox"
                v-model="isServerSourceType"
                @change="onChangeSourceType(1)"
              >
                Server
              </a-checkbox>
            </div>
          </div>
          <a-button
            @click="onSubmitSearch"
            type="primary"
            style="width: 70px; margin-top: 20px"
            :disabled="disabledSubmitButton()"
          >
            ค้นหา
          </a-button>
        </div>
      </div>
      <div style="flex-grow: 1">
        <div style="display: flex; flex-direction: column; height: 100%">
          <div style="flex-grow: 1; position: relative">
            <div v-if="selectedVideo !== ''" class="video-player-container">
              <jessibucaPlayer :selectedVideo="selectedVideo" />
            </div>
            <div
              v-else
              style="
                display: flex;
                align-items: center;
                height: 100%;
                width: 100%;
                justify-content: center;
                background-color: black;
                color: white;
              "
            >
              <div v-if="!isVideoPlayLoading">No Video</div>
              <div v-else>
                <a-spin :indicator="indicator" />
                Fetching Video Monitoring Url
              </div>
            </div>
          </div>
          <playbackDrawer
            :videoFiles="videoList"
            :timeRange="selectedTimeRange"
            @onClickVideo="onClickVideo"
          />
        </div>
      </div>
    </div>
  </defaultLayout>
</template>
<script>
import defaultLayout from '@/views/layouts/defaultLayout.vue';
import locale from 'ant-design-vue/es/date-picker/locale/th_TH';
import playbackDrawer from './components/videoMonitoring/playbackDrawer.vue';
import jessibucaPlayer from './components/videoMonitoring/jessibucaPlayer.vue';
import endpoints from '../config/config';
import axios from '@/plugins/axios';
import { mapGetters } from 'vuex';
import moment from '@/lib/time';
export default {
  components: {
    defaultLayout,
    playbackDrawer,
    jessibucaPlayer,
  },
  data() {
    return {
      videoList: [],
      selectedVideo: '',
      selectedTimeRange: [
        moment().hour(0).minute(0).second(0).millisecond(0).unix(),
        moment().hour(23).minute(59).second(59).millisecond(999).unix(),
      ],
      startTime: moment().hour(0).minute(0).second(0).millisecond(0),
      endTime: moment().hour(23).minute(59).second(59).millisecond(999),
      selectedVehicle: null,
      isSDCardSourceType: true,
      isServerSourceType: false,
      storageLocation: 0,
      isVideoListLoading: false,
      isVideoPlayLoading: false,
      axiosController: null,
      indicator: <a-icon type="loading" style="font-size: 24px" spin />,
      locale,
      previousJimiDevice: null,
      selectedVideoChannel: 0,
    };
  },
  computed: {
    ...mapGetters({
      vehicles: 'vehicle/vehicles',
      user: 'authen/user',
    }),
    filterVideoDeviceList() {
      return this.vehicles.filter((vehicle) => {
        const deviceFilter =
          vehicle.device &&
          (vehicle.device.company === 'Richmor' ||
            vehicle.device.company === 'Jimi');
        return deviceFilter;
      });
    },
  },
  methods: {
    range(start, end) {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    },
    disabledDate(current) {
      return (
        moment().add(-1, 'month') >= current || moment().endOf('days') < current
      );
    },
    disabledEndDateTime() {
      return {
        disabledHours: () => this.range(0, this.startTime.hour()),
        disabledMinutes: () => this.range(0, this.startTime.minute()),
        disabledSeconds: () => this.range(0, this.startTime.second()),
      };
    },
    onSelectVehicle(imei) {
      const vehicle = this.vehicles.find((vehicle) => vehicle.unit_id === imei);
      this.closePlaybackSession();

      this.selectedVehicle = vehicle;
    },
    onChangeSourceType(type) {
      if (type === 0) {
        this.isServerSourceType = false;
      } else {
        this.isSDCardSourceType = false;
      }

      if (!this.isSDCardSourceType && !this.isServerSourceType) {
        this.storageLocation = -1;
      } else {
        this.storageLocation = type;
      }
    },
    onChangeStartTime(time) {
      let numberOfDay = time.date();
      if (this.endTime.date() !== numberOfDay) {
        this.endTime = moment(this.endTime).date(numberOfDay);
      }
    },
    onChangeEndTime(time) {
      let numberOfDay = time.date();
      if (this.startTime.date() !== numberOfDay) {
        this.startTime = moment(this.startTime).date(numberOfDay);
      }
    },
    async onSubmitSearch() {
      this.selectedVideo = '';

      if (this.previousJimiDevice) {
        this.clearJimiSession();
      }

      this.axiosController = new AbortController();
      this.isVideoListLoading = true;

      const { device } = this.selectedVehicle;

      await axios
        .post(
          `${
            device.company === 'Jimi' ? endpoints.jimiApi : endpoints.richmorApi
          }/video-list`,
          {
            license_num:
              device.company === 'Jimi'
                ? device.imei
                : this.selectedVehicle.plate_number,
            start_time: this.startTime.format('YYYY-MM-DD HH:mm:ss'),
            end_time: this.endTime.format('YYYY-MM-DD HH:mm:ss'),
            storage_location: this.storageLocation,
            channels: 4,
          },
          {
            signal: this.axiosController.signal,
          }
        )
        .then((res) => {
          const data = res.data.data;
          this.selectedTimeRange = [this.startTime.unix(), this.endTime.unix()];
          this.videoList = data;
          if (data.length === 0) {
            this.$message.error(
              'ไม่พบข้อมูลในช่วงเวลาดังกล่าว หรือ อุปกรณ์อาจจะกำลัง offline อยู่',
              3
            );
          }
          this.isVideoListLoading = false;
        })
        .catch((err) => {
          if (err.code && err.code === 'ERR_CANCELED') {
            this.isVideoListLoading = true;
          } else {
            this.isVideoListLoading = false;
          }
          console.log(err);
        });
    },
    async onClickVideo(data) {
      this.selectedVideo = '';
      this.axiosController = new AbortController();
      this.isVideoPlayLoading = true;

      this.clearRichmorRealtimeSession();
      this.closePlaybackSession();

      const { device } = this.selectedVehicle;
      let url = `${endpoints.richmorApi}/video-play`;
      let reqBody = {
        license_num: data['licenseNum'],
        device_num: data['deviceNum'],
        start_time: data['startTime'],
        end_time: data['endTime'],
        channel: data['channelNum'],
        resourceType: data['resourceType'],
        streamType: data['storageType'],
        storageType: data['storageType'],
      };

      this.selectedVideoChannel = data['channelNum'];

      if (device.company === 'Jimi') {
        this.previousJimiDevice = device.imei;
        url = `${endpoints.jimiApi}/video-play`;
        reqBody = {
          license_num: device.imei,
          user_id: this.user.id,
          file_name: data['fileName'],
        };
      }

      await axios
        .post(url, reqBody, {
          signal: this.axiosController.signal,
        })
        .then((res) => {
          const data = res.data.data;
          this.selectedVideo = data;
          this.isVideoPlayLoading = false;
          if (data.length === 0) {
            this.$message.error(
              'ขออภัย ไม่สามารถดูวิดีโอช่วงเวลาดังกล่าวได้ ณ ขณะนี้',
              3
            );
          }
        })
        .catch((err) => {
          if (err.code && err.code === 'ERR_CANCELED') {
            this.isVideoPlayLoading = true;
          } else {
            this.isVideoPlayLoading = false;
          }
          console.log(err);
        });
    },
    disabledSubmitButton() {
      if (
        this.selectedVehicle &&
        this.startTime &&
        this.endTime &&
        this.storageLocation > -1
      ) {
        return false;
      }
      return true;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    async clearJimiSession() {
      console.log('Clear session');
      await axios
        .post(`${endpoints.jimiApi}/clear-video-session`, {
          license_num: this.previousJimiDevice,
          user_id: this.user.id,
        })
        .then(() => {
          return true;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
      this.previousJimiDevice = null;
    },
    checkisoverSec(engineStatus, timestamp, speed, overspeed) {
      const sec = 3720;
      const overTime = moment().unix() - timestamp;
      if (overTime > sec) {
        return 'NOT_UPDATED';
      } else {
        if (speed >= overspeed) {
          return 'OVER_SPEED';
        }
        return engineStatus;
      }
    },
    disabledOption(vehicle) {
      if ('last_position_json' in vehicle && vehicle.last_position_json) {
        if ('device' in vehicle && 'position' in vehicle.device) {
          return this.checkisoverSec(
            vehicle.device.position.engineStatus,
            vehicle.device.position.timestamp,
            vehicle.device.position.speed,
            vehicle.overspeed_limit
          ) === 'NOT_UPDATED'
            ? true
            : false;
        }
        return true;
      }
      return true;
    },
    async clearRichmorRealtimeSession() {
      console.log('Clear Richmor Realtime Session');
      await axios
        .post(`${endpoints.richmorApi}/clear-realtime-session`, {
          imei: this.selectedVehicle.unit_id,
          channel_no: this.selectedVideoChannel,
        })
        .then(() => {
          return true;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });
      this.selectedVideoChannel = 0;
    },
    closePlaybackSession() {
      if (this.selectedVehicle && 'unit_id' in this.selectedVehicle) {
        if (
          this.selectedVehicle.device.company === 'Richmor' &&
          this.selectedVideoChannel > 0
        ) {
          this.clearRichmorRealtimeSession();
        }
      }
    },
  },
  destroyed() {
    this.closePlaybackSession();
    if (this.previousJimiDevice) {
      this.clearJimiSession();
    }
  },
};
</script>

<style lang="scss">
.checkbox {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #30c83f;
    border-color: #30c83f !important;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #30c83f !important;
  }
}
</style>

<style lang="scss" scoped>
$md: 768px;
.loading-mask {
  position: absolute;
  background-color: grey;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  z-index: 99;
}
.left-side-drawer {
  //   @media screen and (max-width: $md) {
  //   width: 200px;
  //   padding: 15px 10px;
  //   }
  width: 270px;
  display: inline-block;
  background-color: #fafafa;
  // border: 1px solid #d3d4d6;
  padding: 16px 21px;
}
.title {
  color: #000;
  font-size: 18px;
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.video-player-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#333333 87%, black);
}
</style>
