import moment from '@/lib/time';
import {
  getVehicleOdoOffset,
  getVehicleFuelCanbusEnabled,
  getCanbusTruckEnabled,
  displayOdometer,
  displayFuelLevel,
} from '@/lib/device';
import {
  displayAddress
} from '@/lib/vehicle';
import _ from 'lodash';
import store from '@/store';
import Excel from 'exceljs';
import {
  getGeofenceName
} from '@/lib/device';
import * as XLSX from 'xlsx'; // import xlsx
export const fontName = 'Helvetica';
export const fontSizeNormal = 10;
export const fontNormal = {
  name: fontName,
  size: fontSizeNormal,
};
const https = require('https');

export const convertKnotToKmph = (knot) => {
  return (knot * 1.0).toFixed(2);
};

export const formatDate = (timestamp) => {
  return moment.unix(timestamp).format('D MMM YYYY HH:mm:ss น.');
};

export const formatFromDateTime = (datetime) => {
  return moment(datetime).format('D MMM YY HH:mm:ss น.');
};
export const formatFromDateTimeShort = (datetime) => {
  return moment(datetime).format('DMMMYY HH:mm:ss');
};

export const formatDateTS = (timestamp) => {
  return moment.unix(timestamp).format('YYYY-MM-DD HH:mm:ss');
};
export const formatDuration = (start, stop) => {
  const duration = moment.duration(moment.unix(stop).diff(moment.unix(start)));
  return `${duration.days()} วัน ${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
};
export const translateEngineStatus = (status) => {
  if (status == 'VEHICLE_MOVING_END' || status == 'VEHICLE_MOVING')
    return 'รถวิ่ง';
  else if (status == 'END_TRIP') return 'สิ้นสุดทริป';
  else if (status == 'ENGINE_IDLE_END' || status == 'ENGINE_IDLE')
    return 'ติดเครื่อง';
  else if (status == 'OVER_SPEED_END') return 'ขับเกินความเร็ว';
  else if (status == 'ENGINE_STOP' || status == 'VEHICLE_STOP_END')
    return 'รถจอด';
  else if (status == 'PTO_STOP') return 'ใช้งาน PTO';
  else return status;
};
export const translateGenderStatus = (status) => {
  if (status == 'Male' || status == 'male') return 'ชาย';
  else if (status == 'Female' || status == 'female') return 'หญิง';
  else return status;
};

export const sheetMargin = {
  left: 0.7,
  right: 0.7,
  top: 0.75,
  bottom: 0.75,
  header: 0.3,
  footer: 0.3,
};
export const getStatusColor = (value) => {
  if (value == 'รถวิ่ง')
    return {
      argb: 'FF41C173',
    };
  else if (value == 'สิ้นสุดทริป')
    return {
      argb: 'FF70CDE3',
    };
  else if (value == 'จอดติดเครื่อง' || value == 'ติดเครื่อง')
    return {
      argb: 'FFFAAD14',
    };
  else if (value == 'ขับเกินความเร็ว')
    return {
      argb: 'FF8E34FF',
    };
  else if (value == 'ใช้งาน PTO')
    return {
      argb: 'FFE6E049',
    };
  else if (value == 'รถจอด')
    return {
      argb: 'FFF5223E',
    };
  else
    return {
      argb: 'FFFFFFFF',
    };
};

export const getSpeedColor = (value, overspeed_limit) => {
  if (value > overspeed_limit)
    return {
      argb: 'FF8E34FF',
    };
  else return false;
};

export const imeiToPlateNumber = (vehicles, imei) => {
  const vehicle = _.find(vehicles, {
    imei: imei,
  });
  if (vehicle) return vehicle.plate;
  return '-';
};

export const imeiToVehicleName = (vehicles, imei) => {
  const vehicle = _.find(vehicles, {
    imei: imei,
  });
  if (vehicle) return vehicle.name;
  return '-';
};

export const getOdoData = (vehicles, imei, position) => {
  const vehicle = _.find(vehicles, {
    imei: imei,
  });
  return displayOdometer(vehicle, position);
};

export const getFuelData = (vehicles, imei, position) => {
  const vehicle = _.find(vehicles, {
    imei: imei,
  });
  return displayFuelLevel(vehicle, position);
};

export const getOdoOffset = (vehicles, imei) => {
  const vehicle = _.find(vehicles, {
    imei: imei,
  });
  return getVehicleOdoOffset(vehicle);
};

export const getFuelCanbusEnabled = (vehicles, imei) => {
  const vehicle = _.find(vehicles, {
    imei: imei,
  });
  return getVehicleFuelCanbusEnabled(vehicle);
};

export const getFuelCanbusTruckEnabled = (vehicles, imei) => {
  const vehicle = _.find(vehicles, {
    imei: imei,
  });
  return getCanbusTruckEnabled(vehicle);
};

export const blobToFile = (workbook, fileName) => {
  workbook.xlsx.writeBuffer().then((buffer) => {
    // FileSaver.saveAs(buffer, "test.xslx");
    var bytes = new Uint8Array(buffer); // pass your byte response to this constructor

    var blob = new Blob([bytes], {
      type: 'application/xlsx',
    }); // change resultByte to bytes

    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx`;
    link.click();
    store.dispatch('loading/setReportLoading', false);
  });
};

export const addLogoCompany = (sheet, imageLogoId) => {
  sheet.addRow([]);
  sheet.mergeCells(1, 1, 1, sheet.columns.length + 1);
  sheet.getRow(1).height = 43;

  sheet.getRow(1).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FFFFFFFF',
    },
    bgColor: {
      argb: 'FFFFFFFF',
    },
  };
  sheet.addImage(imageLogoId, {
    tl: {
      col: 0,
      row: 0
    },
    ext: {
      width: 130,
      height: 56
    },
  });
};

export const generateLogoHeader = async (logo) => {
  const ext = logo.ext.substring(1);
  const url = logo.url;

  return await new Promise((resolve, reject) => {
    https
      .get(url, (res) => {
        let data = [];

        res.on('data', (chunk) => {
          data.push(chunk);
        });

        res.on('end', () => {
          const buffer = Buffer.concat(data).toString('base64');
          let myBase64Image = `data:image/${ext};base64,${buffer}`;

          resolve(myBase64Image);
        });
      })
      .on('error', (err) => {
        console.log('Error: ', err.message);
        reject();
      });
  }).catch((error) => {
    console.log(`Something happened: ${error}`);
  });
};

export const hightlightRow = async (sheet, rowNum, color = 'FF513fe8') => {
  const numColumn = sheet.columns.length;
  sheet.mergeCells(rowNum, 1, rowNum, numColumn + 1);
  sheet.getRow(rowNum).height = 28;

  sheet.getRow(rowNum).font = {
    name: fontName,
    family: 4,
    size: 12,
    bold: true,
    color: {
      argb: 'FFFFFFFF',
    },
  };
  sheet.getRow(rowNum).alignment = {
    vertical: 'middle',
    horizontal: 'left',
  };
  sheet.getRow(rowNum).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: color,
    },
    bgColor: {
      argb: color,
    },
  };
};

export const hightlightSubRow = async (sheet, rowNum, color = '538DD5') => {
  const numColumn = sheet.columns.length;
  sheet.mergeCells(rowNum, 1, rowNum, numColumn + 1);
  sheet.getRow(rowNum).height = 28;

  sheet.getRow(rowNum).font = {
    name: fontName,
    family: 4,
    size: 13,
    bold: true,
    color: {
      argb: 'FFFFFFFF',
    },
  };
  sheet.getRow(rowNum).alignment = {
    vertical: 'middle',
    horizontal: 'left',
  };
  sheet.getRow(rowNum).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: color,
    },
    bgColor: {
      argb: color,
    },
  };
};

export const hightlightRow2 = async (
  sheet,
  rowNum,
  color1 = 'FF513fe8',
  color2 = 'FFE820A9'
) => {
  sheet.getRow(rowNum).font = {
    name: fontName,
    family: 4,
    size: 11,
    bold: false,
    color: {
      argb: 'FFFFFFFF',
    },
  };
  sheet.getRow(rowNum).fill = {
    type: 'gredient',
    gradient: 'path',
    degree: 0,
    stops: [{
      position: 0,
      color: {
        argb: color1,
      },
    },
    {
      position: 1,
      color: {
        argb: color2,
      },
    },
    ],
  };
};

export const hightlightTableHead = async (sheet, rowNum) => {
  sheet.getRow(rowNum).font = {
    name: fontName,
    family: 4,
    size: 11,
    bold: true,
    color: {
      argb: 'FFFFFFFF',
    },
  };
  sheet.getRow(rowNum).fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: {
      argb: 'FF455560',
    },
    bgColor: {
      argb: 'FF455560',
    },
  };
};

export const genExcelV2 = ({
  fileName,
  reportByVehicle,
  tableHead,
  columnsSpec,
  statusColumn,
}) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: 'FF41C173',
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
    },
  };

  if (Object.keys(reportByVehicle).length > 0)
    Object.keys(reportByVehicle).forEach((imei) => {
      if (reportByVehicle[imei].length > 0) {
        const plateNo = reportByVehicle[imei][0][0];
        const sheet = workbook.addWorksheet(plateNo, pageProperties);
        sheet.pageSetup.margins = sheetMargin;
        sheet.columns = columnsSpec;
        const metaRow1 = `รายงานของ รถทะเบียน ${plateNo} เวลาเริ่ม ${reportByVehicle[imei][0][2]
          } ถึง ${reportByVehicle[imei][reportByVehicle[imei].length - 1][3]}`;

        sheet.addRow([metaRow1]);
        sheet.addRow([]);
        sheet.addRow(tableHead);
        sheet.addRows(reportByVehicle[imei]);
        if (statusColumn) {
          sheet.getColumn(statusColumn).eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: getStatusColor(cell.value),
            };
          });
        }
        hightlightTableHead(sheet, 3);
        hightlightRow(sheet, 1);
      }
    });
  else {
    workbook.addWorksheet('ไม่พบข้อมูล', pageProperties);
  }

  blobToFile(workbook, fileName);
};

export const generateExcelReport = async (filename, tableBody, tableHead) => {
  const dataWS = XLSX.utils.json_to_sheet(tableBody, {
    header: tableHead,
  });
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, dataWS, 'Daily Report');
  await XLSX.writeFile(workbook, `${filename}.xlsx`);
};

export const geofenceStartEndFromLocation = (geofences, row) => {
  let locationNameStart = '';
  let locationNameStartEnd = '';

  const geofenceNameStart = getGeofenceName(
    geofences,
    row.positionDataStart.latitude / 10000000,
    row.positionDataStart.longitude / 10000000
  );
  const geofenceNameEnd = getGeofenceName(
    geofences,
    row.positionDataEnd.latitude / 10000000,
    row.positionDataEnd.longitude / 10000000
  );
  if (geofenceNameStart != '') {
    locationNameStart = geofenceNameStart;
  } else {
    locationNameStart = row.locationStart ?
      displayAddress(row.locationStart) :
      '-';
  }

  if (geofenceNameEnd != '') {
    locationNameStartEnd = geofenceNameEnd;
  } else {
    locationNameStartEnd = row.locationEnd ?
      displayAddress(row.locationEnd) :
      '-';
  }
  return {
    start: locationNameStart,
    end: locationNameStartEnd,
  };
};

export const geofenceFromLocation = (geofences, row) => {
  let locationName = '';
  const geofenceName = getGeofenceName(
    geofences,
    row.latitude / 10000000,
    row.longitude / 10000000
  );

  if (geofenceName != '') {
    locationName = geofenceName;
  } else {
    locationName = row.location ? displayAddress(row.location) : '-';
  }
  return locationName;
};

export const getODODistance = (odo_canbus_enable, positions) => {
  if ('odo' in positions[0] && odo_canbus_enable) {
    return (
      (positions[positions.length - 1].odo - positions[0].odo) /
      1000
    ).toFixed(2);
  } else {
    return (
      getEndOdoMeter(odo_canbus_enable, positions) -
      getStartOdoMeter(odo_canbus_enable, positions)
    ).toFixed(2);
  }
};

export const getStartOdoMeter = (odo_canbus_enable, pos) => {
  if (pos && pos.length > 0) {
    if (odo_canbus_enable) {
      let i = 0;
      for (; i < pos.length; i++) {
        if (pos[i].io[5] === 1 && pos[i].io[645] !== 4294967295) {
          return pos[i].io[645];
        }
      }
    } else if ('io' in pos[0]) {
      return pos[0].io[65] / 1000;
    } else {
      return 0
    }
  } else {
    return 0;
  }
};

export const getEndOdoMeter = (odo_canbus_enable, pos) => {
  if (pos && pos.length > 0) {
    if (odo_canbus_enable) {
      let i = pos.length - 1;
      for (; i >= 0; i--) {
        if (pos[i].io[5] === 1 && pos[i].io[645] !== 4294967295) {
          return pos[i].io[645];
        }
      }
    } else if ((pos.length - 1) > 0 && 'io' in pos[pos.length - 1]) {
      return pos[pos.length - 1].io[65] / 1000;
    } else {
      return 0
    }
  } else {
    return 0;
  }
};

export const getMCRDriverName = (license_no, driver_name) => {
  return driver_name && driver_name.length > 0 ?
    driver_name
      .split(/\s+/)
      .reverse()
      .join(' ') :
    license_no;
};

export const displayTimeDuration = (timeDuration) => {
  let totalMilliseconds = timeDuration * 1000;
  let minutesSeconds = moment.utc(totalMilliseconds).format('mm:ss');
  let hours = Math.floor(moment.duration(totalMilliseconds).asHours());
  let displayString = (hours + ':' + minutesSeconds).toString();
  return displayString;
};