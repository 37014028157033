import io from "socket.io-client";
import endpoints from "./config/config";
import store from "./store";
import router from "@/router";
// export const positionSocket = io(endpoints.socket)
export var positionSocketConnect;

function socketConnectWithJWT() {
    if (localStorage.getItem("api-token")) {
        let jwtToken = localStorage.getItem("api-token").replace('Bearer ', '');
        const optionSocket = {
            query: `auth_token=${jwtToken}&groupid=${store.state.authen.selectedGroup.id}`
        }
        positionSocketConnect = io.connect(endpoints.socket, optionSocket);
        positionSocketConnect.on('error', function (err) {
            console.log('socket error ', err);
            positionSocketConnect.removeAllListeners();
            setTimeout(() => {
                console.log('retry connect');
                socketConnectWithJWT();
            }, 20000);
        });

        positionSocketConnect.on('unauthorized', (error) => {
            if (error.data.type == 'UnauthorizedError' || error.data.code == 'invalid_token') {
                // redirect user to login page perhaps?
                console.log('User token has expired');
            }
        });


        // if (!store.state.vehicle) {        
        if (store.state.vehicle.vehicleGroups[0] && store.state.vehicle.vehicleGroups[0].vehicles[0]) {
            positionSocketConnect.on("data", async data => {
                await store.dispatch("vehicle/updateVehiclePosition", data);

            });
        }
    } else {
        router.push({
            path: "login",
        });
    }
}
export const connectPositionSocket = async () => {
    if (typeof positionSocketConnect == 'undefined' || positionSocketConnect.connected == false) {
        // await positionSocketConnect.open();
        socketConnectWithJWT();
    }
};