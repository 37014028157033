<template>
  <div>
    <a-select
      size="large"
      style="width: 100%"
      :value="selectedReportType"
      @change="onChangeReportType"
    >
      <a-select-opt-group
        v-if="
          this.user &&
          'report_permissions' in this.user &&
          this.user.report_permissions.length > 0
        "
      >
        <div slot="label" style="font-size: 20px">
          <a-icon type="star" />&nbsp;รายงานพิเศษ {{ user.display_name }}
        </div>
        <a-select-option
          v-for="(reportType, key) in allowSpecialReports"
          :key="key"
          :value="reportType.value"
        >
          <div class="select-menu-item">- {{ reportType.label }}</div>
        </a-select-option>
      </a-select-opt-group>
      <a-select-opt-group>
        <div slot="label" style="font-size: 20px">
          <a-icon type="file-text" />&nbsp;รายงานทั่วไป
        </div>
        <a-select-option
          v-for="(reportType, key) in reportTypes"
          :key="key"
          :value="reportType.value"
        >
          <div class="select-menu-item">
            {{ reportType.no }}. {{ reportType.label }}
          </div>
        </a-select-option>
      </a-select-opt-group>
    </a-select>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import {
  reportTypesEnum,
  specialReportTypeList,
  normalReportTypeList,
} from '@/lib/report';
export default {
  name: 'ReportTypeSelector',
  props: {
    value: { type: String },
  },
  emits: ['onChange'],
  data() {
    return {
      specialReportTypes: specialReportTypeList,
      reportTypes: normalReportTypeList,
      selectedReportType: reportTypesEnum.N01Daily,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.authen.user,
    }),
    allowSpecialReports() {
      const reportList = [];
      if (
        'report_permissions' in this.user &&
        this.user.report_permissions.length > 0
      ) {
        for (const specialType of this.specialReportTypes) {
          if (
            this.user.report_permissions.find(
              (rp) => rp.code === specialType.value
            )
          )
            reportList.push(specialType);
        }
      }
      return reportList;
    },
  },
  watch: {
    value(newValue) {
      this.selectedReportType = newValue;
    },
  },
  methods: {
    onChangeReportType(type) {
      this.selectedReportType = type;
      this.$emit('onChange', type);
    },
  },
};
</script>
<style lang="scss" scoped>
.select-menu-item {
  font-size: 1rem;
}
</style>
