<template>
  <a-select
    mode="multiple"
    size="large"
    label-in-value
    :value="value"
    placeholder="เลือกสถานี"
    style="width: 100%"
    :filter-option="false"
    @search="onSearchGeofence"
    @change="handleChange"
  >
    <a-select-option v-for="result in resultList" :key="result.id">
      {{ result.name }}
    </a-select-option>
  </a-select>
</template>
<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'multipleSelectGeofence',
  emits: ['onHandleChange'],
  data() {
    return {
      resultList: [],
      value: [],
    };
  },
  mounted() {
    if (this.geofences.length === 0) {
      this.getAllGeofences([this.selectedGroup.id]);
    }
    this.resultList = [...this.geofences.slice(0, 10)];
  },
  computed: {
    ...mapState({
      geofences: (state) => state.geofence.geofences,
      selectedGroup: (state) => state.authen.selectedGroup,
    }),
  },
  methods: {
    ...mapActions({
      getAllGeofences: 'geofence/getAllGeofences',
    }),
    onSearchGeofence(name) {
      if (name === '') {
        this.resultList = [...this.geofences.slice(0, 10)];
      } else {
        this.resultList = [
          ...this.geofences.filter((geofence) => geofence.name.includes(name)),
        ].slice(0, 10);
      }
    },
    handleChange(value) {
      this.value = value;
      this.$emit('onHandleChange', value);
    },
  },
};
</script>
<style lang="scss" scoped></style>
