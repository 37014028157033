// import { apolloClient as strapiApollo } from "@/strapi-apollo";
import AxiosService from '@/plugins/axios';
// import positionReport from "@/graphql/positionReport.gql";
import endpoints from '@/config/config';
import {
  getGeofenceName,
  displayTempSensorData,
  displayDriverName,
  displayVoltage,
  CANfuelrate,
  CANenginehour,
  CANfuelused,
  CANengineSpeed,
  CANcoolant,
  CANpedalposition,
} from "@/lib/device";
import moment from "@/lib/time";
import store from "@/store";
import { getODODistance } from "@/lib/report/config";

import Excel from "exceljs";
import { displayAddress } from "@/lib/vehicle";
import {
  fontNormal,
  sheetMargin,
  getStatusColor,
  hightlightTableHead,
  blobToFile,
  imeiToPlateNumber,
  formatDate,
  getOdoData,
  getFuelCanbusEnabled,
  getFuelCanbusTruckEnabled,
  convertKnotToKmph,
  formatDuration,
  hightlightRow,
  translateEngineStatus,
  getSpeedColor,
  imeiToVehicleName,
  generateLogoHeader,
  addLogoCompany,
  // getMCRDriverName,
} from "./config";
import * as Sentry from "@sentry/vue";

const getPositionReport = async (
  vehicles,
  imeis,
  start,
  stop,
  sec,
  geofences,
  dictOfDriver,
  logo,
  customergroup
) => {

  await AxiosService.post(
    `${endpoints.coreApi}/report-positions`,
    {
     
      imeis: imeis,
      start: start,
      stop: stop,      
      customergroup: customergroup,
      minSecData: ((typeof sec!=='undefined')?Number(sec):0)
    }
  ).then(async (eventData) => {
    console.log(eventData);    
    const reportByVehicle = splitPositionReportByVehicle(
      vehicles,
      eventData.data,
      geofences,
      dictOfDriver,
      0
    );
    // console.log(eventData.data.positions)
    const strTimerange =
      moment.unix(start).format("D_MMM_YY") +
      "-ถึง-" +
      moment.unix(stop).format("D_MMM_YY");
    const fileName = "Position_Report_" + strTimerange;
    const tableHead = [
      "ทะเบียนรถ",
      "ชื่อ",
      "วัน เวลา",
      "สถานะ",
      "ละติจูด",
      "ลองจิจูด",
      "อุณหภูมิ",
      "PTO1",
      "PTO2",
      "PTO3",
      "GSM",
      "ดาวเทียม",
      "ความเร็ว",
      "ตำแหน่ง",
      "เลขกม.สะสม",
      "แบตเตอรี่รถยนต์",
      "เชื้อเพลิง",
      "เลขที่ใบขับขี่",
      "ชื่อคนขับ",
      "สถานะบัตร",
      "ประเภทบัตร",
      "AIN1",
      "timestamp",
      "GoogleMap",
      "Coolant",
      "Fuel rate",
      "Engine hour",
      "RPM",
      "Fuel used",
      "Pedal position",
      "อุณหภูมิ2",
    ];
    const columnsSpec = [
      {
        key: "plate_number",
        width: 10,
        style: {
          font: fontNormal,
        },
      },
      {
        key: "name",
        width: 22,
        style: {
          numFmt: "0",
        },
      },
      {
        key: "time",
        width: 20,
      },
      {
        key: "engineStatus",
        width: 15,
      },
      {
        key: "latitude",
        width: 12,
      },
      {
        key: "longitude",
        width: 12,
      },

      {
        key: "temp",
        width: 8,
      },
      {
        key: "pto1",
        width: 7,
      },
      {
        key: "pto2",
        width: 7,
      },
      {
        key: "pto3",
        width: 7,
      },
      {
        key: "gsm",
        width: 10,
      },
      {
        key: "sattelite",
        width: 10,
      },
      {
        key: "speed",
        width: 8,
      },
      {
        key: "geolocation",
        width: 40,
      },
      {
        key: "odo",
        width: 10,
      },
      {
        key: "carbattery",
        width: 14,
      },
      {
        key: "fuel",
        width: 8,
      },
      {
        key: "mcr_license_no",
        width: 12,
      },
      {
        key: "mcr_driver_name",
        width: 30,
      },
      {
        key: "mcr_card_status",
        width: 10,
      },
      {
        key: "mcr_plt_code",
        width: 10,
      },
      {
        key: "io22",
        width: 10,
      },
      {
        key: "timestamp",
        width: 12,
      },
      {
        key: "GoogleMap",
        width: 10,
      },
      {
        key: "Coolant",
        width: 10,
      },
      {
        key: "FuelRate",
        width: 10,
      },
      {
        key: "EngineHour",
        width: 10,
      },
      {
        key: "RPM",
        width: 10,
      },
      {
        key: "Fuelused",
        width: 10,
      },
      {
        key: "PedalSpeed",
        width: 10,
      },
      {
        key: "temp2",
        width: 8,
      },
    ];
    genPositionExcel({
      fileName,
      reportByVehicle,
      tableHead,
      columnsSpec,
      statusColumn: 4,
      vehicles,
      logo,
      position: eventData.data,
    });
  })
  .catch((err) => {
    console.log(err);
    Sentry.captureException(err);
    store.dispatch("loading/setReportLoading", false);
    return;
  });

  // await strapiApollo.resetStore();
  // await strapiApollo
  //   .query({
  //     // Query
  //     query: positionReport,
  //     variables: {
  //       imeis,
  //       start,
  //       stop,
  //     },
  //   })
  //   .then((eventData) => {
  //     const reportByVehicle = splitPositionReportByVehicle(
  //       vehicles,
  //       eventData.data.positions,
  //       geofences,
  //       dictOfDriver,
  //       sec
  //     );
  //     // console.log(eventData.data.positions)
  //     const strTimerange =
  //       moment.unix(start).format("D_MMM_YY") +
  //       "-ถึง-" +
  //       moment.unix(stop).format("D_MMM_YY");
  //     const fileName = "Position_Report_" + strTimerange;
  //     const tableHead = [
  //       "ทะเบียนรถ",
  //       "ชื่อ",
  //       "วัน เวลา",
  //       "สถานะ",
  //       "ละติจูด",
  //       "ลองจิจูด",
  //       "อุณหภูมิ",
  //       "PTO1",
  //       "PTO2",
  //       "PTO3",
  //       "GSM",
  //       "ดาวเทียม",
  //       "ความเร็ว",
  //       "ตำแหน่ง",
  //       "เลขกม.สะสม",
  //       "แบตเตอรี่รถยนต์",
  //       "เชื้อเพลิง",
  //       "เลขที่ใบขับขี่",
  //       "ชื่อคนขับ",
  //       "สถานะบัตร",
  //       "ประเภทบัตร",
  //       "AIN1",
  //       "timestamp",
  //       "GoogleMap",
  //       "Coolant",
  //       "Fuel rate",
  //       "Engine hour",
  //       "RPM",
  //       "Fuel used",
  //       "Pedal position",
  //       "อุณหภูมิ2",
  //     ];
  //     const columnsSpec = [
  //       {
  //         key: "plate_number",
  //         width: 10,
  //         style: {
  //           font: fontNormal,
  //         },
  //       },
  //       {
  //         key: "name",
  //         width: 22,
  //         style: {
  //           numFmt: "0",
  //         },
  //       },
  //       {
  //         key: "time",
  //         width: 20,
  //       },
  //       {
  //         key: "engineStatus",
  //         width: 15,
  //       },
  //       {
  //         key: "latitude",
  //         width: 12,
  //       },
  //       {
  //         key: "longitude",
  //         width: 12,
  //       },

  //       {
  //         key: "temp",
  //         width: 8,
  //       },
  //       {
  //         key: "pto1",
  //         width: 7,
  //       },
  //       {
  //         key: "pto2",
  //         width: 7,
  //       },
  //       {
  //         key: "pto3",
  //         width: 7,
  //       },
  //       {
  //         key: "gsm",
  //         width: 10,
  //       },
  //       {
  //         key: "sattelite",
  //         width: 10,
  //       },
  //       {
  //         key: "speed",
  //         width: 8,
  //       },
  //       {
  //         key: "geolocation",
  //         width: 40,
  //       },
  //       {
  //         key: "odo",
  //         width: 10,
  //       },
  //       {
  //         key: "carbattery",
  //         width: 14,
  //       },
  //       {
  //         key: "fuel",
  //         width: 8,
  //       },
  //       {
  //         key: "mcr_license_no",
  //         width: 12,
  //       },
  //       {
  //         key: "mcr_driver_name",
  //         width: 30,
  //       },
  //       {
  //         key: "mcr_card_status",
  //         width: 10,
  //       },
  //       {
  //         key: "mcr_plt_code",
  //         width: 10,
  //       },
  //       {
  //         key: "io22",
  //         width: 10,
  //       },
  //       {
  //         key: "timestamp",
  //         width: 12,
  //       },
  //       {
  //         key: "GoogleMap",
  //         width: 10,
  //       },
  //       {
  //         key: "Coolant",
  //         width: 10,
  //       },
  //       {
  //         key: "FuelRate",
  //         width: 10,
  //       },
  //       {
  //         key: "EngineHour",
  //         width: 10,
  //       },
  //       {
  //         key: "RPM",
  //         width: 10,
  //       },
  //       {
  //         key: "Fuelused",
  //         width: 10,
  //       },
  //       {
  //         key: "PedalSpeed",
  //         width: 10,
  //       },
  //       {
  //         key: "temp2",
  //         width: 8,
  //       },
  //     ];
  //     genPositionExcel({
  //       fileName,
  //       reportByVehicle,
  //       tableHead,
  //       columnsSpec,
  //       statusColumn: 4,
  //       vehicles,
  //       logo,
  //       position: eventData.data.positions,
  //     });
  //   })
  //   .catch((err) => {
  //     console.log(err);
  //     Sentry.captureException(err);
  //     store.dispatch("loading/setReportLoading", false);
  //     return;
  //   });
};

export const genPositionExcel = async ({
  fileName,
  reportByVehicle,
  tableHead,
  columnsSpec,
  statusColumn,
  vehicles,
  logo,
  position,
}) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: "FF41C173",
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: "landscape",
    },
  };

  let imageLogoId;

  if (logo && logo.url) {
    const ext = logo.ext.substring(1);
    const myBase64Image = await generateLogoHeader(logo);
    imageLogoId = workbook.addImage({
      base64: myBase64Image,
      extension: ext,
    });
  }

  if (Object.keys(reportByVehicle).length > 0)
    Object.keys(reportByVehicle).forEach((imei) => {
      if (reportByVehicle[imei].length > 0) {
        const plateNo = reportByVehicle[imei][0][0];
        const sheet = workbook.addWorksheet(plateNo, pageProperties);
        sheet.pageSetup.margins = sheetMargin;
        sheet.columns = columnsSpec;

        const vehicleByIMEI = vehicles.filter((vehicle) => {
          return parseFloat(vehicle.imei) === parseFloat(imei);
        });

        const positionImei = position.filter((pos) => {
          return pos.imei === Number(imei);
        });
        const totalDistance = getODODistance(
          vehicleByIMEI[0].odo_canbus_enable ||
          vehicleByIMEI[0].canbus_truck_enabled,
          positionImei
        );

        // const totalDistance = (
        //   reportByVehicle[imei][reportByVehicle[imei].length - 1][14] -
        //   reportByVehicle[imei][0][14]
        // ).toFixed(2);
        const totalTime = formatDuration(
          reportByVehicle[imei][0][22],
          reportByVehicle[imei][reportByVehicle[imei].length - 1][22]
        );
        const metaRow1 = `รายงานตำแหน่ง ของ รถทะเบียน ${plateNo} เวลาเริ่ม ${reportByVehicle[imei][0][2]
          } ถึง ${reportByVehicle[imei][reportByVehicle[imei].length - 1][2]}`;
        const metaRow2 = `รวมระยะทางทั้งสิ้น ${totalDistance} กม. รวมเวลา ${totalTime} ชม.:นาที:วินาที`;

        let startingRow = 1;

        if (logo && logo.url) {
          startingRow = 2;
          addLogoCompany(sheet, imageLogoId);
        }

        sheet.addRow([metaRow1]);
        sheet.addRow([metaRow2]);

        sheet.addRow([]);

        sheet.addRow(tableHead);
        sheet.addRows(reportByVehicle[imei]);
        if (statusColumn) {
          sheet.getColumn(statusColumn).eachCell((cell) => {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: getStatusColor(cell.value),
            };
          });
        }

        sheet.getColumn(13).eachCell((cell) => {
          if (getSpeedColor(cell.value, vehicleByIMEI[0].overspeed_limit)) {
            cell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: getSpeedColor(
                cell.value,
                vehicleByIMEI[0].overspeed_limit
              ),
            };
          }
        });
        sheet.getColumn(24).font = {
          underline: true,
          bold: true,
        };
        sheet.getColumn(24).alignment = {
          horizontal: "center",
        };
        hightlightTableHead(sheet, startingRow + 3);
        hightlightRow(sheet, startingRow);
        hightlightRow(sheet, startingRow + 1);
      }
    });
  else {
    workbook.addWorksheet("ไม่พบข้อมูล", pageProperties);
  }

  blobToFile(workbook, fileName);
};

const splitPositionReportByVehicle = (
  vehicles,
  report,
  geofences,
  dictOfDriver,
  sec
) => {
  let result = {};
  let prevPositionOfImei = {};
  let prevCardStatusofImei = {};

  let vehiclesImei = {};
  for (const k in vehicles) {
    vehiclesImei[vehicles[k].imei] = vehicles[k];
  }

  report.forEach((row) => {
    result[row.imei] = result[row.imei] || [];
    const overspeedLimit = vehiclesImei[row.imei].overspeed_limit;
    let fuel = 0;
    if (getFuelCanbusTruckEnabled(vehicles, row.imei) == true) {
      fuel =
        row && "fuelLitreCanbusTruck" in row && row.fuelLitreCanbusTruck
          ? row.fuelLitreCanbusTruck.toFixed(2)
          : "";
    } else if (getFuelCanbusEnabled(vehicles, row.imei) == false) {
      fuel =
        row && "fuelLitre" in row && row.fuelLitre
          ? row.fuelLitre.toFixed(2)
          : "";
    } else {
      fuel =
        row && "fuelLitreCanbus" in row && row.fuelLitreCanbus
          ? row.fuelLitreCanbus.toFixed(2)
          : "";
    }
    let locationName = "";
    const geofenceName = getGeofenceName(
      geofences,
      row.latitude / 10000000,
      row.longitude / 10000000
    );
    if (geofenceName != "") {
      locationName = geofenceName;
    } else {
      locationName = row.location ? displayAddress(row.location) : "";
    }
    if (row.speed > overspeedLimit) {
      row.engineStatus = "OVER_SPEED_END";
    }
    let url = `https://www.google.com/maps/search/?api=1&query=${row.latitude / 10000000
      },${row.longitude / 10000000}`;

    let cardStatusStr = "";
    if (prevCardStatusofImei[row.imei]) {
      if (
        typeof row.mcr_license_no !== 'undefined' &&
        typeof prevCardStatusofImei[row.imei].mcr_license_no !== 'undefined' &&
        row.mcr_license_no === "" &&
        prevCardStatusofImei[row.imei].mcr_license_no.length > 0
      ) {
        cardStatusStr = "ออก";
      }

      if (
        typeof row.mcr_license_no !== 'undefined' &&
        typeof prevCardStatusofImei[row.imei].mcr_license_no !== 'undefined' &&
        row.mcr_license_no.length > 0 &&
        prevCardStatusofImei[row.imei].mcr_license_no === ""
      ) {
        cardStatusStr = "เข้า";
      }
    }

    if (
      parseInt(sec) === 0 ||
      !(row.imei in prevPositionOfImei) ||
      row.timestamp - prevPositionOfImei[row.imei].timestamp >= parseInt(sec)
    ) {
      result[row.imei].push([
        imeiToPlateNumber(vehicles, row.imei),
        imeiToVehicleName(vehicles, row.imei),
        formatDate(row.timestamp),
        translateEngineStatus(row.engineStatus),
        row.latitude / 10000000,
        row.longitude / 10000000,

        displayTempSensorData(row.io),
        row.io[2] == 1 ? "ใช้งาน" : "",
        row.io[3] == 1 ? "ใช้งาน" : "",
        row.io[4] == 1 ? "ใช้งาน" : "",
        parseFloat(row.io[27]),
        row.satellites,
        parseFloat(convertKnotToKmph(row.speed)),
        locationName,
        parseFloat(getOdoData(vehicles, row.imei, row).toFixed(2)),
        parseFloat(displayVoltage(row.io).toFixed(1)),
        !isNaN(parseFloat(fuel)) ? parseFloat(fuel) : 0,
        row.mcr_license_no || "-",
        row.mcr_license_no
          ? displayDriverName(
            false,
            {
              device: {
                position: {
                  mcr_license_no: row.mcr_license_no,
                  mcr_driver_name: row.mcr_driver_name,
                },
              },
            },
            typeof dictOfDriver != "undefined" &&
              row.mcr_license_no in dictOfDriver
              ? dictOfDriver[row.mcr_license_no]
              : null
          )
          : "-",
        cardStatusStr,
        row.mcr_plt_code,
        parseFloat(row.io[22]),
        row.timestamp,
        (row.value = {
          text: "link",
          hyperlink: url,
        }),
        CANcoolant(vehiclesImei[row.imei], row.io),
        CANfuelrate(vehiclesImei[row.imei], row.io),
        CANenginehour(vehiclesImei[row.imei], row.io),
        CANengineSpeed(vehiclesImei[row.imei], row.io),
        CANfuelused(vehiclesImei[row.imei], row.io),
        CANpedalposition(vehiclesImei[row.imei], row.io),
        displayTempSensorData(row.io, 79),
      ]);
      prevPositionOfImei[row.imei] = { ...row };
    }
    prevCardStatusofImei[row.imei] = { ...row };
  });
  return result;
};

export default getPositionReport;
