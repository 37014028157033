// import {
//     apolloClient
// } from "../../vue-apollo";

import AxiosService from "../../plugins/axios";
// import gql from "graphql-tag";
import endpoints from "../../config/config";
import { displayTempSensorData } from "@/lib/device";

const state = () => ({
  positions: [],
  selectedPosition: {},
  selectedVehiclePosition: {},
  showPositionInfo: false,
  showDataTableOnScreen: true,
  showMapOnScreen: true,
});

import {
  translateEngineStatus,
  formatFromDateTime,
  formatFromDateTimeShort,
} from "@/lib/report/config";

// getters
const getters = {
  trips: (state) => state.trips,
  positions: (state) => state.positions,
  path: (state) => {
    // let movingCnt = 0;
    let lastPosition = {};
    let path = [];
    let modNum = parseInt(state.positions.length / 800);
    if (modNum == 0) {
      modNum = 1;
    }
    //console.log('positions', state.positions.length, modNum)
    //(k > 0 && (curPos.io['65'] - lastPosition.io['65']) < 10)
    for (const k in state.positions) {
      const curPos = state.positions[k];
      if (
        (lastPosition.engineStatus === "ENGINE_STOP" &&
          curPos.engineStatus === "ENGINE_STOP") ||
        (lastPosition.engineStatus === "ENGINE_IDLE" &&
          curPos.engineStatus === "ENGINE_IDLE")
      ) {
        //skip
        //  console.log(k);
      } else if (
        lastPosition.engineStatus === curPos.engineStatus &&
        curPos.engineStatus === "VEHICLE_MOVING"
      ) {
        //if (movingCnt % modNum === 0) {
        // console.log(movingCnt)
        path.push({
          lat: parseInt(state.positions[k].latitude) / 10000000,
          lng: parseInt(state.positions[k].longitude) / 10000000,
          timestamp: state.positions[k].timestamp,
        });

        //}
        // movingCnt++;
      } else {
        path.push({
          lat: parseInt(state.positions[k].latitude) / 10000000,
          lng: parseInt(state.positions[k].longitude) / 10000000,
          timestamp: state.positions[k].timestamp,
        });
      }
      lastPosition = state.positions[k];
    }
    // console.log('path', path)
    return path;
  },
  historyPath: (state) => {
    let sliceIndex = [];
    let lineDetail = [];
    let startIndex = 0;
    let cntColorStroke = 1;

    let pathByDriver = [];

    let path = state.positions.map((position, index, arr) => {
      const prevPosData = arr[index - 1];

      if (
        !lineDetail.find(
          (detail) => detail.licenseNo === position.mcr_license_no
        )
      ) {
        if (position.mcr_license_no === "") {
          lineDetail.push({
            licenseNo: position.mcr_license_no,
            lineColor: "#336EFF",
          });
        } else {
          lineDetail.push({
            licenseNo: position.mcr_license_no,
            lineColor: cntColorStroke === 1 ? "#523EE8" : "#E821AA",
          });
          cntColorStroke++;
        }
      }
      let cardStatusStr = "";

      if (prevPosData) {        
        if (
          typeof position.mcr_license_no !== 'undefined' &&
          typeof prevPosData.mcr_license_no  !== 'undefined' &&
          position.mcr_license_no === "" &&
          prevPosData.mcr_license_no.length > 0
        ) {
          cardStatusStr = "ออก";
          sliceIndex.push(index);
        }

        if (
          typeof position.mcr_license_no !== 'undefined' &&
          typeof prevPosData.mcr_license_no  !== 'undefined' &&
          position.mcr_license_no.length > 0 &&
          prevPosData.mcr_license_no === ""
        ) {
          cardStatusStr = "เข้า";
          sliceIndex.push(index);
        }
      }

      return {
        lat: parseInt(position.latitude) / 10000000,
        lng: parseInt(position.longitude) / 10000000,
        licenseNo: position.mcr_license_no,
        driverName: position.mcr_driver_name,
        cardStatus: cardStatusStr,
        timestamp: position.timestamp,
      };
    });

    if (sliceIndex.length > 0) {
      sliceIndex.forEach((idx, index) => {
        pathByDriver.push({
          lineColor: lineDetail.find(
            (detail) => detail.licenseNo === path[startIndex].licenseNo
          ).lineColor,
          path: path.slice(startIndex, idx + 1),
        });
        startIndex = idx;
        if (index === sliceIndex.length - 1) {
          pathByDriver.push({
            lineColor: lineDetail.find(
              (detail) => detail.licenseNo === path[idx].licenseNo
            ).lineColor,
            path: path.slice(idx, path.length - 1),
          });
        }
      });
    } else {
      if (path.length > 0) {
        let color = lineDetail.find(
          (detail) => detail.licenseNo === path[0].licenseNo
        ).lineColor;
        pathByDriver.push({ lineColor: color, path: path });
      }
    }

    let marker = path.filter((pos) => pos.cardStatus === "เข้า");

    return {
      position: pathByDriver,
      marker:
        marker.length > 0
          ? marker.map((position) => {
            return {
              ...position,
              color: lineDetail.find(
                (detail) => detail.licenseNo === position.licenseNo
              ).lineColor,
            };
          })
          : marker,
    };
  },
  direction: (state) => {
    let retData = [];
    let cnt = 0;
    let modNum = 3;
    for (const k in state.positions) {
      if (parseInt(k) % modNum === 0 || cnt === 0) {
        retData[cnt] = state.positions[k];
        cnt++;
      }
    }
    // console.log('direction retData', retData);
    return retData;
  },
  avgSpeed: (state) => {
    const allSpeed = state.positions.map((position) => {
      return parseFloat(position.speed);
    });
    if (allSpeed.length <= 0) return 0;
    return (
      allSpeed.reduce((a, b) => {
        return a + b;
      }) / state.positions.length
    ).toFixed(2);
  },
  maxSpeed: (state) => {
    const allSpeed = state.positions.map((position) => {
      return parseFloat(position.speed);
    });
    if (allSpeed.length <= 0) return 0;
    return Math.max(...allSpeed);
  },
  tripTable: (state) => {
    let posData = [...state.positions];
    let startOdometer = 0;
    if (posData && posData.length > 0 && "odo" in posData[0]) {
      startOdometer = posData[0].odo;
    } else if (
      posData &&
      posData.length > 0 &&
      "io" in posData[0] &&
      65 in posData[0].io &&
      posData[0].io[65] > 0
    ) {
      startOdometer = posData[0].io[65];
    }
    return posData.map((position, index, arr) => {
      const prevPosData = arr[index - 1];
      let cardStatusStr = "";

      if (prevPosData) {        
        if (
          typeof position.mcr_license_no !== 'undefined' &&
          typeof prevPosData.mcr_license_no  !== 'undefined' &&
          position.mcr_license_no === "" &&
          prevPosData.mcr_license_no.length > 0
        ) {
          cardStatusStr = "ออก";
        }

        if (
          typeof position.mcr_license_no !== 'undefined' &&
          typeof prevPosData.mcr_license_no  !== 'undefined' &&
          position.mcr_license_no.length > 0 &&
          prevPosData.mcr_license_no === ""
        ) {
          cardStatusStr = "เข้า";
        }
      }

      let odoVal = 0;
      if ("odo" in position && position.odo > 0) {
        odoVal = ((position.odo - startOdometer) / 1000).toFixed(2);
      } else if (
        posData &&
        posData.length > 0 &&
        "io" in posData[0] &&
        65 in posData[0].io
      ) {
        odoVal = ((position.io[65] - startOdometer) / 1000).toFixed(2);
      }

      return {
        key: index + 1,
        // id: position._id,
        timestamp: formatFromDateTimeShort(position.timestamp * 1000),
        engineStatus: position.engineStatus,
        engineStatusTranslated: translateEngineStatus(position.engineStatus),
        direction: position.angle,
        geofence: "-",
        speed: parseFloat(position.speed).toFixed(2),
        speedNum: parseFloat(position.speed),
        location: position.location,
        angle: position.angle,
        latitude: position.latitude,
        longitude: position.longitude,
        pto1: "io" in position && "2" in position.io ? position.io[2] : 0,
        pto2: "io" in position && "3" in position.io ? position.io[3] : 0,
        pto3: "io" in position && "4" in position.io ? position.io[4] : 0,
        temp: displayTempSensorData(position.io),
        temp2: displayTempSensorData(position.io, 79),
        accOdometer: odoVal,
        licenseNo: position.mcr_license_no,
        driverName: position.mcr_driver_name,
        cardStatus: cardStatusStr,
        io: position.io,
      };
    });
  },
  positionOfTrip: (state) => {
    // console.log('raw', state.positions.reverse())
    let posData = [...state.positions];
    return posData.map((position, index) => {
      return {
        key: index + 1,
        // id: position._id,
        timestamp: formatFromDateTime(position.timestamp * 1000),
        engineStatus: position.engineStatus,
        engineStatusTranslated: translateEngineStatus(position.engineStatus),
        direction: position.angle,
        speed: parseFloat(position.speed).toFixed(2) + " กม./ชม.",
        speedNum: parseFloat(position.speed),
        location: position.location,
        angle: position.angle,
        latitude: position.latitude / 10000000,
        longitude: position.longitude / 10000000,
        pto1: parseInt(position.io[2]) ? "On" : "Off",
        pto2: parseInt(position.io[3]) ? "On" : "Off",
        pto3: parseInt(position.io[4]) ? "On" : "Off",
        temp: displayTempSensorData(position.io),
        temp2: displayTempSensorData(position.io, 79),
      };
    });
  },
};

// actions
const actions = {
  async searchPositions(
    { commit },
    { imei, start, stop, customergroup, overspeed = 80 }
  ) {
    // await apolloClient.resetStore();
    const positionsData = await AxiosService.post(
      `${endpoints.coreApi}/positions`,
      {
        imei: imei,
        start: start,
        stop: stop,
        customergroup: customergroup,
        overspeed: overspeed,
      }
    );
    // console.log(positionsData);
    // const positions = await apolloClient
    //     .query({
    //         // Query
    //         query: gql `
    //   query positions_between(
    //     $imei: Float!
    //     $start: Int!
    //     $stop: Int!
    //     $limit: Int
    //   ) {
    //     positions_between(
    //       imei: $imei
    //       start: $start
    //       stop: $stop
    //       limit: $limit
    //     ) {
    //       id
    //       timestamp
    //       latitude
    //       longitude
    //       angle
    //       satellites
    //       speed
    //       hdop
    //       altitude
    //       event_id
    //       location
    //       io
    //       engineStatus
    //       mcr_license_no
    //     }
    //   }
    // `,
    //         variables: {
    //             imei: parseFloat(imei),
    //             start: parseInt(start),
    //             stop: parseInt(stop),
    //             limit,
    //         },
    //     })
    //     .then(async(eventData) => {
    //         // console.log('eventData', eventData)
    //         return eventData;
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //         return;
    //     });
    // if (positions && positions.data && positions.data.positions_between.length > 0)
    // console.log("ADD_POSITIONS")
    commit("ADD_POSITIONS", positionsData.data);
  },
  selectPosition({ commit }, payload) {
    commit("SELECT_POSITION", payload);
  },
  selectVehiclePosition({ commit }, payload) {
    commit("SELECT_VEHICLE_POSITION", payload);
  },
  showPositionBottomInfo({ commit }, payload) {
    commit("SHOW_POSITION_INFO", payload);
  },
  setShowDataTableOnScreen({ commit }, payload) {
    commit("SHOW_DATATABLE_ON_SCREEN", payload);
  },
  setShowMapOnScreen({ commit }, payload) {
    commit("SHOW_MAP_ON_SCREEN", payload);
  },
  resetState({ commit }) {
    commit("RESET_STATE");
  },
};
// mutations
const mutations = {
  ADD_POSITIONS(state, payload) {
    // if (payload.imei && state.devices.find(device => device.imei === payload.imei)) {
    //     state.devices[]
    // }
    // let newDevice = {
    //   ...state.devices
    // };
    // newDevice[payload.imei] = payload;
    if (payload && payload.length > 0) {
      state.positions = payload;
    }
  },
  SELECT_POSITION(state, payload) {
    state.selectedPosition = payload;
  },
  SHOW_POSITION_INFO(state, payload) {
    state.showPositionInfo = payload;
  },
  SHOW_MAP_ON_SCREEN(state, payload) {
    state.showMapOnScreen = payload;
  },
  SHOW_DATATABLE_ON_SCREEN(state, payload) {
    state.showDataTableOnScreen = payload;
  },
  SELECT_VEHICLE_POSITION(state, payload) {
    state.selectedVehiclePosition = payload;
  },
  RESET_STATE(state) {
    console.log("RESET_STATE");
    state.positions = [];
    state.selectedPosition = {};
    state.selectedVehiclePosition = {};
    state.showPositionInfo = false;
    state.showMapOnScreen = true;
    state.showDataTableOnScreen = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
