import AxiosService from '@/plugins/axios';
import endpoints from '../../config/config';

const headers = {
    "Content-Type": "application/json; charset=utf-8",
}

const state = () => ({
    arrivalGeoAlertsData: [],
    updatedArrivalGeoAlertsData: [],
})

const getters = {}

const actions = {
    async getAllArrivalGeoAlerts({ commit }, cg_id){
        const response = await AxiosService.get(
            `${endpoints.strapiAPI}/arrival-geofence-alerts?customer_group=${cg_id}`, 
            { headers }
        )
            .then((response) => {
                return response.data
            })
            .catch((error) => {
                console.error('Get Arrival Geo Aleart Error:' + error)
                return []
            })
        commit('GET_ALL_ARRIVAL_GEO_ALERTS', response)
    },

    async createArrivalGeofenceAlertsItem({ commit }, payload){
        try {
            const response = await AxiosService.post(
                `${endpoints.strapiAPI}/arrival-geofence-alerts`,
                payload,
                { headers }
            )
            commit('CREATE_ARRIVAL_GEO_ALERTS_ITEM', response.data);
            return response;
        } catch (error) {
            console.log("Arrival geofence alerts creating has failed", error);
            throw error;
        }
    },

    async updateArrivalGeoAlertItem({ commit }, payload){
        const {
            name,
            vehicle,
            notify_email_channel_enabled,
            notify_line_channel_enabled,
            email_list,
            line_token,
            geofence,
            notify_type,
            status,
            expected_arrival_time, 
            customer_group,
        } = payload[1]
        try {
            const response = await AxiosService.put(
                `${endpoints.strapiAPI}/arrival-geofence-alerts/${payload[0]}`,
                JSON.stringify({
                    name: name,
                    vehicle: vehicle,
                    notify_email_channel_enabled: notify_email_channel_enabled,
                    notify_line_channel_enabled: notify_line_channel_enabled,
                    email_list: email_list,
                    line_token: line_token,
                    geofence: geofence,
                    notify_type: notify_type,
                    status: status,
                    expected_arrival_time: expected_arrival_time,
                    customer_group: customer_group,
                }),
                { headers }
            );
            commit('UPDATE_ARRIVAL_GEO_ALERT_ITEM', response.data);
            return response;
        } catch(error){
            console.log("Update arrival geofence alert error", error);
            throw error;
        }
    },

    async deleteArrivalGeoAlertItem({ commit }, delItemData){
        try {
            await AxiosService.delete(
                `${endpoints.strapiAPI}/arrival-geofence-alerts/${delItemData[0]}`,
                {headers}
            )
            commit('DELETE_ARRIVAL_GEO_ALERT_ITEM', delItemData[1])
        } catch (error) {
            console.log("Delete arrival geofence alert error", error)
            throw error
        }
    },
}

const mutations = {
    GET_ALL_ARRIVAL_GEO_ALERTS(state, payload){
        state.arrivalGeoAlertsData = payload
    },

    CREATE_ARRIVAL_GEO_ALERTS_ITEM(state, payload){
        state.arrivalGeoAlertsData.push(payload)
    },

    UPDATE_ARRIVAL_GEO_ALERT_ITEM(state, payload){
        state.arrivalGeoAlertsData = state.arrivalGeoAlertsData.map((item) => 
            item.id === payload.id ? payload : item
        )
    },

    DELETE_ARRIVAL_GEO_ALERT_ITEM(state, payload){
        state.arrivalGeoAlertsData.splice(payload, 1)
        state.arrivalGeoAlertsData = state.arrivalGeoAlertsData.filter((item) => item.id !== payload)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}