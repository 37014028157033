import moment from '@/lib/time';
import Excel from 'exceljs';
import AxiosService from '@/plugins/axios';
import endpoints from '../../config/config';

import {
  sheetMargin,
  getStatusColor,
  hightlightTableHead,
  blobToFile,
  imeiToPlateNumber,
  formatDate,
  formatDuration,
  hightlightRow,
  getOdoData,
  geofenceStartEndFromLocation,
  imeiToVehicleName,
  generateLogoHeader,
  addLogoCompany,
} from './config';
import * as Sentry from '@sentry/vue';
import store from '@/store';

const splitReportByVehicleOfDay = (vehicles, report, geofences) => {
  let result = {};
  let sumDistance = {};
  let sumDuration = {};

  report.forEach((row) => {
    result[row.imei] = result[row.imei] || [];
    const resLoc = geofenceStartEndFromLocation(geofences, row);
    const key = row.imei;
    sumDistance[key] = sumDistance[key] || 0;
    sumDuration[key] = sumDuration[key] || 0;
    let startPosition = row.positionDataStart;
    let endPosition = row.positionDataEnd;
    const distance = parseFloat(
      getOdoData(vehicles, row.imei, row.positionDataEnd).toFixed(2) - getOdoData(vehicles, row.imei, row.positionDataStart).toFixed(2)
    );
    let url = `https://www.google.com/maps/dir/?api=1&origin=${startPosition.latitude / 10000000
      },${startPosition.longitude / 10000000}&destination=${endPosition.latitude / 10000000
      },${endPosition.longitude / 10000000}&travelmode=driving`;

    sumDistance[key] = parseFloat((distance + sumDistance[key]).toFixed(2));
    sumDuration[key] = row.stopTime - row.startTime + sumDuration[key];
    let dataRow;

    dataRow = [
      formatDate(row.startTime),
      resLoc.start,
      formatDate(row.stopTime),
      resLoc.end,
      formatDuration(row.startTime, row.stopTime),
      distance,
      parseFloat(
        getOdoData(vehicles, row.imei, row.positionDataEnd).toFixed(2)
      ),
      (row.value = { text: 'link', hyperlink: url }),
      imeiToPlateNumber(vehicles, row.imei),
      sumDistance[key],
      sumDuration[key],
      imeiToVehicleName(vehicles, row.imei),
    ];
    result[row.imei].push(dataRow);
  });
  // console.log(sumDistance);
  return result;
};

const prepareDataForExcel = (
  fileName,
  vehicles,
  reportData,
  geofences,
  logo
) => {
  const reportByVehicle = splitReportByVehicleOfDay(
    vehicles,
    reportData,
    geofences
  );
  let tableHead = [
    'วัน เวลา เริ่มต้น',
    'ตำแหน่งเริ่มต้น',
    'วัน เวลา สิ้นสุด',
    'ตำแหน่งสิ้นสุด',
    'รวมเวลา',
    'รวมระยะทาง (กม.)',
    'เลขกม.สะสม',
    'GoogleMap',
  ];
  const columnsSpec = [
    {
      key: 'start',
      width: 20,
    },
    {
      key: 'geolocationstart',
      width: 60,
    },
    {
      key: 'stop',
      width: 20,
    },
    {
      key: 'geolocationend',
      width: 60,
    },
    {
      key: 'totalTime',
      width: 20,
    },
    {
      key: 'totalDistance',
      width: 16,
    },
    {
      key: 'cumOdometer',
      width: 12,
    },
    {
      key: 'GoogleMap',
      width: 12,
    },
  ];
  genTripExcel({
    fileName,
    reportByVehicle,
    tableHead,
    columnsSpec,
    statusColumn: null,
    logo,
  });
};

export const getTripReport = async (
  eventTypes,
  vehicles,
  imeis,
  start,
  stop,
  geofences,
  logo
) => {
  const strTimerange =
    moment.unix(start).format('D_MMM_YY') +
    '-ถึง-' +
    moment.unix(stop).format('D_MMM_YY');

  await AxiosService.post(`${endpoints.coreApi}/report-event`, {
    imeis: imeis,
    start: start,
    stop: stop,
    eventType: 'END_TRIP',
  })
    .then(async (eventData) => {
      const fileName = 'Trip_A4_Report_' + strTimerange;
      prepareDataForExcel(fileName, vehicles, eventData.data, geofences, logo);
    })
    .catch((err) => {
      console.log(err);
      Sentry.captureException(err);
      store.dispatch('loading/setReportLoading', false);
      return;
    });
};

export const genTripExcel = async ({
  fileName,
  reportByVehicle,
  tableHead,
  columnsSpec,
  statusColumn,
  logo,
}) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: 'FF41C173',
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
    },
  };

  let imageLogoId;

  if (logo && logo.url) {
    const ext = logo.ext.substring(1);
    const myBase64Image = await generateLogoHeader(logo);
    imageLogoId = workbook.addImage({
      base64: myBase64Image,
      extension: ext,
    });
  }

  if (Object.keys(reportByVehicle).length > 0) {
    Object.keys(reportByVehicle).forEach((imei) => {
      let startingRow = 1;
      let sheet = null;
      let plateNo = '';
      let lastRow = 0;
      let metaRow1 = '';
      let metaRow2 = '';
      let vehicleName;
      const dataEachImei = reportByVehicle[imei];

      plateNo = dataEachImei[0][8];
      vehicleName = dataEachImei[0][11];
      sheet = workbook.addWorksheet(plateNo, pageProperties);
      sheet.pageSetup.margins = sheetMargin;
      sheet.columns = columnsSpec;

      let totalDistance = dataEachImei[dataEachImei.length - 1][9]; // accumulate data

      const totalTime = moment('1970-01-01')
        .startOf('day')
        .seconds(dataEachImei[dataEachImei.length - 1][10])
        .format('HH:mm:ss'); // accumulate data
      metaRow1 = `รายงานการเดินทางของ ${vehicleName} ทะเบียน ${plateNo} เวลาเริ่ม ${dataEachImei[0][0]
        } ถึง ${dataEachImei[dataEachImei.length - 1][2]}`;

      metaRow2 = `รวมระยะทางทั้งสิ้น ${totalDistance} กม. รวมเวลา ${totalTime} ชม.:นาที:วินาที`;

      let tableBody = [];
      for (let i in dataEachImei) {
        tableBody.push(
          dataEachImei[i].filter((value, index) => {
            return index < 8;
          })
        );
      }

      if (logo && logo.url) {
        startingRow = 2;
        addLogoCompany(sheet, imageLogoId);
      }

      sheet.addRow([metaRow1]);
      sheet.addRow([metaRow2]);
      sheet.addRow([]);

      sheet.addRow(tableHead);
      sheet.addRows(tableBody);
      sheet.addRow([]);
      sheet.addRow([]);
      if (statusColumn) {
        sheet.getColumn(statusColumn).eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: getStatusColor(cell.value),
          };
        });
      }

      sheet.getColumn(8).font = {
        underline: true,
        bold: true,
        color: { argb: 'FF0000FF' },
      };
      sheet.getColumn(8).alignment = { horizontal: 'center' };

      hightlightTableHead(sheet, startingRow + 3 + lastRow);
      hightlightRow(sheet, startingRow + lastRow);
      hightlightRow(sheet, startingRow + 1 + lastRow, 'FFA29BFC');
      lastRow = lastRow + dataEachImei.length + 6;
    });
  } else {
    workbook.addWorksheet('ไม่พบข้อมูล', pageProperties);
  }

  blobToFile(workbook, fileName);
};

export default getTripReport;
