import moment from '@/lib/time';
import Excel from 'exceljs';
import AxiosService from '@/plugins/axios';
import endpoints from '../../config/config';
import {
  fontNormal,
  sheetMargin,
  hightlightTableHead,
  blobToFile,
  imeiToPlateNumber,
  formatDate,
  formatDuration,
  hightlightRow,
  imeiToVehicleName,
  generateLogoHeader,
  addLogoCompany,
} from './config';
import store from '@/store';
import * as Sentry from '@sentry/vue';

const splitReportByVehicleOfDay = (vehicles, report) => {
  let result = {};
  let sumDistance = {};
  let sumDuration = {};
  let prevGeofence = {};

  report.forEach((row) => {
    if (row.imei in result) {
      const dayOfEvent = moment
        .unix(row.posExit.timestamp)
        .format('YYYY-MM-DD');
      const keyOfDate = row.imei + '_' + dayOfEvent;
      sumDistance[keyOfDate] = sumDistance[keyOfDate] || 0;
      sumDuration[keyOfDate] = sumDuration[keyOfDate] || 0;
      result[row.imei][dayOfEvent] = result[row.imei][dayOfEvent] || [];

      let distance = 0;
      if (row.posEnter.timestamp == row.posExit.timestamp) {
        distance = parseFloat(
          (
            (row.posExit.io[65] - prevGeofence[row.imei].posExit.io[65]) /
            1000
          ).toFixed(2)
        );
        sumDistance[keyOfDate] = parseFloat(
          (distance + sumDistance[keyOfDate]).toFixed(2)
        );
        sumDuration[keyOfDate] =
          row.posExit.timestamp -
          prevGeofence[row.imei].timeEnterExitSrc.enterTime +
          sumDuration[keyOfDate];
        result[row.imei][dayOfEvent].push([
          imeiToPlateNumber(vehicles, row.imei),
          imeiToVehicleName(vehicles, row.imei),
          row.prevGeofence.name,
          row.enterGeofence.name,
          distance,
          formatDuration(
            prevGeofence[row.imei].timeEnterExitSrc.enterTime,
            row.posEnter.timestamp
          ),
          formatDate(prevGeofence[row.imei].timeEnterExitSrc.enterTime),
          formatDate(prevGeofence[row.imei].timeEnterExitSrc.exitTime),
          formatDuration(
            prevGeofence[row.imei].timeEnterExitSrc.enterTime,
            prevGeofence[row.imei].timeEnterExitSrc.exitTime
          ),
          formatDate(row.timeEnterExitSrc.enterTime),
          null,
          null,
          row.posEnter.timestamp,
          row.posExit.timestamp,
          dayOfEvent + '',
          sumDistance[keyOfDate],
          sumDuration[keyOfDate],
        ]);
      } else {
        distance = parseFloat(
          (
            (row.posExit.io[65] - prevGeofence[row.imei].posExit.io[65]) /
            1000
          ).toFixed(2)
        );
        sumDistance[keyOfDate] = parseFloat(
          (distance + sumDistance[keyOfDate]).toFixed(2)
        );
        sumDuration[keyOfDate] =
          row.posExit.timestamp -
          prevGeofence[row.imei].timeEnterExitSrc.enterTime +
          sumDuration[keyOfDate];
        result[row.imei][dayOfEvent].push([
          imeiToPlateNumber(vehicles, row.imei),
          imeiToVehicleName(vehicles, row.imei),
          row.exitGeofence.name,
          row.enterGeofence.name,
          distance,
          formatDuration(
            prevGeofence[row.imei].timeEnterExitSrc.enterTime,
            row.posExit.timestamp
          ),
          formatDate(prevGeofence[row.imei].timeEnterExitSrc.enterTime),
          formatDate(prevGeofence[row.imei].timeEnterExitSrc.exitTime),
          formatDuration(
            prevGeofence[row.imei].timeEnterExitSrc.enterTime,
            prevGeofence[row.imei].timeEnterExitSrc.exitTime
          ),
          formatDate(row.timeEnterExitSrc.enterTime),
          formatDate(row.timeEnterExitSrc.exitTime),
          formatDuration(
            row.timeEnterExitSrc.enterTime,
            row.timeEnterExitSrc.exitTime
          ),
          row.posEnter.timestamp,
          row.posExit.timestamp,
          dayOfEvent + '',
          sumDistance[keyOfDate],
          sumDuration[keyOfDate],
        ]);
      }
      prevGeofence[row.imei] = {
        timeEnterExitSrc: row.timeEnterExitSrc,
        posExit: row.posExit,
      };
    } else {
      result[row.imei] = [];
      prevGeofence[row.imei] = {
        timeEnterExitSrc: row.timeEnterExitSrc,
        posExit: row.posExit,
      };
    }
  });
  return result;
};

const prepareDataForExcel = (fileName, vehicles, reportData, logo) => {
  const reportByVehicle = splitReportByVehicleOfDay(vehicles, reportData);

  const tableHead = [
    'ทะเบียนรถ',
    'ชื่อ',
    'ต้นทาง',
    'ปลายทาง',
    'ระยะทาง',
    'ระยะเวลา',
    'เข้าต้นทาง',
    'ออกต้นทาง',
    'รวมเวลาต้นทาง',
    'เข้าปลายทาง',
    'ออกปลายทาง',
    'รวมเวลาปลายทาง',
  ];
  const columnsSpec = [
    {
      key: 'plate_number',
      width: 10,
      style: {
        font: fontNormal,
      },
    },
    {
      key: 'name',
      width: 22,
      style: {
        numFmt: '0',
      },
    },
    {
      key: 'exitGeofence',
      width: 25,
    },
    {
      key: 'enterGeofence',
      width: 25,
    },
    {
      key: 'totalDistance',
      width: 16,
    },
    {
      key: 'totalTime',
      width: 20,
    },
    {
      key: 'enterTimeSrc',
      width: 20,
    },
    {
      key: 'exitTimeSrc',
      width: 20,
    },
    {
      key: 'totalTimeSrc',
      width: 20,
    },
    {
      key: 'enterTimeDest',
      width: 20,
    },
    {
      key: 'exitTimeDest',
      width: 20,
    },
    {
      key: 'totalTimeDest',
      width: 20,
    },
  ];
  genGeofenceExcel({
    fileName,
    reportByVehicle,
    tableHead,
    columnsSpec,
    logo,
    vehicles
  });
};

const getGeofenceReport = async (
  vehicles,
  imeis,
  start,
  stop,
  customergroup
) => {
  const strTimerange =
    moment.unix(start).format('D_MMM_YY') +
    '-ถึง-' +
    moment.unix(stop).format('D_MMM_YY');
  await AxiosService.post(`${endpoints.coreApi}/report-geofence`, {
    eventType: 'END_TRIP',
    imeis: imeis,
    start: start,
    stop: stop,
    customergroup: customergroup.id,
  })
    .then(async (response) => {
      const fileName = 'Geofences_Report_' + strTimerange;
      prepareDataForExcel(
        fileName,
        vehicles,
        response.data,
        customergroup.logo
      );
    })
    .catch((error) => {
      console.log('An error occurred:', error);
      Sentry.captureException(error);
      store.dispatch('loading/setReportLoading', false);
      return;
    });
};

export const genGeofenceExcel = async ({
  fileName,
  reportByVehicle,
  tableHead,
  columnsSpec,
  logo,
  vehicles
}) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: 'FF41C173',
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
    },
  };

  let imageLogoId;

  if (logo && logo.url) {
    const ext = logo.ext.substring(1);
    const myBase64Image = await generateLogoHeader(logo);
    imageLogoId = workbook.addImage({
      base64: myBase64Image,
      extension: ext,
    });
  }

  if (Object.keys(reportByVehicle).length > 0) {
    Object.keys(reportByVehicle).forEach((imei) => {
      let startingRow = 1;
      let cnt = 0;
      let sheet = null;
      let plateNo = '';
      let lastRow = 0;
      let sumTotalDistance = 0;
      let sumTotalTime = 0;
      let metaRow1 = '';
      let metaRow2 = '';
      
      plateNo = imeiToPlateNumber(vehicles, Number(imei));      
      sheet = workbook.addWorksheet(plateNo, pageProperties);
      sheet.pageSetup.margins = sheetMargin;
      sheet.columns = columnsSpec;

      if (logo && logo.url) {
        startingRow = 2;
        addLogoCompany(sheet, imageLogoId);
      }

      Object.keys(reportByVehicle[imei]).forEach((dateStr) => {
        const dataEachDay = reportByVehicle[imei][dateStr];
        if (cnt == 0) {
          plateNo = dataEachDay[0][0];
        }

        const totalDistance = dataEachDay[dataEachDay.length - 1][15]; // accumulate data
        const totalTime = moment('1970-01-01')
          .startOf('day')
          .seconds(dataEachDay[dataEachDay.length - 1][16])
          .format('HH:mm:ss'); // accumulate data
        metaRow1 = `รายงานสถานีถึงสถานี ของ รถทะเบียน ${plateNo} เวลาเริ่ม ${dataEachDay[0][6]
          } ถึง ${dataEachDay[dataEachDay.length - 1][9]}`;
        metaRow2 = `รวมระทางทั้งสิ้น ${totalDistance} กม. รวมเวลา ${totalTime} ชม.:นาที:วินาที`;


        sumTotalDistance = sumTotalDistance + totalDistance;
        sumTotalTime = sumTotalTime + dataEachDay[dataEachDay.length - 1][16];
        sheet.addRow([metaRow1]);
        sheet.addRow([metaRow2]);
        sheet.addRow([]);
        sheet.addRow(tableHead);
        sheet.addRows(dataEachDay);
        sheet.addRow([]);
        sheet.addRow([]);

        hightlightTableHead(sheet, startingRow + 3 + lastRow);
        hightlightRow(sheet, startingRow + lastRow);
        hightlightRow(sheet, startingRow + 1 + lastRow);
        lastRow = lastRow + dataEachDay.length + 6;

        cnt++;
      });

      const sumTotalTimeTxt = moment('1970-01-01')
        .startOf('day')
        .seconds(sumTotalTime)
        .format('HH:mm:ss');

      sheet.addRow([
        `รวมระทางทั้งสิ้น ${sumTotalDistance.toFixed(
          2
        )} กม. รวมเวลา ${sumTotalTimeTxt} ชม.:นาที:วินาที`,
      ]);
      hightlightRow(sheet, startingRow + lastRow, 'FF0306A6');
    });
  } else {
    workbook.addWorksheet('ไม่พบข้อมูล', pageProperties);
  }

  blobToFile(workbook, fileName);
};

export default getGeofenceReport;
