import Vue from "vue";
import Vuex from "vuex";
import authen from "./modules/authen";
import vehicle from "./modules/vehicle";
import trip from "./modules/trip";
import position from "./modules/position";
import geofence from "./modules/geofence";
import licensetype from "./modules/licensetype";
import jobs from "./modules/jobs";
import driver from "./modules/driver";
import route from "./modules/route";
import loading from "./modules/loading";
import fcm from "./modules/fcm";
import VuexPersist from "vuex-persist";
import maintenance from "./modules/maintenance";
import arrivalGeoAlerts from "./modules/arrivalGeoAlerts";
import geoActivityAlert from "./modules/geoActivityAlert";
// import firebase, {
//   auth,
//   GoogleProvider,
//   FacebookProvider
// } from "../lib/fireinit";

Vue.use(Vuex);

const vuexSessionStorage = new VuexPersist({
  key: "vuex", // The key to store the state on in the storage provider.
  storage: window.sessionStorage, // or window.sessionStorage or localForage
  // Function that passes the state and returns the state with only the objects you want to store.
  // reducer: state => state,
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
  reducer: (state) => ({
    authen: state.authen,
  }),
});

export default new Vuex.Store({
  namespaced: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    authen,
    loading,
    vehicle,
    trip,
    jobs,
    position,
    geofence,
    driver,
    route,
    licensetype,
    fcm,
    maintenance,
    arrivalGeoAlerts,
    geoActivityAlert
  },
  plugins: [vuexSessionStorage.plugin],
});
