var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    padding: '0 !important',
    width: _vm.isMobileDevice() ? '400px' : '720px',
    display: 'inline-block',
    backgroundColor: '#FAFAFA',
    // border: '1px solid #D3D4D6',
    padding: '16px 21px',
    height: '100vh',
    overflowY: 'scroll',
  })},[_c('h4',[_vm._v("ค้นหาประวัติย้อนหลัง")]),_c('vehicleSearch',{staticStyle:{"margin-right":"10px"}}),_c('a-range-picker',{style:({
      marginTop: '5px',
      width: _vm.isMobileDevice() ? '280px' : '360px',
      display: 'inline-block',
    }),attrs:{"allowClear":true,"placeholder":['เริ้มต้น', 'สิ้นสุด'],"disabled":_vm.selectedVehicleTrip.id == null,"format":"D MMM YYYY HH:mm","showTime":{
      defaultValue: [
        _vm.moment().hour(0).minute(0).second(0).millisecond(0),
        _vm.moment().hour(23).minute(59).second(59).millisecond(999),
      ],
    }},on:{"change":_vm.onDateChange},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),_c('a-button',{style:({
      width: _vm.isMobileDevice() ? '100px' : '280px',
      marginTop: '5px',
    }),attrs:{"md":16,"type":"primary","loading":_vm.searchLoading,"disabled":_vm.dateRange.length <= 0},on:{"click":_vm.search}},[_vm._v("ค้นหา")]),_c('a-switch',{style:({
      marginLeft: '10px',
      marginRight: '10px',
    }),attrs:{"md":4,"default-checked":""},on:{"change":_vm.onShowDataTable}}),_vm._v(" DataTable "),_c('a-switch',{style:({
      marginLeft: '10px',
      marginRight: '10px',
    }),attrs:{"md":4,"default-checked":""},on:{"change":_vm.onShowMap}}),_vm._v(" Map "),(_vm.positions.length > 0)?_c('listResultPosition',{on:{"setCenterPath":_vm.setCenterPath}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }