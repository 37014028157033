<template>
  <div>
    <div class="custom-input__container" :error="showErrorText">
      <!-- icon section -->
      <a-icon v-if="type === 'text'" type="user" />
      <a-icon v-else type="unlock" />
      <!-- input section -->
      <input
        :id="name"
        :name="name"
        autocomplete="none"
        :type="type"
        :placeholder="placeholder"
        v-model="inputText"
        @keyup.enter="onEnter"
      />
      <div v-if="inputText !== ''" class="clear-text" @click="onClickClearText">
        x
      </div>
    </div>
    <div v-if="showErrorText" class="error-text">
      {{ errorMsg }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'CustomInput',
  emits: ['onChange', 'onEnter'],
  props: {
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: function (value) {
        return ['text', 'password'].includes(value);
      },
    },
    placeholder: String,
    value: String,
    errorMsg: String,
  },
  data() {
    return {
      inputText: this.value,
      showErrorText: false,
    };
  },
  watch: {
    inputText(value) {
      this.showErrorText = value === '' ? true : false;
      this.$emit('onChange', value);
    },
  },
  methods: {
    onClickClearText() {
      this.inputText = '';
    },
    onEnter() {
      this.$emit('onEnter');
    },
  },
};
</script>
<style lang="scss" scoped>
$primary-text: #515151;
$error: #e821aa;

.error-text {
  color: $error;
  width: fit-content;
  padding-block-start: 8px;
}

.clear-text {
  position: absolute;
  border: none;
  background-color: #b8bdbd;
  border-radius: 32px;
  width: 12px;
  height: 12px;
  color: #ffffff;
  font-size: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  right: 12px;
}
.custom-input__container {
  position: relative;
  background-color: #f0f3f6;
  padding-block: 15px;
  padding-inline-start: 16px;
  padding-inline-end: 32px;
  border-radius: 8px;
  height: 48px;
  color: $primary-text;

  display: flex;
  align-items: center;
  gap: 8px;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f0f3f6 inset !important;
  }

  > input[type='text'],
  input[type='password'] {
    // height: 100%;
    width: 100%;
    outline: none !important;
    border: none !important;
    background-color: transparent !important;
  }
}
.custom-input__container[error='true'] {
  outline: 1px solid $error !important;
}
</style>
