<template>
  <div
    class="trips"
    @keydown.down="onPressDown"
    @keydown.up="onPressUp"
    tabindex="0"
  >
    <div :style="isMobileDevice() ? 'width: 360px;' : 'width: 675px;'">
      <div v-if="trip">
        <a-row class="card-info info-engine" style="position: relative">
          <a-col :span="24">
            <a-descriptions size="small" title :column="3">
              <a-descriptions-item label span="3">
                <span style="font-weight: 300; font-size: 1.1rem">
                  <icon-font type="icon-Calendar" style="padding-right: 5px" />
                  {{
                    positions.length > 0 &&
                    `${formatDate(positions[0].timestamp)} ถึง ${formatDate(
                      positions[positions.length - 1].timestamp
                    )}`
                  }}
                </span>
              </a-descriptions-item>
              <a-descriptions-item label>
                <icon-font
                  type="icon-Iconmaterial-access-time"
                  style="padding-right: 5px"
                />
                {{
                  positions.length > 0 &&
                  formatDuration(
                    positions[positions.length - 1].timestamp * 1000,
                    positions[0].timestamp * 1000
                  )
                }}
                ชม:นาที:วินาที
              </a-descriptions-item>
              <a-descriptions-item label>
                <icon-font
                  type="icon-Iconawesome-route"
                  style="padding-right: 5px"
                />
                {{
                  positions.length > 0 &&
                  getODODistance(
                    this.selectedVehicleTrip.odo_canbus_enable ||
                      this.selectedVehicleTrip.canbus_truck_enabled,
                    positions
                  )
                }}
                กม.
              </a-descriptions-item>
              <a-descriptions-item label>
                <icon-font
                  type="icon-Iconawesome-tachometer-alt"
                  style="padding-right: 5px"
                />
                เฉลี่ย {{ positions && tripAvgSpeed }} กม./ชม.
              </a-descriptions-item>

              <a-descriptions-item label span="2">
                <icon-font type="icon-location1" style="padding-right: 5px" />
                {{
                  positions &&
                  positions[0] &&
                  displayAddress(positions[0].location, false)
                }}
                ถึง
                {{
                  positions &&
                  positions[positions.length - 1] &&
                  displayAddress(
                    positions[positions.length - 1].location,
                    false
                  )
                }}
              </a-descriptions-item>
              <a-descriptions-item label>
                <img
                  :src="require('@/assets/icons/fast.svg')"
                  width="19"
                  height="19"
                  style="
                    margin-left: -4px;
                    margin-right: 4px;
                    object-fit: cover;
                  "
                />

                ความเร็วสูงสุด
                <span :style="{ color: displayMaxSpeed().color }">
                  {{ positions && displayMaxSpeed().value }}
                  กม./ชม.
                </span>
              </a-descriptions-item>
              <a-descriptions-item label span="2">
                <icon-font
                  type="icon-drivers-license"
                  style="padding-right: 5px"
                />
                {{
                  mcr_license_no
                    ? displayDriverName(
                        mcr_license_no,
                        mcr_driver_name,
                        getDriverNameByLicenseNo(mcr_license_no)
                      )
                    : 'ไม่มีข้อมูลรูดบัตร'
                }}
              </a-descriptions-item>
            </a-descriptions>
          </a-col>
          <div style="position: absolute; right: 0; bottom: 0">
            <a-button @click="downloadTripReport" :loading="reportLoading">
              <icon-font
                type="icon-Excel"
                style="padding-right: 5px"
              />ดาวน์โหลดรายงาน
            </a-button>
          </div>
        </a-row>
        <div>
          <ve-table
            v-if="showDataTableOnScreen === true"
            ref="tableRef"
            class="trip-table-row"
            style="margin-top: 15px"
            :scroll-width="getWithByColEnabled()"
            :columns="isMobileDevice() ? columnsMobile : columns"
            :table-data="tripTableWrapper()"
            max-height="calc(100vh - 320px)"
            :virtual-scroll-option="virtualScrollOption"
            :event-custom-option="eventCustomOption"
            :expand-option="expandOption"
            :rowStyleOption="rowStyleOption"
            :cellSelectionOption="cellSelectionOption"
            rowKeyFieldName="key"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from '@/lib/time';
import { IconFont } from '@/config/config';
import { getODODistance } from '@/lib/report/config';
import report from '@/lib/report';
import { isMobile } from 'mobile-device-detect';
import {
  displayDriverName,
  CANfuelrate,
  CANenginehour,
  CANfuelused,
  CANengineSpeed,
  CANcoolant,
  CANpedalposition,
} from '@/lib/device';

// import { getGeofenceName } from "@/lib/device";
export default {
  components: { IconFont },
  props: {
    setCenterPath: { type: Function },
  },
  data() {
    return {
      mcr_license_no: '',
      mcr_driver_name: '',
      collapsed: true,
      bottomDrawer: true,
      chartEngineLoading: false,
      cellSelectionOption: {
        // disble cell selection
        enable: false,
      },
      rowStyleOption: {
        clickHighlight: true,
        hoverHighlight: true,
      },
      virtualScrollOption: {
        enable: true,
        minRowHeight: 25,
      },
      eventCustomOption: {
        bodyRowEvents: ({ row }) => {
          return {
            click: () => this.tripTableRowClick(row),
          };
        },
      },
      expandOption: {
        render: ({ row }) => {
          return (
            <div style="background-color:#f5f7fa; padding:5%;margin:0">
              <div class="expandable-row">
                <div>ตำแหน่ง:</div> {row.location}
              </div>
              {(this.setting.columnPTODisplay ||
                this.setting.columnPTODisplay === undefined) && (
                <div class="expandable-row">
                  <div>PTO: </div>
                  <div>
                    <span
                      class={`${this.getPTOStatusClass(
                        row['pto1']
                      )} pto-status`}
                    >
                      P1
                    </span>
                    <span
                      class={`${this.getPTOStatusClass(
                        row['pto2']
                      )} pto-status`}
                    >
                      P2
                    </span>
                    <span
                      class={`${this.getPTOStatusClass(
                        row['pto3']
                      )} pto-status`}
                    >
                      P3
                    </span>
                  </div>
                </div>
              )}
              {(this.setting.columnTempDisplay ||
                this.setting.columnTempDisplay === undefined) && (
                <div class="expandable-row">
                  <div>TEMP:</div>
                  {row['temp']}
                </div>
              )}
              {(this.setting.columnTemp2Display ||
                this.setting.columnTempDisplay === undefined) && (
                <div class="expandable-row">
                  <div>TEMP2: </div> {row['temp2']}
                </div>
              )}
              {this.setting.columnDriverDisplay && (
                <div class="expandable-row">
                  <div>คนขับ:</div>
                  {row.licenseNo !== ''
                    ? this.displayDriverName(
                        row.licenseNo,
                        row.driverName,
                        this.getDriverNameByLicenseNo(row.licenseNo)
                      )
                    : '-'}
                </div>
              )}
              {this.setting.columnCardStatusDisplay && (
                <div class="expandable-row">
                  <div>รูดบัตร:</div>
                  {row['cardStatus'] || '-'}
                </div>
              )}
            </div>
          );
        },
      },
      columns: [],
      columnsMobile: [
        {
          field: '',
          key: 'a',
          align: 'center',
          width: 30,
          type: 'expand',
          operationColumn: true,
        },
        {
          field: 'key',
          key: 'index',
          title: 'ที่',
          align: 'center',
          width: 40,
        },
        {
          title: 'เวลา',
          field: 'timestamp',
          key: 'timestamp',
          fixed: 'left',
        },
        {
          title: 'สถานะ',
          field: 'engineStatus',
          key: 'engineStatus',
          renderBodyCell: ({ row, column }) => {
            let engineStatus = row[column.field];
            let className = `${engineStatus} engine-status`;
            return (
              <div class={className}>
                {engineStatus == 'OVER_SPEED'
                  ? 'เร็วเกิน'
                  : row['engineStatusTranslated']}
              </div>
            );
          },
        },
        {
          title: 'กม/ชม',
          field: 'speed',
          key: 'speed',
        },
      ],
    };
  },
  computed: mapState({
    selectedGroup: (state) => state.authen.selectedGroup,
    drivers: (state) => state.driver.drivers,
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      tripsEnd: 'trip/tripsEnd',
      // path: "position/path",
      tripAvgSpeed: 'position/avgSpeed',
      tripMaxSpeed: 'position/maxSpeed',
      // tripsEngine: "trip/tripsEngine",
      tripTable: 'position/tripTable',
      driverByLicenseNo: 'driver/driverByLicenseNo',
      vehicleByIMEI: 'vehicle/vehicleByIMEI',
    }),
    setting: (state) => state.authen.setting,
    selectedTrip: (state) => state.trip.selectedTrip,
    // selectedTrip: (state) => state.trip.showTripInfo,
    trip: (state) => state.trip.selectedTrip,
    selectedVehicleTrip: (state) => state.trip.selectedVehicleTrip,
    positions(state) {
      this.mcr_license_no = '';
      if (state.position.positions.length > 0) {
        let k;
        for (k = state.position.positions.length - 1; k > 0; k--) {
          const mcr_license_no = state.position.positions[k].mcr_license_no;
          if (
            mcr_license_no &&
            mcr_license_no != 'null' &&
            mcr_license_no != ''
          ) {
            this.mcr_license_no = state.position.positions[k].mcr_license_no;
            break;
          }
        }

        for (k = state.position.positions.length - 1; k > 0; k--) {
          const mcr_driver_name = state.position.positions[k].mcr_driver_name;
          if (
            mcr_driver_name &&
            mcr_driver_name != 'null' &&
            mcr_driver_name != ''
          ) {
            this.mcr_driver_name = state.position.positions[k].mcr_driver_name;
            break;
          }
        }
      }
      if (this.showDataTableOnScreen === false) {
        this.tripTableWrapper();
        return state.position.positions;
      } else {
        return state.position.positions;
      }
    },
    geofences: (state) => state.geofence.geofences,
    showTripInfo: (state) => state.trip.showTripInfo,
    selectedPosition: (state) => state.trip.selectedPosition,
    reportLoading: (state) => state.loading.reportLoading,
    showDataTableOnScreen: (state) => state.position.showDataTableOnScreen,
  }),

  async mounted() {
    //get all drivers
    if (this.drivers.length <= 0) {
      await this.getAllDrivers([this.selectedGroup.id]);
    }

    if (this.geofences.length <= 0) {
      await this.getAllGeofences([this.selectedGroup.id]);
    }
    this.columns = [
      {
        field: 'key',
        key: 'index',
        title: 'ที่',
        align: 'center',
        width: 24,
        operationColumn: true,
        fixed: 'left',
      },
      {
        title: 'เวลา',
        field: 'timestamp',
        key: 'timestamp',
        width: 95,
        fixed: 'left',
      },
      {
        title: 'สถานะ',
        field: 'engineStatus',
        key: 'engineStatus',
        width: 50,
        fixed: 'left',
        renderBodyCell: ({ row, column }) => {
          let engineStatus = row[column.field];
          let className = `${engineStatus} engine-status`;
          return (
            <div class={className}>
              {engineStatus == 'OVER_SPEED'
                ? 'เร็วเกิน'
                : row['engineStatusTranslated']}
            </div>
          );
        },
      },
      {
        title: 'กม/ชม',
        field: 'speed',
        key: 'speed',
        width: 34,
        fixed: 'left',
        renderBodyCell: ({ row, column }) => {
          return parseInt(row[column.field]).toFixed(0);
        },
      },
      {
        title: 'ตำแหน่ง',
        field: 'location',
        key: 'location',
        width: 140,
      },
      {
        title: '',
        field: 'gmap',
        key: 'gmap',
        width: 40,
        renderBodyCell: ({ row }) => {
          return (
            <span class="google-map-link">
              <a
                href={`http://maps.google.com/maps?q=loc:${
                  row.latitude / 10000000
                },${row.longitude / 10000000}`}
                target="_blank"
              >
                <a-icon type="google" /> Map
              </a>
            </span>
          );
        },
      },
    ];
    //PTO
    if (
      this.setting.columnPTODisplay === undefined ||
      this.setting.columnPTODisplay
    ) {
      // this.$refs['tableRef'].hideColumnsByKeys(['pto']);
      this.columns.push({
        title: 'PTO',
        field: 'pto',
        key: 'pto',
        width: 60,
        renderBodyCell: ({ row }) => {
          return (
            <div>
              <span class={`${this.getPTOStatusClass(row['pto1'])} pto-status`}>
                P1
              </span>
              <span class={`${this.getPTOStatusClass(row['pto2'])} pto-status`}>
                P2
              </span>
              <span class={`${this.getPTOStatusClass(row['pto3'])} pto-status`}>
                P3
              </span>
            </div>
          );
        },
      });
    }
    //TEMP1
    if (
      this.setting.columnTempDisplay === undefined ||
      this.setting.columnTempDisplay
    ) {
      // this.$refs['tableRef'].hideColumnsByKeys(['temp']);
      this.columns.push({
        title: 'TEMP',
        field: 'temp',
        key: 'temp',
        width: 30,
      });
    }
    //TEMP2
    if (
      (this.setting.columnTemp2Display === undefined) |
      this.setting.columnTemp2Display
    ) {
      // this.$refs['tableRef'].hideColumnsByKeys(['temp2']);
      this.columns.push({
        title: 'TEMP2',
        field: 'temp2',
        key: 'temp2',
        width: 30,
      });
    }
    //Distance
    this.columns.push({
      title: 'ระยะทาง',
      field: 'accOdometer',
      key: 'accOdometer',
      width: 50,
    });
    //Driver
    if (this.setting.columnDriverDisplay) {
      // this.$refs['tableRef'].hideColumnsByKeys(['driver']);
      this.columns.push({
        title: 'ผู้ขับขี่',
        field: 'driver',
        key: 'driver',
        width: 170,
        renderBodyCell: ({ row }) => {
          return (
            <div>
              {row.licenseNo !== ''
                ? this.displayDriverName(
                    row.licenseNo,
                    row.driverName,
                    this.getDriverNameByLicenseNo(row.licenseNo)
                  )
                : '-'}
            </div>
          );
        },
      });
    }
    //CardSwipe Status
    if (this.setting.columnCardStatusDisplay) {
      // this.$refs['tableRef'].hideColumnsByKeys(['cardStatus']);
      this.columns.push({
        title: 'รูดบัตร',
        field: 'cardStatus',
        key: 'cardStatus',
        width: 50,
      });
    }

    if (this.setting.columnCanbusDisplay) {
      // 114 CAN high resolution total vehicle distance
      // 115 CAN engine coolant temperature
      // 116 CAN fuel rate
      // 197 CAN engine speed
      // 203 CAN engine hours
      // 208 CAN engine total fuel used
      // 210 CAN wheel based speed
      this.columns.push({
        title: 'Coolant',
        field: 'coolant',
        key: 'coolant',
        width: 30,
      });
      this.columns.push({
        title: 'FuelRate',
        field: 'fuelrate',
        key: 'fuelrate',
        width: 35,
      });
      this.columns.push({
        title: 'EngineHr',
        field: 'enginehour',
        key: 'enginehour',
        width: 35,
      });
      this.columns.push({
        title: 'RPM',
        field: 'enginespeed',
        key: 'enginespeed',
        width: 30,
      });
      this.columns.push({
        title: 'FuelUsed',
        field: 'fuelused',
        key: 'fuelused',
        width: 35,
      });
      this.columns.push({
        title: 'Pedal',
        field: 'pedalposition',
        key: 'pedalposition',
        width: 30,
      });
    }
  },
  watch: {
    selectedPosition(pos) {
      if (pos) {
        this.$refs['tableRef'].setHighlightRow({ rowKey: pos.key });
        this.$refs['tableRef'].scrollToRowKey({ rowKey: pos.key });
      } else {
        this.$refs['tableRef'].setHighlightRow({ rowKey: 0 });
      }
    },
  },
  methods: {
    ...mapActions({
      selectTrip: 'trip/selectTrip',
      showTripSecondInfo: 'trip/showTripSecondInfo',
      selectPosition: 'trip/selectPosition',
      setReportLoading: 'loading/setReportLoading',
      getAllDrivers: 'driver/getAllDrivers',
      getAllGeofences: 'geofence/getAllGeofences',
    }),
    getWithByColEnabled() {
      if (this.isMobileDevice()) {
        return 355;
      } else {
        let width = 520;
        if (
          this.setting.columnDriverDisplay !== undefined &&
          this.setting.columnDriverDisplay
        ) {
          width = width + 190;
        }
        if (
          this.setting.columnPTODisplay ||
          this.setting.columnPTODisplay === undefined
        ) {
          width = width + 150;
        }
        if (
          this.setting.columnCanbusDisplay !== undefined &&
          this.setting.columnCanbusDisplay
        ) {
          width = width + 480;
        }
        if (this.setting.columnDetailedLocationDisplay) {
          width = width + 300;
        }
        return width;
      }
    },
    getODODistance(canbus_fuel_enable, positions) {
      return getODODistance(canbus_fuel_enable, positions);
    },
    isMobileDevice() {
      return isMobile;
    },

    getDriverNameByLicenseNo(licenseNo) {
      return this.driverByLicenseNo(licenseNo);
    },
    tripTableWrapper() {
      let objTripTable = JSON.parse(JSON.stringify(this.tripTable));
      if (
        this.setting.columnCanbusDisplay === undefined ||
        this.setting.columnCanbusDisplay
      ) {
        const imei = parseFloat(this.selectedVehicleTrip.device.imei);
        const vehicle = this.vehicleByIMEI(imei);

        for (const i in objTripTable) {
          objTripTable[i]['coolant'] = CANcoolant(vehicle, objTripTable[i].io);
          objTripTable[i]['fuelrate'] = CANfuelrate(
            vehicle,
            objTripTable[i].io
          );
          objTripTable[i]['enginehour'] = CANenginehour(
            vehicle,
            objTripTable[i].io
          );
          objTripTable[i]['enginespeed'] = CANengineSpeed(
            vehicle,
            objTripTable[i].io
          );
          objTripTable[i]['fuelused'] = CANfuelused(
            vehicle,
            objTripTable[i].io
          );
          objTripTable[i]['pedalposition'] = CANpedalposition(
            vehicle,
            objTripTable[i].io
          );
        }
      }

      for (const idx in objTripTable) {
        const location = objTripTable[idx].location;
        objTripTable[idx].location = this.displayAddress(location, true);
      }
      // console.log(this.tripTable)
      return objTripTable;
    },
    isToday(date) {
      return moment(date, 'X').fromNow();
    },
    formatDate(timestamp) {
      // console.log(timestamp);
      return moment(timestamp * 1000).format('D MMM YYYY HH:mm:ss');
    },
    formatDuration(start, stop) {
      // console.log("start", moment(start).format("D MMM YYYY HH:mm:ss"));
      // console.log("stop", moment(stop).format("D MMM YYYY HH:mm:ss"));
      const duration = moment.duration(moment(start).diff(moment(stop)));
      return `${duration.get('hours')}:${duration.get(
        'minutes'
      )}:${duration.get('seconds')}`;
    },
    onCloseDrawer() {
      this.selectPosition(null);
      this.showTripSecondInfo(false);
    },
    getEngineStatusClass(status) {
      return status;
    },
    checkShowPTO(position, ioNo) {
      // console.log(position);
      if (
        position &&
        position.io &&
        ioNo in position.io &&
        position.io[ioNo] == 1
      ) {
        return true;
      } else {
        return false;
      }
    },
    getPTOStatusClass(status) {
      return status == 1 ? 'ON' : 'OFF';
    },
    tripTableRowClick(record) {
      //////// GA /////////
      this.$gtag.event('history_vehicle-position_displayed');
      ////////////////////
      this.selectPosition(record);
    },
    getIsActiveRow(record) {
      if (this.selectedPosition && record.id == this.selectedPosition.timestamp)
        return 'trip-active-row';
      return '';
    },
    displayDriverName(licenseNo, driverName, getDriverNameByLicenseNo) {
      const vehicle = {
        device: {
          position: {
            mcr_driver_name: driverName,
            mcr_license_no: licenseNo,
          },
        },
      };
      return displayDriverName(true, vehicle, getDriverNameByLicenseNo);
    },
    async downloadTripReport() {
      //////// GA /////////
      this.$gtag.event('history_report-download_clicked');
      ////////////////////
      this.setReportLoading(true);
      await report.positionReport(
        [
          {
            plate: this.selectedVehicleTrip.plate_number,
            odo_canbus_offset: this.selectedVehicleTrip.odo_canbus_offset,
            canbus_fuel_enable: this.selectedVehicleTrip.canbus_fuel_enable,
            canbus_truck_enabled: this.selectedVehicleTrip.canbus_truck_enabled,
            odo_canbus_enable: this.selectedVehicleTrip.odo_canbus_enable,
            imei: this.selectedVehicleTrip.device
              ? parseFloat(this.selectedVehicleTrip.device.imei)
              : '',
          },
        ],
        [parseFloat(this.selectedVehicleTrip.device.imei)],
        moment(this.positions[0].timestamp * 1000).unix(),
        moment(
          this.positions[this.positions.length - 1].timestamp * 1000
        ).unix(),
        0,
        this.geofences
      );
      this.setReportLoading(false);
    },
    onPressUp() {
      const dataTable = this.tripTableWrapper();
      if (this.selectedPosition) {
        const currentIndex = dataTable.findIndex(
          (row) => row.key === this.selectedPosition.key
        );
        if (currentIndex > 0) {
          this.selectPosition(dataTable[currentIndex - 1]);
        }
      }
    },
    onPressDown() {
      const dataTable = this.tripTableWrapper();
      if (this.selectedPosition) {
        const currentIndex = dataTable.findIndex(
          (row) => row.key === this.selectedPosition.key
        );
        this.selectPosition(dataTable[currentIndex + 1]);
      } else {
        this.selectPosition(dataTable[0]);
      }
    },
    displayAddress(location, isRequiredDetailed) {
      let displayedStr = '';

      if (location.geofenceName && location.geofenceName !== '') {
        displayedStr = location.geofenceName;
      } else {
        if (isRequiredDetailed) {
          displayedStr = this.setting.columnDetailedLocationDisplay
            ? location.detailedAddress
            : location.address;
        } else {
          displayedStr = location.address;
        }
      }

      return displayedStr && displayedStr !== '' ? displayedStr : '-';
    },
    displayMaxSpeed() {
      let color = '';

      if (
        'overspeed_limit' in this.selectedVehicleTrip &&
        this.tripMaxSpeed >= this.selectedVehicleTrip.overspeed_limit
      ) {
        color = 'violet';
      }

      return {
        color: color,
        value: this.tripMaxSpeed || '0.00',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.expandable-row {
  display: -webkit-flex;
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: space-between;
}
.trips {
  margin-top: 30px;
  outline: none !important;
  position: relative;
}
.heliot-info-trip {
  &.active {
    background: #e3e3e3 0% 0% no-repeat padding-box;
    cursor: pointer;
  }
  &:hover {
    background: #efefef 0% 0% no-repeat padding-box;
    cursor: pointer;
  }

  background: none;
  /* margin: 5px 0px; */
  border-radius: 7px;
  padding: 5px 10px 10px 10px;
}

.trip-info {
  text-align: left;
  .date {
    font-size: 16px;
    font-weight: bold;
    color: #505050;
    line-height: 24px;
    white-space: nowrap;
    margin-top: 0px;
  }
  .distance {
    font-size: 15px;
    font-weight: normal;
    color: #505050;
    line-height: 24px;
    white-space: nowra;
  }
}

.x-small {
  font-size: 13px;
}
.ant-drawer-body {
  padding: 15px 30px;
}
.heliot-bottom-title {
  text-align: left;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0;
  color: #00c246;
  opacity: 1;
  line-height: 24px;
  margin-bottom: 0;
  margin-right: 0px;
}
// .heliot-bottom-group {
//   padding-top: 15px;
// }
// .heliot-button-header {
// }

.col-info-right {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  opacity: 1;
  margin-left: 10px;
}
.col-info-left {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 7px;
  opacity: 1;
  margin-right: 10px;
}

.heliot-col-info-dotted {
  border-right-style: dotted;
  width: 2px;
}
.heliot-info-icon-left {
  line-height: 16px;
  margin: 8px 12px !important;
}
.engine-status {
  color: #fff;
  padding: 3px 8px;
  width: 53px;
  margin: auto;
  font-size: 0.7rem;
  border-radius: 300px;
  &.ENGINE_STOP {
    background-color: #f5223e;
  }
  &.ENGINE_IDLE,
  &.ENGINE_IDLE_END {
    background-color: #faad14;
  }
  &.VEHICLE_MOVING {
    background-color: #00c246;
  }
  &.OVER_SPEED {
    background-color: violet;
  }
}
.pto-status {
  color: #fff;
  padding: 2px 6px;
  border-radius: 300px;
  margin-left: 2px;
  font-size: 0.6rem;
  &.ON {
    background-color: #00c246;
  }
  &.OFF {
    background-color: #aaa;
  }
}
.all-trip-summary {
  background-color: #faad14;
  color: #fff;
  padding: 3px 10px;
  text-align: center;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 15px;
}
.google-map-link {
  padding: 3px 5px;
  font-size: 0.6rem;
  background-color: #00c246;
  border-radius: 3px;
  &:hover {
    background-color: #ddd;
    a {
      color: #fff;
    }
  }
  cursor: pointer;
  a {
    color: #fff;
  }
}
</style>
<style global>
.trip-table-row {
  cursor: pointer;
}
.ve-table-expand-td {
  padding: 0 !important;
}
.ve-table-expand-td-content {
  padding: 0 !important;
}
.trip-active-row {
  background-color: #ddd;
}
.trip-table-row td {
  padding: 3px 3px !important;
}

.ve-table
  .ve-table-container
  .ve-table-content-wrapper
  table.ve-table-content
  thead.ve-table-header
  tr.ve-table-header-tr
  th.ve-table-header-th {
  padding: 3px !important;
}
.ve-table
  .ve-table-container
  .ve-table-content-wrapper
  table.ve-table-content
  thead.ve-table-header
  tr.ve-table-header-tr {
  height: 28px !important;
}

.ve-table
  .ve-table-container
  .ve-table-content-wrapper
  table.ve-table-content
  tbody.ve-table-body
  tr.ve-table-body-tr,
.ve-table
  .ve-table-container
  .ve-table-content-wrapper
  table.ve-table-content
  tbody.ve-table-body
  tr.ve-table-expand-tr {
  height: 28px !important;
}
</style>
