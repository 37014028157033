import { apolloClient as strapiApollo } from '@/strapi-apollo';
import positionReport from '@/graphql/positionReport.gql';
import moment from '@/lib/time';
import Excel from 'exceljs';
import * as Sentry from '@sentry/vue';
import store from '@/store';

import {
  fontNormal,
  sheetMargin,
  getStatusColor,
  hightlightTableHead,
  blobToFile,
  imeiToPlateNumber,
  formatDate,
  getFuelCanbusEnabled,
  getFuelCanbusTruckEnabled,
  hightlightRow,
  convertKnotToKmph,
  getOdoData,
  formatDuration,
  geofenceFromLocation,
  imeiToVehicleName,
  getMCRDriverName,
  generateLogoHeader,
  addLogoCompany,
} from './config';

export const getFuelReport = async (
  vehicles,
  imeis,
  start,
  stop,
  geofences,
  dictOfDriver,
  logo
) => {
  await strapiApollo.resetStore();
  await strapiApollo
    .query({
      // Query
      query: positionReport,
      variables: {
        imeis,
        start,
        stop,
      },
    })
    .then((eventData) => {
      const reportByVehicle = splitFuelReportByVehicle(
        vehicles,
        eventData.data.positions,
        geofences,
        dictOfDriver
      );
      const strTimerange =
        moment.unix(start).format('D_MMM_YY') +
        '-ถึง-' +
        moment.unix(stop).format('D_MMM_YY');
      const fileName = 'Fuel_Report_' + strTimerange;
      const tableHead = [
        'ทะเบียนรถ',
        'ชื่อ',
        'วัน เวลา',
        'เชื้อเพลิง',
        'ตำแหน่ง',
        'สถานที่',
        'ความเร็ว',
        'เลขกม.สะสม',
        'เลขที่ใบขับขี่',
        'timestamp',
      ];
      const columnsSpec = [
        {
          key: 'plate_number',
          width: 10,
          style: {
            font: fontNormal,
          },
        },
        {
          key: 'name',
          width: 22,
          style: {
            numFmt: '0',
          },
        },
        {
          key: 'time',
          width: 20,
        },
        {
          key: 'fuel',
          width: 12,
        },
        {
          key: 'latLng',
          width: 24,
        },
        {
          key: 'geolocation',
          width: 30,
        },
        {
          key: 'speed',
          width: 8,
        },
        {
          key: 'odo',
          width: 10,
        },
        {
          key: 'mcr_license_no',
          width: 12,
        },
        {
          key: 'timestamp',
          width: 12,
        },
      ];
      genFuelExcel({
        fileName,
        reportByVehicle,
        tableHead,
        columnsSpec,
        statusColumn: false,
        logo,
      });
    })
    .catch((err) => {
      console.log(err);
      Sentry.captureException(err);
      store.dispatch('loading/setReportLoading', false);
      return;
    });
};

export const genFuelExcel = async ({
  fileName,
  reportByVehicle,
  tableHead,
  columnsSpec,
  statusColumn,
  logo,
}) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: 'FF41C173',
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
    },
  };

  let imageLogoId;
  if (logo && logo.url) {
    const ext = logo.ext.substring(1);
    const myBase64Image = await generateLogoHeader(logo);
    imageLogoId = workbook.addImage({
      base64: myBase64Image,
      extension: ext,
    });
  }

  if (Object.keys(reportByVehicle).length > 0)
    Object.keys(reportByVehicle).forEach((imei) => {
      if (reportByVehicle[imei].length > 0) {
        const plateNo = reportByVehicle[imei][0][0];
        const sheet = workbook.addWorksheet(plateNo, pageProperties);
        sheet.pageSetup.margins = sheetMargin;
        sheet.columns = columnsSpec;

        const totalDistance = (
          reportByVehicle[imei][reportByVehicle[imei].length - 1][7] -
          reportByVehicle[imei][0][7]
        ).toFixed(2);
        const totalTime = formatDuration(
          reportByVehicle[imei][0][9],
          reportByVehicle[imei][reportByVehicle[imei].length - 1][9]
        );
        const metaRow1 = `รายงานเชื้อเพลิง ของ รถทะเบียน ${plateNo} เวลาเริ่ม ${
          reportByVehicle[imei][0][2]
        } ถึง ${reportByVehicle[imei][reportByVehicle[imei].length - 1][2]}`;
        const metaRow2 = `รวมระยะทางทั้งสิ้น ${totalDistance} กม. รวมเวลา ${totalTime} ชม.:นาที:วินาที`;

        let startingRow = 1;

        if (logo && logo.url) {
          startingRow = 2;
          addLogoCompany(sheet, imageLogoId);
        }

        sheet.addRow([metaRow1]);
        sheet.addRow([metaRow2]);

        sheet.addRow([]);

        sheet.addRow(tableHead);
        sheet.addRows(reportByVehicle[imei]);
        if (statusColumn) {
          sheet.getColumn(statusColumn).eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: getStatusColor(cell.value),
            };
          });
        }
        hightlightTableHead(sheet, startingRow + 3);
        hightlightRow(sheet, startingRow);
        hightlightRow(sheet, startingRow + 1);
      }
    });
  else {
    workbook.addWorksheet('ไม่พบข้อมูล', pageProperties);
  }

  blobToFile(workbook, fileName);
};

const splitFuelReportByVehicle = (
  vehicles,
  report,
  geofences,
  dictOfDriver
) => {
  let result = {};
  report.forEach((row) => {
    result[row.imei] = result[row.imei] || [];
    const locationName = geofenceFromLocation(geofences, row);
    let fuel = 0;
    if (getFuelCanbusTruckEnabled(vehicles, row.imei) == true) {
      fuel =
        'fuelLitreCanbusTruck' in row &&
        row.fuelLitreCanbusTruck != null &&
        !isNaN(row.fuelLitreCanbusTruck)
          ? row.fuelLitreCanbusTruck.toFixed(2)
          : 0;
    } else if (getFuelCanbusEnabled(vehicles, row.imei) == false) {
      fuel =
        'fuelLitre' in row && !isNaN(row.fuelLitre)
          ? row.fuelLitre.toFixed(2)
          : 0;
    } else {
      fuel =
        'fuelLitreCanbus' in row &&
        row.fuelLitreCanbus &&
        !isNaN(row.fuelLitreCanbus)
          ? row.fuelLitreCanbus.toFixed(2)
          : 0;
    }

    result[row.imei].push([
      imeiToPlateNumber(vehicles, row.imei),
      imeiToVehicleName(vehicles, row.imei),
      formatDate(row.timestamp),
      parseFloat(fuel),
      row.latitude / 10000000 + ', ' + row.longitude / 10000000,
      locationName,
      parseFloat(convertKnotToKmph(row.speed)),
      parseFloat(getOdoData(vehicles, row.imei, row).toFixed(1)),
      typeof dictOfDriver != 'undefined' && row.mcr_license_no in dictOfDriver
        ? dictOfDriver[row.mcr_license_no].name
        : getMCRDriverName(row.mcr_license_no, row.mcr_driver_name),
      row.timestamp,
    ]);
  });
  return result;
};

export default getFuelReport;
