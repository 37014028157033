<template>
  <apexchart
    id="fuel_chart"
    type="area"
    height="350"
    :options="chartOptions"
    :series="fuelChartData"
  ></apexchart>
</template>
<script>
import moment from '@/lib/time';
export default {
  props: {
    fuelChartData: {
      type: Array,
      default: new Array(),
    },
    chartPNGTitle: {
      type: String,
      default: '',
    },
  },
  data: function () {
    var thisObj = this;
    return {
      chartOptions: {
        stroke: {
          curve: 'stepline',
          width: 1,
        },
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: false,
            },
          },
          animations: {
            enabled: false,
          },
          events: {
            click(event, chartContext, config) {
              if (config.dataPointIndex > 0) {
                const data =
                  config.config.series[config.seriesIndex].data[
                    config.dataPointIndex
                  ];
                thisObj.showAnno(data);
              }
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          colors: ['#00c246', '#FAAD14', '#F5223E'],
        },
        colors: ['#00c246', '#FAAD14', '#F5223E'],
        fill: {
          colors: ['#00c246', '#FAAD14', '#F5223E'],

          type: 'gradient',
          gradient: {
            shade: 'dark',
            shadeIntensity: 0.0,
            opacityFrom: 1,
            opacityTo: 1,
          },
        },
        yaxis: {
          min: 0,
          labels: {
            formatter: function (val) {
              return `${val != null ? val.toFixed(2) : '-'}`;
            },
            style: {
              colors: '#00c246',
            },
          },
          title: {
            text: 'ระดับน้ำมัน (ลิตร)',
          },
        },
        xaxis: {
          tickAmount: 24,
          type: 'datetime',
          labels: {
            rotate: -45,
            rotateAlways: true,
            formatter: function (val, timestamp) {
              return moment(new Date(timestamp)).format('HH:mm');
            },
          },
        },
        tooltip: {
          x: {
            format: 'เวลา HH:mm:ss',
          },
          z: {
            title: '',
            formatter: () => {
              return '';
            },
          },
          y: {
            formatter: (value, opts) => {
              const d =
                opts.w.config.series[opts.seriesIndex].data[
                  opts.dataPointIndex
                ];
              return `น้ำมัน: ${value != null ? value : '-'} ลิตร<br/>ระยะทาง ${
                d[2] != null ? d[2].toFixed(2) : '-'
              }  กม.<br/>AIN1:  ${d[1] != null ? d[1].toFixed(0) : '-'}  mVolt`;
            },
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          offsetX: -10,
        },
      },
    };
  },
  methods: {
    showAnno(data) {
      const lat = data[3];
      const lng = data[5];
      const url = `http://maps.google.com/maps?q=loc:${lat / 10000000},${
        lng / 10000000
      }`;
      if (lat > 0 && lng > 0)
        this.chartOptions = {
          ...this.chartOptions,
          annotations: {
            xaxis: [
              {
                x: data[0],
                borderColor: '#00c246',
                label: {
                  offsetY: -15,
                  orientation: 'horizontal',
                  borderColor: '#00c246',
                  style: {
                    color: '#fff',
                    background: '#00c246',
                  },
                  text: url,
                },
              },
            ],
          },
        };
    },

    updateChartPNGTitle() {
      this.chartOptions = {
        ...this.chartOptions,
        title: {
          text: this.chartPNGTitle,
          align: 'left',
        },
      };
    },
  },
  updated() {
    const nodes = this.$el.querySelectorAll('.apexcharts-xaxis-annotations');
    if (nodes)
      nodes.forEach((element) => {
        element.addEventListener('click', () =>
          window.open(element.textContent, '_blank')
        );
        element.addEventListener('mouseover', () => {
          element.setAttribute('style', 'cursor:pointer');
        });
      });
  },
  watch: {
    chartPNGTitle: function () {
      if (this.chartPNGTitle) {
        this.updateChartPNGTitle();
      }
    },
  },
  mounted() {
    if (this.chartPNGTitle) {
      this.updateChartPNGTitle();
    }
  },
};
</script>
<style lang="scss">
#fuel_chart .apexcharts-tooltip {
    background: rgba(255, 255, 255, 0.85) !important;
  }
</style>
