<template>
  <div
    v-if="
      vehicle &&
      vehicle.device &&
      vehicle.device.position &&
      vehicle.last_position_json
    "
    style="min-width: 360px"
  >
    <a-row>
      <div class="heliot-bottom-header">
        <a-row style="height: 25px">
          <a-col :xs="6" :sm="4" :md="10" :lg="10">
            <span
              :class="[
                'hide-sm',
                'heliot-bottom-title',
                vehicle.device && vehicle.device.position
                  ? checkOverSpeed(
                      vehicle.device.position.engineStatus,
                      vehicle.device.position.speed,
                      vehicle.overspeed_limit
                    )
                  : '',
              ]"
            >
              {{ vehicle && vehicle.plate_number }}
            </span>
            <span class="hide-sm">&nbsp;</span>
            <span class="hide-sm">
              <icon-font
                type="icon-car"
                style="margin: 0px 3px 0px 7px; font-size: 13px"
              />
            </span>
            <span class="hide-sm" style="margin-right: 5px">{{
              vehicle && vehicle.model
            }}</span>
            <div style="display: inline-flex; vertical-align: top">
              <a-button
                style="height: 24px; padding: 0px 5px 0px 5px; z-index: 1"
                type="primary"
                @click="downloadCertificate"
              >
                <a-spin v-if="cert_downloading">
                  <a-icon
                    slot="indicator"
                    type="loading"
                    style="font-size: 12px; color: white; margin-right: 5px"
                    spin
                  />
                </a-spin>
                <img
                  v-else
                  style="
                    margin-right: 5px;
                    height: 70%;
                    width: auto;
                    filter: brightness(0) invert(1);
                  "
                  :src="require('/src/assets/certi_icon.svg')"
                />
                หนังสือรับรอง</a-button
              >
              <a-modal
                centered
                :visible="showCertiModal"
                :mask="false"
                :footer="null"
                :width="360"
                @cancel="
                  () => {
                    this.showCertiModal = false;
                  }
                "
              >
                <div style="text-align: center">
                  <div style="font-size: 34px">หนังสือรับรอง</div>
                  <div style="font-size: 16px">
                    ข้อมูลของพาหนะไม่เพียงพอต่อการขอหนังสือรับรอง<br />เพื่ออัพเดทข้อมูลพาหนะ
                    กรุณาติดต่อ
                  </div>
                  <a href="tel:+020106990" style="font-size: 24px; color: "
                    >02 010 6990</a
                  >
                  <div style="font-size: 16px">หรือ</div>
                  <a-button class="shareLinkLine" @click="openLineHeliot">
                    <img
                      style="margin-right: 5px"
                      :src="require('/src/assets/line_icon.svg')"
                    />
                    @Heliot</a-button
                  >
                </div>
              </a-modal>
            </div>
          </a-col>
          <a-col
            :md="14"
            :lg="14"
            :sm="14"
            :xs="14"
            style="
              text-align: left;
              padding-left: 5px;
              padding-right: 5px;
              z-index: 2;
            "
          >
            <a-radio-group
              value="tab"
              size="small"
              defaultValue="info-engine"
              class="heliot-button-header"
              buttonStyle="solid"
              autofocus="true"
              v-model="selectedTab"
            >
              <a-radio-button value="info-engine">รถพาหนะ</a-radio-button>
              <a-radio-button value="info-io">เซนเซอร์</a-radio-button>
              <a-radio-button value="info-accessories">อุปกรณ์</a-radio-button>
              <a-radio-button value="info-driver">ผู้ขับขี่</a-radio-button>
              <a-radio-button
                v-if="
                  vehicle.device &&
                  vehicle.device.position &&
                  vehicle.device.position.io &&
                  94 in vehicle.device.position.io
                "
                value="info-canbus"
                >OBD CanBus</a-radio-button
              >
              <a-radio-button
                v-if="vehicle.canbus_truck_enabled"
                value="canbus-truck"
              >
                CanBus
              </a-radio-button>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row style="font-size: 0.9rem">
          <a-col :sm="24" :md="24" :lg="24">
            <span
              v-if="
                vehicle.device &&
                vehicle.device.position &&
                vehicle.device.position.geofenceName
              "
              style="color: #2e8851"
              class="geofence"
              ><icon-font type="icon-map-pin" style="padding-right: 5px" />
              {{ vehicle.device.position.geofenceName }}</span
            >{{ displayAddress(vehicle.lastLocation) }}
          </a-col>
        </a-row>
        <a-row
          style="font-size: 0.9rem; color: #1890ff"
          v-if="vehicle && vehicle.name != vehicle.plate_number"
        >
          <a-col :sm="24" :md="24" :lg="24">
            {{ vehicle.name }}
          </a-col>
        </a-row>
      </div>
    </a-row>
    <a-row class="card-info info-engine" v-show="selectedTab == 'info-engine'">
      <a-col :span="13">
        <a-row :span="12" class="col-info-left">
          <a-col :span="24">
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font
                type="icon-Iconawesome-map-marker-alt"
                style="padding-right: 2px"
              />
              {{
                vehicle && vehicle.device.position.latitude
                  ? (vehicle.device.position.latitude / 10000000).toFixed(4)
                  : ''
              }},
              {{
                vehicle && vehicle.device.position.longitude
                  ? (vehicle.device.position.longitude / 10000000).toFixed(4)
                  : ''
              }}
              &nbsp;<span class="google-map-link">
                <a @click="showModalShare()"
                  ><a-icon type="share-alt" /> Share</a
                >
              </span>
            </p>
            <p
              v-else-if="vehicle && vehicle.device.position"
              class="heliot-info-icon-left"
            >
              <icon-font
                type="icon-Iconawesome-map-marker-alt"
                style="padding-right: 5px"
              />
              {{ vehicle && vehicle.device.position.latitude }},{{
                vehicle && vehicle.device.position.longitude
              }}
            </p>
            <p class="heliot-info-icon-left">
              <icon-font
                type="icon-Iconmaterial-access-time"
                style="padding-right: 5px"
              />
              {{ dtFormat(vehicle.device.position.timestamp * 1000) }}
            </p>
            <p class="heliot-info-icon-left">
              <icon-font type="icon-engineoutline" style="padding-right: 5px" />
              {{ translateStatus(vehicle.device.position.engineStatus) }}
            </p>
            <p class="heliot-info-icon-left">
              <icon-font
                type="icon-Iconawesome-car-battery"
                style="padding-right: 5px"
              />

              {{ displayVoltage(vehicle.device.position.io) }} โวลต์
            </p>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="11">
        <a-row :span="12" class="col-info-right">
          <a-col :span="24">
            <p class="heliot-info-icon-left">
              <icon-font
                title="Virtual ODOMeter (km)"
                type="icon-Iconawesome-tachometer-alt"
                style="padding-right: 5px"
              />
              {{ displayOdometer(vehicle, vehicle.device.position) }} กม.
            </p>
            <p class="heliot-info-icon-left">
              <icon-font
                title="Fuel Level"
                type="icon-oil-can"
                style="padding-right: 5px"
              />
              {{ displayFuelLevel(vehicle, vehicle.device.position) }} ลิตร
            </p>
            <p class="heliot-info-icon-left">
              <icon-font type="icon-temperature" style="padding-right: 5px" />
              {{
                displayTempSensorData(vehicle.device.position.io) + ' &deg;C'
              }}
              <template
                v-if="tempSensorIsAvaiable(vehicle.device.position.io, 79)"
              >
                ,
                <icon-font type="icon-temperature" style="padding-right: 5px" />
                {{
                  displayTempSensorData(vehicle.device.position.io, 79) +
                  ' &deg;C'
                }}
              </template>
            </p>
            <p class="heliot-info-icon-left">
              <a-icon
                type="bulb"
                theme="twoTone"
                :twoToneColor="
                  vehicle.device.position.io[2] == 1 ? '#00c246' : '#F5223E'
                "
              />
              PTO1
              <a-icon
                type="bulb"
                theme="twoTone"
                :twoToneColor="
                  vehicle.device.position.io[3] == 1 ? '#00c246' : '#F5223E'
                "
              />
              PTO2
              <a-icon
                type="bulb"
                theme="twoTone"
                :twoToneColor="
                  vehicle.device.position.io[4] == 1 ? '#00c246' : '#F5223E'
                "
              />
              PTO3
            </p>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row class="card-info info-io" v-show="selectedTab == 'info-io'">
      <a-col :span="12">
        <a-row :span="12" class="col-info-left">
          <a-col :span="24">
            <p class="heliot-info-icon-left">
              <icon-font
                type="icon-settings-input-compo"
                style="padding-right: 5px"
              />
              PTO1 (DIN1)
              {{ vehicle.device.position.io[2] == 1 ? ' On' : ' Off' }}
            </p>
            <p class="heliot-info-icon-left">
              <icon-font
                type="icon-settings-input-compo"
                style="padding-right: 5px"
              />
              PTO2 (DIN2)
              {{ vehicle.device.position.io[3] == 1 ? ' On' : ' Off' }}
            </p>
            <p class="heliot-info-icon-left">
              <icon-font
                type="icon-settings-input-compo"
                style="padding-right: 5px"
              />
              PTO3 (DIN3)
              {{ vehicle.device.position.io[4] == 1 ? ' On' : ' Off' }}
            </p>
            <p class="heliot-info-icon-left">
              <icon-font
                type="icon-settings-input-compo"
                style="padding-right: 5px"
              />
              hdop.
              {{
                vehicle.device.position.hdop
                  ? vehicle.device.position.hdop
                  : 'n/a'
              }}
            </p>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="12">
        <a-row :span="12" class="col-info-right">
          <a-col :span="24">
            <p class="heliot-info-icon-left">
              <icon-font
                type="icon-settings-input-compo"
                style="padding-right: 5px"
              />
              AIN1 (mVolt)
              {{
                vehicle.device.position.io[22]
                  ? vehicle.device.position.io[22]
                  : 'n/a'
              }}
            </p>
            <p class="heliot-info-icon-left">
              <icon-font
                type="icon-settings-input-compo"
                style="padding-right: 5px"
              />
              AIN2 (mVolt)
              {{
                vehicle.device.position.io[23]
                  ? vehicle.device.position.io[23]
                  : 'n/a'
              }}
            </p>
            <p class="heliot-info-icon-left">
              <icon-font
                type="icon-settings-input-compo"
                style="padding-right: 5px"
              />
              SAT.
              {{
                vehicle.device.position.satellites
                  ? vehicle.device.position.satellites
                  : 'n/a'
              }}
            </p>
            <p class="heliot-info-icon-left">
              <icon-font
                type="icon-settings-input-compo"
                style="padding-right: 5px"
              />
              GSM.
              {{
                vehicle.device.position.io[27]
                  ? vehicle.device.position.io[27]
                  : 'n/a'
              }}
            </p>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row
      class="card-info info-accessories"
      v-show="selectedTab == 'info-accessories'"
    >
      <a-col :span="12">
        <a-row :span="12" class="col-info-left">
          <a-col :span="24">
            <p class="heliot-info-icon-left">
              <icon-font type="icon-chip" style="padding-right: 5px" />
              {{ vehicle.device.name }}
              &nbsp;
              <icon-font
                type="icon-Iconawesome-car-battery"
                style="padding-right: 5px"
              />
              {{
                '30' in vehicle.device.position.io
                  ? (vehicle.device.position.io[30] / 1000).toFixed(1)
                  : 0
              }}
            </p>
            <p class="heliot-info-icon-left">
              <icon-font type="icon-chip" style="padding-right: 5px" />
              {{ vehicle.device.model }}
            </p>
            <p class="heliot-info-icon-left">
              <icon-font type="icon-chip" style="padding-right: 5px" />
              {{ vehicle.device.company }}
            </p>
            <p
              v-if="vehicle && vehicle.device && vehicle.device.serial_no"
              class="heliot-info-icon-left"
            >
              <icon-font type="icon-number" style="padding-right: 5px" />
              {{ vehicle.device.serial_no }}
            </p>
            <p
              v-if="tempSensorIsAvaiable(vehicle.device.position.io, 600)"
              class="heliot-info-icon-left"
            >
              <icon-font
                type="icon-temperature"
                style="padding-right: 5px; color: #6796f9; font-size: 16px"
              />
              {{
                displayBTSensorData(vehicle.device.position.io, 600) + ' &deg;C'
              }}
              <template
                v-if="tempSensorIsAvaiable(vehicle.device.position.io, 605)"
              >
                &nbsp;<span style="color: #6796f9">Humidity</span>
                {{
                  displayBTSensorData(vehicle.device.position.io, 605) +
                  ' &percnt;RH'
                }}
              </template>
            </p>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="12">
        <a-row :span="12" class="col-info-right">
          <a-col :span="24">
            <p class="heliot-info-icon-left">
              <icon-font
                type="icon-Iconmaterial-access-time"
                style="padding-right: 5px"
              />ซื้อเมื่อ {{ isDate(vehicle.device.purchase_date) }}
            </p>
            <p class="heliot-info-icon-left">
              <icon-font
                type="icon-Iconmaterial-access-time"
                style="padding-right: 5px"
              />ประกันเริ่ม {{ isDate(vehicle.device.warranty_begin) }}
            </p>
            <p
              class="heliot-info-icon-left"
              v-if="
                'block_ignition_enabled' in vehicle &&
                vehicle.block_ignition_enabled === true
              "
            >
              <icon-font type="icon-chip" style="padding-right: 5px" />DOUT2
              On/Off
              <a-popconfirm
                title="ยืนยันการตัดสตาร์ท?"
                okText="ยืนยัน"
                cancelText="ยกเลิก"
                @confirm="
                  confirmChangeIgnition(vehicle.device, !block_ignition_status)
                "
              >
                <a-switch
                  :md="4"
                  size="small"
                  :checked="block_ignition_status"
                  :loading="changeStatusBlockIgnitionLoading"
                  :style="{
                    marginLeft: '5px',
                    marginRight: '5px',
                    marginTop: '1px',
                    marginBottom: '1px',
                  }"
                />
              </a-popconfirm>
            </p>
            <p
              class="heliot-info-icon-left"
              v-if="
                'block_ignition_with_card_enabled' in vehicle &&
                vehicle.block_ignition_with_card_enabled === true
              "
            >
              <icon-font type="icon-chip" style="padding-right: 5px" />Magnetic
              Card Block
              <a-popconfirm
                title="ยืนยันการปิดตัดสตาร์ทด้วยบัตร?"
                okText="ยืนยัน"
                cancelText="ยกเลิก"
                @confirm="
                  confirmChangeBlockWithCard(
                    vehicle.device.imei,
                    !block_ignition_with_card_status
                  )
                "
              >
                <a-switch
                  :md="4"
                  size="small"
                  :checked="block_ignition_with_card_status"
                  :loading="changeStatusBlockWithCardLoading"
                  :style="{
                    marginLeft: '5px',
                    marginRight: '5px',
                    marginTop: '1px',
                    marginBottom: '1px',
                  }"
                />
              </a-popconfirm>
            </p>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row
      class="card-info info-driver"
      v-show="selectedTab == 'info-driver'"
      v-if="vehicle.device.position && vehicle.device.position.mcr_license_no"
    >
      <div
        v-if="
          getDriverNameByLicenseNo(vehicle.device.position.mcr_license_no) ||
          (vehicle.device.position.mcr_driver_name &&
            vehicle.device.position.mcr_driver_name.length > 0)
        "
      >
        <a-col :span="12">
          <a-row
            :span="12"
            class="col-info-left"
            style="display: flex; height: 92px"
          >
            <div style="display: flex">
              <div
                class="hide-driver-icon"
                v-if="
                  vehicle.device.position.mcr_license_no &&
                  vehicle.device.position.mcr_license_no.length > 0 &&
                  getDriverNameByLicenseNo(
                    vehicle.device.position.mcr_license_no
                  ) &&
                  getDriverNameByLicenseNo(
                    vehicle.device.position.mcr_license_no
                  ).photo &&
                  getDriverNameByLicenseNo(
                    vehicle.device.position.mcr_license_no
                  ).photo.url.length > 0
                "
              >
                <img
                  :src="`${
                    getDriverNameByLicenseNo(
                      vehicle.device.position.mcr_license_no
                    ).photo.url
                  }`"
                  style="
                    margin: 16px 0px 16px 12px;
                    border-radius: 30px;
                    background: #ffff;
                  "
                  width="60px"
                  height="60px"
                />
              </div>
              <div v-else>
                <a-avatar
                  class="hide-driver-icon"
                  :size="60"
                  icon="user"
                  style="margin: 16px 0px 16px 12px; border-radius: 30px"
                />
              </div>
              <div style="margin: 15px 0px 16px 0">
                <p
                  :class="['heliot-info-icon-left', 'sm-driver-name']"
                  style="font-size: 18px; font-weight: 300"
                >
                  {{
                    displayDriverName(
                      vehicle,
                      getDriverNameByLicenseNo(
                        vehicle.device.position.mcr_license_no
                      )
                    )
                  }}
                </p>

                <p class="heliot-info-icon-left">
                  เพศ
                  {{
                    displayDriverGender(
                      vehicle,
                      getDriverNameByLicenseNo(
                        vehicle.device.position.mcr_license_no
                      )
                    )
                  }}
                </p>

                <p class="heliot-info-icon-left">
                  เลขใบขับขี่
                  {{ vehicle.device.position.mcr_license_no }}
                </p>
              </div>
            </div>
          </a-row>
        </a-col>
        <a-col :span="12">
          <a-row :span="12" class="col-info-right" style="height: 92px">
            <a-col :span="24" style="margin: 10px 0px 10px 0px">
              <p class="heliot-info-icon-left">
                โทร
                {{
                  vehicle.device.position.mcr_license_no &&
                  vehicle.device.position.mcr_license_no.length > 0 &&
                  getDriverNameByLicenseNo(
                    vehicle.device.position.mcr_license_no
                  )
                    ? getDriverNameByLicenseNo(
                        vehicle.device.position.mcr_license_no
                      ).mobile
                    : '-'
                }}
              </p>
              <p class="heliot-info-icon-left">
                ประเภท
                {{
                  vehicle.device.position.mcr_plt_code &&
                  getlicenseTypeByPLT(vehicle.device.position.mcr_plt_code) &&
                  getlicenseTypeByPLT(vehicle.device.position.mcr_plt_code)
                    .name_thai
                    ? getlicenseTypeByPLT(vehicle.device.position.mcr_plt_code)
                        .name_thai
                    : ''
                }}
                ({{
                  vehicle.device.position.mcr_plt_code &&
                  getlicenseTypeByPLT(vehicle.device.position.mcr_plt_code) &&
                  getlicenseTypeByPLT(vehicle.device.position.mcr_plt_code)
                    .plt_code
                    ? getlicenseTypeByPLT(vehicle.device.position.mcr_plt_code)
                        .plt_code
                    : '-'
                }})
              </p>
              <p class="heliot-info-icon-left">
                สถานที่ออกบัตร

                {{
                  vehicle.device.position.mcr_issue_branch
                    ? vehicle.device.position.mcr_issue_branch
                    : '-'
                }}
              </p>
            </a-col>
          </a-row>
        </a-col>
      </div>
      <div v-else>
        <a-col :span="12">
          <a-alert
            :message="`พบข้อมูลการรูดบัตรหมายเลข ${vehicle.device.position.mcr_license_no}`"
            type="warning"
          />
        </a-col>
      </div>
    </a-row>

    <a-row
      class="card-info info-driver"
      v-show="selectedTab == 'info-driver'"
      v-else
    >
      <a-col :span="12">
        <a-alert message="ไม่พบข้อมูลรูดบัตร" type="error" />
      </a-col>
    </a-row>
    <!---------------------------------------------------------->
    <!---------------------- Canbus-Truck ----------------------------->
    <!---------------------------------------------------------->
    <a-row
      class="card-info canbus-truck"
      v-show="selectedTab == 'canbus-truck'"
    >
      <a-col :span="12">
        <a-row :span="12" class="col-info-left">
          <a-col :span="24">
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font
                type="icon-gas-station-line"
                style="padding-right: 5px"
              />
              Fuel
              {{ displayFuelLevel(vehicle, vehicle.device.position) }} L
            </p>
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font
                type="icon-gas-station-line"
                style="padding-right: 5px"
              />
              Consumption rate
              {{ CANfuelrate(vehicle, vehicle.device.position.io) }}
              L/hr
            </p>
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font
                type="icon-gas-station-line"
                style="padding-right: 5px"
              />
              Fuel used
              {{ CANfuelused(vehicle, vehicle.device.position.io) }}
              L
            </p>
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font
                type="icon-Iconmaterial-access-time"
                style="padding-right: 5px"
              />
              Engine hour
              {{ CANenginehour(vehicle, vehicle.device.position.io) }}
              hr
            </p>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="12">
        <a-row :span="12" class="col-info-left">
          <a-col :span="24">
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font
                type="icon-Iconmetro-meter"
                style="padding-right: 5px"
              />
              Odometer
              {{ displayOdometer(vehicle, vehicle.device.position).toFixed(0) }}
              km.
            </p>
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font
                type="icon-Iconmetro-meter"
                style="padding-right: 5px"
              />
              RPM
              {{ CANengineSpeed(vehicle, vehicle.device.position.io) }}
            </p>
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font type="icon-temperature" style="padding-right: 5px" />
              Engine Coolant
              {{ CANcoolant(vehicle, vehicle.device.position.io) }}
              °C
            </p>
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font
                type="icon-Iconmetro-meter"
                style="padding-right: 5px"
              />
              pedal position
              {{ CANpedalposition(vehicle, vehicle.device.position.io) }}
              %
            </p>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <!---------------------------------------------------------->
    <!---------------------- OBDCanbus----------------------------->
    <!---------------------------------------------------------->
    <a-row class="card-info info-canbus" v-show="selectedTab == 'info-canbus'">
      <a-col :span="12">
        <a-row :span="12" class="col-info-left">
          <a-col :span="24">
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font type="icon-chip" style="padding-right: 5px" />
              RPM
              {{
                vehicle.device &&
                vehicle.device.position &&
                vehicle.device.position.io &&
                94 in vehicle.device.position.io
                  ? vehicle.device.position.io[94] * 0.25
                  : ''
              }}
            </p>
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font type="icon-speed" style="padding-right: 5px" />
              ความเร็ว
              {{
                vehicle.device &&
                vehicle.device.position &&
                parseFloat(vehicle.device.position.speed).toFixed(2)
              }}
              กม/ชม
            </p>
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font type="icon-temperature" style="padding-right: 5px" />
              Engine Coolant
              {{
                96 in vehicle.device.position.io &&
                vehicle.device.position.io[96] * 1 > 0
                  ? vehicle.device.position.io[96] * 1 - 40
                  : ''
              }}
              °C
            </p>
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font type="icon-circle1" style="padding-right: 5px" />
              อุณหภูมิภายนอก
              {{ displayCanbusAmbientAir(vehicle.device.position.io) }} °C
            </p>
          </a-col>
        </a-row>
      </a-col>
      <a-col :span="12">
        <a-row :span="12" class="col-info-left">
          <a-col :span="24">
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font
                type="icon-gas-station-line"
                style="padding-right: 5px"
              />
              น้ำมัน
              {{ displayFuelLevel(vehicle, vehicle.device.position) }} ลิตร
            </p>
            <p class="heliot-info-icon-left" v-if="vehicle.lastLocation">
              <icon-font
                type="icon-Iconmetro-meter"
                style="padding-right: 5px"
              />
              เลขกม.
              {{ displayOdometer(vehicle, vehicle.device.position) }} กม.
            </p>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <a-modal
      :title="`แชร์ตำแหน่งรถไปยัง`"
      :class="[`edit-share-modal`]"
      :visible="visibleShareModal"
      :mask="true"
      :closable="true"
      :footer="false"
      @cancel="hideShareModal"
      centered
      wrapClassName="shareModal"
    >
      <a-row>
        <a-col class="form-container">
          <a-form-item
            style="padding-bottom: 15px; padding-top: 0px; text-align: center"
          >
            <a-button
              type="primary"
              icon="google"
              :size="'large'"
              @click="shareLinkUrl(vehicle, false)"
              class="shareLinkBtn"
            >
              Google Map
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col class="form-container">
          <a-form-item style="padding-bottom: 15px; text-align: center">
            <a-button
              type="primary"
              icon="copy"
              :size="'large'"
              @click="shareLinkUrl(vehicle, true)"
              class="shareLinkBtn"
            >
              คัดลอกลิงก์
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col v-if="canShareNative()" class="form-container">
          <a-form-item style="padding-bottom: 15px; text-align: center">
            <a-button
              type="primary"
              icon="share-alt"
              :size="'large'"
              @click="otherShare(vehicle)"
              class="shareLinkBtn"
            >
              อื่นๆ
            </a-button>
          </a-form-item>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
import { displayAddress, getVehicles } from '@/lib/vehicle';
import { IconFont } from '@/config/config';
import endpoints from '../../config/config';
import { mapState, mapGetters } from 'vuex';
import moment from '@/lib/time';
import fileDownload from 'js-file-download';
import AxiosService from '@/plugins/axios';
import {
  translateEngineStatus,
  translateGenderStatus,
} from '@/lib/report/config';
import {
  displayCanbusAmbientAir,
  displayFuelLevel,
  displayTempSensorData,
  displayBTSensorData,
  displayOdometer,
  displayVoltage,
  displayDriverName,
  displayDriverGender,
  CANfuelrate,
  CANenginehour,
  CANfuelused,
  CANengineSpeed,
  CANcoolant,
  CANpedalposition,
  tempSensorIsAvaiable,
} from '@/lib/device';

export default {
  components: {
    IconFont,
  },
  data() {
    return {
      visibleShareModal: false,
      mcrLi: '',
      selectedTab: 'info-engine',
      block_ignition_status: false,
      block_ignition_with_card_status: false,
      showCertiModal: false,
      cert_downloading: false,
    };
  },
  computed: mapState({
    user: (state) => state.authen.user,
    drivers: (state) => state.drivers,
    selectedGroup: (state) => state.authen.selectedGroup,
    changeStatusBlockIgnitionLoading: (state) =>
      state.loading.changeStatusBlockIgnitionLoading,
    changeStatusBlockWithCardLoading: (state) =>
      state.loading.changeStatusBlockWithCardLoading,
    ...mapGetters({
      vehicle: 'vehicle/getSelectedVehicle',
      driverByLicenseNo: 'driver/driverByLicenseNo',
      licenseTypeByPLT: 'licensetype/licenseTypeByPLT',
    }),
  }),
  watch: {
    vehicle: function (val) {
      if (val && 'block_ignition_status' in val) {
        this.block_ignition_status = val.block_ignition_status;
      }

      if (val && 'block_ignition_with_card_status' in val) {
        this.block_ignition_with_card_status =
          val.block_ignition_with_card_status;
      }
    },
    selectedTab: function (val) {
      //////// GA /////////
      switch (val) {
        case 'info-engine':
          this.$gtag.event('track_vehicle-info-tab_clicked');
          break;
        case 'info-io':
          this.$gtag.event('track_sensor-info-tab_clicked');
          break;
        case 'info-accessories':
          this.$gtag.event('track_device-info-tab_clicked');
          break;
        case 'info-driver':
          this.$gtag.event('track_driver-info-tab_clicked');
          break;
        case 'info-canbus':
          this.$gtag.event('track_OBD-canbus-tab_clicked');
          break;
        case 'canbus-truck':
          this.$gtag.event('track_canbus-tab_clicked');
          break;
        default:
          break;
      }
      ////////////////////
    },
  },

  mounted() {
    if (
      this.vehicle &&
      'block_ignition_status' in this.vehicle &&
      this.vehicle.block_ignition_status
    ) {
      this.block_ignition_status = this.vehicle.block_ignition_status;
    }

    if (
      'block_ignition_with_card_status' in this.vehicle &&
      this.vehicle &&
      'block_ignition_with_card_status' in this.vehicle
    ) {
      this.block_ignition_with_card_status =
        this.vehicle.block_ignition_with_card_status;
    }
  },
  methods: {
    handleCancel() {
      this.visibleShareModal = false;
    },
    async confirmChangeIgnition(device, status) {
      let self = this;
      this.$store.dispatch('loading/setChageStatusBlockIgnitionLoading', true);
      await AxiosService.post(`${endpoints.coreApiTmp}/send-command`, {
        status: status,
        imei: device.imei,
        company: device.company,
        user: this.$store.state.authen.user.id,
        command: 'SET_BLOCK_IGNITION_STATUS',
        customergroup: this.selectedGroup.id,
      })
        .then(async (response) => {
          setTimeout(() => {
            if (response.data.error_code >= 0) {
              self.block_ignition_status = response.data.status;
            }
            this.$store.dispatch(
              'loading/setChageStatusBlockIgnitionLoading',
              false
            );
          }, 1000);
          await getVehicles(
            self.user.customer_groups.map((group) => {
              return group._id;
            })
          );
        })
        .catch((error) => {
          console.log('An error occurred:', error);
        });
    },
    async confirmChangeBlockWithCard(imei, status) {
      this.$store.dispatch('loading/setChangeStatusBlockWithCardLoading', true);
      await AxiosService.post(`${endpoints.coreApiTmp}/send-command`, {
        status: status,
        imei: imei,
        command: 'SET_BLOCK_IGNITION_WITH_CARD',
        user: this.$store.state.authen.user.id,
        customergroup: this.selectedGroup.id,
      })
        .then(async (response) => {
          setTimeout(() => {
            if (response.data.error_code >= 0) {
              this.block_ignition_with_card_status = response.data.status;
            }
            this.$store.dispatch(
              'loading/setChangeStatusBlockWithCardLoading',
              false
            );
          }, 1000);

          await getVehicles(
            this.user.customer_groups.map((group) => {
              return group._id;
            })
          );
        })
        .catch((error) => {
          console.log('An error occurred:', error);
        });
    },
    onChangeInfo(e) {
      this.selectedTab = e.target.value;
    },
    isToday(date) {
      return moment(date, 'X').fromNow();
    },
    isDate(timestamp) {
      if (timestamp) {
        return moment(timestamp, 'YYYY-MM-DD').format('DD MMM YYYY');
      } else {
        return '-';
      }
    },
    getDriverNameByLicenseNo(licenseNo) {
      return this.driverByLicenseNo(licenseNo);
    },
    getlicenseTypeByPLT(code) {
      return this.licenseTypeByPLT(code);
    },
    checkOverSpeed(enginestatus, speed, overspeed) {
      if (speed >= overspeed) {
        return (enginestatus = 'OVER_SPEED');
      } else {
        return enginestatus;
      }
    },
    dtFormat(timestamp) {
      if (timestamp) {
        return moment(timestamp, 'x').format('DD MMM YYYY HH:mm:ss');
      } else {
        return '-';
      }
    },
    CANcoolant(vehicle, io) {
      return CANcoolant(vehicle, io);
    },
    CANpedalposition(vehicle, io) {
      return CANpedalposition(vehicle, io);
    },
    CANfuelrate(vehicle, io) {
      return CANfuelrate(vehicle, io);
    },
    CANenginehour(vehicle, io) {
      return CANenginehour(vehicle, io);
    },
    CANfuelused(vehicle, io) {
      return CANfuelused(vehicle, io);
    },
    CANengineSpeed(vehicle, io) {
      return CANengineSpeed(vehicle, io);
    },
    translateStatus(status) {
      return translateEngineStatus(status);
    },
    translateGender(status) {
      return translateGenderStatus(status);
    },
    displayCanbusAmbientAir(io) {
      return displayCanbusAmbientAir(io);
    },
    displayAddress(lastLocation) {
      return displayAddress(lastLocation);
    },
    displayFuelLevel(vehicle, position) {
      return displayFuelLevel(vehicle, position);
    },
    displayOdometer(vehicle, position) {
      return displayOdometer(vehicle, position);
    },
    displayTempSensorData(io, no) {
      return displayTempSensorData(io, no);
    },
    displayBTSensorData(io, no) {
      return displayBTSensorData(io, no);
    },
    tempSensorIsAvaiable(io, no) {
      return tempSensorIsAvaiable(io, no);
    },
    displayVoltage(io) {
      return displayVoltage(io);
    },
    displayDriverGender(vehicle, getDriverNameByLicenseNo) {
      return displayDriverGender(vehicle, getDriverNameByLicenseNo);
    },
    displayDriverName(vehicle, getDriverNameByLicenseNo) {
      return displayDriverName(false, vehicle, getDriverNameByLicenseNo);
    },
    showModalShare() {
      this.visibleShareModal = true;
    },
    openLineHeliot() {
      window.open('https://lin.ee/XbYL41c', '_blank');
    },
    async downloadCertificate() {
      //////// GA /////////
      this.$gtag.event('track_certificate-download_clicked');
      ////////////////////

      this.cert_downloading = true;
      await AxiosService.post(
        `${endpoints.coreApi}/certificates-service`,
        {
          imei: this.vehicle.unit_id,
          customergroup_id: this.selectedGroup.id,
          user: this.user.username,
        },
        {
          responseType: 'blob',
        }
      )
        .then((res) => {
          moment.locale('en');
          fileDownload(
            res.data,
            `หนังสือรับรองบริษัทเฮลลิออทจำกัด_${
              this.vehicle.plate_number
            }_${moment().format('DDMMMYYYY')}.pdf`
          );
          this.cert_downloading = false;
        })
        .catch((err) => {
          console.log(err);
          this.showCertiModal = true;
          this.cert_downloading = false;
          return;
        });
    },
    canShareNative() {
      return navigator.share ? true : false;
    },
    otherShare(vehicle) {
      const url = `https://www.google.com/maps/search/?api=1&query=${
        vehicle.device.position.latitude / 10000000
      },${vehicle.device.position.longitude / 10000000}`;
      if (navigator.share) {
        navigator
          .share({
            title: 'แชร์ตำแหน่งรถไปยัง',
            url: url,
          })
          .then(() => {})
          .catch(console.error);
      }
    },
    hideShareModal() {
      this.visibleShareModal = false;
    },
    shareLinkUrl(vehicle, clipboard) {
      const key = 'updatable';
      const url = `https://www.google.com/maps/search/?api=1&query=${
        vehicle.device.position.latitude / 10000000
      },${vehicle.device.position.longitude / 10000000}`;
      if (clipboard === false) {
        window.open(url, '_blank');
      } else {
        navigator.clipboard
          .writeText(url)
          .then(() => {
            this.$message.success({
              content: 'คัดลอกลิงก์สำเร็จ',
              key,
              duration: 3,
            });
          })
          .catch(() => {
            this.$message.warning({
              content: 'เกิดข้อผิดพลาดบางอย่าง กรุณาลองอีกครั้ง',
              key,
              duration: 3,
            });
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$md: 768px;
$sm: 576px;

@media screen and (max-width: $sm) {
  .hide-driver-icon {
    display: none;
  }
  .sm-driver-name {
    font-size: 14px !important;
  }
}
.shareModal .ant-modal-body {
  padding-top: 15px !important;
}
.shareLinkBtn {
  display: inline-block;
  width: 30%;
  @media screen and (max-width: $sm) {
    width: 50%;
  }
}
.shareLinkLine {
  margin-top: 5px;
  font-size: 16px;
  background-color: #e5e5e5;
  border-color: white;
  color: green;
  height: 50px;
  border-radius: 10px;
  &:hover,
  &:active,
  &:focus {
    background-color: #e5e5e5;
    border-color: white !important;
  }
  cursor: pointer;
}

.remove {
  display: block;
  font-size: 16px;
  color: #505050;
  line-height: 24px;
  float: right;
  &:hover {
    color: red;
  }
}
.geofence {
  background-color: #efefef;
  border-radius: 3px;
  padding: 2px 5px;
  margin-right: 5px;
}
.ant-drawer-body {
  padding: 10px 30px;
}
.heliot-bottom-title {
  text-align: left;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  color: #00c246;
  opacity: 1;
  line-height: 24px;
  margin-bottom: 0;
  margin-right: 0px;
  &.ENGINE_STOP {
    color: #f5223e;
  }
  &.ENGINE_IDLE,
  .ENGINE_IDLE_END {
    color: #faad14;
  }
  &.OVER_SPEED {
    color: #8e34ff;
  }
  &.VEHICLE_MOVING {
    color: #00c246;
  }
}

.col-info-right {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  opacity: 1;
  margin-left: 5px;
}
.col-info-left {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e3e3e3;
  border-radius: 7px;
  opacity: 1;
  margin-right: 5px;
}
.heliot-info-icon-left {
  font-size: 14px;
}
.heliot-col-info-dotted {
  border-right-style: dotted;
  width: 2px;
}
.heliot-info-icon-left {
  line-height: 12px;
  margin: 8px 12px !important;
  .PTO-On {
  }
}

.google-map-link {
  padding: 3px 5px;
  background-color: #00c246;
  border-radius: 3px;
  &:hover {
    background-color: #00c246;
    a {
      color: #fff;
    }
  }
  &:focus {
    background-color: #00c246;
    a {
      color: #fff;
    }
  }
  &:visited {
    background-color: #00c246;
    a {
      color: #fff;
    }
  }
  &:active {
    background-color: #00c246;
    a {
      color: #fff;
    }
  }
  cursor: pointer;
  a {
    color: #fff;
  }
}
</style>
<style lang="scss" global>
.edit-share-modal {
  .ant-modal-title {
    text-align: center;
  }
}
</style>
