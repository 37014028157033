var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"240px","display":"flex","flex-direction":"column"}},[_c('div',{staticClass:"navigation-container"},[_c('div',{class:['navigation-button', _vm.isTimelineTabActive && 'active'],on:{"click":function($event){return _vm.onClickTab(true)}}},[_c('a-icon',{attrs:{"type":"clock-circle"}}),_vm._v(" Timeline ")],1),_c('div',{class:['navigation-button', !_vm.isTimelineTabActive && 'active'],on:{"click":function($event){return _vm.onClickTab(false)}}},[_c('a-icon',{attrs:{"type":"profile"}}),_vm._v(" รายงาน ")],1)]),(_vm.isTimelineTabActive)?_c('div',{staticStyle:{"height":"20px","background-color":"#fafafa","display":"flex","align-items":"flex-end"}},[_c('div',{ref:"timelineRuler",style:({
        position: 'relative',
        display: 'flex',
        alignItems: 'flex-end',
        flexGrow: '1',
        height: '50%',
        marginLeft: `${_vm.channelTextColumnWidth}px`,
        marginRight: `${_vm.windowMarginRight}px`,
        borderRight: '#a1a1aa 1px solid',
      })},_vm._l((24),function(n){return _c('div',{key:n,style:({
          height: `${n % 4 === 1 ? '10' : '5'}px`,
          flexGrow: '1',
          borderLeft: '#a1a1aa 1px solid',
        })})}),0)]):_vm._e(),_c('div',{ref:"content",staticStyle:{"flex-grow":"1"}},[(_vm.isTimelineTabActive)?_c('div',{staticClass:"timeline-container"},[_c('div',{staticClass:"channel-column-container"},_vm._l((_vm.maxChannels),function(channel){return _c('div',{key:`CH${channel}`,class:[
            'channel-column-item',
            channel === _vm.maxChannels && 'last-item',
          ]},[_c('div',{style:({
              width: `${_vm.channelTextColumnWidth}px`,
              textAlign: 'center',
            })},[_vm._v(" CH"+_vm._s(channel)+" ")])])}),0),_c('div',{style:({
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          marginLeft: `${_vm.channelTextColumnWidth}px`,
          marginRight: `${_vm.windowMarginRight}px`,
        }),on:{"mousemove":_vm.onMouseMove,"mouseover":_vm.onMouseOver,"mouseleave":_vm.onMouseLeave}},[_c('div',{staticClass:"channel-column-container"},_vm._l((_vm.maxChannels),function(channel){return _c('div',{key:`video-ch-${channel}`,staticStyle:{"flex-grow":"1"}},[_c('div',{staticStyle:{"position":"relative","height":"100%","padding":"5px 0px"}},_vm._l((_vm.videoFiles.filter(
                  (file) => file.channelNum === channel
                )),function(data,key){return _c('div',{key:key},[_c('div',{class:[
                    'video-element',
                    _vm.selectedVideo === `CH${channel}-${key}` && 'active',
                  ],style:({
                    left: `${_vm.calculateWidth(data).startPoint}px`,
                    width: `${_vm.calculateWidth(data).width}px`,
                  }),on:{"click":function($event){return _vm.onClickVideo(data, `CH${channel}-${key}`)}}})])}),0)])}),0)]),(_vm.displayVerticalLine)?_c('div',{style:({
          position: 'absolute',
          left: `${_vm.position}px`,
          height: '168px',
          marginTop: '11px',
          borderLeft: '#16a34a 1px solid',
          pointerEvents: 'none',
        })},[_c('div',{staticClass:"annotation-line"},[_c('a-icon',{staticStyle:{"font-size":"20px","color":"#16a34a"},attrs:{"type":"caret-down"}}),_c('div',{staticStyle:{"color":"#16a34a"}},[_vm._v(" "+_vm._s(_vm.positionTimeUnix ? _vm.displayTime(_vm.positionTimeUnix) : '-')+" ")])],1)]):_vm._e()]):_c('div',[_c('ve-table',{attrs:{"columns":_vm.columns,"table-data":_vm.videoFiles,"max-height":200}}),(_vm.videoFiles.length === 0)?_c('div',{staticStyle:{"margin-top":"20px"}},[_c('a-empty')],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }