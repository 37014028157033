import {
    apolloClient
} from "../../strapi-apollo";
const state = () => ({
    geofences: [],
    selectedGeofence: {},
    showGeofenceInfo: false,
});
import createGeofence from "@/graphql/createGeofence.gql";
import deleteGeofence from "@/graphql/deleteGeofence.gql";
import geofences from "@/graphql/geofences.gql";

// getters
const getters = {
    geofences: (state) => state.geofences,
    geofencesCircle: (state) =>
        state.geofences.filter((geofence) => {
            return geofence.type == "circle";
        }),
    geofencesPolygon: (state) =>
        state.geofences.filter((geofence) => {
            return geofence.type == "polygon";
        }),
    geofenceByID: (state) => (id) => {
        if (state.geofences.length > 0) {
            return state.geofences.find((geofence) => geofence.id == id);
        } else return {};
    },
};

// actions
const actions = {
    async createGeofence({
        commit
    }, {
        name,
        type,
        positions,
        geojson,
        org,
        radius
    }) {
        // console.log('commit', commit)
        await apolloClient.resetStore();
        const result = await apolloClient
            .mutate({
                // Query
                mutation: createGeofence,
                variables: {
                    input: {
                        data: {
                            name,
                            type,
                            Positions: positions,
                            geojson,
                            org,
                            radius,
                        },
                    },
                },
            })
            .then(async (eventData) => {
                commit("SELECT_GEOFENCE", eventData.data.createGeofence.geofence);
                commit("SHOW_GEOFENCE_INFO", true);
                return eventData;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });
        return result;
        // .catch(err => {
        //   console.log(err);
        //   return false;
        // });
        // commit("SELECT_GEOFENCE", createGeofenceResult.data.createGeofence);
        // commit("SHOW_GEOFENCE_INFO", true);
    },
    async deleteGeofence({
        commit
    }, geofenceID) {
        // console.log('commit', commit)
        await apolloClient.resetStore();
        const result = await apolloClient
            .mutate({
                // Query
                mutation: deleteGeofence,
                variables: {
                    input: {
                        where: {
                            id: geofenceID,
                        },
                    },
                },
            })
            .then(async (eventData) => {
                // commit("SELECT_GEOFENCE", eventData.data.createGeofence.geofence);
                commit("SHOW_GEOFENCE_INFO", false);
                return eventData;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });
        return result;
        // .catch(err => {
        //   console.log(err);
        //   return false;
        // });
        // commit("SELECT_GEOFENCE", createGeofenceResult.data.createGeofence);
        // commit("SHOW_GEOFENCE_INFO", true);
    },
    async getAllGeofences({
        commit
    }, orgs) {
        await apolloClient.resetStore();
        const allGeofences = await apolloClient
            .query({
                // Query
                query: geofences,
                variables: {
                    orgs,
                },
            })
            .then(async (eventData) => {
                // console.log(eventData)
                return eventData;
            })
            // .catch((err) => {
            //     console.log(err);
            //     return;
            // });
        if (allGeofences && 'data' in allGeofences && allGeofences.data) {
            commit("ALL_GEOFENCES", allGeofences.data.geofences);
        }
    },
    selectGeofence({
        commit
    }, payload) {
        commit("SELECT_GEOFENCE", payload);
    },
    showGeofenceBottomInfo({
        commit
    }, payload) {
        commit("SHOW_GEOFENCE_INFO", payload);
    },
    resetState({
        commit
    }) {
        commit("RESET_STATE");
    },
};
// mutations
const mutations = {
    ALL_GEOFENCES(state, payload) {
        state.geofences = payload;
    },
    SELECT_GEOFENCE(state, payload) {
        state.selectedGeofence = payload;
    },
    SHOW_GEOFENCE_INFO(state, payload) {
        state.showGeofenceInfo = payload;
    },
    RESET_STATE(state) {
        state.showGeofenceInfo = false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};