<template>
  <div style="height: 240px; display: flex; flex-direction: column">
    <div class="navigation-container">
      <div
        @click="onClickTab(true)"
        :class="['navigation-button', isTimelineTabActive && 'active']"
      >
        <a-icon type="clock-circle" />
        Timeline
      </div>
      <div
        @click="onClickTab(false)"
        :class="['navigation-button', !isTimelineTabActive && 'active']"
      >
        <a-icon type="profile" />
        รายงาน
      </div>
    </div>
    <div
      v-if="isTimelineTabActive"
      style="
        height: 20px;
        background-color: #fafafa;
        display: flex;
        align-items: flex-end;
      "
    >
      <div
        ref="timelineRuler"
        :style="{
          position: 'relative',
          display: 'flex',
          alignItems: 'flex-end',
          flexGrow: '1',
          height: '50%',
          marginLeft: `${channelTextColumnWidth}px`,
          marginRight: `${windowMarginRight}px`,
          borderRight: '#a1a1aa 1px solid',
        }"
      >
        <div
          v-for="n in 24"
          :key="n"
          :style="{
            height: `${n % 4 === 1 ? '10' : '5'}px`,
            flexGrow: '1',
            borderLeft: '#a1a1aa 1px solid',
          }"
        ></div>
      </div>
    </div>
    <div style="flex-grow: 1" ref="content">
      <div v-if="isTimelineTabActive" class="timeline-container">
        <div class="channel-column-container">
          <div
            v-for="channel in maxChannels"
            :key="`CH${channel}`"
            :class="[
              'channel-column-item',
              channel === maxChannels && 'last-item',
            ]"
          >
            <div
              :style="{
                width: `${channelTextColumnWidth}px`,
                textAlign: 'center',
              }"
            >
              CH{{ channel }}
            </div>
          </div>
        </div>

        <div
          @mousemove="onMouseMove"
          @mouseover="onMouseOver"
          @mouseleave="onMouseLeave"
          :style="{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            marginLeft: `${channelTextColumnWidth}px`,
            marginRight: `${windowMarginRight}px`,
          }"
        >
          <div class="channel-column-container">
            <div
              v-for="channel in maxChannels"
              :key="`video-ch-${channel}`"
              style="flex-grow: 1"
            >
              <div style="position: relative; height: 100%; padding: 5px 0px">
                <div
                  v-for="(data, key) in videoFiles.filter(
                    (file) => file.channelNum === channel
                  )"
                  :key="key"
                >
                  <div
                    :class="[
                      'video-element',
                      selectedVideo === `CH${channel}-${key}` && 'active',
                    ]"
                    :style="{
                      left: `${calculateWidth(data).startPoint}px`,
                      width: `${calculateWidth(data).width}px`,
                    }"
                    @click="onClickVideo(data, `CH${channel}-${key}`)"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="displayVerticalLine"
          :style="{
            position: 'absolute',
            left: `${position}px`,
            height: '168px',
            marginTop: '11px',
            borderLeft: '#16a34a 1px solid',
            pointerEvents: 'none',
          }"
        >
          <div class="annotation-line">
            <a-icon type="caret-down" style="font-size: 20px; color: #16a34a" />
            <div style="color: #16a34a">
              {{ positionTimeUnix ? displayTime(positionTimeUnix) : '-' }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <ve-table
          :columns="columns"
          :table-data="videoFiles"
          :max-height="200"
        />
        <div v-if="videoFiles.length === 0" style="margin-top: 20px">
          <a-empty />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from '@/lib/time';

export default {
  name: 'playbackDrawer',
  props: ['videoFiles', 'timeRange'],
  data() {
    return {
      position: 510,
      mSlope: 864,
      windowMarginRight: 100,
      initPoint: 510,
      windowSize: 0,
      positionTimeUnix: null,
      displayVerticalLine: false,
      maxChannels: 4,
      channelTextColumnWidth: 101,
      selectedVideo: '',
      columns: [
        {
          field: 'action',
          key: 'action',
          title: 'เล่นวิดีโอ',
          align: 'center',
          width: '15%',
          renderBodyCell: ({ row }) => {
            return (
              <div style="display:flex; justify-content:center; align-items:center; gap:20px">
                <a-icon
                  type="play-circle"
                  class="action-icon"
                  onClick={() => this.onClickVideo(row)}
                />
                {/* <a-icon type="cloud-upload" class="action-icon" /> */}
              </div>
            );
          },
        },
        {
          field: 'channelName',
          key: 'channelName',
          title: 'กล้อง',
          align: 'center',
        },
        {
          field: 'startTime',
          key: 'startTime',
          title: 'เวลาเริ่มต้น',
          align: 'center',
        },
        {
          field: 'endTime',
          key: 'endTime',
          title: 'เวลาสิ้นสุด',
          align: 'center',
        },
        {
          field: 'videoDuration',
          key: 'videoDuration',
          title: 'ความยาววิดีโอ',
          align: 'center',
          renderBodyCell: ({ row, column }) => {
            let videoDuration = row[column.field];
            return <div>{moment.utc(videoDuration).format('HH:mm:ss')}</div>;
          },
        },
        {
          field: 'videoSize',
          key: 'videoSize',
          title: 'ขนาดวิดีโอ',
          align: 'center',
          renderBodyCell: ({ row, column }) => {
            let fileSize = row[column.field];
            return <div>{this.humanFileSize(fileSize)}</div>;
          },
        },
        {
          field: 'storageType',
          key: 'storageType',
          title: 'พื้นที่จัดเก็บ',
          align: 'center',
          renderBodyCell: ({ row, column }) => {
            let storageType = row[column.field];
            return <div>{storageType === 1 ? 'SD Card' : 'Server'}</div>;
          },
        },
      ],
      isTimelineTabActive: true,
      virtualScrollOption: {
        enable: true,
        minRowHeight: 4,
      },
    };
  },
  mounted() {
    this.windowSize = window.innerWidth;
    this.windowMarginRight = this.windowSize * 0.075;
    this.onChangeMSlope();

    window.addEventListener('resize', () => {
      this.windowSize = window.innerWidth;
    });
  },
  watch: {
    timeRange() {
      this.onChangeMSlope();
    },
    windowSize(newValue) {
      if (newValue < 768) {
        this.initPoint = 370;
      } else {
        this.initPoint = 510;
      }
      this.windowMarginRight = newValue * 0.075;
      this.onChangeMSlope();
    },
    videoFiles() {
      this.selectedVideo = '';
    },
  },
  methods: {
    onMouseMove(value) {
      let x = value.clientX;

      this.position = x - (this.initPoint - this.channelTextColumnWidth);

      // y = mx + c linear equation
      this.positionTimeUnix =
        (x - this.initPoint) * this.mSlope + this.timeRange[0];
    },
    humanFileSize(size) {
      var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        ' ' +
        ['B', 'kB', 'MB', 'GB', 'TB'][i]
      );
    },
    onMouseOver() {
      this.displayVerticalLine = true;
    },
    onMouseLeave() {
      this.displayVerticalLine = false;
    },
    displayTime(unix) {
      return moment.unix(unix).format('YYYY-MM-DD HH:mm:ss');
    },
    onClickVideo(data, key) {
      this.$emit('onClickVideo', data);
      this.selectedVideo = key;
    },
    calculateWidth(obj) {
      const startUnix = moment(obj.startTime).unix();
      const endUnix = moment(obj.endTime).unix();

      const width = endUnix / this.mSlope - startUnix / this.mSlope;
      const startPoint = (startUnix - this.timeRange[0]) / this.mSlope;

      return { width, startPoint };
    },
    onClickTab(isActive) {
      this.isTimelineTabActive = isActive;
    },
    onChangeMSlope() {
      this.mSlope =
        (this.timeRange[1] - this.timeRange[0]) /
        (this.windowSize - this.windowMarginRight - this.initPoint);
    },
  },
  destroyed() {
    window.removeEventListener('resize', () => {
      this.windowSize = 0;
    });
  },
};
</script>
<style lang="scss" scoped>
.video-element {
  position: absolute;
  top: 25%;
  height: 60%;
  background-color: #bfbfbf;
  border-radius: 10px;

  cursor: pointer;

  &:hover {
    opacity: 40%;
  }
  &.active {
    background-color: #30c83f;
  }
}
.navigation-container {
  margin-top: 8px;
  font-size: 18px;
  display: flex;
  .navigation-button {
    height: 30px;
    padding: 0 16px;
    display: flex;
    text-align: center;
    align-items: center;
    gap: 7px;
    border-bottom: 2px solid white;
    cursor: pointer;

    &.active {
      border-bottom: 2px solid #30c83f;
    }
  }
}
.timeline-container {
  position: relative;
  display: flex;
  height: 100%;
  // padding: 5px 0px;
}
.channel-column-container {
  height: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding-top: 2px;

  .channel-column-item {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: #d9d9d9 1px solid;
    padding: 5px 0px;

    &.last-item {
      border: none;
    }
  }
}

.action-icon {
  font-size: 18px;
  color: #30c83f;
  cursor: pointer;
}

.annotation-line {
  display: flex;
  width: 110px;
  flex-direction: column;
  align-items: center;
  line-height: 0px;
  margin-left: -55px;
  margin-top: -26px;
}
</style>
