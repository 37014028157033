import {
    apolloClient
} from "@/strapi-apollo";
import notifications from "@/graphql/notifications.gql";
import unreadNofifications from "@/graphql/getUnreadNotifications.gql";
import moment from '@/lib/time';
import {
    getLastRead
} from '@/lib/authen';
import store from '@/store'
export const getNotifications = async function (imeis, xMore, reset) {
    await apolloClient.resetStore();
    let allNoti = [];
    let offset = 0;
    if (!reset) offset = store.state.fcm.notifications.length;

    await apolloClient
        .query({
            // Query
            query: notifications,
            variables: {
                imeis,
                limit: xMore,
                start: offset,

            },
        })
        .then(async (eventData) => {
            // console.log(eventData.data.notifications)
            const dataWithTime = eventData.data.notifications.map(
                (noti) => {
                    return {
                        data: noti,
                        arrive: moment().unix()
                    }
                });
            if (reset) await store.dispatch("fcm/replaceNoti", dataWithTime);
            else await store.dispatch("fcm/addNoti", dataWithTime);
            allNoti = eventData.data.notifications;
        })
        .catch((err) => {
            console.log(err);
            return;
        });
    if (allNoti) return allNoti;
    return null;

};

export const getUnreadNotifications = async function (imeis) {
    await apolloClient.resetStore();
    let allNoti = [];

    const lastRead = await getLastRead({
        userID: store.state.authen.user.id
    });
    // console.log('imeis', imeis);
    await apolloClient
        .query({
            // Query
            query: unreadNofifications,
            variables: {
                imeis,
                timestamp: lastRead
            },
        })
        .then((eventData) => {
            store.dispatch("fcm/setUnread", eventData.data.notifications.length);
        })
        .catch((err) => {
            console.log(err);
            return;
        });
    if (allNoti) return allNoti;
    return null;

};

export const formatMessage = (type, messageObj) => {
    // console.log(messageObj)
    const dateThai = moment.unix(messageObj.timestamp).format('D MMM YYYY k:mm:ss น.');
    switch (type) {
        case 'OVERSPEED':
            // code block
            return `ขับความเร็ว ${messageObj.speed} กม/ชม เมื่อ ${dateThai}`;
        case 'THAI_MCR_SWIPE_IN':
            // code block
            return messageObj.driver_name ? `รูดบัตรเข้าโดย ${messageObj.driver_name} เมื่อ ${dateThai}` : `รูดบัตรเข้าโดย บัตรหมายเลข ${messageObj.mcr_license_no} เมื่อ ${dateThai}`;
        case 'THAI_MCR_SWIPE_OUT':
            // code block
            return messageObj.driver_name ? `รูดบัตรออกโดย ${messageObj.driver_name} เมื่อ ${dateThai}` : `รูดบัตรออกโดย บัตรหมายเลข ${messageObj.mcr_license_no} เมื่อ ${dateThai}`;
        case 'GEOFENCE_ENTER':
            // code block
            return `ขับเข้าสถานี เมื่อ ${dateThai}`;
        case 'GEOFENCE_EXIT':
            // code block
            return `ขับออกสถานีเมื่อ ${dateThai}`;

        default:
            return {};
            // code block
    }

};

export const formatTitle = (type, imei, messageObj) => {
    // console.log('plate', req.app.locals.mapVehicles[data.imei]);
    // console.log(messageObj)
    const vehicle = store.getters["vehicle/vehicleByIMEI"](imei);
    let result = "";
    // const dateThai = moment.unix(data.timestamp).tz('Asia/Bangkok').format('h:mm:ss');
    switch (type) {
        case 'OVERSPEED':
            // code block
            result = `${vehicle.plate_number} ความเร็วเกิน`;
            break
            // console.log(vehicle);

        case 'THAI_MCR_SWIPE_IN':
            // code block
            result = `${vehicle.plate_number} รูดบัตรเข้า`;
            break;

        case 'THAI_MCR_SWIPE_OUT':
            // code block
            result = `${vehicle.plate_number} รูดบัตรออก`;
            break;

        case 'GEOFENCE_ENTER':
            // code block
            result = `${vehicle.plate_number} ขับเข้าสถานี ${messageObj.geofence}`;
            break;

        case 'GEOFENCE_EXIT':
            // code block
            result = `${vehicle.plate_number} ขับออกสถานี ${messageObj.prevGeofence}`;
            break;
        default:
            break;
            // code block
    }
    return result;
}

export const formatIcon = (type) => {
    // console.log('plate', req.app.locals.mapVehicles[data.imei]);
    let result = "";
    // const dateThai = moment.unix(data.timestamp).tz('Asia/Bangkok').format('h:mm:ss');
    switch (type) {
        case 'OVERSPEED':
            // code block
            result = `icon-Iconmetro-meter`;
            // console.log(vehicle);
            break;
        case 'THAI_MCR_SWIPE_IN':
            // code block
            result = `icon-card`;
            break;
        case 'THAI_MCR_SWIPE_OUT':
            // code block
            result = `icon-card`;
            break;
        case 'GEOFENCE_ENTER':
            // code block
            result = `icon-Geotagplace`;
            break;
        case 'GEOFENCE_EXIT':
            // code block
            result = `icon-Geotagplace`;
            break;
        default:
            result = `icon-play1`;
            break;


            // code block
    }
    return result;
}