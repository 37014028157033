<template>
  <a-select
    :class="['select-input', 'input-box']"
    option-filter-prop="children"
    show-search
    showArrow
    :filter-option="filterOption"
    placeholder="เลือกรถ"
    @change="onChangeVehicle"
    :value="selectedVehicle"
  >
    <a-select-option
      v-for="vehicle in vehicles"
      :key="vehicle.id"
      :value="vehicle.id"
    >
      {{ vehicle.plate_number }} ( {{ vehicle.name }} )
    </a-select-option>
  </a-select>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'vehicleSingleSelect',
  props: ['defaultValue'],
  emits: ['onSelect'],

  data() {
    return {
      selectedVehicle: undefined,
    };
  },

  watch: {
    defaultValue(value) {
      this.selectedVehicle = value === '' ? undefined : value;
    },
  },

  mounted() {
    this.selectedVehicle =
      this.defaultValue === '' ? undefined : this.defaultValue;
  },

  computed: {
    ...mapGetters({
      vehicles: 'vehicle/vehicles',
    }),
  },

  methods: {
    onChangeVehicle(id) {
      this.selectedVehicle = id;

      const vehicleObj = this.vehicles.find((vehicle) => vehicle.id === id);
      this.$emit('onSelect', vehicleObj);
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>

<style lang="scss">
.select-input .ant-select-selection--single {
  height: 48px !important;
  &:hover {
    border-color: #523ee8;
  }

  .ant-select-selection__rendered {
    height: 48px !important;
  }
  .ant-select-selection-selected-value {
    padding-top: 8px;
  }
}

.input-box {
  height: 48px;
  width: 100%;

  &:hover {
    border-color: #523ee8 !important;
  }
}
</style>
