import { apolloClient } from '@/vue-apollo';
import eventsTypeByIMEI from '@/graphql/eventsTypeByIMEI.gql';
import moment from '@/lib/time';
import Excel from 'exceljs';
import {
  fontNormal,
  sheetMargin,
  getStatusColor,
  hightlightTableHead,
  blobToFile,
  imeiToPlateNumber,
  formatDate,
  formatDuration,
  hightlightRow,
  geofenceStartEndFromLocation,
  imeiToVehicleName,
  getMCRDriverName,
  generateLogoHeader,
  addLogoCompany,
} from './config';

import * as Sentry from '@sentry/vue';
import store from '@/store';

export const getPTOReport = async (
  eventTypes,
  vehicles,
  imeis,
  start,
  stop,
  geofences,
  dictOfDriver,
  logo
) => {
  await apolloClient.resetStore();
  apolloClient
    .query({
      // Query
      query: eventsTypeByIMEI,
      variables: {
        eventTypes,
        imeis,
        start,
        stop,
      },
    })
    .then(async (eventData) => {
      const reportByVehicle = splitPTOReportByVehicle(
        vehicles,
        eventData.data.eventsTypeByIMEI,
        geofences,
        dictOfDriver
      );
      const strTimerange =
        moment.unix(start).format('D_MMM_YY') +
        '-ถึง-' +
        moment.unix(stop).format('D_MMM_YY');
      const fileName = 'PTO_Report_' + strTimerange;
      const tableHead = [
        'ทะเบียนรถ',
        'name',
        'วัน เวลา เริ่มต้น',
        'วัน เวลา สิ้นสุด',
        'PTO ที่',
        'รวมเวลา',
        'ตำแหน่งเริ่มต้น',
        'ตำแหน่งสิ้นสุด',
        'ละติจูด ลองจิจูด',
        'ผู้ใช้รถ',
        'timestamp เริ่มต้น',
        'timestamp สิ้นสุด',
      ];
      const columnsSpec = [
        {
          key: 'plate_number',
          width: 10,
          style: {
            font: fontNormal,
          },
        },
        {
          key: 'name',
          width: 22,
          style: {
            numFmt: '0',
          },
        },
        {
          key: 'start',
          width: 20,
        },
        {
          key: 'stop',
          width: 20,
        },
        {
          key: 'pto',
          width: 8,
        },
        {
          key: 'totalTime',
          width: 8,
        },
        {
          key: 'reversegeolocationstart',
          width: 40,
        },
        {
          key: 'reversegeolocationend',
          width: 40,
        },
        {
          key: 'geolocation',
          width: 30,
        },
        {
          key: 'driver',
          width: 15,
        },
        {
          key: 'timeStampStart',
          width: 15,
        },
        {
          key: 'timeStampStop',
          width: 15,
        },
      ];
      genPTOExcel({
        fileName,
        reportByVehicle,
        tableHead,
        columnsSpec,
        statusColumn: null,
        logo,
      });
    })
    .catch((err) => {
      console.log(err);
      Sentry.captureException(err);
      store.dispatch('loading/setReportLoading', false);
      return;
    });
};

export const genPTOExcel = async ({
  fileName,
  reportByVehicle,
  tableHead,
  columnsSpec,
  statusColumn,
  logo,
}) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: 'FF41C173',
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
    },
  };

  let imageLogoId;

  if (logo && logo.url) {
    const ext = logo.ext.substring(1);
    const myBase64Image = await generateLogoHeader(logo);
    imageLogoId = workbook.addImage({
      base64: myBase64Image,
      extension: ext,
    });
  }

  if (Object.keys(reportByVehicle).length > 0)
    Object.keys(reportByVehicle).forEach((imei) => {
      if (reportByVehicle[imei].length > 0) {
        let startingRow = 1;
        const plateNo = reportByVehicle[imei][0][0];
        const sheet = workbook.addWorksheet(plateNo, pageProperties);
        sheet.pageSetup.margins = sheetMargin;
        sheet.columns = columnsSpec;
        const totalTimeSec =
          reportByVehicle[imei][reportByVehicle[imei].length - 1][12];
        const totalTimeStr = moment('1970-01-01')
          .startOf('day')
          .seconds(totalTimeSec)
          .format('HH:mm:ss');

        const metaRow1 = `รายงาน PTO ของ รถทะเบียน ${plateNo} เวลาเริ่ม ${
          reportByVehicle[imei][0][2]
        } ถึง ${reportByVehicle[imei][reportByVehicle[imei].length - 1][3]}`;
        const metaRow2 = `รวมเวลา ${totalTimeStr} ชม.:นาที:วินาที`;

        if (logo && logo.url) {
          startingRow = 2;
          addLogoCompany(sheet, imageLogoId);
        }

        sheet.addRow([metaRow1]);
        sheet.addRow([metaRow2]);

        sheet.addRow([]);

        sheet.addRow(tableHead);
        sheet.addRows(reportByVehicle[imei]);
        if (statusColumn) {
          sheet.getColumn(statusColumn).eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: getStatusColor(cell.value),
            };
          });
        }
        hightlightTableHead(sheet, startingRow + 3);
        hightlightRow(sheet, startingRow);
        hightlightRow(sheet, startingRow + 1);
      }
    });
  else {
    workbook.addWorksheet('ไม่พบข้อมูล', pageProperties);
  }

  blobToFile(workbook, fileName);
};

const splitPTOReportByVehicle = (vehicles, report, geofences, dictOfDriver) => {
  let result = {};
  let accumulateDuration = {};
  report.forEach((row) => {
    const mcr_license_no = row.positionDataStart.mcr_license_no;
    const mcr_driver_name = row.positionDataStart.mcr_driver_name;
    result[row.imei] = result[row.imei] || [];
    accumulateDuration[row.imei] = accumulateDuration[row.imei] || 0;
    const resLoc = geofenceStartEndFromLocation(geofences, row);

    accumulateDuration[row.imei] =
      parseInt(accumulateDuration[row.imei]) +
      parseInt(row.stopTime - row.startTime);
    result[row.imei].push([
      imeiToPlateNumber(vehicles, row.imei),
      imeiToVehicleName(vehicles, row.imei),
      formatDate(row.startTime),
      formatDate(row.stopTime),
      row.pto,
      formatDuration(row.startTime, row.stopTime),
      resLoc.start,
      resLoc.end,
      `${row.lat / 10000000}, ${row.lng / 10000000}`,
      typeof dictOfDriver != 'undefined' && mcr_license_no in dictOfDriver
        ? dictOfDriver[mcr_license_no].name
        : getMCRDriverName(mcr_license_no, mcr_driver_name),
      row.startTime,
      row.stopTime,
      accumulateDuration[row.imei],
    ]);
  });
  return result;
};

export default getPTOReport;
