import {
  apolloClient
} from "../../strapi-apollo";
const state = () => ({
  routes: [],
  selectedRoute: {},
  showRouteInfo: false,
});
import createRoute from "@/graphql/createRoute.gql";
import updateRoute from "@/graphql/updateRoute.gql";
import deleteRoute from "@/graphql/deleteRoute.gql";
import routes from "@/graphql/routes.gql";

// getters
const getters = {
  routes: (state) => state.routes,
};

// actions
const actions = {
  async createRoute({
    commit
  }, {
    Name,
    positions,
    org,
  }) {
    // console.log('commit', commit)
    await apolloClient.resetStore();
    const result = await apolloClient
      .mutate({
        // Query
        mutation: createRoute,
        variables: {
          input: {
            data: {
              Name,
              Positions: positions,
              org,
            },
          },
        },
      })
      .then(async (eventData) => {
        commit("SELECT_ROUTE", eventData.data.createRouting.routing);
        commit("SHOW_ROUTE_INFO", true);
        return eventData;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
    return result;
    // .catch(err => {
    //   console.log(err);
    //   return false;
    // });
    // commit("SELECT_ROUTE", createRouteResult.data.createRoute);
    // commit("SHOW_ROUTE_INFO", true);
  },

  async updateRoute({
    commit
  }, {
    routeID,
    Name,
    positions,
    org,
  }) {
    await apolloClient.resetStore();
    const result = await apolloClient
      .mutate({
        // Query
        mutation: updateRoute,
        variables: {
          input: {
            where: {
              id: routeID
            },
            data: {
              Name,
              Positions: positions,
              org,
            },
          },
        },
      })
      .then(async (eventData) => {
        commit("SELECT_ROUTE", eventData.data.updateRouting.routing);
        commit("SHOW_ROUTE_INFO", true);
        return eventData;
      })
      .catch((err) => {
        console.log(err);
        return false;
      })


    return result;

  },

  async deleteRoute({
    commit
  }, routeID) {
    // console.log('commit', commit)
    await apolloClient.resetStore();
    const result = await apolloClient
      .mutate({
        // Query
        mutation: deleteRoute,
        variables: {
          input: {
            where: {
              id: routeID,
            },
          },
        },
      })
      .then(async (eventData) => {
        // commit("SELECT_ROUTE", eventData.data.createRoute.route);
        commit("SHOW_ROUTE_INFO", false);
        return eventData;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
    return result;
    // .catch(err => {
    //   console.log(err);
    //   return false;
    // });
    // commit("SELECT_ROUTE", createRouteResult.data.createRoute);
    // commit("SHOW_ROUTE_INFO", true);
  },
  async getAllRoutes({
    commit
  }, orgs) {
    await apolloClient.resetStore();
    const allRoute = await apolloClient
      .query({
        // Query
        query: routes,
        variables: {
          orgs,
        },
      })
      .then(async (eventData) => {
        // console.log(eventData)
        return eventData;
      })
      .catch((err) => {
        console.log(err);
        return;
      });
    commit("ALL_ROUTES", allRoute.data.routings);
  },
  selectRoute({
    commit
  }, payload) {
    commit("SELECT_ROUTE", payload);
  },
  showRouteBottomInfo({
    commit
  }, payload) {
    commit("SHOW_ROUTE_INFO", payload);
  },
  resetState({
    commit
  }) {
    commit("RESET_STATE");
  },
};
// mutations
const mutations = {
  ALL_ROUTES(state, payload) {
    state.routes = payload;
  },
  SELECT_ROUTE(state, payload) {
    state.selectedRoute = payload;
  },
  SHOW_ROUTE_INFO(state, payload) {
    state.showRouteInfo = payload;
  },
  RESET_STATE(state) {
    state.showRouteInfo = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};