<template>
  <defaultLayout>
    <div class="trip">
      <historyDrawer @setCenterPath="setCenterPath" />
      <div
        :class="[showTripInfo ? 'google-map-tripinfo' : 'google-map']"
        :style="getWidthOfMap()"
      >
        <div class="google-map-toolbar">
          <div
            v-if="showMapOnScreen === true"
            class="toggle-geofence-button"
            @click="toggleGeofence"
          >
            <a-checkbox :checked="showGeofence" /> สถานี
          </div>
          <div
            v-if="showMapOnScreen === true"
            class="toggle-direction-button"
            @click="toggleDirection"
          >
            <a-checkbox :checked="showDirection" /> ทิศ
          </div>
          <div
            v-if="showMapOnScreen === true"
            class="toggle-pto-button"
            @click="togglePTO"
          >
            <a-checkbox :checked="showPTO" /> pto
          </div>
        </div>
        <template v-if="showMapOnScreen === true">
          <div class="all-position-container" v-if="showGeofence">
            <h4>{{ allPositionTitle }}</h4>
            <div
              :key="`${index}`"
              v-for="(pos, index) in positionList"
              class="position"
            >
              <a-badge
                :count="index + 1"
                :numberStyle="{
                  backgroundColor: '#444444',
                  fontSize: '1.2rem',
                }"
              />
              {{ `${pos.lat}, ${pos.lng}` }}
            </div>
          </div>
          <GmapMap
            key="mapInHistoryPage"
            gestureHandling="greedy"
            v-if="gmapDisplay === true"
            :center="{ lat: 13.7460067, lng: 100.5440535 }"
            :zoom="this.mapZoom"
            map-type-id="roadmap"
            style="width: 100%; height: calc(99vh)"
            ref="HisotryGoogleMap"
            @drag="startDragMap"
            @bounds_changed="startDragMap"
            @idle="endDragMap"
            @zoom_changed="zoomLevelChange"
            :options="{
              zoomControl: true,
              mapTypeControl: true,
              scaleControl: false,
              streetViewControl: true,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUi: true,
              zoomControlOptions: {
                position: 3,
              },
              streetViewControlOptions: {
                position: 3,
              },
              clickableIcons: true,
            }"
          >
            <!-- Route Line  -->
            <template v-if="paths.position.length > 0 && gmapDisplay === true">
              <template v-for="(positionDetail, i) in paths.position">
                <gmap-polyline
                  v-if="positionDetail.path.length > 0"
                  :path="positionDetail.path"
                  :editable="false"
                  :draggable="false"
                  :clickable="!measureToolIsEnable"
                  ref="vehicleHistory"
                  :key="`${'line' + Math.random() + i}`"
                  :options="{
                    strokeColor: positionDetail.lineColor,
                    strokeWeight: 3,
                  }"
                  @click="lineClick($event)"
                  @rightclick="rightClickOnPolyLine($event)"
                ></gmap-polyline>
              </template>
            </template>

            <!-- Driver Marker -->
            <template v-for="(position, i) in paths.marker">
              <template>
                <gmap-custom-marker
                  :key="`${position.timestamp + i + Math.random()}`"
                  :marker="{
                    lat: position.lat,
                    lng: position.lng,
                  }"
                  alignment="center"
                >
                  <a-popover>
                    <template slot="content">
                      {{
                        displayDriverName(
                          position.licenseNo,
                          position.driverName,
                          getDriverNameByLicenseNo(position.licenseNo)
                        )
                      }}
                    </template>
                    <img
                      src="../assets/driver-ic.svg"
                      class="driver-ic"
                      :style="`background-color:${position.color}`"
                    />
                  </a-popover>
                </gmap-custom-marker>
              </template>
            </template>

            <!-- Direction Icon -->
            <template v-if="showDirection">
              <template v-for="(position, indexDir) in positionsDirection">
                <template
                  v-if="
                    mapMoving === false &&
                    directionShowLevel(indexDir) &&
                    directionIsInBound(
                      position.latitude / 10000000,
                      position.longitude / 10000000
                    )
                  "
                >
                  <gmap-custom-marker
                    :key="`${position.timestamp + Math.random()}`"
                    :marker="{
                      lat: position.latitude / 10000000,
                      lng: position.longitude / 10000000,
                    }"
                    alignment="center"
                  >
                    <a-icon
                      type="caret-up"
                      :class="['direction-arrow', position.engineStatus]"
                      v-bind:style="{
                        transform: getDirectionClass(position.angle),
                      }"
                    />
                  </gmap-custom-marker>
                </template>
              </template>
            </template>
            <!--- PTO --->
            <template v-if="showPTO">
              <template v-for="position in tripTable">
                <template
                  v-if="
                    gmapDisplay === true &&
                    mapMoving === false &&
                    position.pto1 === 1 &&
                    directionIsInBound(
                      position.latitude / 10000000,
                      position.longitude / 10000000
                    )
                  "
                >
                  <gmap-custom-marker
                    :key="'pto-icon' + position.timestamp"
                    :marker="{
                      lat: position.latitude / 10000000,
                      lng: position.longitude / 10000000,
                    }"
                    alignment="center"
                  >
                    <div class="pto-status">PTO1</div>
                  </gmap-custom-marker>
                </template>
              </template>
            </template>
            <!-- Car Icon -->
            <template
              v-if="
                selectedPosition && gmapDisplay === true && mapMoving === false
              "
            >
              <gmap-custom-marker
                :key="`position-${selectedPosition.timestamp + Math.random()}`"
                :marker="{
                  lat: selectedPosition
                    ? selectedPosition.latitude / 10000000
                    : 0,
                  lng: selectedPosition
                    ? selectedPosition.longitude / 10000000
                    : 0,
                }"
                :offsetX="-20"
                :offsetY="-20"
              >
                <img
                  :class="[
                    'device-marker',
                    'vehicle-status',
                    getFilterClass(
                      selectedPosition
                        ? selectedPosition.engineStatus
                        : 'ENGINE_STOP'
                    ),
                  ]"
                  src="../assets/top-car4.png"
                  v-bind:style="{
                    transform: getDirectionClass(
                      selectedPosition ? selectedPosition.angle : 0
                    ),
                  }"
                />
              </gmap-custom-marker>
            </template>

            <template
              v-if="
                showGeofence === true && mapMoving === false && mapZoom >= 16
              "
            >
              <!-- Geofence -->
              <GmapCircle
                v-for="(geofence, index) in geofencesCircle"
                :key="`geofence-circle-${index}`"
                :center="geofence.Positions[0]"
                :radius="geofence.radius"
                :visible="showGeofence"
                :options="{
                  fillColor: '#00c246',
                  fillOpacity: 0.3,
                  strokeColor: '#00c246',
                  strokeOpacity: 0.8,
                }"
              ></GmapCircle>
              <!-- Geofence -->
              <GmapPolygon
                v-for="(geofence, index) in geofencesPolygon"
                :key="`geofence-polygon-${index}`"
                :path="geofence.Positions"
                :visible="showGeofence"
                :options="{
                  fillColor: '#00c246',
                  fillOpacity: 0.3,
                  strokeColor: '#00c246',
                  strokeOpacity: 0.8,
                }"
              />
              <!-- Geofence -->
              <gmap-custom-marker
                v-for="(geofence, index) in geofencesList"
                :key="`geofence-name-${index}`"
                :marker="geofence.Positions[0]"
                alignment="center"
                v-show="showGeofence"
              >
                <div class="geofence-name" v-show="showGeofence">
                  {{ geofence.name }}
                </div>
              </gmap-custom-marker>
            </template>
            <template
              v-if="
                showGeofence === true && mapMoving === false && mapZoom < 16
              "
            >
              <gmap-marker
                :key="index"
                v-for="(m, index) in geofencesList"
                :position="{
                  lat: parseFloat(m.Positions[0].lat),
                  lng: parseFloat(m.Positions[0].lng),
                }"
                :clickable="true"
                :draggable="false"
                @mouseover="showByIndex = index"
                @mouseout="showByIndex = null"
                @click="setCenterPathGeofence(m)"
              >
                <gmap-info-window :opened="showByIndex === index">
                  สถานี {{ m.name }}
                </gmap-info-window>
              </gmap-marker>
            </template>
          </GmapMap>
        </template>
      </div>
    </div>
    <a-modal
      :visible="createGeofenceModalVisible"
      :confirm-loading="saveGeofenceLoading"
      @ok="createGeofenceSubmit"
      @cancel="cancelCreateGeofence"
    >
      <h2>สร้างสถานีจากตำแหน่ง {{ selectedVehicleTrip.plate_number }}</h2>
      <a-form layout="inline" :form="form">
        <a-row>
          <a-col :span="24">
            <a-input
              placeholder="ชื่อสถานี"
              :maxLength="30"
              v-model="geofenceName"
            />
          </a-col>
        </a-row>
        <a-row style="margin-top: 10px">
          <a-col :span="24">
            <a-input
              type="number"
              addon-before="รัศมี"
              addon-after="เมตร"
              v-model="geofenceRadius"
              :min="10"
              :max="500.0"
              :step="10"
            />
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <a-drawer
      v-if="showGeofence"
      placement="bottom"
      :closable="false"
      :mask="false"
      :visible="true"
      :maskClosable="true"
      :height="190"
      wrapClassName="add-geofence-drawer"
      :destroyOnClose="true"
    >
      <div>
        <div class="add-position">
          <a-row type="flex" justify="space-around" align="top" :gutter="16">
            <a-col :span="12">
              <h2>สร้างสถานี</h2>
            </a-col>
            <a-col :span="12" style="text-align: right">
              <a-radio-group
                v-model="geofenceType"
                defaultValue="circle"
                buttonStyle="solid"
                :readOnly="true"
                @change="resetPositionList"
                style="margin-right: 30px"
              >
                <a-radio-button value="circle">วงกลม</a-radio-button>
                <a-radio-button value="polygon">โพลีกอน</a-radio-button>
              </a-radio-group>
            </a-col>
            <a-col :span="10">
              <a-row>
                <a-col :span="24">
                  <a-input
                    placeholder="ชื่อสถานี"
                    v-model="geofenceName"
                    style="margin-top: 10px"
                  />
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <a-input
                    placeholder="รัศมี"
                    v-model="geofenceRadius"
                    suffix="เมตร"
                    :readOnly="true"
                    size="small"
                    style="margin-top: 10px"
                    v-if="geofenceType == 'circle'"
                  />
                </a-col>
                <a-col :span="24">
                  <div style="margin-top: 8px; text-align: left">
                    <a-button
                      type="default"
                      html-type="submit"
                      style="margin-right: 10px"
                      @click="resetPositionList"
                      >เริ่มใหม่</a-button
                    >
                    <a-button
                      type="primary"
                      html-type="submit"
                      style="margin-bottom: 10px"
                      :disabled="!validateGeofence"
                      @click="saveGeofence"
                      :loading="saveGeofenceLoading"
                      >บันทึกสถานี</a-button
                    >
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="14" style="text-align: right">
              <div
                class="add-position-container"
                v-show="showAddPosition && geofenceType == 'circle'"
              >
                <h4>{{ addPositionTitle }}</h4>

                <a-input
                  v-model="clickedLat"
                  size="small"
                  :readOnly="true"
                  placeholder="ละติจูด"
                ></a-input>

                <a-input
                  v-model="clickedLng"
                  size="small"
                  :readOnly="true"
                  placeholder="ลองจิจูด"
                ></a-input>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-drawer>
  </defaultLayout>
</template>

<script>
import { isMobile } from 'mobile-device-detect';
import defaultLayout from '@/views/layouts/defaultLayout.vue';
import historyDrawer from '@/views/components/history/historyDrawer';
import { mapState, mapGetters, mapActions } from 'vuex';
import { getVehicles } from '../lib/vehicle';
import CustomMarker from '@/views/components/customMarker';
import { getGoogleMapsAPI } from 'vue2-google-maps';
import MeasureTool from 'measuretool-googlemaps-v3';
import { displayDriverName } from '@/lib/device';

const geolib = require('geolib');
export default {
  name: 'history',
  components: {
    defaultLayout,
    historyDrawer,
    'gmap-custom-marker': CustomMarker,
  },
  data() {
    return {
      measureToolIsEnable: false,
      showByIndex: null,
      selectedShape: null,
      drawingManager: null,
      positionList: [],
      clickedLat: '',
      clickedLng: '',
      geofenceType: 'circle',
      geofenceName: '',
      geofenceRadius: 200,
      boundRect: [],
      showBoundryGeofence: false,
      mapMoving: false,
      mapZoom: 12,
      path2: [
        { lat: 1.33, lng: 103.75 },
        { lat: 1.43, lng: 103.85 },
      ],
      createGeofenceModalVisible: false,
      form: this.$form.createForm(this, { name: 'createGeofenceForm' }),
      showGeofence: false,
      showDirection: false,
      showPTO: false,
      geofencesCircle: [],
      geofencesPolygon: [],
      geofencesList: [],
      gmapDisplay: true,

      //MD
      measureTool: null,
    };
  },
  computed: mapState({
    google: getGoogleMapsAPI,
    selectedGroup: (state) => state.authen.selectedGroup,
    user: (state) => state.authen.user,
    ...mapGetters({
      vehicles: 'vehicle/vehicles',
      paths: 'position/historyPath',
      positionsDirection: 'position/direction',
      tripTable: 'position/tripTable',
      positionOfTrip: 'position/positionOfTrip',
      driverByLicenseNo: 'driver/driverByLicenseNo',
    }),
    showMapOnScreen: (state) => state.position.showMapOnScreen,
    selectedVehicleTrip: (state) => state.trip.selectedVehicleTrip,
    saveGeofenceLoading: (state) => state.loading.saveGeofenceLoading,
    positions: (state) => state.position.positions,
    showTripInfo: (state) => state.trip.showTripInfo,
    selectedPosition: (state) => state.trip.selectedPosition,
    geofenceData: (state) => state.geofence.geofences,
    allPositionTitle() {
      if (this.geofenceType == 'circle') {
        return this.positionList.length > 0 ? 'ศูนย์กลางสถานี' : '';
      } else {
        console.log(this.geofenceType, this.positionList.length);
        return this.positionList.length > 0
          ? 'ตำแหน่งทั้งหมด'
          : 'คลิก + เพื่อเพิ่มตำแหน่ง';
      }
    },
    addPositionTitle() {
      if (this.geofenceType == 'circle') {
        return 'ศูนย์กลาง';
      } else {
        return 'เพิ่มตำแหน่งใหม่';
      }
    },
    showAddPosition() {
      if (this.geofenceType == 'circle' && this.positionList.length > 0)
        return false;
      else if (this.geofenceType == 'polygon' && this.positionList.length >= 20)
        return false;
      return true;
    },
    validateGeofence() {
      if (this.geofenceName.length < 0) {
        return false;
      }
      if (this.positionList.length <= 0) {
        return false;
      }
      if (this.geofenceType == 'polygon') {
        if (this.positionList.length < 3) return false;
      }
      if (this.geofenceType == 'circle') {
        if (
          this.geofenceRadius.length < 0 ||
          isNaN(this.geofenceRadius) ||
          this.geofenceRadius <= 0
        )
          return false;
      }
      return true;
    },
  }),
  mounted() {
    let thisObj = this;
    if (this.vehicles.length <= 0) {
      getVehicles(
        thisObj.user.customer_groups.map((group) => {
          return group._id;
        })
      );
    }

    this.setBoundOfMap();

    //Measure distance
    this.$refs.HisotryGoogleMap.$mapPromise.then((map) => {
      thisObj.measureTool = new MeasureTool(map, {
        contextMenu: true,
      });

      thisObj.measureTool.addListener('measure_start', function () {
        thisObj.measureToolIsEnable = true;
      });
      thisObj.measureTool.addListener('measure_end', function () {
        thisObj.measureToolIsEnable = false;
      });
    });
  },
  beforeDestroy() {
    this.measureTool = null;
    // this.$refs.googleMap.$el.remove();
    this.resetPositionState();
    this.resetVehicleState();
  },
  methods: {
    ...mapActions({
      getAllGeofences: 'geofence/getAllGeofences',
      selectPosition: 'trip/selectPosition',
      resetVehicleState: 'vehicle/resetState',
      resetPositionState: 'position/resetState',
      setSaveGeofenceLoading: 'loading/setSaveGeofenceLoading',
      resetGeofenceState: 'geofence/resetState',
      createGeofence: 'geofence/createGeofence',
    }),
    rightClickOnPolyLine(e) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      // populate yor box/field with lat, lng
      console.log('Lat=' + lat + '; Lng=' + lng);
      // this.measureTool.start();
      // this.$refs.HisotryGoogleMap.$mapPromise.then(() => {
      //   this.measureTool.start();
      // });
    },
    resetPositionList() {
      this.geofenceName = '';
      this.geofenceRadius = 0;
      this.positionList = [];
      if (this.selectedShape) {
        this.selectedShape.setMap(null);
        this.selectedShape.setEditable(false);
      }
    },
    directionIsInBound(lat, lng) {
      const isIn = geolib.isPointInPolygon(
        {
          latitude: lat,
          longitude: lng,
        },
        this.boundRect
      );
      // console.log(indexDir, isIn)
      return isIn;
    },
    directionShowLevel(idx) {
      // console.log('directionShowLevel', this.mapZoom, idx)
      if (this.mapZoom >= 13) {
        return true;
      } else if (this.mapZoom > 10 && this.mapZoom < 13) {
        if (idx % 3 === 0) {
          return true;
        } else {
          return false;
        }
      } else if (this.mapZoom > 0 && this.mapZoom <= 10) {
        if (idx % 6 === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    lineClick(e) {
      if (!this.measureToolIsEnable) {
        this.currentSelectLine = {};
        let latitude = e.latLng.lat();
        let longitude = e.latLng.lng();
        // console.log(latitude, longitude)
        const nearPlace = geolib.findNearest(
          { latitude, longitude },
          this.positionOfTrip
        );
        this.currentSelectLine = nearPlace;

        let pos = nearPlace;
        pos.latitude = pos.latitude * 10_000_000;
        pos.longitude = pos.longitude * 10_000_000;
        this.selectPosition(pos);
      }
    },
    loadMapDrawingManager() {
      let self = this;
      this.$refs.HisotryGoogleMap.$mapPromise.then(() => {
        // console.log(map, window.google.maps.ControlPosition.TOP_CENTER);
        self.drawingManager = new window.google.maps.drawing.DrawingManager({
          drawingControl: true,
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [
              window.google.maps.drawing.OverlayType.POLYGON,
              window.google.maps.drawing.OverlayType.CIRCLE,
            ],
          },
          polygonOptions: {
            fillColor: '#0099FF',
            fillOpacity: 0.2,
            strokeColor: '#AA2143',
            strokeWeight: 4,
            editable: true,
          },
          circleOptions: {
            fillColor: '#ffff00',
            fillOpacity: 0.2,
            strokeWeight: 3,
            clickable: false,
            editable: true,
            zIndex: 1,
          },
        });
        self.drawingManager.setMap(this.$refs.HisotryGoogleMap.$mapObject);
        window.google.maps.event.addListener(
          self.drawingManager,
          'drawingmode_changed',
          function () {
            // console.log(drawingManager.drawingMode);
            if (self.drawingManager.drawingMode === 'circle') {
              self.geofenceType = 'circle';
            } else if (self.drawingManager.drawingMode === 'polygon') {
              self.geofenceType = 'polygon';
            }
          }
        );

        window.google.maps.event.addListener(
          self.drawingManager,
          'overlaycomplete',
          function (event) {
            // Get overlay paths
            // let paths = event.overlay.getPaths().getArray();
            // Remove overlay from map
            // event.overlay.setMap(null);

            // Disable drawingManager
            self.drawingManager.setDrawingMode(null);

            if (event.type !== window.google.maps.drawing.OverlayType.MARKER) {
              let newShape = event.overlay;
              newShape.type = event.type;
              window.google.maps.event.addListener(
                newShape,
                'click',
                function () {
                  self.selectedShape = newShape;
                }
              );

              self.selectedShape = newShape;
            }

            // Create Polygon
            if (event.type === 'polygon') {
              let paths = event.overlay.getPath().getArray();
              self.positionList = [];
              let pts = [];
              paths.forEach((path) => {
                const lat = parseFloat(path.lat().toFixed(6));
                const lng = parseFloat(path.lng().toFixed(6));
                const pt = new window.google.maps.LatLng(lat, lng);
                pts.push(pt);
                self.positionList.push({ lat: lat, lng: lng });
              });

              event.overlay.setMap(null);
              //drawingManager.setDrawingMode(null);
              self.createPolygon(pts);
            } else if (event.type === 'circle') {
              let center = [
                event.overlay.center.lat(),
                event.overlay.center.lng(),
              ];
              let radius = event.overlay.radius;
              event.overlay.setMap(null);
              self.drawingManager.setDrawingMode(null);
              // console.log(center, radius);
              self.geofenceRadius = parseInt(radius.toFixed(0));
              self.clickedLat = parseFloat(
                event.overlay.center.lat().toFixed(6)
              );
              self.clickedLng = parseFloat(
                event.overlay.center.lng().toFixed(6)
              );
              self.positionList[0] = {
                lat: self.clickedLat,
                lng: self.clickedLng,
              };
              // Remove overlay from map
              // Create circle
              self.createCircle(center, radius);
            }
          }
        );
      });
    },
    createPolygon(paths) {
      let self = this;
      this.$refs.HisotryGoogleMap.$mapPromise.then((map) => {
        var polygon = new window.google.maps.Polygon({
          paths: paths,
          fillColor: '#ffff00',
          fillOpacity: 0.2,
          strokeWeight: 3,
          draggable: true,
          editable: true,
          // map: map,
        });
        console.log('path', paths, polygon, map);
        polygon.setMap(map);

        self.selectedShape = polygon;
      });
    },
    createCircle(center, radius) {
      let self = this;
      this.$refs.HisotryGoogleMap.$mapPromise.then((map) => {
        var circle = new window.google.maps.Circle({
          fillColor: '#ffff00',
          fillOpacity: 0.2,
          strokeWeight: 3,
          draggable: true,
          editable: true,
          map: map,
          center: {
            lat: parseFloat(center[0]),
            lng: parseFloat(center[1]),
          },
          radius: radius,
        });

        window.google.maps.event.addListener(
          circle,
          'radius_changed',
          function () {
            // console.log('circle radius changed');
            self.geofenceRadius = parseInt(circle.getRadius().toFixed(0));
          }
        );

        window.google.maps.event.addListener(
          circle,
          'center_changed',
          function () {
            // console.log('circle center changed', circle.getCenter().lat());
            self.clickedLat = parseFloat(circle.getCenter().lat().toFixed(6));
            self.clickedLng = parseFloat(circle.getCenter().lng().toFixed(6));
            self.positionList[0] = {
              lat: self.clickedLat,
              lng: self.clickedLng,
            };
          }
        );
        self.selectedShape = circle;
      });
    },
    getDirectionClass(degree) {
      const direction = degree / 100;
      return `rotate(${direction}deg)`;
    },
    setCenterPath() {
      if (this.showMapOnScreen === true) {
        this.$refs.HisotryGoogleMap.$mapPromise.then((map) => {
          let bounds = new window.google.maps.LatLngBounds();
          this.paths.position.forEach((positionDetail) => {
            positionDetail.path.forEach((pos) => {
              bounds.extend(pos);
            });
          });
          map.fitBounds(bounds);
          map.panTo(bounds.getCenter());
          // map.setZoom(16);
        });
      }
    },

    getWidthOfMap() {
      if (isMobile) {
        return 'width: calc(100vw - 540px)';
      } else {
        return 'width: calc(100vw - 860px)';
      }
    },
    setCenterPathGeofence(geofence) {
      if (this.showMapOnScreen === true) {
        if (geofence.type == 'circle') {
          this.$refs.HisotryGoogleMap.$mapPromise.then((map) => {
            const circle = new window.google.maps.Circle({
              map: map,
              radius: geofence.radius, // 3000 km
              center: {
                lat: parseFloat(geofence.Positions[0].lat),
                lng: parseFloat(geofence.Positions[0].lng),
              },
              fillColor: '#fff',
              fillOpacity: 0.0,
              strokeColor: '#fff',
              strokeOpacity: 0.0,
            });
            var bounds = new window.google.maps.LatLngBounds();
            bounds.union(circle.getBounds());
            map.fitBounds(bounds);
            map.panTo(circle.getBounds().getCenter());
          });
        } else {
          this.$refs.HisotryGoogleMap.$mapPromise.then((map) => {
            let bounds = new window.google.maps.LatLngBounds();
            for (let i = 0; i < geofence.Positions.length; i++) {
              bounds.extend(geofence.Positions[i]);
            }
            map.fitBounds(bounds);
            map.panTo(bounds.getCenter());
            map.setZoom(17);
          });
        }
      }
    },
    getFilterClass(status) {
      if (status == 'VEHICLE_MOVING') return 'running';
      else if (status == 'ENGINE_IDLE') return 'idle';
      else if (status == 'OVER_SPEED') return 'overspeed';
      else return 'stop';
    },
    async toggleGeofence() {
      //////// GA /////////
      this.$gtag.event('history_google-map-geofences_displayed');
      ////////////////////
      this.showGeofence = !this.showGeofence;
      if (this.showGeofence) {
        // console.log('this.geofenceData.length', this.geofenceData.length);
        if (this.geofenceData.length <= 0) {
          await this.getAllGeofences([this.selectedGroup.id]);
          // console.log('getAllGeofences', this.selectedGroup.id)
        }
        this.setGeofenceData();
        this.loadMapDrawingManager();
      } else {
        this.drawingManager.setMap(null);
      }
    },
    toggleDirection() {
      //////// GA /////////
      this.$gtag.event('history_google-map-direction_displayed');
      ////////////////////
      this.showDirection = !this.showDirection;
    },
    togglePTO() {
      //////// GA /////////
      this.$gtag.event('history_google-map-PTO_displayed');
      ////////////////////
      this.showPTO = !this.showPTO;
    },
    async createGeofenceSubmit() {
      // console.log(this.selectedVehicle, this.geofenceName, this.geofenceRadius);
      // console.log(this.selectedPosition);
      if (
        !(
          this.selectedPosition &&
          this.geofenceName.length > 0 &&
          this.geofenceRadius > 0
        )
      ) {
        this.$notification['error']({
          message: 'ข้อมูลสถานีไม่ถูกต้อง',
          description: 'กรุณาตรวจสอบข้อมูลสถานี และมีพาหนะที่กำลังเลือกอยู่',
        });
        return;
      }

      this.setSaveGeofenceLoading(true);

      let geojson = {
        type: 'Point',
        coordinates: [
          this.selectedPosition.longitude / 10000000,
          this.selectedPosition.latitude / 10000000,
        ],
      };
      await this.createGeofence({
        name: this.geofenceName,
        type: 'circle',
        radius: parseFloat(this.geofenceRadius),
        org: this.selectedGroup.id,
        positions: [
          {
            lat: this.selectedPosition.latitude / 10000000,
            lng: this.selectedPosition.longitude / 10000000,
          },
        ],
        geojson,
      })
        .then(async (saveResult) => {
          if (saveResult) {
            this.setSaveGeofenceLoading(false);
            this.geofenceName = '';
            this.geofenceRadius = 0.0;
            this.createGeofenceModalVisible = false;

            await this.getAllGeofences([this.selectedGroup.id]);

            this.$notification['success']({
              message: 'บันทึกข้อมูลเรียบร้อยแล้ว',
              description:
                'ข้อมูสถานีถูกบันทึกเรียบร้อยแล้ว การประมวลผลการเข้าออกสถานีจะใช้เวลาหลังจากสร้างสถานี 5-10 นาที',
            });
          } else {
            this.$notification['error']({
              message: 'เกิดข้อผิดพลาด',
              description: 'ไม่สามารถบันทึกข้อมูลสถานีได้ กรุณาลองอีกครั้ง',
            });
            this.setSaveGeofenceLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notification['error']({
            message: 'เกิดข้อผิดพลาด',
            description: 'ไม่สามารถบันทึกข้อมูลสถานีได้ กรุณาลองอีกครั้ง',
          });
          this.setSaveGeofenceLoading(false);
        });
    },
    cancelCreateGeofence() {
      this.createGeofenceModalVisible = false;
    },
    setGeofenceData() {
      this.geofencesCircle = [];
      this.geofencesPolygon = [];
      this.geofencesList = [];
      let tmpGeofenceList = [];
      let tmpGeofenceCircleList = [];
      let tmpGeofencePolygonList = [];
      this.$nextTick(() => {
        // console.log('this.showGeofence', this.showGeofence)
        if (this.showGeofence === true) {
          // console.log('all setGeofenceData', this.geofenceData.length)
          for (const k in this.geofenceData) {
            const geofence = this.geofenceData[k];
            let isIn = false;
            if (this.boundRect.length > 0) {
              if (geofence.type == 'polygon') {
                let centerPolygon = geolib.getCenterOfBounds(
                  geofence.Positions
                );
                isIn = geolib.isPointInPolygon(
                  {
                    latitude: centerPolygon.latitude,
                    longitude: centerPolygon.longitude,
                  },
                  this.boundRect
                );
              } else {
                isIn = geolib.isPointInPolygon(
                  {
                    latitude: geofence.Positions[0].lat,
                    longitude: geofence.Positions[0].lng,
                  },
                  this.boundRect
                );
              }
            } else {
              isIn = true;
            }
            if (isIn) {
              if (geofence.type == 'circle') {
                tmpGeofenceCircleList.push(geofence);
              } else if (geofence.type == 'polygon') {
                tmpGeofencePolygonList.push(geofence);
              }
              tmpGeofenceList.push(geofence);
            }
          }
          // console.log('circle',this.geofencesCircle);
          // console.log('polygon', this.geofencesPolygon);
          // console.log('final all',tmpGeofenceList.length);
          this.geofencesList = tmpGeofenceList;
          this.geofencesPolygon = tmpGeofencePolygonList;
          this.geofencesCircle = tmpGeofenceCircleList;
        }
      });
    },
    setBoundOfMap() {
      if (
        this.$refs.HisotryGoogleMap &&
        this.$refs.HisotryGoogleMap.$mapObject
      ) {
        // console.log("mapMoving " ,this.mapMoving);
        const neBound = this.$refs.HisotryGoogleMap.$mapObject
          .getBounds()
          .getNorthEast();
        const swBound = this.$refs.HisotryGoogleMap.$mapObject
          .getBounds()
          .getSouthWest();

        const lat0 = neBound.lat();
        const lng0 = neBound.lng();
        const lat1 = swBound.lat();
        const lng1 = swBound.lng();
        const boundRect = [
          { latitude: lat0, longitude: lng0 },
          { latitude: lat0, longitude: lng1 },
          { latitude: lat1, longitude: lng1 },
          { latitude: lat1, longitude: lng0 },
        ];
        this.boundRect = boundRect;
        // console.log(this.boundRect);
      }
    },
    startDragMap() {
      this.mapMoving = true;
    },
    endDragMap() {
      // setTimeout(() => {
      this.setBoundOfMap();
      //   // console.log(this.boundRect);
      if (this.mapMoving === true) {
        this.mapMoving = false;
        this.setGeofenceData();
      }
      // }, 800);
    },
    async saveGeofence() {
      this.setSaveGeofenceLoading(true);
      let geojson = {};
      if (this.geofenceType == 'circle') {
        geojson = {
          type: 'Point',
          coordinates: [
            parseFloat(this.positionList[0].lng),
            parseFloat(this.positionList[0].lat),
          ],
        };
      } else {
        geojson = {
          type: 'Polygon',
          coordinates: [
            this.positionList.map((position) => [
              parseFloat(position.lng),
              parseFloat(position.lat),
            ]),
          ],
        };
      }
      await this.createGeofence({
        name: this.geofenceName,
        type: this.geofenceType,
        radius: parseFloat(this.geofenceRadius),
        org: this.selectedGroup.id,
        positions: this.positionList,
        geojson,
      })
        .then((saveResult) => {
          if (saveResult) {
            this.resetPositionList();
            this.getAllGeofences([this.selectedGroup.id]).then(() => {
              this.setGeofenceData();
            });
            this.$message.success('บันทึกสถานีสำเร็จ', 5);
          } else {
            this.$message.warning(
              'ไม่สามารถบันทึกสถานีได้ กรุณาลองอีกครั้ง',
              5
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.$message.warning('ไม่สามารถบันทึกสถานีได้ กรุณาลองอีกครั้ง', 5);
        });
      await this.setSaveGeofenceLoading(false);
    },
    zoomLevelChange(zoomLevel) {
      this.mapZoom = zoomLevel;
      let obj = this;
      setTimeout(() => {
        if (zoomLevel >= 16) {
          obj.showBoundryGeofence = true;
        } else {
          obj.showBoundryGeofence = false;
        }
      }, 1000);
    },
    displayDriverName(licenseNo, driverName, getDriverNameByLicenseNo) {
      const vehicle = {
        device: {
          position: {
            mcr_driver_name: driverName,
            mcr_license_no: licenseNo,
          },
        },
      };
      return displayDriverName(true, vehicle, getDriverNameByLicenseNo);
    },
    getDriverNameByLicenseNo(licenseNo) {
      return this.driverByLicenseNo(licenseNo);
    },
  },
  watch: {
    selectedPosition: function (newPosition) {
      // console.log('selectedPosition', newPosition);
      if (
        this.$refs.HisotryGoogleMap &&
        newPosition &&
        newPosition.latitude &&
        newPosition.longitude &&
        this.showMapOnScreen === true
      )
        this.$refs.HisotryGoogleMap.$mapPromise.then((map) => {
          map.setCenter(
            new window.google.maps.LatLng(
              newPosition.latitude / 10000000,
              newPosition.longitude / 10000000
            )
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.driver-ic {
  width: 24px;
  height: 24px;
  border-radius: 24px;
}
.positionDetailModal .ant-modal-body {
  padding-top: 2px !important;
}
$md: 768px;
.add-geofence-drawer {
  .ant-drawer-wrapper-body {
    overflow: hidden;
  }

  background: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: flex-end;
  .ant-drawer-content-wrapper {
    @media screen and (max-width: $md) {
      width: 560px !important;
    }
    @media screen and (min-width: $md) {
      width: 560px !important;
    }

    margin: 0 auto;
    // right: 0px;
    // margin-right: 0px !important;

    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    .ant-drawer-content {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      background: #ffffff;
      .ant-drawer-body {
        padding: 15px 24px;
      }
    }
  }
  .ant-drawer-close {
    color: #bbb;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    // color: #fff;
  }
  h1 {
    font-weight: 700 !important;
  }
}
.google-map {
  width: calc(100vw - 860px);
  display: inline-block;
  position: absolute;
}
.google-map-tripinfo {
  display: inline-block;
  position: absolute;
  right: 0;
}
.direction-arrow {
  font-size: 1.9rem;
  &.ENGINE_STOP {
    color: #f5223e;
  }
  &.ENGINE_IDLE,
  &.ENGINE_IDLE_END {
    color: #faad14;
  }
  &.OVER_SPEED {
    color: #8e34ff;
  }
  &.VEHICLE_MOVING {
    color: #00c246;
  }
}
.device-marker {
  width: 40px;
  position: absolute;
  z-index: 99;
}
.running {
  // filter: invert(41%) sepia(72%) saturate(1296%) hue-rotate(87deg)
  //   brightness(102%) contrast(111%);
  filter: grayscale(50%) hue-rotate(240deg) saturate(3.3);
}
.idle {
  filter: grayscale(32%) hue-rotate(210deg) saturate(3.8);
}
.stop {
  // filter: invert(56%) sepia(5%) saturate(16%) hue-rotate(136deg) brightness(95%)
  //   contrast(88%);
  filter: grayscale(30%) hue-rotate(153deg) saturate(3.3);
}
.overspeed {
  //filter: invert(24%) sepia(90%) saturate(3678%) hue-rotate(258deg) brightness(65%) contrast(90%);
  filter: grayscale(58%) hue-rotate(68deg) saturate(2.5);
}

.google-map-toolbar {
  overflow: hidden;
  width: 70px;
  position: relative;
  user-select: none;
  background-color: rgb(255, 255, 255);
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-left: 0px;
  z-index: 9999;
  position: absolute;
  right: 10px;
  top: 190px;

  .toggle-geofence-button {
    position: relative;
    cursor: pointer;
    padding: 0px 5px;
    font-size: 1rem;
    color: rgb(86, 86, 86);
    font-family: 'Prompt', Arial, sans-serif;
    font-weight: bold;
    &:hover {
      background-color: #ddd;
    }
  }
  .toggle-direction-button {
    position: relative;
    cursor: pointer;
    padding: 0px 5px;
    font-size: 1rem;
    color: rgb(86, 86, 86);
    font-family: 'Prompt', Arial, sans-serif;
    font-weight: bold;
    &:hover {
      background-color: #ddd;
    }
  }
  .toggle-pto-button {
    position: relative;

    cursor: pointer;
    padding: 0px 5px;
    font-size: 1rem;
    color: rgb(86, 86, 86);
    font-family: 'Prompt', Arial, sans-serif;
    font-weight: bold;
    &:hover {
      background-color: #ddd;
    }
  }
}
.pto-status {
  color: #fff;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #fff;
  font-size: 1rem;
  background-color: #00c246;
  display: inline-block;
  text-align: center;
}

.add-position {
  overflow: hidden;
  input {
    display: inline;
  }
}

.tip-container {
  background-color: #eee;
  padding: 10px 7px 1px 10px;
  margin: 0 0 10px 0;
  border-radius: 5px;
}
.add-position-container {
  background-color: #eee;
  padding: 10px 7px 10px 7px;
  margin: 10px 0 10px 0;
  border-radius: 5px;
  overflow: hidden;
  text-align: left;
  input {
    display: inline-block;
    margin-right: 4px;
    width: 110px;
  }
  button {
    display: inline-block;
  }
}

.all-position-container {
  border: dashed;
  border-width: 1px;
  background-color: #eee;
  padding: 10px 7px 10px 7px;
  // margin: 10px 0 10px 0;
  z-index: 9999;
  border-radius: 5px;
  position: absolute;
  right: 10px;
  top: 280px;
  input {
    display: inline-block;
    margin-right: 4px;
    width: 110px;
  }
  button {
    display: inline-block;
  }
}
.geofence-name {
  font-family: sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  z-index: 9999;
  text-align: left;
  color: #f5223e;
}
.position-detail-modal .ant-row.ant-form-item {
  font-size: 18px;
}

//Measure Distance
.segment-measure-text {
  font-size: 1rem;
  pointer-events: none;
  text-shadow: none;

  /* &.inverted {
    stroke: white;
    text-shadow: -2.4px -2.4px $alpha-black, -2.4px 2.4px $alpha-black,
      2.4px 2.4px $alpha-black, 2.4px -2.4px $alpha-black, -2.4px 0 $alpha-black,
      0 2.4px $alpha-black, 2.4px 0 $alpha-black, 0 -2.4px $alpha-black;
  } */
}

.node-measure-text {
  font-size: 1rem;
  pointer-events: none;
  text-shadow: none;

  /* &.inverted {
    stroke: white;
    text-shadow: -2.4px -2.4px $alpha-black, -2.4px 2.4px $alpha-black,
      2.4px 2.4px $alpha-black, 2.4px -2.4px $alpha-black, -2.4px 0 $alpha-black,
      0 2.4px $alpha-black, 2.4px 0 $alpha-black, 0 -2.4px $alpha-black;
  } */

  &.head-text {
    visibility: hidden;
  }
}
</style>
