<template>
  <div class="container">
    <div v-if="Object.keys(position).length > 0" class="cancel-btn-container">
      <button @click="() => this.$emit('onClose')" class="cancel-btn">
        <a-icon type="close" danger />
      </button>
    </div>
    <div style="width: 50px">
      {{ Object.keys(position).length > 0 ? 'แก้ไข' : 'เพิ่มจาก' }}
    </div>
    <div class="option-selecting-container">
      <a-select
        v-model="selectedOption"
        @change="onSelectingOption"
        class="select-route-input"
      >
        <a-select-option
          v-for="(option, index) in options"
          :key="index"
          :value="option.value"
        >
          {{ option.label }}
        </a-select-option>
      </a-select>
      <div class="input-container">
        <div v-if="selectedOption === positionType.Coordinates">
          <a-input
            :placeholder="labelTypes.Coordinates"
            v-model="inputText"
            @input="onInputCoordinates"
            style="width: 150px"
          />
        </div>
        <div v-else-if="selectedOption === positionType.Place">
          <vue-google-autocomplete
            id="map"
            class="type-place"
            placeholder="ค้นหา"
            ref="locationSearch"
            size="small"
            :country="['th']"
            :enable-geolocation="true"
            :types="null"
            @inputChange="onInputPlaceChange"
            @placechanged="onPlaceChanged"
          />
        </div>
        <div v-else-if="selectedOption === positionType.Vehicle">
          <a-select
            :show-arrow="false"
            showSearch
            placeholder="เลือกพาหนะ"
            @change="onSelectVehicle"
            optionFilterProp="children"
            :filterOption="filterOption"
            style="width: 150px; border-radius: 30px"
            :defaultValue="inputText === '' ? undefined : inputText"
          >
            <a-select-option
              v-for="vehicle in vehicles"
              :key="vehicle.id"
              :value="vehicle.plate_number"
            >
              {{ vehicle.plate_number.trim() }} ( {{ vehicle.name.trim() }} )
            </a-select-option>
          </a-select>
        </div>
        <div v-else-if="selectedOption === positionType.Geofence">
          <a-auto-complete
            :data-source="resultList"
            placeholder="เลือกสถานี"
            style="width: 150px; border-radius: 30px"
            :defaultValue="inputText === '' ? undefined : inputText"
            @search="onSearchGeofence"
            @select="onSelectGeofence"
          />
        </div>

        <div class="action">
          <a-button
            type="primary"
            :disabled="disableSaveButton()"
            :icon="Object.keys(position).length > 0 ? 'save' : 'plus'"
            @click="onClick"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import { mapGetters, mapActions, mapState } from 'vuex';

const positionType = Object.freeze({
  Coordinates: 'latlng',
  Place: 'search',
  Vehicle: 'vehicle',
  Geofence: 'geofence',
});
const labelTypes = Object.freeze({
  Coordinates: 'ละติจูด, ลองจิจูด',
  Place: 'สถานที่',
  Vehicle: 'พาหนะ',
  Geofence: 'สถานี',
});

export default {
  name: 'positionSelection',
  components: {
    'vue-google-autocomplete': VueGoogleAutocomplete,
  },
  props: {
    index: Number,
    position: Object,
    latlngMap: String,
  },
  data() {
    return {
      labelTypes: labelTypes,
      positionType: positionType,
      options: [
        {
          value: positionType.Coordinates,
          label: labelTypes.Coordinates,
        },
        {
          value: positionType.Place,
          label: labelTypes.Place,
        },
        {
          value: positionType.Vehicle,
          label: labelTypes.Vehicle,
        },
        {
          value: positionType.Geofence,
          label: labelTypes.Geofence,
        },
      ],
      selectedOption: positionType.Coordinates,
      inputText: '',
      coordinates: {
        lat: 0,
        lng: 0,
      },
      resultList: [],
    };
  },
  watch:{
    latlngMap(val){
      if (this.selectedOption === positionType.Coordinates){
        this.inputText = val
        this.onInputCoordinates()
      }
    }
  },
  computed: {
    ...mapState({
      geofences: (state) => state.geofence.geofences,
      selectedGroup: (state) => state.authen.selectedGroup,
    }),
    ...mapGetters({
      vehicles: 'vehicle/vehicles',
    }),
  },
  async mounted() {
    if (this.geofences.length === 0) {
      this.getAllGeofences([this.selectedGroup.id]);
    }
    if (Object.keys(this.position).length > 0) {
      this.inputText = this.position.name;
      this.selectedOption = this.position.type;
      this.coordinates.lat = this.position.lat;
      this.coordinates.lng = this.position.lng;

      if (this.selectedOption === positionType.Place) {
        if (!this.$refs.locationSearch) {
          this.$nextTick(() => {
            this.$refs.locationSearch.updateCoordinates(this.coordinates);
          });
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getAllGeofences: 'geofence/getAllGeofences',
    }),
    disableSaveButton() {
      if (this.coordinates.lat > 0 && this.coordinates.lng > 0) {
        return false;
      }
      return true;
    },
    onClick() {
      this.$emit('onSubmit', {
        index: this.index,
        data: {
          lat: parseFloat(this.coordinates.lat),
          lng: parseFloat(this.coordinates.lng),
          name: this.inputText,
          type: this.selectedOption,
        },
      });
    },
    setCoordinates(coordinates) {
      this.coordinates = {
        lat: parseFloat(coordinates.lat),
        lng: parseFloat(coordinates.lng),
      };
      this.$emit('onCoordinatesChange', this.coordinates);
    },
    validateCoordinates(coordinates) {
      //   const coordinatesPattern =
      //     /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
      const latPattern = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)/;
      const lngPattern = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

      const validLat = latPattern.test(coordinates.lat);
      const validLng = lngPattern.test(coordinates.lng);
      //   const validateCoordinates = coordinatesPattern.test(text);

      return validLat && validLng;
    },
    onSelectingOption(value) {
      this.selectedOption = value;
      this.inputText = '';
      this.resultList = [];
      this.coordinates = {
        lat: 0,
        lng: 0,
      };
    },
    onPlaceChanged(addressData, place) {
      this.inputText = place.formatted_address;

      this.setCoordinates({
        lat: addressData.latitude,
        lng: addressData.longitude,
      });
    },
    onInputPlaceChange(value) {
      if (value.newVal.length === 0) {
        this.setCoordinates({
          lat: 0,
          lng: 0,
        });
      }
    },
    onInputCoordinates() {
      const arrayConverted = this.inputText.split(',');
      if (arrayConverted.length === 2) {
        const coordinates = {
          lat: arrayConverted[0].trim(),
          lng: arrayConverted[1].trim(),
        };
        if (this.validateCoordinates(coordinates)) {
          return this.setCoordinates(coordinates);
        }
      }
      this.setCoordinates({ lat: 0, lng: 0 });
    },
    onSelectVehicle(plateNumber) {
      const vehicle = this.vehicles.find(
        (vehicle) => vehicle.plate_number === plateNumber
      );
      if (vehicle) {
        this.inputText = `${vehicle.plate_number.trim()} ( ${vehicle.name.trim()} )`;

        let coordinates = {
          lat: 0,
          lng: 0,
        };
        if (vehicle.last_position_json) {
          coordinates.lat = vehicle.last_position_json.latitude / 10_000_000;
          coordinates.lng = vehicle.last_position_json.longitude / 10_000_000;
        }
        this.setCoordinates(coordinates);
      }
    },
    onSelectGeofence(name) {
      const geofence = this.geofences.find(
        (geofence) => geofence.name === name
      );
      if (geofence) {
        this.inputText = name;
        let coordinates = {
          lat: 0,
          lng: 0,
        };

        if (geofence.Positions.length > 0) {
          coordinates = geofence.Positions[0];
        }

        this.setCoordinates(coordinates);
      }
    },
    onSearchGeofence(name) {
      this.inputText = '';
      let coordinates = {
        lat: 0,
        lng: 0,
      };
      this.setCoordinates(coordinates);

      if (name === '') {
        this.resultList = [];
      } else {
        this.resultList = [
          ...this.geofences.filter((geofence) => geofence.name.includes(name)),
        ]
          .map((obj) => obj.name)
          .slice(0, 10);
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background-color: #eee;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  column-gap: 8px;
  position: relative;

  .option-selecting-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .select-route-input {
      max-width: 135px;
    }
    .input-container {
      display: flex;
      gap: 14px;
      .type-place {
        display: inline-block;
        width: 150px;
        //   margin: 8px 8px 8px 3px;
        box-sizing: border-box;
        padding: 10px 7px 10px 7px;
        font-variant: tabular-nums;
        list-style: none;
        -webkit-font-feature-settings: 'tnum';
        font-feature-settings: 'tnum';
        position: relative;
        display: inline-block;
        height: 32px;
        padding: 4px 11px;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        line-height: 1.5;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        transition: all 0.3s;
      }
    }
  }

  .cancel-btn-container {
    position: absolute;
    right: 8px;
    top: 8px;

    .cancel-btn {
      color: #505050;
      padding: 0;
      border: none;
      margin-left: 1px;
      background-color: rgba(255, 255, 255, 0);
      &:hover {
        color: red;
      }
      cursor: pointer;
    }
  }
}
</style>
