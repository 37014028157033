<template>
  <div>
    <div class="search-bar">
      <div>
        <a-input
          v-if="activeSearchOption === searchOptions.VEHICLE"
          class="search-input"
          placeholder="ค้นหารถ"
          v-model="vehicleSearchKeyword"
        />
        <div v-if="activeSearchOption === searchOptions.COORDINATES">
          <a-input
            class="search-input"
            placeholder="ค้นหาพิกัด"
            v-model="coordinatesInput"
            @change="onChangeCoordinates"
          />
          <div
            v-if="isDisplaySuggestion"
            style="position: absolute; color: #9fa0a0; padding: 4px 10px"
          >
            ตัวอย่าง 13.92732, 100.582060
          </div>
        </div>

        <div v-if="activeSearchOption === searchOptions.LOCATION">
          <vueGoogleAutocomplete
            id="map"
            :class="['search-input', 'auto-complete']"
            @placechanged="getAddressData"
            @inputChange="onChangeGAutoComplete"
            placeholder="ค้นหาสถานที่"
            ref="locationSearch"
            size="small"
            :country="['th']"
            :enable-geolocation="true"
            :types="null"
          />
        </div>
        <div v-if="activeSearchOption === searchOptions.GEOFENCE">
          <a-auto-complete
            :data-source="geofenceDataSource()"
            class="search-input"
            placeholder="ค้นหาสถานี"
            @select="onSelectGeofence"
            @change="onChangeGeofence"
            :filter-option="filterOption"
          />
        </div>
      </div>
      <a-dropdown class="nearby-dropdown" placement="bottomRight">
        <a-button style="background-color: #4838de; border-color: #4838de; padding: 5px;">
          <img :src="require('@/assets/chevron-down.png')" />
        </a-button>
        <a-menu
          slot="overlay"
          @click="handleMenuClick"
          style="width: 120px; text-align: center"
        >
          <a-menu-item :key="searchOptions.VEHICLE" style="color: #9fa0a0">
            ค้นหารถ
          </a-menu-item>
          <a-menu-item :key="searchOptions.LOCATION" style="color: #9fa0a0">
            ค้นหาสถานที่
          </a-menu-item>
          <a-menu-item :key="searchOptions.COORDINATES" style="color: #9fa0a0">
            ค้นหาพิกัด
          </a-menu-item>
          <a-menu-item :key="searchOptions.GEOFENCE" style="color: #9fa0a0">
            ค้นหาสถานี
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>

    <div class="vehicle-list-container">
      <div v-if="activeSearchOption === searchOptions.VEHICLE">
        <h3>รถทั้งหมด</h3>
        <listResultCar
          :clickVehicle="clickVehicle"
          :keyword="vehicleSearchKeyword"
        />
      </div>
      <div v-else>
        <div v-if="validLocationData">
          <nearByListResult
            @displaySuggestionLabel="displaySuggestionLabel"
            :locationData="locationData"
            :googleMap="googleMap"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vueGoogleAutocomplete from 'vue-google-autocomplete';
import listResultCar from '@/views/components/listResultCar';
import nearByListResult from '@/views/components/nearby/nearByListResult';
import { mapState, mapActions } from 'vuex';
import * as geolib from 'geolib';

const searchOptions = Object.freeze({
  VEHICLE: 'vehicle_searching',
  LOCATION: 'location_searching',
  COORDINATES: 'coordinates_searching',
  GEOFENCE: 'geofence_searching',
});

export default {
  components: { listResultCar, nearByListResult, vueGoogleAutocomplete },
  props: ['clickVehicle', 'googleMap'],
  data() {
    return {
      searchOptions,
      activeSearchOption: searchOptions.VEHICLE,
      vehicleSearchKeyword: '',
      coordinatesInput: '',
      validLocationData: false,
      isDisplaySuggestion: true,
      locationData: {
        coordinates: {},
        name: '',
      },
    };
  },
  computed: {
    ...mapState({
      geofences: (state) => state.geofence.geofences,
      selectedGroup: (state) => state.authen.selectedGroup,
    }),
  },
  // async mounted() {    
    
  // },
  methods: {
    ...mapActions({
      getAllGeofences: 'geofence/getAllGeofences',
      selectVehicle: 'vehicle/selectVehicle',
      showVehicleInfo: 'vehicle/showVehicleBottomInfo',
      setTracking: 'vehicle/setTracking',
    }),
    displaySuggestionLabel(value) {
      this.isDisplaySuggestion = value;
    },
    filterOption(input, option) {
      // console.log(option.componentOptions.children)
      if (option.componentOptions.children.length > 0) {
        return (
          option.componentOptions.children[0].text
            .toUpperCase()
            .indexOf(input.toUpperCase()) >= 0
        );
      } else {
        return false
      }
    },
    async handleMenuClick(e) {
      this.resetInputValue();
      this.activeSearchOption = e.key;
      if (this.geofences.length <= 0 && this.activeSearchOption === searchOptions.GEOFENCE) {       
        await this.getAllGeofences([this.selectedGroup.id]);
      }
    },
    validateCoordinate(coordinateString) {
      const lat_lngPattern =
        /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)\s*,\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
      return lat_lngPattern.test(coordinateString);
    },

    geofenceDataSource() {
      return this.geofences.map((geofence) => {
        return {
          value: JSON.stringify(geofence),
          text: geofence.name,
        };
      });
    },
    getAddressData(addressData, place) {
      const locationName = place.formatted_address;
      const locationCoordinates = {
        lat: addressData.latitude,
        lng: addressData.longitude,
      };
      this.setLocationData(locationName, locationCoordinates);
    },
    onChangeCoordinates() {
      if (this.validateCoordinate(this.coordinatesInput)) {
        const latLng = this.coordinatesInput.split(',');
        const locationCoordinates = {
          lat: parseFloat(latLng[0]),
          lng: parseFloat(latLng[1]),
        };
        this.setLocationData(this.coordinatesInput, locationCoordinates);
      } else {
        this.setLocationData('', {});
      }
    },
    setLocationData(name, coordinates) {      
      this.validLocationData = name !== '' ? true : false;
      this.locationData = {
        coordinates: coordinates,
        name: name,
      };
    },
    onChangeGAutoComplete(event) {
      if (event.newVal == '' || event.oldVal == '') {
        this.setLocationData('', {});
      }
    },
    onChangeGeofence(value) {
      if (value === '') {
        this.setLocationData('', {});
      }
    },
    onSelectGeofence(value) {
      const geofence = JSON.parse(value);      
      if (geofence.type === 'polygon') {
        let centerPolygon = geolib.getCenter(geofence.Positions);
        const locationCoordinates = {
          lat: centerPolygon.latitude,
          lng: centerPolygon.longitude,
        };
        this.setLocationData(geofence.name, locationCoordinates);
      }
      if (geofence.type === 'circle') {
        this.setLocationData(geofence.name, geofence.Positions[0]);
      }
    },
    resetInputValue() {
      this.setTracking(true);
      this.displaySuggestionLabel(true);
      this.selectVehicle({});
      this.showVehicleInfo(false);
      this.coordinatesInput = '';
      this.vehicleSearchKeyword = '';
      this.setLocationData('', {});
    },
  },
};
</script>
<style lang="scss" scoped>
$md: 768px;
$sm: 450px;
.search-bar>div {
  width: 100%;
}
.search-bar {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  margin-bottom: 12px;
  width: 100%;
  @media screen and (max-width: $md) {
    margin-top: 40;
  }
  @media screen and (max-width: $sm) {
    margin-top: 40px;
  }
}
.search-input {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  &.auto-complete {
    height: 32px;
    padding: 10px 7px 10px 7px;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px 0 0 4px;
  }
}
.nearby-dropdown {
  width: 20%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  @media screen and (max-width: $md) {
    width: 30%;
  }
  @media screen and (max-width: $sm) {
    width: 30%;
  }
}
</style>
