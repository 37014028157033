import moment from '@/lib/time';
import Excel from 'exceljs';
import AxiosService from '@/plugins/axios';
import endpoints from '../../config/config';
import {
    fontNormal,
    sheetMargin,
    hightlightTableHead,
    blobToFile,
    imeiToPlateNumber,
    formatDate,
    formatDuration,
    hightlightRow,
    imeiToVehicleName,
    generateLogoHeader,
    addLogoCompany,
} from './config';
import store from '@/store';
import * as Sentry from '@sentry/vue';

function pushingSummaryRow(array, spendingTimeEachGeofence) {
    const duration = moment.duration(spendingTimeEachGeofence * 1e3); // milliseconds
    array.push([
        '',
        '',
        '',
        '',
        'รวม',
        `${duration.days()} วัน ${duration.hours()}:${duration.minutes()}:${duration.seconds()}`]
    )
}

async function enterExitGeofenceReport(imeis, selectedGeofences, start, stop, customergroup) {
    const strTimerange =
        moment.unix(start).format('D_MMM_YY') +
        '-ถึง-' +
        moment.unix(stop).format('D_MMM_YY');
    await AxiosService.post(
        `${endpoints.coreApi}/report-enter-exit-geofence`,
        {
            imeis: imeis,
            selectedGeofences: selectedGeofences,
            start: start,
            stop: stop,
        }
    ).then((response) => {
        const fileName = "Enter_Exit_Geofence_Report_" + strTimerange;
        prepareDataForExcel(
            fileName,
            response.data,
            customergroup.logo
        );
    }).catch((error) => {
        console.log('An error occurred:', error);
        Sentry.captureException(error);
        store.dispatch('loading/setReportLoading', false);
        return;
    });
}

function prepareDataForExcel(fileName, rawData, logo) {
    const reportByVehicle = {}
    const vehicles = [...store.getters['vehicle/vehicles'].map((vehicle) => {
        return {
            plate: vehicle.plate_number,
            name: vehicle.name,
            odo_canbus_offset: vehicle.odo_canbus_offset,
            canbus_fuel_enable: vehicle.canbus_fuel_enable,
            odo_canbus_enable: vehicle.odo_canbus_enable,
            overspeed_limit: vehicle.overspeed_limit,
            imei: vehicle.device ? parseFloat(vehicle.device.imei) : '',
        };
    })]

    Object.keys(rawData).forEach((imei) => {
        if (rawData[imei].length > 0) {

            /* init report by vehicle key and value */
            if (!(imei in reportByVehicle)) {
                reportByVehicle[imei] = {
                    data: [],
                    startTime: formatDate(rawData[imei][0]['enter_time']),
                    endTime: formatDate(rawData[imei][rawData[imei].length - 1]['exit_time']),
                }
            }
            let spendingTimeEachGeofence = 0

            for (let i = 0; i <= rawData[imei].length - 1; i++) {

                const currentEnterExitInfo = rawData[imei][i]
                if (i > 0) {
                    /* pushing summary row */
                    const prevEnterExitInfo = rawData[imei][i - 1]
                    if (currentEnterExitInfo['name'] !== prevEnterExitInfo['name']) {
                        pushingSummaryRow(reportByVehicle[imei].data, spendingTimeEachGeofence)
                        spendingTimeEachGeofence = 0
                    }
                }

                spendingTimeEachGeofence = spendingTimeEachGeofence + currentEnterExitInfo['exit_time'] - currentEnterExitInfo['enter_time']

                reportByVehicle[imei].data.push([
                    currentEnterExitInfo['name'],
                    imeiToPlateNumber(vehicles, Number(imei)),
                    imeiToVehicleName(vehicles, Number(imei)),
                    formatDate(currentEnterExitInfo['enter_time']),
                    formatDate(currentEnterExitInfo['exit_time']),
                    formatDuration(currentEnterExitInfo['enter_time'], currentEnterExitInfo['exit_time'])
                ])

                /* pushing summary row on last index */
                if (i === rawData[imei].length - 1) {
                    pushingSummaryRow(reportByVehicle[imei].data, spendingTimeEachGeofence)
                }
            }

        }
    })

    return generateExcel(fileName, reportByVehicle, logo)
}
async function generateExcel(fileName, reportByVehicle, logo) {
    const tableHead = [
        'ชื่อสถานี',
        'ทะเบียน',
        'ชื่อ',
        'เวลาเข้า',
        'เวลาออก',
        'รวมระยะเวลา',
    ];
    const columnsSpec = [
        {
            key: 'geofenceName',
            width: 25,
        },
        {
            key: 'plateNumber',
            width: 15,
            style: {
                font: fontNormal,
            },
        },
        {
            key: 'name',
            width: 15,
            style: {
                numFmt: '0',
            },
        },
        {
            key: 'enterTime',
            width: 20,
        },
        {
            key: 'exitTime',
            width: 20,
        },
        {
            key: 'totalSpendingTime',
            width: 20,
        },
    ];

    const workbook = new Excel.Workbook();
    const pageProperties = {
        properties: {
            tabColor: {
                argb: 'FF41C173',
            },
        },
        pageSetup: {
            paperSize: 9,
            orientation: 'landscape',
        },
    };

    let imageLogoId;

    if (logo && logo.url) {
        const ext = logo.ext.substring(1);
        const myBase64Image = await generateLogoHeader(logo);
        imageLogoId = workbook.addImage({
            base64: myBase64Image,
            extension: ext,
        });
    }

    if (Object.keys(reportByVehicle).length > 0) {
        Object.keys(reportByVehicle).forEach((imei) => {
            let startingRow = 1;
            let sheet = null;
            let plateNo = reportByVehicle[imei]['data'][0][1];

            sheet = workbook.addWorksheet(plateNo, pageProperties);
            sheet.pageSetup.margins = sheetMargin;
            sheet.columns = columnsSpec;

            let metaRow1 = `รายงานเข้า-ออกสถานี ของ รถทะเบียน ${plateNo} เวลาเริ่ม ${reportByVehicle[imei].startTime} ถึง ${reportByVehicle[imei].endTime}`;
            // let metaRow2 = `รวมระทางทั้งสิ้น - กม. รวมเวลา ${reportByVehicle[imei].totalTime} ชม.:นาที:วินาที`;

            if (logo && logo.url) {
                startingRow = 2;
                addLogoCompany(sheet, imageLogoId);
            }

            sheet.addRow([metaRow1]);
            // sheet.addRow([metaRow2]);
            sheet.addRow([]);
            sheet.addRow(tableHead);
            // sheet.addRows([[1, 2, 3, 4], [1, 2, 3, 4], [1, 2, 3, 4]])
            sheet.addRows(reportByVehicle[imei].data)
            sheet.addRow([]);
            sheet.addRow([]);

            hightlightTableHead(sheet, startingRow + 2);
            hightlightRow(sheet, startingRow);
            // hightlightRow(sheet, startingRow + 1);
        });

    } else {
        workbook.addWorksheet('ไม่พบข้อมูล', pageProperties);
    }
    return blobToFile(workbook, fileName)
}
export default enterExitGeofenceReport;