<template>
  <a-layout id="components-layout-demo-top" class="layout">
    <a-layout class="login-layout">
      <a-layout-content style="padding: 0 0px">
        <a-row type="flex">
          <a-col :xs="24" class="logo-container">
            <img
              class="login-logo"
              :src="require('../assets/heliot_logo_xl.png')"
              width="120px"
          /></a-col>
        </a-row>
        <a-row type="flex" class="login-container">
          <a-col :xs="0" :md="14" :lg="14" class="login-image-container">
            <img class="login-image" :src="require('../assets/login.jpg')" />
          </a-col>
          <a-col :xs="24" :md="10" :lg="10">
            <a-row type="flex">
              <a-col :xs="24" class="login-box">
                <h1>ขออภัย :(</h1>
                <p>
                  <a-icon type="alert" />
                  ไม่พบหน้าที่คุณต้องการดู หากท่านต้องการทราบฟีเจอร์ทั้งหมด
                  กรุณาติดต่อเฮลลิออท
                </p></a-col
              >
            </a-row>
          </a-col>
        </a-row>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
export default {
  name: "NotFoundComponent"
};
</script>
<style scoped lang="scss">
$header_height: 80px;
$ciprimary: #00c246;
$logo-margin: 40px;
.logo-container {
  text-align: left;
  height: $header_height;
}
.login-container {
  .login-box {
    padding: 0 2.5rem;
    p {
      font-size: 1.2rem;
      text-align: left;
      color: #666;
    }
    h1 {
      font-size: 3rem;
      text-align: left;
      font-weight: 300;
      margin: 0;
    }
  }

  height: calc(100vh - 100px);
  position: relative;
  text-align: center;
  .login-image-container {
    height: calc(100vh - 80px);
    position: relative;
    margin-top: 50px;
    padding-left: $logo-margin;
    .login-image {
      width: 100%;
    }
  }
}
.form-container {
  padding: 0 2.5rem;
  text-align: left;
  .ant-form-item {
    font-size: 1rem !important;
    margin: 5px 0px;
  }
}

.ant-input {
  font-size: 1rem !important;
  margin: 0px;
}

.alternate-login {
  p {
    color: #666;
  }
  margin-top: 10px;
}
.logo-container {
  vertical-align: middle;
  height: $header_height;
  width: 100%;
  display: inline-block;
  text-align: left;
  .login-logo {
    margin: $logo-margin;
    vertical-align: middle;
  }
}

.login-form,
.social-btn {
  margin-right: 10px;
}
.google-btn {
}
.fb-btn {
  // background-color: #3b5998;
  // color: #fff;
}
.ant-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 0;
  background: #fff;
}
.register-image-container {
  text-align: right;
  height: 170px;
  width: 100%;
  margin-top: 30px;
  img {
    width: 80%;
  }
}
</style>
