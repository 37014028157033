<template>
  <div class="geofences">
    <div class="heliot-search">
      <span style="color: #0000f0; font-size: 1.1rem"
        >จำนวนสถานีทั้งหมด {{ geofencesResult.length }} สถานี</span
      >
      <input
        type="text"
        class="heliot-search-input"
        placeholder="ค้นหาสถานี"
        ref="geofenceSearch"
        @keyup="searchTimeOut()"
      />
    </div>
    <div
      v-for="(geofence, index) in geofencesResult"
      :key="geofence.id"
      @click="clickGeofence(geofence)"
      :class="[
        'heliot-info-geofence',
        selectedGeofence.id == geofence.id ? 'active' : '',
      ]"
    >
      <div class="geofence-info">
        <div class="type">
          <icon-font
            :type="geofenceIcon(geofence.type)"
            style="padding-right: 5px"
          />
          <a-icon :type="geofenceIcon(geofence.type)" />
        </div>
        <div class="name">
          <b>{{ index + 1 }}. {{ geofence.name }}</b>
        </div>
        <a-popconfirm
          title="ต้องการลบสถานี?"
          @confirm="$emit('deleteGeofence', geofence.id)"
          okText="ยืนยัน"
          cancelText="ยกเลิก"
        >
          <div class="remove">
            <icon-font type="icon-delete1" />
          </div>
        </a-popconfirm>
        <div class="latlng">
          {{ parseFloat(geofence.Positions[0].lat).toFixed(5) }},{{
            parseFloat(geofence.Positions[0].lng).toFixed(5)
          }}
          &nbsp;
          <span class="google-map-link">
            <a
              :href="`http://maps.google.com/maps?q=loc:${geofence.Positions[0].lat},${geofence.Positions[0].lng}`"
              target="_blank"
            >
              <a-icon type="google" />Map
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { IconFont } from '@/config/config';
export default {
  components: {
    IconFont,
  },
  props: {
    setCenterPath: { type: Function },
    searchKeyword: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.geofencesResult = this.geofences;
  },
  data() {
    return {
      keyword: '',
      geofencesResult: [],
      timeout: null,
      // filteredVehicleGroup: [],
    };
  },
  watch: {
    geofences: function (newGeofences) {
      this.geofencesResult = newGeofences;
    },
  },
  computed: mapState({
    ...mapGetters({}),
    selectedGeofence: (state) => state.geofence.selectedGeofence,
    geofences: (state) => state.geofence.geofences,
  }),
  methods: {
    ...mapActions({
      selectGeofence: 'geofence/selectGeofence',
    }),
    searchTimeOut() {
      clearTimeout(this.timeout);
      // Make a new timeout set to go off in 800ms
      let keyword = this.$refs.geofenceSearch.value.trim().toUpperCase();
      this.timeout = setTimeout(() => {
        let listResult = this.geofences.filter((geofence) => {
          if (
            geofence.name.toUpperCase().indexOf(keyword) !== -1 ||
            keyword === ''
          ) {
            return true;
          } else {
            return false;
          }
        });
        // console.log('set result ', keyword, listResult.length);
        this.geofencesResult = listResult;
      }, 800);
    },
    geofenceIcon: (type) => {
      return type == 'circle' ? 'icon-circle' : 'icon-polygon';
    },
    clickGeofence(geofence) {
      //////// GA /////////
      this.$gtag.event(`geofence_geofences-list_clicked`);
      ////////////////////
      this.selectGeofence(geofence);
      this.$emit('setCenterPath', geofence);
    },
  },
};
</script>

<style lang="scss" scoped>
.trips {
  margin-top: 30px;
}
.geofences {
  margin-top: 10px;
}
.heliot-info-geofence {
  &.active {
    background: #e3e3e3 0% 0% no-repeat padding-box;
    cursor: pointer;
  }
  &:hover {
    background: #efefef 0% 0% no-repeat padding-box;
    cursor: pointer;
  }

  background: #f4f4f4;
  margin: 5px 0 5px 0;
  border-radius: 7px;
  padding: 10px 10px 10px 10px;

  .geofence-info {
    text-align: left;

    .type {
      display: inline-block;
      font-weight: bold;
      color: #505050;
      line-height: 24px;
      white-space: nowrap;
      margin: 0 10px 0 0;
    }
    .name {
      display: inline-block;
      font-size: 1.1rem;
      font-weight: 400;
      color: #404040;
      line-height: 24px;
      white-space: nowra;
      vertical-align: middle;

      white-space: nowrap;
      text-overflow: ellipsis;
      width: 150px;
      overflow: hidden;
    }
    .latlng {
      font-size: 16px;
      font-weight: normal;
      color: #505050;
      padding-left: 30px;
    }
    .remove {
      display: block;
      font-size: 16px;
      color: #505050;
      line-height: 24px;
      float: right;
      &:hover {
        color: red;
      }
    }
  }

  .google-map-link {
    font-size: 16px;
    padding: 2px 5px;
    font-size: 0.7rem;
    float: right;
    background-color: #00c246;
    border-radius: 3px;
    &:hover {
      background-color: #ddd;
      a {
        color: #fff;
      }
    }
    cursor: pointer;
    a {
      color: #fff;
    }
  }
}
</style>
