import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import * as VueGoogleMaps from 'vue2-google-maps';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueApexCharts from 'vue-apexcharts';
import firebase from 'firebase/app';
import 'firebase/analytics';

import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import vmodal from 'vue-js-modal';
import { isMobile } from 'mobile-device-detect';
const { detect } = require('detect-browser');
const browser = detect();


import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library


import { createProvider } from './strapi-apollo';

import VueGtag from 'vue-gtag';
import LottieAnimation from 'lottie-web-vue'


import vueFlvPlayer from 'vue-flv-player'
Vue.use(vueFlvPlayer)

// const options = {
//   path: "/position",

//   transportOptions: {
//     polling: {
//       extraHeaders: {
//         'x-clientid': 'abc'
//       }
//     }
//   }

// }; //Options object to pass into SocketIO

// Vue.use(new VueSocketIO({
//   debug: false,
//   connection: positionSocket, //options object is Optional
//   vuex: {
//     store,
//     actionPrefix: "SOCKET_",
//     mutationPrefix: "SOCKET_"
//   }
// }));
// Vue.use(
//   new VueSocketIO({
//     debug: true,
//     connection: SocketIO("http://demos.kaazing.com", options), //options object is Optional
//     vuex: {
//       store,
//       actionPrefix: "SOCKET_",
//       mutationPrefix: "SOCKET_"
//     }
//   })
// );
// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: 'https://realtime-data.heliotplatform.com',
//   vuex: {
//     store,
//     actionPrefix: 'SOCKET_',
//     mutationPrefix: 'SOCKET_'
//   },
//   options: {
//     path: "/position"
//   } //Optional options
// }))
// Vue.component('v-chart', ECharts)

// Vue.use(firebasePlugin);

// console.log('browser.name', browser.name);
if (browser.name != 'safari') {
  import('@firebase/messaging').then(() => {
    console.log('firebase/messaging loaded');

    let firebaseConfig = {};
    if (process.env.NODE_ENV === 'production') {
      firebaseConfig = {
        apiKey: 'AIzaSyA2-SezNUUGA3bcoZrWFklT2Jw6CnKx-TA',
        authDomain: 'heliot-logistrack.firebaseapp.com',
        databaseURL: 'https://heliot-logistrack.firebaseio.com',
        projectId: 'heliot-logistrack',
        storageBucket: 'heliot-logistrack.appspot.com',
        messagingSenderId: '47617004221',
        appId: '1:47617004221:web:0e12652de7e3014549ddd4',
        measurementId: 'G-9FQT4H4YVZ',
      };
    } else {
      console.log('init');
      firebaseConfig = {
        apiKey: 'AIzaSyDxadx0b9EWOlmk8Hu_7CSs7RZazSChEvM',
        authDomain: 'heliot-dev.firebaseapp.com',
        databaseURL: 'https://heliot-dev.firebaseio.com',
        projectId: 'heliot-dev',
        storageBucket: 'heliot-dev.appspot.com',
        messagingSenderId: '1004462935129',
        appId: '1:1004462935129:web:bb784383acd1c18be6b548',
      };
    }
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    Vue.prototype.$firebase = firebase;

    if (!isMobile) {
      Vue.prototype.$messaging = firebase.messaging();
      let firebaseScript = './firebase-messaging-sw.js';
      if (process.env.NODE_ENV !== 'production') {
        firebaseScript = './firebase-messaging-sw-staging.js';
      }

      navigator.serviceWorker
        .register(firebaseScript)
        .then((registration) => {
          Vue.prototype.$messaging.useServiceWorker(registration);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });
}

Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(VueEasytable);
Vue.use(vmodal);
Vue.component('apexchart', VueApexCharts);
Vue.use(LottieAnimation); 
// Vue.component('gmap-cluster', VueGoogleMaps.Cluster);
Vue.component('cluster', GmapCluster);
Vue.use(Loading, {
  canCancel: true,
  color: '#00c246',
  loader: 'spinner',
  width: 48,
  height: 48,
});

Vue.use(VueGoogleMaps, {
  load: {
    key:
      process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'staging'
        ? 'AIzaSyARAghB5ITlEdenkpwbcUYRra_8Q7ImoTU'
        : 'AIzaSyACqVQeGls4n7U9jSJx-ED92nAMEZXqlyA',
    libraries: 'places,drawing,visualization,geometry', // This is required if you use the Autocomplete plugin
    region: 'TH',
    language: 'th',
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  autoBindAllEvents: true,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  installComponents: true,
});

Vue.use(
  VueGtag,
  {
    config: { id: 'G-9FQT4H4YVZ' },
  },
  router
);

const app = new Vue({
  data: {
    loading: false,
  },
  router,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');

Vue.component('loading', {
  template: '<div>Loading!</div>',
});
// vueRouter = new VueRouter({
//   mode: "history",
//   routes,
// });

router.beforeEach((to, from, next) => {
  app.loading = true;
  next();
});

router.afterEach(() => {
  app.loading = false; // timeout for demo purposes
  // next();
});

document.querySelectorAll('meta').forEach(function (el) {
  if (el.name == 'apple-mobile-web-app-title') {
    // console.log(el);
    el.content = 'Heliot Fleet';
  }
});
