<template>
  <div v-if="vehicleGroups.length === 1">
    <div
      :class="['heliot-group']"
      v-for="vehicleGroup in vehicleGroups"
      :key="vehicleGroup.name"
    >
      <div style="height: 36px; margin-bottom: 16px">
        <h3 class="vehicle-group">
          <div class="truncated-text">{{ vehicleGroup.label }}</div>
        </h3>
      </div>
      <div v-for="vehicle in vehicleGroup.vehicles" :key="vehicle.id">
        <vehicleInfoContainer :vehicle="vehicle" :clickVehicle="clickVehicle" />
      </div>
    </div>
  </div>
  <div v-else id="collapse">
    <a-collapse v-model="activeGroup" :bordered="false">
      <a-collapse-panel
        :style="{ border: 0, paddingBottom: '5px' }"
        v-for="(vehicleGroup, key) in vehicleGroups"
        :key="`${vehicleGroup.name}${key}`"
        :show-arrow="false"
      >
        <h3 slot="header" class="vehicle-group">
          <div class="truncated-text">{{ vehicleGroup.label }}</div>
          <a-icon
            type="up"
            :class="[
              activeGroup.includes(`${vehicleGroup.name}${key}`)
                ? 'arrow-up-open'
                : 'arrow-up',
            ]"
            style="font-size: 14px; color: white !important"
          />
        </h3>
        <div v-for="vehicle in vehicleGroup.vehicles" :key="vehicle.id">
          <vehicleInfoContainer
            :vehicle="vehicle"
            :clickVehicle="clickVehicle"
          />
        </div>
      </a-collapse-panel>
    </a-collapse>
  </div>
</template>
<script>
import vehicleInfoContainer from '@/views/components/vehicleInfoContainer.vue';
import moment from '@/lib/time';
import { mapState } from 'vuex';
export default {
  components: {
    vehicleInfoContainer,
  },
  props: {
    clickVehicle: { type: Function },
    keyword: String,
  },
  data() {
    return {
      activeGroup: [],
    };
  },
  mounted() {
    if (this.vehicleGroups.length > 0) {
      this.activeGroup = [
        `${this.vehicleGroups[this.vehicleGroups.length - 1].name}${
          this.vehicleGroups.length - 1
        }`,
      ];
    }
  },
  watch: {
    vehicleGroups(newValue, oldValue) {      
      /// prevent from obj socket
      if (newValue.length !== oldValue.length) {
        if (newValue.length > 0) {
          this.activeGroup = [
            `${newValue[newValue.length - 1].name}${newValue.length - 1}`,
          ];
        }
      }
    },
  },
  computed: mapState({
    vehicleGroups(state) {
      let groups = state.vehicle.vehicleGroups.map((ele) => ({ ...ele }));

      for (const k in groups) {
        if (
          typeof groups[k].vehicles === 'object' &&
          groups[k].vehicles.length > 0
        ) {
          groups[k].vehicles = groups[k].vehicles.filter((vehicle) => {
            const vehicleIMEI =
              vehicle.device && vehicle.device.imei ? vehicle.device.imei : '';
            const vehicleChassis = vehicle.chassis_no ? vehicle.chassis_no : '';
            const keywordFilter =
              (
                vehicle.plate_number.toLowerCase() +
                vehicle.name.toLowerCase() +
                vehicleIMEI +
                vehicleChassis.toLowerCase()
              ).search(this.keyword.toLowerCase()) == -1
                ? false
                : true;

            if (vehicle.device && vehicle.device.position)
              vehicle.device.position.engineStatus = this.checkIsOverSec(
                vehicle.device.position.engineStatus,
                vehicle.device.position.timestamp,
                vehicle.device.position.speed,
                vehicle.overspeed_limit
              );

            let statusFilter = false;
            if (vehicle.device && vehicle.device.position)
              statusFilter =
                this.filteredEngineStatus.length <= 0 ||
                this.filteredEngineStatus.includes(
                  vehicle.device.position.engineStatus
                )
                  ? true
                  : false;

            return keywordFilter && statusFilter;
          });

          groups[k].vehicles = groups[k].vehicles.sort((a, b) => {
            if ('display_order' in a) {
              return a.display_order - b.display_order;
            }
          });
        }
      }
      groups = groups.filter((group) => {
        return group.vehicles.length > 0;
      });

      return groups.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
      );
    },
    filteredEngineStatus(state) {
      return state.vehicle.filteredEngineStatus;
    },
  }),
  methods: {
    checkIsOverSec(engineStatus, timestamp, speed, overspeed) {
      const sec = 3720;
      const overTime = moment().unix() - timestamp;
      if (overTime > sec) {
        return 'NOT_UPDATED';
      } else {
        if (speed >= overspeed) {
          return 'OVER_SPEED';
        }
        return engineStatus;
      }
    },
  },
};
</script>

<style lang="scss">
#collapse {
  .ant-collapse
    > .ant-collapse-item.ant-collapse-no-arrow
    > .ant-collapse-header {
    padding-left: 0px !important;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0px 0px !important;
    height: 36px !important;
    margin-bottom: 4px;
  }
}
</style>
<style lang="scss" scoped>
.vehicle-group {
  background-color: #513fe8;
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 0px 16px;
  align-items: center;
  height: 100%;
  flex-grow: 1;
  color: #fff;
  border-radius: 8px;
  text-overflow: ellipsis;
}

.arrow-up-open {
  transform: rotate(-180deg);
  transition: transform 0.1s linear;
  // font-size: 25px;
}

.arrow-up {
  transform: rotate(0deg);
  transition: transform 0.1s linear;
  // font-size: 25px;
}

.truncated-text {
  max-width: 80%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* Number of lines to display */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-overflow: ellipsis;
}
</style>
