import moment from '@/lib/time';
import Excel from 'exceljs';
import endpoints from '../../config/config';
import AxiosService from '@/plugins/axios';
import {
  fontNormal,
  sheetMargin,
  getStatusColor,
  hightlightTableHead,
  blobToFile,
  imeiToPlateNumber,
  formatDate,
  hightlightRow,
  getOdoData,
  formatDuration,
  getMCRDriverName,
} from './config';
import * as Sentry from '@sentry/vue';
import store from '@/store';

const getTemperatureReport = async (
  vehicles,
  imeis,
  start,
  stop,
  customergroup,
  sec,
  dictOfDriver
) => {
  const strTimerange =
    moment.unix(start).format('D_MMM_YY') +
    '-ถึง-' +
    moment.unix(stop).format('D_MMM_YY');
  await AxiosService.post(`${endpoints.coreApi}/report-temperature`, {
    imeis: imeis,
    start: start,
    stop: stop,
    customergroup: customergroup,
    sec: parseInt(sec),
    vehicles: vehicles,
    dictOfDriver: dictOfDriver,
  })
    .then((eventData) => {
      const reportByVehicle = splitTemperatureReportByVehicle(
        vehicles,
        eventData.data,
        dictOfDriver
      );
      const fileName = 'Temperature_Report_' + strTimerange;
      const tableHead = [
        'ทะเบียนรถ',
        'IMEI',
        'วัน เวลา',
        'ความเร็ว',
        'อุณหภูมิ',
        'ตำแหน่ง',
        'สถานที่',
        'เลขกม.สะสม',
        'เลขที่ใบขับขี่',
        'timestamp',
      ];
      const columnsSpec = [
        {
          key: 'plate_number',
          width: 14,
          style: {
            font: fontNormal,
          },
        },
        {
          key: 'imei',
          width: 18,
          alignment: {
            horizontal: 'center',
          },
          style: {
            numFmt: '0.0',
          },
        },
        {
          key: 'time',
          width: 20,
        },
        {
          key: 'speed',
          width: 12,
        },
        {
          key: 'fuel',
          width: 12,
        },
        {
          key: 'latLng',
          width: 24,
        },
        {
          key: 'geolocation',
          width: 30,
        },
        {
          key: 'odo',
          width: 10,
        },
        {
          key: 'mcr_license_no',
          width: 12,
        },
        {
          key: 'timestamp',
          width: 12,
        },
      ];
      genTemperatureExcel({
        fileName,
        reportByVehicle,
        tableHead,
        columnsSpec,
        statusColumn: false,
      });
    })
    .catch((err) => {
      console.log(err);
      Sentry.captureException(err);
      store.dispatch('loading/setReportLoading', false);
      return;
    });
};

const genTemperatureExcel = ({
  fileName,
  reportByVehicle,
  tableHead,
  columnsSpec,
  statusColumn,
}) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: 'FF41C173',
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: 'landscape',
    },
  };

  if (Object.keys(reportByVehicle).length > 0)
    Object.keys(reportByVehicle).forEach((imei) => {
      if (reportByVehicle[imei].length > 0) {
        const plateNo = reportByVehicle[imei][0][0];
        const sheet = workbook.addWorksheet(plateNo, pageProperties);
        sheet.pageSetup.margins = sheetMargin;
        sheet.columns = columnsSpec;
        let maxTemp = null;
        let minTemp = null;
        let avgTemp = 0;
        let accuTemp = 0;
        for (const i in reportByVehicle[imei]) {
          const celsius = parseFloat(reportByVehicle[imei][i][4]);
          if (celsius > maxTemp || maxTemp === null) {
            maxTemp = celsius;
          }
          if (minTemp > celsius || minTemp === null) {
            minTemp = celsius;
          }
          accuTemp = accuTemp + celsius;
        }
        avgTemp = accuTemp / reportByVehicle[imei].length;

        const totalDistance = (
          reportByVehicle[imei][reportByVehicle[imei].length - 1][7] -
          reportByVehicle[imei][0][7]
        ).toFixed(2);

        const totalTime = formatDuration(
          reportByVehicle[imei][0][9],
          reportByVehicle[imei][reportByVehicle[imei].length - 1][9]
        );
        const metaRow1 = `รายงานอุณหภูมิ ของ รถทะเบียน ${plateNo} เวลาเริ่ม ${
          reportByVehicle[imei][0][2]
        } ถึง ${reportByVehicle[imei][reportByVehicle[imei].length - 1][2]}`;
        const metaRow2 = `รวมระยะทางทั้งสิ้น ${totalDistance} กม. รวมเวลา ${totalTime} ชม.:นาที:วินาที`;

        sheet.addRow([metaRow1]);
        sheet.addRow([metaRow2]);
        sheet.addRow([
          `ค่าอุณหภูมิสูงสุด`,
          parseFloat(maxTemp.toFixed(2)),
          'องศาเซลเซียส',
        ]);
        sheet.addRow([
          `ค่าอุณหภูมิต่ำสุด`,
          parseFloat(minTemp.toFixed(2)),
          'องศาเซลเซียส',
        ]);
        sheet.addRow([
          `ค่าอุณหภูมิเฉลี่ย`,
          parseFloat(avgTemp.toFixed(2)),
          'องศาเซลเซียส',
        ]);

        sheet.addRow([]);

        sheet.addRow(tableHead);
        sheet.addRows(reportByVehicle[imei]);
        if (statusColumn) {
          sheet.getColumn(statusColumn).eachCell((cell) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: getStatusColor(cell.value),
            };
          });
        }
        hightlightTableHead(sheet, 7);
        hightlightRow(sheet, 1);
        hightlightRow(sheet, 2);
      }
    });
  else {
    workbook.addWorksheet('ไม่พบข้อมูล', pageProperties);
  }

  blobToFile(workbook, fileName);
};

const splitTemperatureReportByVehicle = (vehicles, report, dictOfDriver) => {
  let result = {};
  report.forEach((row) => {
    result[row.imei] = result[row.imei] || [];

    result[row.imei].push([
      imeiToPlateNumber(vehicles, row.imei),
      row.imei,
      formatDate(row.timestamp),
      row.speed,
      parseFloat(row.temperature),
      row.latitude / 10000000 + ', ' + row.longitude / 10000000,
      row.locationName,
      parseFloat(getOdoData(vehicles, row.imei, row).toFixed(1)),
      typeof dictOfDriver != 'undefined' && row.mcr_license_no in dictOfDriver
        ? dictOfDriver[row.mcr_license_no].name
        : getMCRDriverName(row.mcr_license_no, row.mcr_driver_name),
      row.timestamp,
    ]);
  });
  return result;
};

export default getTemperatureReport;
