var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:[
      'heliot-info-car',
      _vm.selectedVehicle.id == _vm.vehicle.id ? 'active' : '',
    ],on:{"click":function($event){return _vm.clickVehicle(_vm.vehicle)}}},[(_vm.vehicle.device)?[_c('div',{staticClass:"InfoAndPTO"},[_c('div',{class:['info', _vm.selectedVehicle.id == _vm.vehicle.id ? 'active' : '']},[_c('div',{staticClass:"vehicle-info"},[_c('div',{class:[
                'model',

                _vm.vehicle.device && _vm.vehicle.device.position
                  ? _vm.checkisoverSec(
                      _vm.vehicle.device.position.engineStatus,
                      _vm.vehicle.device.position.timestamp,
                      _vm.vehicle.device.position.speed,
                      _vm.vehicle.overspeed_limit
                    )
                  : '',
              ]},[_c('div',[_vm._v(" "+_vm._s(_vm.vehicle.plate_number.substring(0, 8))+" "),_c('icon-font',{staticClass:"icon-setting",attrs:{"type":"icon-setting"},on:{"click":function($event){return _vm.showModal(_vm.vehicle)}}})],1)]),_c('div',{staticClass:"location clearfix"},[(
                  _vm.vehicle.device.position &&
                  _vm.vehicle.device.position.geofenceName
                )?_c('span',{staticStyle:{"color":"#00c246"}},[_vm._v(" "+_vm._s(_vm.vehicle && _vm.vehicle.device && _vm.vehicle.device.position && _vm.vehicle.device.position.geofenceName)+" ")]):[_vm._v(" "+_vm._s(_vm.vehicle.lastLocation ? _vm.vehicle.lastLocation.district + ' ' + _vm.vehicle.lastLocation.province : 'ไม่พบสถานที่')+"  ")]],2),_c('div',{staticClass:"name",attrs:{"title":_vm.vehicle.name}},[_vm._v(" "+_vm._s(_vm.vehicle.name)+"  ")])]),_c('div',{staticClass:"vehicle-speed"},[(_vm.vehicle.device)?_c('div',{class:[
                'speed',
                _vm.vehicle.device && _vm.vehicle.device.position
                  ? _vm.checkisoverSec(
                      _vm.vehicle.device.position.engineStatus,
                      _vm.vehicle.device.position.timestamp,
                      _vm.vehicle.device.position.speed,
                      _vm.vehicle.overspeed_limit
                    )
                  : '',
              ]},[_c('div',{staticClass:"speed-block"},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('div',{key:_vm.vehicle.device && _vm.vehicle.device.position
                        ? _vm.vehicle.device.position.speed
                        : 'no-speed'},[_vm._v(" "+_vm._s(_vm.vehicle.device && _vm.vehicle.device.position && _vm.checkisoverSec( _vm.vehicle.device.position.engineStatus, _vm.vehicle.device.position.timestamp, _vm.vehicle.device.position.speed, _vm.vehicle.overspeed_limit ) != 'NOT_UPDATED' ? _vm.vehicle.device && _vm.vehicle.device.position && _vm.vehicle.device.position.speed : 'X')+" ")])])],1),_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('div',{staticClass:"kmhr"},[_vm._v(" "+_vm._s(_vm.vehicle.device && _vm.vehicle.device.position && _vm.checkisoverSec( _vm.vehicle.device.position.engineStatus, _vm.vehicle.device.position.timestamp, _vm.vehicle.device.position.speed, _vm.vehicle.overspeed_limit ) != 'NOT_UPDATED' ? 'กม/ชม' : 'ไม่เชื่อมต่อ')+" ")])]),_c('span',{staticClass:"kmhr"},[_vm._v(_vm._s(_vm.vehicle.last_position_json && 'startEventTime' in _vm.vehicle.last_position_json ? _vm.timeDuration( _vm.vehicle.last_position_json.startEventTime * 1000 ) : '-'))]),(
                  _vm.vehicle.device.position && _vm.vehicle.device.position.timestamp
                )?_c('span',{staticClass:"kmhr"},[_vm._v(_vm._s(`ล่าสุด ${_vm.dtFormat( _vm.vehicle.device.position.timestamp * 1000 )}`))]):_vm._e()],1):_c('div',{class:['speed']},[_c('a-icon',{attrs:{"type":"disconnect"}})],1)]),_c('div',{staticClass:"vehicle-speed-sm"},[(_vm.vehicle.device)?_c('div',{class:[
                'speed',
                _vm.vehicle.device && _vm.vehicle.device.position
                  ? _vm.checkisoverSec(
                      _vm.vehicle.device.position.engineStatus,
                      _vm.vehicle.device.position.timestamp,
                      _vm.vehicle.device.position.speed,
                      _vm.vehicle.overspeed_limit
                    )
                  : '',
              ]},[_c('div',{staticClass:"speed-block"},[_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('div',{key:_vm.vehicle.device && _vm.vehicle.device.position
                        ? _vm.vehicle.device.position.speed
                        : 'no-speed'},[_vm._v(" "+_vm._s(_vm.vehicle.device && _vm.vehicle.device.position && _vm.checkisoverSec( _vm.vehicle.device.position.engineStatus, _vm.vehicle.device.position.timestamp, _vm.vehicle.device.position.speed, _vm.vehicle.overspeed_limit ) != 'NOT_UPDATED' ? _vm.vehicle.device && _vm.vehicle.device.position && _vm.vehicle.device.position.speed : 'X')+" ")])])],1),_c('transition',{attrs:{"name":"slide-fade","mode":"out-in"}},[_c('div',{staticClass:"kmhr"},[_vm._v(" "+_vm._s(_vm.vehicle.device && _vm.vehicle.device.position && _vm.checkisoverSec( _vm.vehicle.device.position.engineStatus, _vm.vehicle.device.position.timestamp, _vm.vehicle.device.position.speed, _vm.vehicle.overspeed_limit ) != 'NOT_UPDATED' ? 'กม/ชม' : '-')+" ")])])],1):_c('div',{class:['speed']},[_c('a-icon',{attrs:{"type":"disconnect"}})],1)])]),(
            (_vm.setting.positionPTO &&
              (_vm.checkShowPTO(_vm.vehicle, 2) ||
                _vm.checkShowPTO(_vm.vehicle, 3) ||
                _vm.checkShowPTO(_vm.vehicle, 4))) ||
            (_vm.setting.positionTemp &&
              _vm.tempSensorIsAvaiable(_vm.vehicle.device.position.io)) ||
            (_vm.setting.positionFuel &&
              _vm.displayFuelLevel(_vm.vehicle, _vm.vehicle.device.position) != '')
          )?[(
              _vm.setting.positionPTO &&
              (_vm.checkShowPTO(_vm.vehicle, 2) ||
                _vm.checkShowPTO(_vm.vehicle, 3) ||
                _vm.checkShowPTO(_vm.vehicle, 4))
            )?_c('div',{staticClass:"vehicle-PTO"},[(_vm.checkShowPTO(_vm.vehicle, 2))?[_c('div',{staticClass:"ptoIcon",class:_vm.checkShowPTO(_vm.vehicle, 2) ? 'ptoBgGreen' : ''},[_c('div',{staticClass:"pto-status"},[_vm._v("PTO1")])])]:_vm._e(),(_vm.checkShowPTO(_vm.vehicle, 3))?[_c('div',{staticClass:"ptoIcon",class:_vm.checkShowPTO(_vm.vehicle, 3) ? 'ptoBgGreen' : ''},[_c('div',{staticClass:"pto-status"},[_vm._v("PTO2")])])]:_vm._e(),(_vm.checkShowPTO(_vm.vehicle, 4))?[_c('div',{staticClass:"ptoIcon",class:_vm.checkShowPTO(_vm.vehicle, 4) ? 'ptoBgGreen' : ''},[_c('div',{staticClass:"pto-status"},[_vm._v("PTO3")])])]:_vm._e()],2):_vm._e(),(
              _vm.setting.positionTemp &&
              _vm.tempSensorIsAvaiable(_vm.vehicle.device.position.io)
            )?_c('div',{staticClass:"vehicle-Temp"},[_c('icon-font',{attrs:{"type":"icon-temperature"}}),_vm._v(_vm._s(_vm.displayTempSensorData(_vm.vehicle.device.position.io))+" ")],1):_vm._e(),(
              _vm.setting.positionTemp &&
              _vm.tempSensorIsAvaiable(_vm.vehicle.device.position.io, 79)
            )?_c('div',{staticClass:"vehicle-Temp"},[_vm._v(" , "+_vm._s(_vm.displayTempSensorData(_vm.vehicle.device.position.io, 79))+"  ")]):_vm._e(),(
              _vm.setting.positionFuel &&
              _vm.displayFuelLevel(_vm.vehicle, _vm.vehicle.device.position) != ''
            )?_c('div',{staticClass:"vehicle-Fuel"},[_c('icon-font',{attrs:{"type":"icon-water"}}),_vm._v(_vm._s(_vm.displayFuelLevel(_vm.vehicle, _vm.vehicle.device.position))+"L ")],1):_vm._e()]:_vm._e()],2),(
          _vm.vehicle.device.position.mcr_license_no &&
          _vm.setting.positionActivedCard
        )?_c('div',{staticClass:"status_card"},[_c('span',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticClass:"icon_driver_license"},[_c('icon-font',{attrs:{"type":"icon-drivers-license"}})],1),_vm._v(" "+_vm._s(_vm.displayDriverName( _vm.vehicle, _vm.getDriverNameByLicenseNo(_vm.vehicle.device.position.mcr_license_no) ))+" ")]),_c('span',{staticStyle:{"text-align":"right"}},[_vm._v(_vm._s(_vm.vehicle.device.position.mcr_license_no))])]):_vm._e()]:_vm._e()],2),_c('a-modal',{class:[`edit-vehicle-modal`],attrs:{"title":`แก้ไขพาหนะ ${_vm.selectedVehicle.plate_number}`,"visible":_vm.visible,"mask":false,"closable":false,"confirm-loading":_vm.confirmLoading,"z-index":100,"centered":""},on:{"cancel":() => {
        this.visible = false;
      }}},[_c('a-form-item',{attrs:{"label":"ชื่อพาหนะ","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input',{attrs:{"max-length":25},model:{value:(_vm.editingVehicle.name),callback:function ($$v) {_vm.$set(_vm.editingVehicle, "name", $$v)},expression:"editingVehicle.name"}})],1),_c('a-form-item',{attrs:{"label":"ความเร็วจำกัด","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-input-number',{attrs:{"min":0,"max":200},model:{value:(_vm.editingVehicle.overspeed_limit),callback:function ($$v) {_vm.$set(_vm.editingVehicle, "overspeed_limit", $$v)},expression:"editingVehicle.overspeed_limit"}})],1),_c('a-form-item',{attrs:{"label":"ประเภทพาหนะ","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-select',{staticStyle:{"width":"100%"},attrs:{"default-value":_vm.editingVehicle.vehicle_icon && _vm.editingVehicle.vehicle_icon !== ''
            ? _vm.editingVehicle.vehicle_icon
            : _vm.vehicleTypes[0].value},on:{"change":_vm.onChangeVehicleType}},_vm._l((_vm.vehicleTypes),function(type,i){return _c('a-select-option',{key:i,attrs:{"value":type.value}},[_vm._v(" "+_vm._s(type.label)+" ")])}),1)],1),(
        _vm.selectedPlan && ['gold', 'platinum', 'diamond'].includes(_vm.selectedPlan)
      )?_c('div',[_c('a-form-item',{attrs:{"label":"Temp Sensor1 ต่ำสุด","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-space',[_c('a-input-number',{attrs:{"precision":2,"step":0.01},model:{value:(_vm.editingVehicle.sensor1_temp_min),callback:function ($$v) {_vm.$set(_vm.editingVehicle, "sensor1_temp_min", $$v)},expression:"editingVehicle.sensor1_temp_min"}}),_vm._v(" สูงสุด : "),_c('a-input-number',{attrs:{"precision":2,"step":0.01},model:{value:(_vm.editingVehicle.sensor1_temp_max),callback:function ($$v) {_vm.$set(_vm.editingVehicle, "sensor1_temp_max", $$v)},expression:"editingVehicle.sensor1_temp_max"}})],1)],1),_c('a-form-item',{attrs:{"label":"Temp Sensor2 ต่ำสุด","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-space',[_c('a-input-number',{attrs:{"precision":2,"step":0.01},model:{value:(_vm.editingVehicle.sensor2_temp_min),callback:function ($$v) {_vm.$set(_vm.editingVehicle, "sensor2_temp_min", $$v)},expression:"editingVehicle.sensor2_temp_min"}}),_vm._v(" สูงสุด : "),_c('a-input-number',{attrs:{"precision":2,"step":0.01},model:{value:(_vm.editingVehicle.sensor2_temp_max),callback:function ($$v) {_vm.$set(_vm.editingVehicle, "sensor2_temp_max", $$v)},expression:"editingVehicle.sensor2_temp_max"}})],1)],1),_c('a-form-item',{attrs:{"label":"แจ้งเตือนอุณหภูมิ","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol}},[_c('a-switch',{model:{value:(_vm.editingVehicle.temp_notify_enabled),callback:function ($$v) {_vm.$set(_vm.editingVehicle, "temp_notify_enabled", $$v)},expression:"editingVehicle.temp_notify_enabled"}})],1)],1):_vm._e(),_c('template',{slot:"footer"},[_c('a-button',{on:{"click":_vm.handleCancel}},[_vm._v("ยกเลิก")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleOk}},[_vm._v("บันทึก")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }