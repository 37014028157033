<template>
  <div class="icon-status">
    <div
      :class="[
        'icon-style',
        { active: filteredEngineStatus.includes('VEHICLE_MOVING') },
      ]"
      @click="onFilterEngineStatusClicked('VEHICLE_MOVING')"
    >
      <icon-font type="icon-play1" style="color: #00c246" />
      <p>{{ getVehicleList('VEHICLE_MOVING').length }}</p>
    </div>

    <div
      :class="[
        'icon-style',
        { active: filteredEngineStatus.includes('ENGINE_IDLE') },
      ]"
      @click="onFilterEngineStatusClicked('ENGINE_IDLE')"
    >
      <icon-font style="color: #faad14" type="icon-pause" />
      <p>{{ getVehicleList('ENGINE_IDLE').length }}</p>
    </div>

    <div
      :class="[
        'icon-style',
        { active: filteredEngineStatus.includes('ENGINE_STOP') },
      ]"
      @click="onFilterEngineStatusClicked('ENGINE_STOP')"
    >
      <icon-font style="color: #f5223e" type="icon-stop" />
      <p>{{ getVehicleList('ENGINE_STOP').length }}</p>
    </div>

    <div
      :class="[
        'icon-style',
        { active: filteredEngineStatus.includes('NOT_UPDATED') },
      ]"
      @click="onFilterEngineStatusClicked('NOT_UPDATED')"
    >
      <icon-font style="color: #aaaaaa" type="icon-cancel" />
      <p v-if="pageType === 'video'">
        {{ 
          filterVehicleByDevice().length - 
          (getVehicleList('VEHICLE_MOVING').length + 
           getVehicleList('ENGINE_IDLE').length + 
           getVehicleList('ENGINE_STOP').length)
        }}
      </p>
      <p v-else>
        {{
          vehicles.length -
          (getVehicleList('VEHICLE_MOVING').length + 
           getVehicleList('ENGINE_IDLE').length + 
           getVehicleList('ENGINE_STOP').length)
        }}
      </p>
    </div>
  </div>
</template>
<script>
import { IconFont } from '@/config/config';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  components: { IconFont },
  props: {
    pageType: String,
  },

  computed: {
    ...mapState({
      ...mapGetters({
        vehicles: 'vehicle/vehicles',
      }),

      filteredEngineStatus(state) {
        return state.vehicle.filteredEngineStatus;
      },
    }),
  },  

  methods: {
    ...mapActions({
      setFilteredEngineStatus: 'vehicle/setFilteredEngineStatus',
    }),

    // Find vehicles with Jimi and Richmor devices.
    filterVehicleByDevice() {
      return this.vehicles.filter(vehicle => {
        if (vehicle.device && vehicle.device.company) {
          return (vehicle.device.company === 'Jimi' || vehicle.device.company === 'Richmor');
        } else return 0;
      });
    },

    onFilterEngineStatusClicked(engineStatus) {
      if (engineStatus == 'VEHICLE_MOVING' || engineStatus == 'OVER_SPEED') {
        if (
          !this.filteredEngineStatus.includes('VEHICLE_MOVING') ||
          !this.filteredEngineStatus.includes('OVER_SPEED')
        )
          this.filteredEngineStatus.push('VEHICLE_MOVING', 'OVER_SPEED');
        else
          this.filteredEngineStatus.splice(
            this.filteredEngineStatus.indexOf('VEHICLE_MOVING'),
            2
          );
      } else if (engineStatus == 'ENGINE_IDLE') {
        if (!this.filteredEngineStatus.includes('ENGINE_IDLE'))
          this.filteredEngineStatus.push('ENGINE_IDLE');
        else
          this.filteredEngineStatus.splice(
            this.filteredEngineStatus.indexOf('ENGINE_IDLE'),
            1
          );
      } else if (engineStatus == 'ENGINE_STOP') {
        if (!this.filteredEngineStatus.includes('ENGINE_STOP'))
          this.filteredEngineStatus.push('ENGINE_STOP');
        else
          this.filteredEngineStatus.splice(
            this.filteredEngineStatus.indexOf('ENGINE_STOP'),
            1
          );
      } else if (engineStatus == 'NOT_UPDATED') {
        if (!this.filteredEngineStatus.includes('NOT_UPDATED'))
          this.filteredEngineStatus.push('NOT_UPDATED');
        else
          this.filteredEngineStatus.splice(
            this.filteredEngineStatus.indexOf('NOT_UPDATED'),
            1
          );
      }

      this.setFilteredEngineStatus(this.filteredEngineStatus);
    },

    getVehicleList(status) {
      const vehicleList = this.pageType === 'video' ? this.filterVehicleByDevice() : this.vehicles;
      return vehicleList.filter(vehicle => {
        if (status === 'VEHICLE_MOVING') {
          return vehicle.device && 
          vehicle.device.position && 
          status.includes(vehicle.device.position.engineStatus || 'OVER_SPEED');
        } else {
          return vehicle.device && 
          vehicle.device.position && 
          status.includes(vehicle.device.position.engineStatus);
        }
      }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
$md: 768px;
$sm: 450px;
.icon-status {
  background-color: #f4f4f4;
  width: 100%;
  height: 60px;
  margin: 0%;

  .icon-style {
    @media screen and (max-width: $sm) {
      padding: 4px 4px 2px 5px;
    }
    padding: 13px 21px 6px 21px;
    &:hover {
      background: #ddd 0% 0% no-repeat padding-box;
      cursor: pointer;
    }

    &.active {
      background: #ddd 0% 0% no-repeat padding-box;
    }

    float: left;
    width: 24%;
    height: 60px;
    p {
      text-align: center;
    }
  }
}
</style>
