import AxiosService from "@/plugins/axios";
import endpoints from "../../config/config";

const state = () => ({
  jobs: [],
  byPaginationJobs: [],
  // vehicle_types: [],
  bySearchJobs: [],
  byStatusJobs: [],
  filteredBy: "",
  showLink: "",
  JobsUpdated: false,
  url: "",
});

const getters = {
  allJobs: (state) => state.jobs,
  byStatusJobs: (state) => state.byStatusJobs,
};
const actions = {
  async getJobs({ commit }, customer_groups_id) {
    const response = await AxiosService.get(
      `${endpoints.strapiAPI}/jobs?customer_group=${customer_groups_id}`
    )
      .then((response) => {
        // console.log("getJobs Success", response.config.url)
        commit("SET_URL", response.config.url);
        return response.data;
      })
      .catch((error) => {
        console.log("getJobs Error", error);
        return [];
      });
    commit("SET_JOBS", response);
  },
  async getJobsbyPagination({ commit }, payloads) {
    // console.log("payloads",payloads)
    if (payloads.action) {
      // console.log("Have", payloads);
      const response = await AxiosService.post(
        `${endpoints.coreApi}/jobs`,
        JSON.stringify(payloads),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          // console.log("JobsbyPagination", response);
          return response.data;
        })
        .catch((error) => {
          console.log("JobsbyPagination", error);
          return [];
        });
      commit("SET_JOBSBYPAGINATION", response);
    } else {
      // console.log("No", payloads);
      const response = await AxiosService.get(
        `${payloads.url}&_start=${payloads.pagination.start}&_limit=${payloads.pagination.limit}`
      )
        .then((response) => {
          // console.log("Pagination", response);
          return response.data;
        })
        .catch((error) => {
          console.log("getJobs Error", error);
          return [];
        });
      commit("SET_JOBSBYPAGINATION", response);
    }
  },
  async getJobsbyDate({ commit }, payloads) {
    let url = `${endpoints.strapiAPI}/jobs?customer_group=${payloads.Groupid}`;
    if (payloads.driverID) {
      url = url + `&drivers.id=${payloads.driverID}`;
    }
    if (payloads.start != "") {
      url = url + `&startdate_gte=${payloads.start}`;
    }
    if (payloads.end != "") {
      url = url + `&startdate_lte=${payloads.end}`;
    }

    const response = await AxiosService.get(url)
      .then((response) => {
        // console.log("getJobsbyDate", response);
        commit("SET_URL", response.config.url);
        return response.data;
      })
      .catch((error) => {
        console.log("getJobs Error", error);
        return [];
      });
    commit("SET_JOBS", response);
  },
  async getJobsbyStatus({ commit }, payloads) {
    // console.log(payloads)
    if (!payloads.searchMode) {
      await AxiosService.get(
        `${endpoints.strapiAPI}/jobs?status_contains=${
          payloads.status
        }&customer_group=${payloads.Groupid}${
          payloads.isTodayJobs
            ? `&startdate_gte=${payloads.rangeDate.start}&startdate_lte=${payloads.rangeDate.end}`
            : ""
        }`
      )
        .then((response) => {
          // console.log(response.data);
          commit("SET_JOBS", response.data);
          commit("SET_URL", response.config.url);
        })
        .catch((error) => console.log("getJobsbyStatus", error));
    } else {
      await AxiosService.get(
        `${endpoints.strapiAPI}/jobs?${payloads.searchKey}_contains=${
          payloads.value
        }&status_contains=${payloads.status}&customer_group=${
          payloads.Groupid
        }${
          payloads.isTodayJobs
            ? `&startdate_gte=${payloads.rangeDate.start}&startdate_lte=${payloads.rangeDate.end}`
            : ""
        }`
      )
        .then((response) => {
          // console.log(response.data);
          commit("SET_JOBS", response.data);
          commit("SET_URL", response.config.url);
        })
        .catch((error) => console.log("getJobsbyStatus", error));
    }
  },
  async getJobsbySearch({ commit }, payloads) {
    // console.log(payloads);
    await AxiosService.get(
      `${endpoints.strapiAPI}/jobs?${payloads.searchKey}_contains=${
        payloads.value
      }&customer_group=${payloads.customer_groups_id}${
        payloads.isTodayJobs
          ? `&startdate_gte=${payloads.rangeDate.start}&startdate_lte=${payloads.rangeDate.end}`
          : ""
      }`
    )
      .then((response) => {
        // console.log(response.data);
        commit("SET_JOBS", response.data);
        commit("SET_URL", response.config.url);
      })
      .catch((error) => console.log("getJobsbySearch", error));
  },
  // async getVehicle_types({ commit }) {
  //   const response = await AxiosService.get(
  //     `${endpoints.strapiAPI}/vehicle-types`
  //   )
  //     .then((response) => {
  //       // console.log("getVehicletype Success", response.data);
  //       return response.data;
  //     })
  //     .catch((error) => {
  //       console.log("getVehicletype", error);
  //       return [];
  //     });
  //   commit("GET_VEHICLE_TYPES", response);
  // },
  async getLink({ commit }, payloads) {
    // console.log(payloads);
    await AxiosService.post(
      `${endpoints.coreApi}/jobs`,
      JSON.stringify(payloads),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        // console.log(" getLink result", response.data);
        commit("SET_SHOWLINK", response.data.url);
      })
      .catch((error) => {
        console.log("getLink", error);
      });
  },
  async searchJobsbyStoreName({ commit }, payloads) {
    // console.log(payloads)
    await AxiosService.post(
      `${endpoints.coreApi}/jobs`,
      JSON.stringify(payloads),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        // console.log("StoreName", response);
        commit("SET_JOBS", response.data);
        commit("SET_URL", response.config.url);
      })
      .catch((error) => {
        console.log("SearchStoreName", error);
      });
  },
  async uploadMore({ commit }, payloads) {
    // console.log("After", payloads);
    await AxiosService.post(
      `${endpoints.coreApi}/jobs`,
      JSON.stringify(payloads[0]),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then(async (res) => {
        // console.log(res.data.success);
        if (res.data.success == 1) {
          await AxiosService.put(
            `${endpoints.strapiAPI}/jobs/${payloads[1].id}`,
            JSON.stringify(payloads[1].body),
            {
              headers: { "Content-Type": "application/json;charset=utf-8" },
            }
          ).then(() => {
            commit("UPDATE_JOBSTASK", true);
          });
        }
      })
      .catch((error) => {
        console.log("uploadMore", error);
      });
  },
  async dateSortedJobs({ commit }, payloads) {
    if (payloads.action) {
      // console.log("Yes",payloads);
      await AxiosService.post(
        `${endpoints.coreApi}/jobs`,
        JSON.stringify(payloads),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          // console.log("StoreName", response);
          // commit("SET_JOBS", response.data);
          let myURL = `${response.config.url}/${response.config.data.slice(
            response.config.data.indexOf("startdate:"),
            response.config.data.length - 2
          )}`;
          // console.log(myURL)
          commit("SET_URL", myURL);
        })
        .catch((error) => {
          console.log("SearchStoreName", error);
        });
    } else {
      await AxiosService.get(
        `${payloads.url}&_sort=startdate:${
          payloads.dateSorted ? `DESC` : `ASC`
        }`
      )
        .then((response) => {
          commit("SET_JOBS", response.data);
          commit("SET_URL", response.config.url);
          // console.log(response);
        })
        .catch((error) => {
          console.log("dateSorted", error);
        });
    }
  },
  async addJobsTask({ commit }, task) {
    // console.log('task',task)
    let res = await AxiosService.post(`${endpoints.strapiAPI}/jobs`, task, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        // console.log("Success", response);
        commit(
          "SET_URL",
          `${response.config.url}?customer_group=${response.data.customer_group._id}`
        );
        commit("ADD_JOBSTASK", response.data);
        return true;
      })
      .catch((error) => {
        console.log("Error", error);
        return false;
      });
    return res;
  },
  async deleteJobsTask({ commit }, id) {
    await AxiosService.delete(`${endpoints.strapiAPI}/jobs/${id}`, {
      headers: { "Content-Type": "application/json" },
    })
      .then(() => {
        // console.log("Success", response);
        commit("DELETE_JOBSTASK", id);
        commit("DELETE_JOBSBYPAGINATION", id);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  },
  async updateJobsTask({ commit }, task) {
    // console.log(task)
    // console.log(JSON.stringify(task));
    let res = await AxiosService.put(
      `${endpoints.strapiAPI}/jobs/${task[0].id}`,
      JSON.stringify(task[1]),
      {
        headers: { "Content-Type": "application/json;charset=utf-8" },
      }
    )
      .then(() => {
        // console.log("Success", response);
        commit("UPDATE_JOBSTASK", true);
        return true;
      })
      .catch((error) => {
        console.log("Error", error);
        return false;
      });
    return res;
  },
  async editTaskDesc({ commit }, value) {
    await AxiosService.post(
      `${endpoints.coreApi}/jobs`,
      JSON.stringify({
        action: "set_description",
        value: value.value,
        store: value.id,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then(() => {
      commit("UPDATE_JOBSTASK", true);
    });
  },
  resetJobUpdatedInitial({ commit }) {
    commit("UPDATE_JOBSTASK", false);
  },
};
const mutations = {
  SET_JOBS(state, payloads) {
    state.jobs = payloads;
  },
  SET_SEARCHJOBS(state, payloads) {
    state.bySearchJobs = payloads;
  },
  SET_FILTEREDBY(state, payloads) {
    state.filteredBy = payloads;
  },
  SET_SHOWLINK(state, payloads) {
    state.showLink = payloads;
  },
  // GET_VEHICLE_TYPES(state, payloads) {
  //   state.vehicle_types = payloads;
  // },
  ADD_JOBSTASK(state, payloads) {
    state.jobs.unshift(payloads);
  },
  DELETE_JOBSTASK(state, payloads) {
    state.jobs = state.jobs.filter((job) => job._id != payloads);
  },
  UPDATE_JOBSTASK(state, payloads) {
    // let index = state.jobs.findIndex((job) => job._id == payloads._id);
    // state.jobs[index] = payloads;
    state.JobsUpdated = payloads;
  },
  SET_JOBSBYPAGINATION(state, payloads) {
    state.byPaginationJobs = payloads;
  },
  DELETE_JOBSBYPAGINATION(state, payloads) {
    state.byPaginationJobs = state.byPaginationJobs.filter(
      (job) => job._id != payloads
    );
  },
  SET_URL(state, payloads) {
    state.url = payloads;
  },
  DO_NOTHING() {},
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
