<template>
  <div>
    <div v-if="loading">
      <Preload />
    </div>
    <div v-else id="login__container">
      <div class="image__container">
        <img
          class="preview-image"
          loading="lazy"
          :src="getRandomImage"
          alt="preview image"
        />
      </div>
      <div class="content__container">
        <div class="content__logo">
          <img
            class="login-logo"
            :src="require('../assets/heliot_logo_xl.png')"
            width="120px"
          />
        </div>
        <div class="form__container">
          <div class="form__title">
            <div class="title">
              <h1>ยินดีต้อนรับกลับมา</h1>
              <img loading="lazy" :src="require('/src/assets/gif/smile.gif')" />
            </div>
            <p>
              <span>⚠️</span> เพื่อความปลอดภัยในการเข้าใช้งาน
              เราขอแนะนำให้คุณเปลี่ยนรหัสผ่าน ทุกๆ 3 เดือน
              และตั้งรหัสผ่านโดยไม่มีคำที่อ่านได้
            </p>
          </div>
          <form class="form__input">
            <CustomInput
              :name="'email'"
              :placeholder="'ชื่อผู้ใช้งาน หรือ อีเมล'"
              :type="'text'"
              :value="username"
              :errorMsg="'กรุณากรอกอีเมล์/ชื่อผู้ใช้งาน'"
              @onChange="onChangeUsername"
              @onEnter="onLoginClick"
            />

            <CustomInput
              :name="'password'"
              :placeholder="'รหัสผ่าน'"
              :type="'password'"
              :value="password"
              :errorMsg="'กรุณากรอกรหัสผ่าน'"
              @onChange="onChangePassword"
              @onEnter="onLoginClick"
            />
          </form>
          <div v-if="authErrorMsg" class="auth-err__msg">
            {{ authErrorMsg }}
          </div>
          <div class="form__option">
            <div class="remember">
              <input type="checkbox" />
              <div>จำฉันไว้</div>
            </div>
            <div class="forget-password" @click="openLineOA">
              <div>ลืมรหัสผ่าน?</div>
            </div>
          </div>
          <div class="form__cta">
            <button
              id="login-button"
              type="button"
              @click="onLoginClick"
              :disabled="!canLogin"
            >
              เข้าสู่ระบบ
            </button>
          </div>
        </div>
        <div class="registration__button" @click="openLineOA">
          <div>สมัครใช้บริการ</div>
        </div>
      </div>
    </div>
    <div style="position: fixed; right: 5px; bottom: 0">
      v{{ currentAppVersion }}
    </div>
  </div>
</template>
<script>
import CustomInput from './components/login/customInput.vue';
import Preload from '@/views/components/preload';
import { mapActions, mapState } from 'vuex';
import { localLogin } from '@/lib/authen';
import AxiosService from '@/plugins/axios';
import endpoints, { currentAppVersion } from '@/config/config';
export default {
  components: {
    CustomInput,
    Preload,
  },
  data() {
    return {
      currentAppVersion,
      username: '',
      password: '',
      indexImage: 0,
      timeInterval: null,
      imageList: [],
    };
  },
  async mounted() {
    await AxiosService.get(
      `${endpoints.strapiAPI}/login-banners?_limit=5&_sort=createdAt:DESC`
    )
      .then((res) => {
        const { data } = res;
        if (data && data.length > 0) {
          this.indexImage = this.getRandomInt(data.length);
          for (const banner of data) {
            const { image } = banner;
            if (image) {
              this.imageList.push(image['url']);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (this.imageList.length > 0) {
      this.timeInterval = setInterval(() => {
        this.setIndexImageValue();
      }, 15000);
    }
  },
  watch: {
    user(auth) {
      if (auth) {
        this.$router.push({ name: 'home' });
      }
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.authen.user,
      loading: (state) => state.loading.loading,
    }),
    getRandomImage() {
      return this.imageList[this.indexImage];
    },
    canLogin() {
      return this.username !== '' && this.password !== '';
    },
    authErrorMsg() {
      return this.$route.query && this.$route.query.message;
    },
  },
  methods: {
    ...mapActions({
      setLoading: 'loading/setLoading',
    }),
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    onLoginClick() {
      this.setLoading(true);
      localLogin(this.username, this.password);
    },
    setIndexImageValue() {
      this.indexImage = this.getRandomInt(this.imageList.length);
    },
    onChangeUsername(username) {
      this.username = username;
    },
    onChangePassword(password) {
      this.password = password;
    },
    openLineOA() {
      window.open('https://lin.ee/XbYL41c', '_blank');
    },

    // sendForgotPasswordEmail() {
    //   forgetPassword(this.registerEmail)
    //     .then(() => {
    //       this.$message.success(
    //         'ระบบส่งข้อมูลการตั้งรหัสผ่านใหม่ไปยังอีเมล์ของท่านแล้ว',
    //         5
    //       );
    //       this.showForgetPasswordModal = false;
    //     })
    //     .catch((error) => {
    //       console.log(error.response.data);
    //       if (error.response.data.message[0])
    //         this.$message.warning(
    //           error.response.data.message[0].messages[0].message,
    //           5
    //         );
    //       else
    //         this.$message.error(
    //           'ขออภัยไม่สามารถส่งอีเมล์ได้ กรุณาลองอีกครั้ง',
    //           5
    //         );
    //       this.showForgetPasswordModal = false;
    //     })
    //     .finally(() => {
    //       this.registerEmail = '';
    //     });
    // },
    // googleSignIn() {
    //   // const firebaseConfig = {
    //   //   apiKey: "AIzaSyDxadx0b9EWOlmk8Hu_7CSs7RZazSChEvM",
    //   //   authDomain: "heliot-dev.firebaseapp.com",
    //   //   databaseURL: "https://heliot-dev.firebaseio.com",
    //   //   projectId: "heliot-dev",
    //   //   storageBucket: "heliot-dev.appspot.com",
    //   //   messagingSenderId: "1004462935129",
    //   //   appId: "1:1004462935129:web:bb784383acd1c18be6b548"
    //   // };

    //   // firebase.initializeApp(firebaseConfig);
    //   // console.log(this.$firebase);
    //   var provider = new firebase.auth.GoogleAuthProvider();
    //   firebase
    //     .auth()
    //     .signInWithPopup(provider)
    //     .then(
    //       () => {
    //         // console.log(firebase.auth().currentUser);
    //         // console.log(user.credential.idToken);
    //         firebase
    //           .auth()
    //           .currentUser.getIdToken(/* forceRefresh */ true)
    //           .then(function (idToken) {
    //             firebaseLogin(idToken);
    //             console.log(idToken);
    //             // Send token to your backend via HTTPS
    //             // ...
    //           })
    //           .catch(function (error) {
    //             // Handle error
    //             console.log(error);
    //           });
    //         // this.$router.replace("home");
    //       },
    //       (err) => {
    //         alert(err.message);
    //       }
    //     );
    // },
    // facebookSignIn() {
    //   // const firebaseConfig = {
    //   //   apiKey: "AIzaSyDxadx0b9EWOlmk8Hu_7CSs7RZazSChEvM",
    //   //   authDomain: "heliot-dev.firebaseapp.com",
    //   //   databaseURL: "https://heliot-dev.firebaseio.com",
    //   //   projectId: "heliot-dev",
    //   //   storageBucket: "heliot-dev.appspot.com",
    //   //   messagingSenderId: "1004462935129",
    //   //   appId: "1:1004462935129:web:bb784383acd1c18be6b548"
    //   // };

    //   // firebase.initializeApp(firebaseConfig);
    //   // console.log(this.$firebase);
    //   var provider = new firebase.auth.FacebookAuthProvider();
    //   firebase
    //     .auth()
    //     .signInWithPopup(provider)
    //     .then(
    //       () => {
    //         // console.log(firebase.auth().currentUser);
    //         // console.log(user.credential.idToken);
    //         firebase
    //           .auth()
    //           .currentUser.getIdToken(/* forceRefresh */ true)
    //           .then(function (idToken) {
    //             firebaseLogin(idToken);
    //             console.log(idToken);
    //             // Send token to your backend via HTTPS
    //             // ...
    //           })
    //           .catch(function (error) {
    //             // Handle error
    //             console.log(error);
    //           });
    //         // this.$router.replace("home");
    //       },
    //       (err) => {
    //         alert(err.message);
    //       }
    //     );
    // },
  },
  destroyed() {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }
  },
};
</script>
<style lang="scss">
#login__container {
  font-size: 16px;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 720px;
  overflow-y: auto;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }

  .image__container {
    @media screen and (max-width: 1024px) {
      display: none;
    }

    display: block;
    height: 100vh;

    .preview-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
<style lang="scss" scoped>
$primary-color-1: #e821aa;
$primary-color-2: #523ee8;
$primary-text: #515151;
$mobile-size: 414px;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  line-height: 100%;
}
.content__container {
  text-align: left;
  margin: 80px;

  @media screen and (max-width: $mobile-size) {
    margin: 48px 24px;
  }
}

.auth-err__msg {
  color: $primary-color-1;
  padding-block-start: 8px;
}

.form__container {
  padding-block-start: 3rem !important;
  .form__title {
    .title {
      display: flex;
      align-items: center;
      width: fit-content;
      > img {
        width: 48px;
        height: 48px;
        object-fit: cover;
      }
    }
    h1 {
      font-size: 2.5rem;
      line-height: 100%;
      font-weight: 300;
    }
    p {
      @media screen and (max-width: $mobile-size) {
        display: none;
      }
      display: block;
      padding-block-start: 24px;
      font-size: 1.125rem;
      line-height: 24px;
    }
  }
  .form__input {
    @media screen and (max-width: $mobile-size) {
      padding-block-start: 24px;
    }
    padding-block-start: 42px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .form__option {
    padding-block-start: 12px;
    display: flex;
    justify-content: space-between;

    .remember {
      display: flex;
      gap: 4px;
      color: #6b6b6b;
      cursor: default;
      input[type='checkbox'] {
        cursor: pointer;
      }
    }
    .forget-password {
      color: $primary-color-1;
      cursor: pointer;
    }
  }

  .form__cta {
    padding-block-start: 36px;
    #login-button {
      display: block;
      width: 100%;
      height: 48px;
      padding: 12px;

      outline: none !important;
      border: none !important;
      border-radius: 16px;
      background-color: $primary-color-2;
      color: #ffffff;

      text-align: center;
      font-size: 1.25rem;
      line-height: 24px;
      font-weight: 500;
      cursor: pointer;

      &:enabled {
        &:hover {
          opacity: 0.8;
        }
      }

      &:disabled {
        cursor: not-allowed;
        background-color: #e0e0e0;
      }
    }
  }
}
.registration__button {
  width: fit-content;
  margin-block-start: 130px;
  margin-inline: auto;
  text-decoration: underline;
  font-size: 1.25rem;
  color: $primary-text;
  cursor: pointer;
}
</style>
