import AxiosService from '@/plugins/axios';
import endpoints from '../../config/config';

const headers = {
    "Content-Type": "application/json; charset=utf-8"
}

const state = () => ({
    geoActivityAlertsData: []
})

const getters = {};
const actions = {
    async getAllGeoActAlerts({ commit }, cg_id) {
        const response = await AxiosService.get(
            `${endpoints.strapiAPI}/geo-activity-alerts?customer_group=${cg_id}`,
            { headers }
        )
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error('Get geo activity alerts error:' + error);
            })
        commit('GET_ALL_GEO_ACT_ALERTS', response)
    },

    async createGeoActAlertsItem({commit}, payload) {
        try {
            const response = await AxiosService.post(
              `${endpoints.strapiAPI}/geo-activity-alerts`,
              payload,
              { headers }
            )
            commit('CREATE_GEO_ACT_ALERTS_ITEM', response.data);
            return response
        } catch (error) {
            console.log('Creating geo activity alerts item error', error);
            throw error;
        }
    },

    async updateGeoActAlertsItem({commit}, payload) {
        try {
            const response = await AxiosService.put(
                `${endpoints.strapiAPI}/geo-activity-alerts/${payload[0]}`,
                payload[1],
                { headers }
            )
            commit('UPDATE_GEO_ACT_ALERTS_ITEM', response.data);
            return response;
        } catch (error) {
            console.error('Updating geo activity alerts item error', error);
            throw error;
        }
    },

    async deleteGeoActAlertsItem({commit}, deleteItemData) {
        try {
            await AxiosService.delete(`${endpoints.strapiAPI}/geo-activity-alerts/${deleteItemData[0]}`, { headers })
            commit('DELETE_GEO_ACT_ALERTS_ITEM', deleteItemData[1]);
        } catch (error) {
            console.error('Deleting geo activity alerts item error', error);
            throw error;
        }
    }
};

const mutations = {
    GET_ALL_GEO_ACT_ALERTS(state, payload){
        state.geoActivityAlertsData = payload;
    },
    CREATE_GEO_ACT_ALERTS_ITEM(state, payload){
        state.geoActivityAlertsData.push(payload);
    },
    UPDATE_GEO_ACT_ALERTS_ITEM(state, payload){
        state.geoActivityAlertsData = state.geoActivityAlertsData.map((item) => 
            item.id === payload.id ? payload : item
        )
    },
    DELETE_GEO_ACT_ALERTS_ITEM(state, item_id){
        state.geoActivityAlertsData.splice(item_id, 1);
        state.geoActivityAlertsData = state.geoActivityAlertsData.filter((item) => item.id !== item_id);
    }

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}