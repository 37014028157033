import moment from '@/lib/time';
import Excel from 'exceljs';
import AxiosService from '@/plugins/axios';
import endpoints from '../../config/config';
import {
  fontNormal,
  sheetMargin,
  hightlightTableHead,
  blobToFile,
  imeiToPlateNumber,
  formatDate,
  hightlightRow,
  generateLogoHeader,
  addLogoCompany,
} from './config';
import * as Sentry from '@sentry/vue';
import store from '@/store';

const prepareDataForExcel = (
  fileName,
  vehicles,
  reportByVehicle,
  start,
  stop,
  logo,
  isSummarizedByDay
) => {
  const tableHead = ['ที่', 'เหตุการณ์', 'ค่ารายงาน', 'หน่วย'];
  const columnsSpec = [
    {
      key: 'no',
      width: 5,
      style: {
        numFmt: '0',
        font: fontNormal,
      },
    },
    {
      key: 'item',
      width: 42,
      style: {
        font: fontNormal,
      },
    },
    {
      key: 'amount',
      width: 18,
      style: {
        numFmt: '0',
        font: fontNormal,
        bold: true,
      },
      alignment: {
        vertical: 'middle',
        horizontal: 'right',
      },
    },
    {
      key: 'unit',
      width: 32,
      style: {
        font: fontNormal,
      },
    },
  ];
  genSummeryExcel({
    fileName,
    reportByVehicle,
    tableHead,
    columnsSpec,
    vehicles,
    start,
    stop,
    logo,
    isSummarizedByDay
  });
};

const getSummaryReport = async (vehicles, imeis, start, stop, logo, isSummarizedByDay) => {
  const strTimerange =
    moment.unix(start).format('D_MMM_YY') +
    '-ถึง-' +
    moment.unix(stop).format('D_MMM_YY');
  await AxiosService.post(`${endpoints.coreApi}/report-summary-event`, {
    eventType: 'END_TRIP',
    imeis: imeis,
    start: start,
    stop: stop,
    isSummarizedByDay
  })
    .then(async (response) => {
      const fileName = 'Summary_Report_' + strTimerange;
      prepareDataForExcel(fileName, vehicles, response.data, start, stop, logo, isSummarizedByDay);
    })
    .catch((error) => {
      console.log('An error occurred:', error);
      Sentry.captureException(error);
      store.dispatch('loading/setReportLoading', false);
    });
};

export const genSummeryExcel = async ({
  fileName,
  reportByVehicle,
  tableHead,
  columnsSpec,
  vehicles,
  start,
  stop,
  logo,
  isSummarizedByDay
}) => {
  const workbook = new Excel.Workbook();
  const pageProperties = {
    properties: {
      tabColor: {
        argb: 'FF41C173',
      },
    },
    pageSetup: {
      paperSize: 9,
      orientation: 'portrait',
      fitToPage: true,
    },
    headerFooter: {
      differentFirst: false,
      firstFooter:
        'โดย บริษัทเฮลลิออท จำกัด https://www.heliot.co.th อีเมล์ contact@heliot.co.th',
    },
  };
  let imageLogoId;

  if (logo && logo.url) {
    const ext = logo.ext.substring(1);
    const myBase64Image = await generateLogoHeader(logo);
    imageLogoId = workbook.addImage({
      base64: myBase64Image,
      extension: ext,
    });
  }

  if (Object.keys(reportByVehicle).length > 0) {
    Object.keys(reportByVehicle).forEach((imei) => {
      let startingRow = 1;
      let cnt = 0;
      let sheet = null;
      let plateNo = '';


      const dataEachVehicle = reportByVehicle[imei];
      if (cnt == 0) {
        plateNo = imeiToPlateNumber(vehicles, Number(imei));
        sheet = workbook.addWorksheet(plateNo, pageProperties);
        sheet.pageSetup.margins = sheetMargin;
        sheet.columns = columnsSpec;
      }

      if (logo && logo.url) {
        startingRow = 2;
        addLogoCompany(sheet, imageLogoId);
      }

      if (isSummarizedByDay) {

        let tableCount = 0;
        for (const date in dataEachVehicle) {
          tableCount = tableCount + 1;
          const dataByDate = dataEachVehicle[date]

          let startDate = moment(date).hour(0).minute(0).second(0);
          let stopDate = moment(date).hour(23).minute(59).second(59);

          let startDateUnix = parseInt(startDate.valueOf() / 1000);
          let endDateUnix = parseInt(stopDate.valueOf() / 1000);


          if (tableCount === 1) {
            startDateUnix = start
          }

          if (tableCount === Object.keys(dataEachVehicle).length) {
            endDateUnix = stop
          }

          const metaRow1 = `รายงานสรุป พาหนะทะเบียน ${plateNo} เวลาเริ่ม ${formatDate(
            startDateUnix
          )} ถึง ${formatDate(endDateUnix)}`;

          sheet.addRow([metaRow1]);
          sheet.addRow([]);
          sheet.addRow(tableHead);

          let cntRow = 1;
          for (const k in dataByDate) {
            sheet.addRow([
              cntRow,
              dataByDate[k].title,
              dataByDate[k].value,
              dataByDate[k].unit,
            ]);
            cntRow++;
          }
          sheet.addRow([]);
          sheet.addRow([]);
          hightlightRow(sheet, startingRow);
          hightlightTableHead(sheet, startingRow + 2);

          sheet.getColumn('amount').eachCell((cell, rowNum) => {
            if (rowNum > startingRow + 2) {
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'right',
              };
              cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {
                  argb: 'FFF0EFFC',
                },
                bgColor: {
                  argb: 'FF0000FF',
                },
              };
              cell.border = {
                top: {
                  style: 'thin',
                },
                left: {
                  style: 'thin',
                },
                bottom: {
                  style: 'thin',
                },
                right: {
                  style: 'thin',
                },
              };
            }
          });

          startingRow = startingRow + Object.keys(dataByDate).length + 5
        }

      }
      else {
        let lastRow = 0;
        const metaRow1 = `รายงานสรุป ของ พาหนะทะเบียน ${plateNo} เวลาเริ่ม ${formatDate(
          start
        )} ถึง ${formatDate(stop)}`;

        sheet.addRow([metaRow1]);

        sheet.addRow([]);
        sheet.addRow(tableHead);
        let cntRow = 1;
        for (const k in dataEachVehicle) {
          sheet.addRow([
            cntRow,
            dataEachVehicle[k].title,
            dataEachVehicle[k].value,
            dataEachVehicle[k].unit,
          ]);
          cntRow++;
        }
        sheet.addRow([]);
        sheet.addRow([]);

        hightlightRow(sheet, startingRow);
        hightlightTableHead(sheet, startingRow + 2 + lastRow);

        sheet.getColumn('amount').eachCell((cell, rowNum) => {
          if (rowNum > startingRow + 2) {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'right',
            };
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb: 'FFF0EFFC',
              },
              bgColor: {
                argb: 'FF0000FF',
              },
            };
            cell.border = {
              top: {
                style: 'thin',
              },
              left: {
                style: 'thin',
              },
              bottom: {
                style: 'thin',
              },
              right: {
                style: 'thin',
              },
            };
          }
        });
      }

      cnt++;
    });
  } else {
    workbook.addWorksheet('ไม่พบข้อมูล', pageProperties);
  }

  blobToFile(workbook, fileName);
};

export default getSummaryReport;
