import {
  firebaseVapidKey,
  firebaseAPIServer
} from "@/config/config";
import axios from 'axios';
// initial state
// shape: [{ id, quantity }]
const state = () => ({
  deviceToken: null,
  permission: null,
  notifications: [],
  offset: 0,
  lastRead: 0,
  unreadCount: 0
});

// getters
const getters = {
  getUnread: (state) => {
    // console.log('driverByLicenseNo',state.drivers.length);
    return state.notifications.filter((noti) => {
      noti.arrive > state.lastRead
    }).length;

  },
};

// actions
const actions = {
  requestPermission({
    commit
  }, payload) {
    console.log('request requestPermission')
    payload.messaging.requestPermission().then(async () => {
      console.log('Notification permission granted.');
      await commit("RESET_STATE");

      payload.messaging.getToken({
        vapidKey: firebaseVapidKey
      }).then(async (currentToken) => {


        if (currentToken) {
          // console.log('currentToken', currentToken)
          await axios({
            method: 'post',
            url: `${firebaseAPIServer}/register/groupregister`,
            data: {
              deviceToken: currentToken,
              customerGroups: payload.customerGroups,
              userid: payload.userid
            },

          }).then(() => { //messageObj
            // console.log('firebase token granted, device group created', messageObj);

            // axios({
            //   method: 'post',
            //   url: `${firebaseAPIServer}/creategroup`,
            //   data: {
            //     deviceToken: currentToken,
            //     customerGroups: payload.customerGroups,
            //     userid: payload.userid
            //   },

            // }).then((message) => {
            //   console.log(message);

            // });

            /* set current token to localStorage to remember that it has been sent to server */
            if (typeof (Storage) !== "undefined") {
              localStorage.setItem("token", currentToken);
            }
          });


          // sendTokenToServer(currentToken);

        } else {
          // Show permission request.
          console.log('No Instance ID token available. Request permission to generate one.');
          // Show permission UI.
          commit("SET_PERMISSION", 'DENIED');
          // updateUIForPushPermissionRequired();
          // setTokenSentToServer(false);
        }
        commit("SET_TOKEN", currentToken);
      }).catch(function (err) {
        console.log('An error occurred while retrieving token. ', err);
        // showToken('Error retrieving Instance ID token. ', err);
        // setTokenSentToServer(false);
      });

      // [END_EXCLUDE]
    }).catch(function (err) {
      console.log('Unable to get permission to notify.', err);
      commit("SET_PERMISSION", 'DENIED');
    });
  },
  addNoti({
    commit
  }, payload) {
    // console.log('payload', payload)
    commit("ADD_NOTI", payload);
  },
  replaceNoti({
    commit
  }, payload) {
    // console.log('payload', payload)
    commit("REPLACE_NOTI", payload);
  },
  setOffset({
    commit
  }, payload) {
    // console.log('payload', payload)
    commit("SET_OfFSET", payload);
  },
  setUnread({
    commit
  }, payload) {
    // console.log('payload', payload)
    commit("SET_UNREAD", payload);
  },
  addOneUnread({
    commit
  }, payload) {
    // console.log('payload', payload)
    commit("ADD_ONE_UNREAD", payload);
  },
  setLastRead({
    commit
  }, payload) {
    // console.log('payload', payload)
    commit("SET_LAST_READ", payload);
  },
  resetState({
    commit
  }) {
    commit("RESET_STATE");
  },
};
// mutations
const mutations = {
  SET_TOKEN(state, payload) {
    state.deviceToken = payload;
  },
  SET_PERMISSION(state, payload) {
    state.permission = payload;

  },
  ADD_NOTI(state, payload) {
    state.notifications.push(...payload);
  },
  REPLACE_NOTI(state, payload) {
    state.notifications = payload;
  },
  SET_OfFSET(state, payload) {
    state.offset = payload;
  },
  SET_LAST_READ(state, payload) {
    state.lastRead = payload;
  },
  SET_UNREAD(state, payload) {
    state.unreadCount = payload;
  },
  ADD_ONE_UNREAD(state) {
    state.unreadCount += 1;
  },
  RESET_STATE(state) {
    state.deviceToken = null;
    state.permission = false;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};