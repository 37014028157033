<template>
  <defaultLayout>
    <div class="trip">
      <tripDrawer @setCenterPath="setCenterPath" />
      <div
        :class="[showTripInfo ? 'google-map-tripinfo' : 'google-map']"
        :style="getWidthOfMap()"
      >
        <div class="google-map-toolbar">
          <div class="toggle-geofence-button" @click="toggleGeofence">
            <a-checkbox :checked="showGeofence" />แสดงสถานี
          </div>
          <div
            class="google-map-button"
            @click="showGeofenceModal"
            v-show="selectedPosition"
          >
            สร้างสถานี
          </div>
        </div>
        <GmapMap
          key="mapInTripPage"
          :center="{ lat: 13.7460067, lng: 100.5440535 }"
          :zoom="this.mapZoom"
          map-type-id="roadmap"
          style="width: 100%; height: 99vh"
          ref="TripGoogleMap"
          @drag="startDragMap"
          @bounds_changed="startDragMap"
          @idle="endDragMap"
          @zoom_changed="zoomLevelChange"
          :options="{
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: false,
            streetViewControl: true,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: true,
            zoomControlOptions: {
              position: 3,
            },
            streetViewControlOptions: {
              position: 3,
            },
            clickableIcons: true,
          }"
        >
          <template v-if="mapMoving === false">
            <!-- Route Line  -->
            <gmap-polyline
              v-if="path.length > 0"
              v-bind:path="path"
              :key="`${'line' + Math.random()}`"
              :editable="false"
              ref="vehicleTrip"
              :options="{ strokeColor: '#3633FF', strokeWeight: 3 }"
            ></gmap-polyline>
            <!-- Direction Icon -->
            <gmap-custom-marker
              :key="`direction-icon-${position.timestamp}`"
              v-for="position in positionsDirection"
              :marker="{
                lat: position.latitude / 10000000,
                lng: position.longitude / 10000000,
              }"
              alignment="center"
            >
              <a-icon
                type="caret-up"
                :class="['direction-arrow', position.engineStatus]"
                v-bind:style="{
                  transform: getDirectionClass(position.angle),
                }"
              />
            </gmap-custom-marker>
            <!-- Car Icon -->
            <gmap-custom-marker
              :key="`position-${selectedPosition.timestamp + Math.random()}`"
              v-if="selectedPosition"
              :marker="{
                lat: selectedPosition
                  ? selectedPosition.latitude / 10000000
                  : 0,
                lng: selectedPosition
                  ? selectedPosition.longitude / 10000000
                  : 0,
              }"
              :offsetX="-20"
              :offsetY="-20"
            >
              <img
                :class="[
                  'device-marker',
                  'vehicle-status',
                  getFilterClass(
                    selectedPosition
                      ? selectedPosition.engineStatus
                      : 'ENGINE_STOP'
                  ),
                ]"
                src="../assets/top-car4.png"
                v-bind:style="{
                  transform: getDirectionClass(
                    selectedPosition ? selectedPosition.angle : 0
                  ),
                }"
              />
            </gmap-custom-marker>
          </template>
          <template
            v-if="
              showGeofence === true &&
              mapMoving === false &&
              showBoundryGeofence === true
            "
          >
            <!-- Geofence -->
            <GmapCircle
              v-for="(geofence, index) in geofencesCircle"
              :key="`geofence-circle-${index}`"
              :center="geofence.Positions[0]"
              :radius="geofence.radius"
              :visible="showGeofence"
              :options="{
                fillColor: '#00c246',
                fillOpacity: 0.3,
                strokeColor: '#00c246',
                strokeOpacity: 0.8,
              }"
            ></GmapCircle>
            <!-- Geofence -->
            <GmapPolygon
              v-for="(geofence, index) in geofencesPolygon"
              :key="`geofence-polygon-${index}`"
              :path="geofence.Positions"
              :visible="showGeofence"
              :options="{
                fillColor: '#00c246',
                fillOpacity: 0.3,
                strokeColor: '#00c246',
                strokeOpacity: 0.8,
              }"
            />
            <!-- Geofence Name-->
            <gmap-custom-marker
              v-for="(geofence, index) in geofencesList"
              :key="`geofence-name-${index}`"
              :marker="geofence.Positions[0]"
              alignment="center"
              v-show="showGeofence"
            >
              <div class="geofence-name" v-show="showGeofence">
                {{ geofence.name }}
              </div>
            </gmap-custom-marker>
          </template>
          <template
            v-if="showGeofence === true && mapMoving === false && mapZoom < 16"
          >
            <gmap-marker
              :key="index"
              v-for="(m, index) in geofencesList"
              :position="{
                lat: parseFloat(m.Positions[0].lat),
                lng: parseFloat(m.Positions[0].lng),
              }"
              :clickable="true"
              :draggable="false"
              @click="setCenterPathGeofence(m)"
            />
          </template>
        </GmapMap>
      </div>
    </div>
    <a-modal
      :visible="createGeofenceModalVisible"
      :confirm-loading="saveGeofenceLoading"
      @ok="createGeofenceSubmit"
      @cancel="cancelCreateGeofence"
    >
      <h2>สร้างสถานีจากตำแหน่ง {{ selectedVehicleTrip.plate_number }}</h2>
      <a-form layout="inline" :form="form">
        <a-row>
          <a-col :span="24">
            <a-input
              placeholder="ชื่อสถานี"
              :maxLength="30"
              v-model="geofenceName"
            />
          </a-col>
        </a-row>
        <a-row style="margin-top: 10px">
          <a-col :span="24">
            <a-input
              type="number"
              addon-before="รัศมี"
              addon-after="เมตร"
              v-model="geofenceRadius"
              :min="10"
              :max="500.0"
              :step="10"
            />
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </defaultLayout>
</template>

<script>
import defaultLayout from '@/views/layouts/defaultLayout.vue';
import tripDrawer from '@/views/components/tripDrawer';
import { mapState, mapGetters, mapActions } from 'vuex';
import { getVehicles } from '../lib/vehicle';
import CustomMarker from '@/views/components/customMarker';
import { isMobile } from 'mobile-device-detect';
import { getGoogleMapsAPI } from 'vue2-google-maps';
import MeasureTool from 'measuretool-googlemaps-v3';
const geolib = require('geolib');
export default {
  name: 'trip',
  components: {
    defaultLayout,
    tripDrawer,
    'gmap-custom-marker': CustomMarker,
  },
  data() {
    return {
      boundRect: [],
      showBoundryGeofence: false,
      mapMoving: false,
      mapZoom: 12,
      googleMap: {},
      path2: [
        { lat: 1.33, lng: 103.75 },
        { lat: 1.43, lng: 103.85 },
      ],
      createGeofenceModalVisible: false,
      geofenceRadius: 0.0,
      geofenceName: '',
      form: this.$form.createForm(this, { name: 'createGeofenceForm' }),
      showGeofence: false,
      geofencesCircle: [],
      geofencesPolygon: [],
      geofencesList: [],

      //MD
      measureTool: null,
    };
  },
  computed: mapState({
    google: getGoogleMapsAPI,
    selectedGroup: (state) => state.authen.selectedGroup,
    user: (state) => state.authen.user,
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      vehicles: 'vehicle/vehicles',
      path: 'position/path',
      positionsDirection: 'position/direction',
      positions: 'position/positions',
    }),
    selectedVehicleTrip: (state) => {
      // console.log(state.trip.selectedVehicleTrip);
      return state.trip.selectedVehicleTrip;
    },
    saveGeofenceLoading: (state) => state.loading.saveGeofenceLoading,
    positions: (state) => state.position.positions,
    showTripInfo: (state) => state.trip.showTripInfo,
    selectedPosition: (state) => state.trip.selectedPosition,
    geofenceData: (state) => state.geofence.geofences,
  }),
  async mounted() {
    // console.log('isMobile', isMobile)
    if (this.vehicles.length <= 0) {
      await getVehicles(
        this.user.customer_groups.map((group) => {
          return group._id;
        })
      );
    }
    if (this.geofenceData.length <= 0) {
      this.getAllGeofences([this.selectedGroup.id]).then(() => {
        // this.setGeofenceData();
        // this.getGeofenceInBound();
        // this.setCenterAll();
      });
    }
    // console.log(this.path);

    this.$refs.TripGoogleMap.$mapPromise.then((map) => {
      this.measureTool = new MeasureTool(map);
    });
  },
  beforeDestroy() {
    console.log('destroy');
    this.resetPositionState();
    this.resetVehicleState();
  },
  methods: {
    ...mapActions({
      getAllGeofences: 'geofence/getAllGeofences',
      resetVehicleState: 'vehicle/resetState',
      resetPositionState: 'position/resetState',
      setSaveGeofenceLoading: 'loading/setSaveGeofenceLoading',
      resetGeofenceState: 'geofence/resetState',
      createGeofence: 'geofence/createGeofence',
    }),
    getDirectionClass(degree) {
      const direction = degree / 100;
      return `rotate(${direction}deg)`;
    },
    getWidthOfMap() {
      if (this.showTripInfo) {
        if (isMobile) {
          return 'width: calc(100vw - 400px)';
        } else {
          return 'width: calc(100vw - 660px)';
        }
      } else {
        return 'width: calc(100vw - 460px)';
      }
    },
    setCenterPath() {
      this.$refs.TripGoogleMap.$mapPromise.then((map) => {
        let bounds = new window.google.maps.LatLngBounds();
        for (let i = 0; i < this.path.length; i++) {
          bounds.extend(this.path[i]);
        }
        map.fitBounds(bounds);
        map.panTo(bounds.getCenter());
        // map.setZoom(16);
      });
    },

    setCenterPathGeofence(geofence) {
      if (geofence.type == 'circle') {
        this.$refs.TripGoogleMap.$mapPromise.then((map) => {
          const circle = new window.google.maps.Circle({
            map: map,
            radius: geofence.radius, // 3000 km
            center: {
              lat: parseFloat(geofence.Positions[0].lat),
              lng: parseFloat(geofence.Positions[0].lng),
            },
            fillColor: '#fff',
            fillOpacity: 0.0,
            strokeColor: '#fff',
            strokeOpacity: 0.0,
          });
          var bounds = new window.google.maps.LatLngBounds();
          bounds.union(circle.getBounds());
          map.fitBounds(bounds);
          map.panTo(circle.getBounds().getCenter());
        });
      } else {
        this.$refs.TripGoogleMap.$mapPromise.then((map) => {
          let bounds = new window.google.maps.LatLngBounds();
          for (let i = 0; i < geofence.Positions.length; i++) {
            bounds.extend(geofence.Positions[i]);
          }
          map.fitBounds(bounds);
          map.panTo(bounds.getCenter());
          map.setZoom(17);
        });
      }
    },
    getFilterClass(status) {
      if (status == 'VEHICLE_MOVING') return 'running';
      else if (status == 'ENGINE_IDLE') return 'idle';
      else if (status == 'OVER_SPEED') return 'overspeed';
      else return 'stop';
    },
    showGeofenceModal() {
      this.createGeofenceModalVisible = true;
    },
    async toggleGeofence() {
      //////// GA /////////
      this.$gtag.event('trip_google-map-geofences_displayed');
      ////////////////////
      this.showGeofence = !this.showGeofence;
      if (this.showGeofence) {
        if (this.geofenceData.length <= 0) {
          await this.getAllGeofences([this.selectedGroup.id]);
          this.setGeofenceData();
        }
      }
    },
    async createGeofenceSubmit() {
      // console.log(this.selectedVehicle, this.geofenceName, this.geofenceRadius);
      // console.log(this.selectedPosition);
      if (
        !(
          this.selectedPosition &&
          this.geofenceName.length > 0 &&
          this.geofenceRadius > 0
        )
      ) {
        this.$notification['error']({
          message: 'ข้อมูลสถานีไม่ถูกต้อง',
          description: 'กรุณาตรวจสอบข้อมูลสถานี และมีพาหนะที่กำลังเลือกอยู่',
        });
        return;
      }

      this.setSaveGeofenceLoading(true);

      let geojson = {
        type: 'Point',
        coordinates: [
          this.selectedPosition.longitude / 10000000,
          this.selectedPosition.latitude / 10000000,
        ],
      };
      await this.createGeofence({
        name: this.geofenceName,
        type: 'circle',
        radius: parseFloat(this.geofenceRadius),
        org: this.selectedGroup.id,
        positions: [
          {
            lat: this.selectedPosition.latitude / 10000000,
            lng: this.selectedPosition.longitude / 10000000,
          },
        ],
        geojson,
      })
        .then(async (saveResult) => {
          if (saveResult) {
            // console.log(saveResult);
            this.setSaveGeofenceLoading(false);
            this.geofenceName = '';
            this.geofenceRadius = 0.0;
            this.createGeofenceModalVisible = false;

            await this.getAllGeofences([this.selectedGroup.id]);
            this.showGeofence = true;
            this.setCenterPathGeofence(saveResult.data.createGeofence.geofence);

            this.$notification['success']({
              message: 'บันทึกข้อมูลเรียบร้อยแล้ว',
              description:
                'ข้อมูสถานีถูกบันทึกเรียบร้อยแล้ว การประมวลผลการเข้าออกสถานีจะใช้เวลาหลังจากสร้างสถานี 5-10 นาที',
            });
          } else {
            this.$notification['error']({
              message: 'เกิดข้อผิดพลาด',
              description: 'ไม่สามารถบันทึกข้อมูลสถานีได้ กรุณาลองอีกครั้ง',
            });
            this.setSaveGeofenceLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notification['error']({
            message: 'เกิดข้อผิดพลาด',
            description: 'ไม่สามารถบันทึกข้อมูลสถานีได้ กรุณาลองอีกครั้ง',
          });
          this.setSaveGeofenceLoading(false);
        });
    },
    cancelCreateGeofence() {
      this.createGeofenceModalVisible = false;
    },
    setGeofenceData() {
      this.geofencesCircle = [];
      this.geofencesPolygon = [];
      this.geofencesList = [];
      this.$nextTick(() => {
        // console.log('setGeofenceData', this.geofenceData)
        for (const k in this.geofenceData) {
          const geofence = this.geofenceData[k];
          let isIn = false;
          if (this.boundRect.length > 0) {
            if (geofence.type == 'polygon') {
              let centerPolygon = geolib.getCenterOfBounds(geofence.Positions);
              isIn = geolib.isPointInPolygon(
                {
                  latitude: centerPolygon.latitude,
                  longitude: centerPolygon.longitude,
                },
                this.boundRect
              );
            } else {
              isIn = geolib.isPointInPolygon(
                {
                  latitude: geofence.Positions[0].lat,
                  longitude: geofence.Positions[0].lng,
                },
                this.boundRect
              );
            }
          } else {
            isIn = true;
          }
          if (isIn) {
            if (geofence.type == 'circle') {
              this.geofencesCircle.push(geofence);
            } else if (geofence.type == 'polygon') {
              this.geofencesPolygon.push(geofence);
            }
            this.geofencesList.push(geofence);
          }
        }
        // console.log('circle',this.geofencesCircle);
        // console.log('polygon', this.geofencesPolygon);
        // console.log('all',this.geofencesList);

        return this.geofencesList;
      });
    },
    startDragMap() {
      this.mapMoving = true;
    },
    endDragMap() {
      setTimeout(() => {
        if (this.$refs.TripGoogleMap && this.$refs.TripGoogleMap.$mapObject) {
          // console.log("mapMoving " ,this.mapMoving);
          const neBound = this.$refs.TripGoogleMap.$mapObject
            .getBounds()
            .getNorthEast();
          const swBound = this.$refs.TripGoogleMap.$mapObject
            .getBounds()
            .getSouthWest();

          const lat0 = neBound.lat();
          const lng0 = neBound.lng();
          const lat1 = swBound.lat();
          const lng1 = swBound.lng();
          const boundRect = [
            { latitude: lat0, longitude: lng0 },
            { latitude: lat0, longitude: lng1 },
            { latitude: lat1, longitude: lng1 },
            { latitude: lat1, longitude: lng0 },
          ];
          if (this.mapMoving === true) {
            this.mapMoving = false;
            this.setGeofenceData();
            this.boundRect = boundRect;
          } else {
            this.boundRect = [];
          }
        }
        // console.log(this.boundRect);
      }, 800);
    },
    zoomLevelChange(zoomLevel) {
      this.mapZoom = zoomLevel;
      let obj = this;
      setTimeout(() => {
        if (zoomLevel >= 16) {
          obj.showBoundryGeofence = true;
        } else {
          obj.showBoundryGeofence = false;
        }
      }, 1000);
    },
  },
  watch: {
    selectedPosition: function (newPosition) {
      if (
        this.$refs.TripGoogleMap &&
        newPosition &&
        newPosition.latitude &&
        newPosition.longitude
      )
        this.$refs.TripGoogleMap.$mapPromise.then((map) => {
          map.setCenter(
            new window.google.maps.LatLng(
              newPosition.latitude / 10000000,
              newPosition.longitude / 10000000
            )
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.google-map {
  width: calc(100vw - 460px);
  display: inline-block;
  position: absolute;
}
.google-map-tripinfo {
  display: inline-block;
  position: absolute;
  right: 0;
}
.direction-arrow {
  font-size: 1.2rem;
  &.ENGINE_STOP {
    color: #f5223e;
  }
  &.ENGINE_IDLE,
  &.ENGINE_IDLE_END {
    color: #faad14;
  }
  &.OVER_SPEED {
    color: #8e34ff;
  }
  &.VEHICLE_MOVING {
    color: #00c246;
  }
}
.device-marker {
  width: 40px;
  position: absolute;
  z-index: 99;
}
.running {
  // filter: invert(41%) sepia(72%) saturate(1296%) hue-rotate(87deg)
  //   brightness(102%) contrast(111%);
  filter: grayscale(50%) hue-rotate(240deg) saturate(3.3);
}
.idle {
  filter: grayscale(32%) hue-rotate(210deg) saturate(3.8);
}
.stop {
  // filter: invert(56%) sepia(5%) saturate(16%) hue-rotate(136deg) brightness(95%)
  //   contrast(88%);
  filter: grayscale(30%) hue-rotate(153deg) saturate(3.3);
}
.overspeed {
  //filter: invert(24%) sepia(90%) saturate(3678%) hue-rotate(258deg) brightness(65%) contrast(90%);
  filter: grayscale(58%) hue-rotate(68deg) saturate(2.5);
}

.google-map-toolbar {
  direction: ltr;
  overflow: hidden;
  text-align: center;
  height: 40px;
  display: table-cell;
  vertical-align: middle;
  position: relative;
  user-select: none;
  background-color: rgb(255, 255, 255);
  padding: 0px;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  background-clip: padding-box;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  min-width: 66px;
  border-left: 0px;
  z-index: 9999;

  position: absolute;
  left: 205px;
  top: 10px;
  .google-map-button {
    position: relative;
    display: inline-block;
    height: 40px;
    cursor: pointer;
    padding: 7px 17px;
    font-size: 1rem;
    color: rgb(86, 86, 86);
    font-family: 'Prompt', Arial, sans-serif;
    font-weight: bold;
    &:hover {
      background-color: #ddd;
    }
  }
  .toggle-geofence-button {
    position: relative;
    display: inline-block;
    height: 40px;
    cursor: pointer;
    padding: 7px 17px;
    font-size: 1rem;
    color: rgb(86, 86, 86);
    font-family: 'Prompt', Arial, sans-serif;
    font-weight: bold;
    &:hover {
      background-color: #ddd;
    }
  }
}

//Measure Distance
.segment-measure-text {
  font-size: 1rem;
  pointer-events: none;
  text-shadow: none;

  /* &.inverted {
    stroke: white;
    text-shadow: -2.4px -2.4px $alpha-black, -2.4px 2.4px $alpha-black,
      2.4px 2.4px $alpha-black, 2.4px -2.4px $alpha-black, -2.4px 0 $alpha-black,
      0 2.4px $alpha-black, 2.4px 0 $alpha-black, 0 -2.4px $alpha-black;
  } */
}

.node-measure-text {
  font-size: 1rem;
  pointer-events: none;
  text-shadow: none;

  /* &.inverted {
    stroke: white;
    text-shadow: -2.4px -2.4px $alpha-black, -2.4px 2.4px $alpha-black,
      2.4px 2.4px $alpha-black, 2.4px -2.4px $alpha-black, -2.4px 0 $alpha-black,
      0 2.4px $alpha-black, 2.4px 0 $alpha-black, 0 -2.4px $alpha-black;
  } */

  &.head-text {
    visibility: hidden;
  }
}
</style>
