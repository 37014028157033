<template>
  <defaultLayout>
    <div>
      <a-row>
        <a-col :span="24">
          <div class="help-message-container">
            <div class="help-message">
              <a-row>
                <a-col :lg="18" :md="18" :xs="24">
                  <div class="message">
                    <h1>ต้องการเรียนรู้วิธีใช้งาน?</h1>
                    <p>
                      <a-icon
                        type="alert"
                      />เพื่อให้ลูกค้าใช้งานซอฟท์แวร์ได้ง่าย
                      และเต็มประสิทธิภาพมากขึ้น
                      เราได้จัดทำคู่มือที่ง่ายต่อความเข้าใจพร้อมภาพประกอบในแต่ละส่วน
                      นอกจากนี้ลูกค้ายังสามารถโทรสอบถามข้อมูลได้ที่ศูนย์บริการลูกค้า
                      02-010-6990 หรือ
                      <a href="mailto:contact@heliot.co.th"
                        >contact@heliot.co.th</a
                      >
                      เสมอ
                      <!-- <a-button type="primary" size="small">
                        อ่านคู่มือ
                      </a-button>-->
                    </p>
                  </div>
                </a-col>
                <a-col :lg="6" :md="6" :xs="0">
                  <div class="help-image">
                    <img
                      class="login-image"
                      :src="require('../assets/mechanic.png')"
                    />
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <a-col class="vehicle-status-container" :span="24">
          <a-row>
            <a-col class="status-container" :lg="6" :md="12">
              <div class="status" v-if="vehicles">
                <h3>เคลื่อนที่</h3>
                <div class="amount amount-moving">
                  {{
                    vehicles &&
                      vehicles[0] &&
                      vehicles[0].device.position &&
                      vehicles.filter((vehicle) => {
                        if (vehicle.device && vehicle.device.position) {
                          return (
                            vehicle.device.position.engineStatus ==
                            "VEHICLE_MOVING"
                          );
                        } else {
                          return false;
                        }
                      }).length
                  }}
                </div>
                <div class="percent">
                  <a-progress
                    :percent="getPercent('VEHICLE_MOVING')"
                    size="small"
                    strokeColor="#00c246"
                    :format="(percent) => percent.toFixed(2) + '%'"
                  />
                </div>
              </div>
              <div class="status" v-else>
                <a-icon type="loading" />
              </div>
            </a-col>
            <a-col class="status-container" :lg="6" :md="12">
              <div class="status" v-if="vehicles">
                <h3>จอดติดเครื่องยนต์</h3>
                <div class="amount amount-idle">
                  {{
                    vehicles &&
                      vehicles[0] &&
                      vehicles[0].device.position &&
                      vehicles.filter((vehicle) => {
                        if (vehicle.device && vehicle.device.position) {
                          return (
                            vehicle.device.position.engineStatus ==
                            "ENGINE_IDLE"
                          );
                        } else {
                          return false;
                        }
                      }).length
                  }}
                </div>
                <div class="percent">
                  <a-progress
                    :percent="getPercent('ENGINE_IDLE')"
                    size="small"
                    strokeColor="#FAAD14"
                    :format="(percent) => percent.toFixed(2) + '%'"
                  />
                </div>
              </div>
              <div class="status" v-else>
                <a-icon type="loading" />
              </div>
            </a-col>
            <a-col class="status-container" :lg="6" :md="12">
              <div class="status" v-if="vehicles">
                <h3>หยุด</h3>
                <div class="amount amount-stop">
                  {{
                    vehicles &&
                      vehicles[0] &&
                      vehicles[0].device.position &&
                      vehicles.filter((vehicle) => {
                        if (vehicle.device && vehicle.device.position) {
                          return (
                            vehicle.device.position.engineStatus ==
                            "ENGINE_STOP"
                          );
                        } else {
                          return false;
                        }
                      }).length
                  }}
                </div>
                <div class="percent">
                  <a-progress
                    :percent="getPercent('ENGINE_STOP')"
                    size="small"
                    strokeColor="#F5223E"
                    :format="(percent) => percent.toFixed(2) + '%'"
                  />
                </div>
              </div>
              <div class="status" v-else>
                <a-icon type="loading" />
              </div>
            </a-col>
            <a-col class="status-container" :lg="6" :md="12">
              <div class="status" v-if="vehicles">
                <h3>ไม่พบสถานะ</h3>
                <div class="amount amount-nostatus">
                  <!-- {{
                  vehicles &&
                  vehicles[0] &&
                  vehicles[0].device.position &&
                  vehicles.filter((vehicle) => {
                  if (vehicle.device && vehicle.device.position) {
                  return (
                  vehicle.device.position.timestamp ==
                  "NOT_UPDATED"
                  );
                  } else {
                  return false;
                  }
                  }).length
                  }} -->
                  {{
                    vehicles &&
                      vehicles[0] &&
                      vehicles[0].device.position &&
                      vehicles.filter((vehicle) => {
                        if (vehicle.device && vehicle.device.position) {
                          return ![
                            "VEHICLE_MOVING",
                            "ENGINE_STOP",
                            "ENGINE_IDLE",
                          ].includes(vehicle.device.position.engineStatus);
                        } else {
                          return true;
                        }
                      }).length
                  }}
                </div>
                <div class="percent">
                  <a-progress
                    :percent="getPercent('OTHERS')"
                    size="small"
                    strokeColor="#888"
                    :format="(percent) => percent.toFixed(2) + '%'"
                  />
                </div>
              </div>
              <div class="status" v-else>
                <a-icon type="loading" />
              </div>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
  </defaultLayout>
</template>

<script>
// @ is an alias to /src
import defaultLayout from "@/views/layouts/defaultLayout.vue";
import { mapActions, mapState, mapGetters } from "vuex";
import { getVehicles } from "../lib/vehicle";

export default {
  name: "home",
  components: {
    defaultLayout,
  },
  data() {
    return {};
  },
  computed: mapState({
    user: (state) => state.authen.user,
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      vehicles: "vehicle/vehicles",
    }),
  }),

  // watch: {
  //   vehicles(newValue) {
  //      console.log(newValue);
  //   }
  // },
  mounted() {
    // console.log('czarft', this.vehicles.length);
    // setTimeout(() => {
    //   this.getDevice();
    // }, 0);
    if (this.vehicles.length <= 0)
      getVehicles(
        this.user.customer_groups.map((group) => {
          return group._id;
        })
      );
  },
  sockets: {
    connect: function() {
      console.log("socket connected");
    },
  },
  created: function() {
    // this.googleMap.$mapPromise.then(map => {
    //   map.panTo({ lat: 13.7460067, lng: 100.5440535, zoom: 16 });
    // });
  },
  methods: {
    ...mapActions({}),
    getPercent(status) {
      if (this.vehicles && this.vehicles[0] && this.vehicles[0].device.position)
        if (["VEHICLE_MOVING", "ENGINE_STOP", "ENGINE_IDLE"].includes(status))
          return (
            (this.vehicles.filter((vehicle) => {
              if (vehicle.device && vehicle.device.position) {
                return vehicle.device.position.engineStatus == status;
              } else {
                return false;
              }
            }).length /
              this.vehicles.length) *
            100
          );
        else
          return (
            ((this.vehicles.length -
              this.vehicles.filter((vehicle) => {
                if (vehicle.device && vehicle.device.position) {
                  return [
                    "VEHICLE_MOVING",
                    "ENGINE_STOP",
                    "ENGINE_IDLE",
                  ].includes(vehicle.device.position.engineStatus);
                } else {
                  return false;
                }
              }).length) /
              this.vehicles.length) *
            100
          );
    },
  },
};
</script>
<style lang="scss" scoped>
.vehicle-status-container {
  padding: 30px 100px 0 100px;
  .status-container {
    padding: 8px 10px;
    .status {
      border-radius: 7px;
      padding: 15px 20px;
      background: #e6ebf0;
      h3 {
        font-size: 1.2rem;
        color: #444;
      }
      .amount {
        &.amount-moving {
          color: #00c246;
        }
        &.amount-idle {
          color: #FAAD14;
        }
        &.amount-stop {
          color: #F5223E;
        }
        &.amount-nostatus {
          color: #888;
        }
        font-size: 4rem;
        line-height: 3rem;
      }
      .percent {
      }
    }
  }
}
.help-message-container {
  padding: 40px 100px 0 100px;
  .help-message {
    position: relative;
    height: 160px;
    .help-image {
      text-align: right;
      img {
        height: 100%;
        max-height: 155px;
      }
    }

    .message {
      width: 100%;
      border-radius: 7px;
      background: #e6ebf0;
      position: relative;
      left: 0;
      bottom: 0px;
      min-height: 155px;
      padding: 15px 25px 15px 25px;
      h1 {
        font-size: 2rem;
        margin-bottom: 0.25rem;
      }
    }
  }
}
.sorry-message-container {
  // width: 600px;
  // margin: 100px auto;
  .sorry-message {
    padding: 0 80px 0 0;
    h1 {
      font-size: 2rem;
    }
  }
  padding-top: 200px;
  img {
    display: block;
    margin: 0 auto 0 auto;
    width: 300px;
    max-width: 100%;
  }
}
</style>
