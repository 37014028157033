import { translateGenderStatus } from "./report/config";

const geolib = require("geolib");

export const getGeofenceName = (geofences, lat, lng) => {
  let name = "";
  for (const k in geofences) {
    if (geofences[k].type == "polygon") {
      const isIn = geolib.isPointInPolygon(
        {
          latitude: lat,
          longitude: lng,
        },
        geofences[k].geojson.coordinates[0]
      );
      if (isIn) {
        return geofences[k].name;
      }
    } else if (geofences[k].type == "circle") {
      const isIn = geolib.isPointWithinRadius(
        {
          latitude: lat,
          longitude: lng,
        },
        geofences[k].geojson.coordinates,
        geofences[k].radius
      );
      if (isIn) {
        return geofences[k].name;
      }
    }
  }
  return name;
};

export const displayVoltage = (io) => {
  if (io && 29 in io) {
    const volt = (io[29] * 1) / 1000;
    if (volt > 0) {
      return volt;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const tempSensorIsEnable = (io) => {
  if (io && (78 in io || 79 in io)) {
    if (io[78] == 0 && io[79] == 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const tempSensorIsAvaiable = (io, no = 78) => {
  if (io && no in io && io[no] * 1 != 2000) {
    return true;
  } else {
    return false;
  }
};

export const displayTempSensorData = (io, no = 78) => {
  if (io && no in io && io[no] * 1 != 2000) {
    let temp = (io[no] * 1) / 10;
    if (temp >= -50 && temp <= 50) {
      return temp;
    } else {
      return "-";
    }
  } else {
    return "-";
  }
};

export const displayBTSensorData = (io, no = 600) => {
  if (io && no in io) {
    let temp = (io[no] * 1) / 100;
    return temp;
  } else {
    return "-";
  }
};


export const displayCanbusAmbientAir = (io) => {
  if (io && 97 in io && io[97] * 1 > 0) {
    return io[97] * 1 - 40;
  } else {
    return "";
  }
};

export const getVehicleOdoOffset = (vehicle) => {
  let offset = 0;
  if (
    vehicle &&
    "odo_canbus_offset" in vehicle &&
    parseInt(vehicle.odo_canbus_offset) > 0
  ) {
    offset = parseInt(vehicle.odo_canbus_offset);
  }
  return offset;
};

export const getVehicleFuelCanbusEnabled = (vehicle) => {
  let enabled = false;
  if (
    vehicle &&
    "canbus_fuel_enable" in vehicle &&
    vehicle.canbus_fuel_enable == true
  ) {
    enabled = true;
  }
  return enabled;
};
export const getCanbusTruckEnabled = (vehicle) => {
  let enabled = false;
  if (
    vehicle &&
    "canbus_truck_enabled" in vehicle &&
    vehicle.canbus_truck_enabled == true
  ) {
    enabled = true;
  }
  return enabled;
};

export const getVehicleOdoCanbusEnabled = (vehicle) => {
  let enabled = false;
  if (
    vehicle &&
    "odo_canbus_enable" in vehicle &&
    vehicle.odo_canbus_enable == true
  ) {
    enabled = true;
  }
  return enabled;
};

export const displayOdometer = (vehicle, position) => {
  const io = position.io;
  if (getVehicleOdoCanbusEnabled(vehicle)) {
    // console.log(io[645], (io[65] +  getVehicleOdoOffset(vehicle)) / 1000)
    if (io && 645 in io && io[645] != 4294967295) {
      return io[645];
    } else {
      return (io[65] + getVehicleOdoOffset(vehicle)) / 1000;
    }
  } else if (getCanbusTruckEnabled(vehicle)) {
    if (io && 114 in io && !(io[114] >= 4261412864 && io[114] <= 4278190079)) {
      return (io[114] * 5) / 1000;
    } else {
      return io[65] / 1000;
    }
  } else if (io && io[65]) {
    return io[65] / 1000;
  } else if ("odo" in position && position.odo > 0 && io && io[65] === null) {
    return position.odo;
  } else {
    return 0;
  }
};

export const displayFuelLevel = (vehicle, position) => {
  const io = position && "io" in position ? position.io : null;
  // console.log(getVehicleFuelCanbusEnabled(vehicle));
  if (getVehicleFuelCanbusEnabled(vehicle)) {
    if (io && 642 in io && io[642] != 65535) {
      return io[642];
    } else if ("fuelLitreCanbus" in position && position.fuelLitreCanbus > 0) {
      return position.fuelLitreCanbus.toFixed(2);
    } else {
      return vehicle.lastFuelLitre.toFixed(2);
    }
  } else if (getCanbusTruckEnabled(vehicle)) {
    if (position && "fuelLitreCanbusTruck" in position && position["fuelLitreCanbusTruck"]) {
      return position.fuelLitreCanbusTruck
        ? position.fuelLitreCanbusTruck.toFixed(2)
        : vehicle.lastFuelLitre.toFixed(2);
    }
    else if (io && 207 in io && io[207] != 254) {
      let litre = (io[207] * 0.4 * vehicle.oil_liters_max) / 100;
      return litre.toFixed(2);
    }
    else {
      return 0;
    }

  } else {
    if (position && "fuelLitre" in position && position["fuelLitre"]) {
      return position["fuelLitre"].toFixed(2);
    } else if (vehicle && "lastFuelLitre" in vehicle && vehicle.lastFuelLitre) {
      return vehicle.lastFuelLitre.toFixed(2);
    }
  }
  return 0;
};
export const CANfuelrate = (vehicle, io) => {
  if (getCanbusTruckEnabled(vehicle)) {
    return 116 in io && !(io[116] >= 65024 && io[116] <= 65279)
      ? (io[116] * 0.05).toFixed(2)
      : "-";
  }
  return "-";
};
export const CANenginehour = (vehicle, io) => {
  if (getCanbusTruckEnabled(vehicle)) {
    return 203 in io && !(io[203] >= 4261412864 && io[203] <= 4278190079)
      ? (io[203] * 0.05).toFixed(0)
      : "-";
  }
  return "-";
};
export const CANfuelused = (vehicle, io) => {
  if (getCanbusTruckEnabled(vehicle)) {
    return 92 in io && !(io[92] >= 4261412864 && io[92] <= 4278190079)
      ? (io[92] * 0.001).toFixed(2)
      : "-";
  }
  return "-";
};
export const CANengineSpeed = (vehicle, io) => {
  if (getCanbusTruckEnabled(vehicle)) {
    return 197 in io && !(io[197] >= 65024 && io[197] <= 65279)
      ? (io[197] * 0.125).toFixed(2)
      : "-";
  }
  return "-";
};
export const CANcoolant = (vehicle, io) => {
  if (getCanbusTruckEnabled(vehicle)) {
    return 115 in io && io[115] > 0 && io[115] != 254
      ? (io[115] * 1 - 40).toFixed(0)
      : "-";
  }
  return "-";
};
export const CANpedalposition = (vehicle, io) => {
  if (getCanbusTruckEnabled(vehicle)) {
    return 206 in io && io[206] != 254 ? (io[206] * 0.4).toFixed(0) : "-";
  }
  return "-";
};

export const displayDriverName = (defaultLicenseNoDisplayed, vehicle, getDriverNameByLicenseNo) => {
  let license_no = vehicle.device.position.mcr_license_no;
  let driver_name = vehicle.device.position.mcr_driver_name;

  if (getDriverNameByLicenseNo) {
    return getDriverNameByLicenseNo.name;
  } else {
    if (license_no && license_no.length > 0) {
      return driver_name && driver_name.length > 0
        ? driver_name
          .split(/\s+/)
          .reverse()
          .join(" ")
        : defaultLicenseNoDisplayed ? license_no : '-';
    } else {
      return "-"
    }
  }
};

export const displayDriverGender = (vehicle, getDriverNameByLicenseNo) => {
  let license_no = vehicle.device.position.mcr_license_no;
  let gender = vehicle.device.position.mcr_gender;
  if (license_no && license_no.length > 0 && getDriverNameByLicenseNo) {
    return translateGenderStatus(getDriverNameByLicenseNo.gender);
  } else {
    return typeof gender === "number" ? displayGenderFromTelto(gender) : "-";
  }
};

const displayGenderFromTelto = (value) => {
  let gender;
  switch (value) {
    case 1:
      gender = "ชาย";
      break;
    case 2:
      gender = "หญิง";
      break;

    default:
      gender = "-";
      break;
  }
  return gender;
};
