<template>
  <apexchart
    ref="speedChart"
    type="area"
    height="350"
    :options="chartOptions"
    :series="speedChartData"
  ></apexchart>
</template>
<script>
import moment from '@/lib/time';
export default {
  props: {
    speedChartData: Array,
    overSpeedLimit: Number,
    timeRange: Array,
  },
  data: function () {
    return {
      chartOptions: {
        stroke: {
          curve: 'stepline',
          width: 1,
        },
        chart: {
          type: 'area',
          stacked: false,
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: true,
              zoom: true,
              zoomin: true,
              zoomout: true,
              pan: true,
              reset: false,
            },
          },
        },
        animations: {
          enabled: false,
        },

        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          colors: ['#00c246', '#FAAD14', '#F5223E'],
        },
        colors: ['#00c246', '#FAAD14', '#F5223E'],
        fill: {
          colors: ['#00c246', '#FAAD14', '#F5223E'],

          type: 'gradient',
          gradient: {
            shade: 'dark',
            shadeIntensity: 0.0,
            opacityFrom: 1,
            opacityTo: 1,
          },
        },
        yaxis: {
          tickAmount: 6,
          labels: {
            formatter: function (val) {
              return `${val != null ? val.toFixed(0) : '-'} กม./ชม.`;
            },
            style: {
              colors: '#00c246',
            },
          },
          title: {
            text: 'ความเร็ว',
          },
        },
        xaxis: this.chartXaxisOption,
        tooltip: {
          x: {
            formatter: function (val) {
              return moment(new Date(val)).format('YYYY-MM-DD HH:mm:ss');
            },
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
          offsetX: -10,
        },
      },
      chartXaxisOption: {
        tickAmount: 6,
        type: 'datetime',
        labels: {
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: true,
          offsetX: 0,
          offsetY: 0,
          formatter: function (val, timestamp) {
            return moment(new Date(timestamp)).format('YYYY-MM-DD');
          },
        },
      },
    };
  },

  watch: {
    overSpeedLimit(val) {
      this.renderOverspeedAnnotation(val);
    },
    speedChartData() {
      this.renderXaxis();
    },
  },
  mounted() {
    this.renderXaxis();
    this.renderOverspeedAnnotation(this.overSpeedLimit);
  },
  methods: {
    renderXaxis() {
      if (
        this.timeRange[0].date() === this.timeRange[1].date() &&
        this.timeRange[0].month() === this.timeRange[1].month()
      ) {
        this.$refs['speedChart'].updateOptions({
          xaxis: {
            tickAmount: 24,
            type: 'datetime',
            labels: {
              rotate: -45,
              rotateAlways: true,
              formatter: function (val, timestamp) {
                return moment(new Date(timestamp)).format('HH:mm');
              },
            },
          },
        });
        return;
      }

      this.$refs['speedChart'].updateOptions({
        xaxis: this.chartXaxisOption,
      });
    },
    renderOverspeedAnnotation(speed) {
      if (this.$refs['speedChart']) {
        this.$refs['speedChart'].clearAnnotations();
        this.$refs['speedChart'].addYaxisAnnotation({
          y: speed,
          borderColor: '#8e34ff',
          label: {
            borderColor: '#8e34ff',
            style: {
              color: '#fff',
              background: '#8e34ff',
            },
            text: `ความเร็วจำกัด ${speed} กม./ชม.`,
          },
        });
      }
    },
  },
};
</script>
