import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Report from "@/views/Report.vue";
import Route from "@/views/Route.vue";
import Geofence from "@/views/Geofence.vue";
import TMS from "@/views/TMS.vue";
import Setting from "@/views/Setting.vue";
import Trip from "@/views/Trip.vue";
import History from "@/views/History.vue";
import Guard from "@/views/Guard.vue";
import Dashboard from "@/views/Dashboard.vue";
import Maintenance from "@/views/Maintenance.vue";
import VideoMonitoring from "@/views/VideoMonitoring.vue";
import VideoPlayback from "@/views/VideoPlayback.vue";
import NotFoundComponent from "@/views/NotFoundComponent.vue";
import NewReport from "@/views/NewReport.vue";
import store from "@/store";
import { signOut } from "@/lib/authen";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      authRequred: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
    meta: {
      authRequred: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },

  {
    path: "/track",
    name: "track",
    component: Home,
    meta: {
      authRequred: true,
    },
  },
  {
    path: "/trip",
    name: "trip",
    component: Trip,
    meta: {
      authRequred: true,
    },
  },
  {
    path: "/history",
    name: "history",
    component: History,
    meta: {
      authRequred: true,
    },
  },
  {
    path: "/report",
    name: "report",
    component: Report,
    meta: {
      authRequred: true,
    },
  },
  {
    path: "/new-report",
    name: "new_report",
    component: NewReport,
    meta: {
      authRequred: true
    }
  },
  {
    path: "/route",
    name: "route",
    component: Route,
    meta: {
      authRequred: true,
    },
  },
  {
    path: "/geofence",
    name: "geofence",
    component: Geofence,
    meta: {
      authRequred: true,
    },
  },
  {
    path: "/guard",
    name: "guard",
    component: Guard,
    meta: {
      authRequred: true,
    },
  },

  {
    path: "/maintenance",
    name: "maintenance",
    component: Maintenance,
    meta: {
      authRequred: true,
    },
  },
  {
    path: "/TMS",
    name: "TMS",
    component: TMS,
    meta: {
      authRequred: true,
    },
  },
  {
    path: "/setting",
    name: "setting",
    component: Setting,
  },
  {
    path: "/profile",
    name: "profile",
    component: Setting,
  },
  {
    path: "*",
    component: NotFoundComponent,
  },
  {
    path: "/video_monitoring",
    name: "video_monitoring",
    component: VideoMonitoring,
    meta: {
      authRequred: true,
    },
  },
  {
    path: "/video_playback",
    name: "video_playback",
    component: VideoPlayback,
    meta: {
      authRequred: true,
    },
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.restored;
  // console.log('wait for restore')
  if (to.matched.some((record) => record.meta.authRequred)) {
    if (!store.state.authen.user) {
      // console.log("state.authen", store.state.authen.user);
      // signOut({
      //   topic: "ขออภัย",
      //   message: "ข้อมูลของท่านยังไม่สามารถเข้าใช้งานได้ กรุณารอการติดต่อจากเฮลลิออท",
      //   type: "error",
      // });

      router.push({
        path: "login",
      });
    } else if (store.state.authen.user.role.type == "prospoect") {
      signOut({
        topic: "ขออภัย",
        message:
          "ข้อมูลของท่านยังไม่สามารถเข้าใช้งานได้ กรุณารอการติดต่อจากเฮลลิออท",
        type: "error",
      });
    }

    const path = to.name === 'home' ? 'track' : to.name

    if (path !== 'guard') {
      const featurePermissions = store.getters['authen/featurePermissions']
      if (featurePermissions.find(menu => menu.code === path)) {
        next()
      } else {
        next({ path: 'guard' })
      }
    }
    next();
  } else {
    next();
  }
  if (from.path) {
    to.params.previousPath = from.path;
  }
});

export default router;
