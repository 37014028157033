import { apolloClient as strapiApollo } from '@/strapi-apollo';
import positionReport from '@/graphql/positionReport.gql';
import store from '@/store';
import _ from 'lodash';
import {
  getFuelData,
  getODODistance
} from './config';
import moment from '@/lib/time';
import * as Sentry from '@sentry/vue';
const { LTTB } = require('downsample');

export const getFuelChartData = async (vehicles, imeis, start, stop) => {
  await strapiApollo.resetStore();
  return await strapiApollo
    .query({
      // Query
      query: positionReport,
      variables: {
        imeis,
        start,
        stop,
      },
    })
    .then((eventData) => {
      const positions = eventData.data.positions

      if (positions && positions.length > 0) {
        let dataPoints = [...positions.map((pos, n) => {
          let fuel = getFuelData(vehicles, pos.imei, pos)
          return {
            x: n, y: parseFloat(fuel)
          }
        })]
        const downsampled = LTTB(dataPoints, 1440).map((sampled) => sampled.x)
        const filterPositions = positions.filter((row, index) => downsampled.includes(index))

        const statusArray = {
          VEHICLE_MOVING: [],
          ENGINE_IDLE: [],
          ENGINE_STOP: [],
        };

        let lastStatus = null;
        let startOdo = filterPositions[0].io[65];

        filterPositions.map((pos) => {
          const fuel = getFuelData(vehicles, pos.imei, pos);
          if (pos.engineStatus)
            if (fuel !== null) {
              if (isContinueStatus(lastStatus, pos.engineStatus)) {
                statusArray[pos.engineStatus].push([
                  moment.unix(pos.timestamp).valueOf(),
                  pos.io[22],
                  (pos.io[65] - startOdo) / 1000,
                  pos.latitude,
                  fuel,
                  pos.longitude,
                ]);
              } else {
                //reset and stop drawing last status
                statusArray[lastStatus].push([
                  moment.unix(pos.timestamp).valueOf() - 2,
                  pos.io[22],
                  (pos.io[65] - startOdo) / 1000,
                  pos.latitude,
                  fuel,
                  pos.longitude,
                ]);

                statusArray[lastStatus].push([
                  moment.unix(pos.timestamp).valueOf() - 1,
                  null,
                  null,
                  null,
                  null,
                  null,
                ]);

                //start drawing new status
                statusArray[pos.engineStatus].push([
                  moment.unix(pos.timestamp).valueOf(),
                  pos.io[22],
                  (pos.io[65] - startOdo) / 1000,
                  pos.latitude,
                  fuel,
                  pos.longitude,
                ]);
              }
            } else {
              statusArray[pos.engineStatus].push([
                moment.unix(pos.timestamp).valueOf(),
                pos.io[22],
                (pos.io[65] - startOdo) / 1000,
                pos.latitude,
                null,
                pos.longitude,
              ]);
            }

          lastStatus = pos.engineStatus;
          return [moment.unix(pos.timestamp).valueOf(), fuel ? fuel : 0];
        });

        store.dispatch('loading/setReportLoading', false);

        //calculate total distance
        const vehicle = _.find(vehicles, {
          imei: imeis,
        });
        if (typeof vehicle === 'undefined' || !('odo_canbus_enable' in vehicle)) {
          vehicle.odo_canbus_enable = false;
        }

        const totalDistance = getODODistance(vehicle.odo_canbus_enable || vehicle.canbus_truck_enabled, filterPositions);
        return {
          chartData: [
            {
              name: 'รถวิ่ง',
              data: statusArray['VEHICLE_MOVING'],
            },
            {
              name: 'จอดติดเครื่อง',
              data: statusArray['ENGINE_IDLE'],
            },
            {
              name: 'รถจอด',
              data: statusArray['ENGINE_STOP'],
            },
          ],
          totalDistance,
        };
      }
    })
    .catch((err) => {
      console.log(err);
      store.dispatch('loading/setReportLoading', false);
      Sentry.captureException(err);
      return;
    });
};
const isContinueStatus = (lastStatus, currentStatus) => {
  if (lastStatus == null || lastStatus == currentStatus) return true;
  return false;
};

export default getFuelChartData;
