<template lang="">  
  <div v-if="locationData">    
    <div v-if="displayVehicleList">
      <div
        style="
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
        "
      >
        <div>รถใกล้เคียง</div>
        <div>ใกล้ที่สุด</div>
      </div>
      <div v-for="vehicle in sortingVehicleList()" :key="vehicle.id">
        <vehicleInfoContainer
          :onClickVehicle="onClickVehicle"
          :vehicle="vehicle"
          :distance="
            calculateDistance(locationData.coordinates, {
              lat: vehicle.device.position.latitude / 10_000_000,
              lng: vehicle.device.position.longitude / 10_000_000,
            })
          "
        />
      </div>
    </div>
    <div v-else>
      <a-row class="result-location-data">
        <a-col :span="4"
          ><img :src="require('@/assets/marker-custom.png')" width="14px"
        /></a-col>
        <a-col :span="20">{{ locationData.name }}</a-col>
      </a-row>
      <a-row class="btn">
        <button @click="onDisplayVehicleList()" class="nearByBtn">
          <img :src="require('@/assets/near-car.png')" width="18px" />
          <span>รถใกล้บริเวณนี้</span>
        </button>
      </a-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import vehicleInfoContainer from '@/views/components/nearby/vehicleInfoContainer';
export default {
  props: ['locationData', 'googleMap'],
  emits: ['displaySuggestionLabel'],
  components: {
    vehicleInfoContainer,
  },
  data() {
    return {
      displayVehicleList: false,
      marker: {},
    };
  },
  computed: {
    ...mapGetters({
      vehicles: 'vehicle/vehicles',
    }),
  },
  watch: {
    locationData() {
      this.resetMarker();
      this.initMarker();
    },
  },
  mounted() {
    this.initMarker();
  },
  destroyed() {
    this.resetMarker();
  },
  methods: {
    ...mapActions({
      selectVehicle: 'vehicle/selectVehicle',
      showVehicleInfo: 'vehicle/showVehicleBottomInfo',
      setTracking: 'vehicle/setTracking',
    }),
    onClickVehicle(vehicle) {
      if (!vehicle.device) return;      
      const positions = [
        this.locationData.coordinates,
        {
          lat: vehicle.device.position.latitude / 10_000_000,
          lng: vehicle.device.position.longitude / 10_000_000,
        },
      ];

      this.googleMap.$mapPromise.then((map) => {
        if (vehicle.device.position && 'latitude' in vehicle.device.position) {
          let bounds = new window.google.maps.LatLngBounds();
          for (let i = 0; i < positions.length; i++) {
            bounds.extend(positions[i]);
          }
          map.fitBounds(bounds);
          map.panTo(bounds.getCenter());
        }
      });
      setTimeout(() => {
        let el = document.getElementsByClassName('heliot-info-car active');
        if (el && el.length > 0) {
          el[0].scrollIntoView({ block: 'center', behavior: 'smooth' });
        }        
      }, 500);

      this.setTracking(false);
      this.selectVehicle(vehicle);
      this.showVehicleInfo(true);      
    },
    initMarker() {
      const icon = {
        url: require('@/assets/marker-custom.png'), // URL to the icon image
        scaledSize: new window.google.maps.Size(29, 34.5), // Size of the icon
      };
      this.marker = new window.google.maps.Marker({
        icon: icon,
        animation: window.google.maps.Animation.BOUNCE,
        position: {
          lat: parseFloat(this.locationData.coordinates.lat),
          lng: parseFloat(this.locationData.coordinates.lng),
        },
      });
      this.marker.setMap(this.googleMap.$mapObject);
      this.googleMap.$mapPromise.then((map) => {
        map.panTo({
          lat: parseFloat(this.locationData.coordinates.lat),
          lng: parseFloat(this.locationData.coordinates.lng),
        });
        map.setZoom(16);
      });
    },
    onDisplayVehicleList() {
      this.displayVehicleList = !this.displayVehicleList;
      this.$emit('displaySuggestionLabel', false);
    },
    sortingVehicleList() {
      return this.vehicles.sort((currentPos, nextPos) => {
        if (currentPos.device.position && nextPos.device.position) {
          const aPos = {
            lat: currentPos.device.position.latitude / 10_000_000,
            lng: currentPos.device.position.longitude / 10_000_000,
          };
          const bPos = {
            lat: nextPos.device.position.latitude / 10_000_000,
            lng: nextPos.device.position.longitude / 10_000_000,
          };
          return (
            this.calculateDistance(this.locationData.coordinates, aPos) -
            this.calculateDistance(this.locationData.coordinates, bPos)
          );
        }
      });
    },

    calculateDistance(originPos, destinationPos) {
      const radius = 6371e3; // metres
      const φ1 = (originPos.lat * Math.PI) / 180; // φ, λ in radians
      const φ2 = (destinationPos.lat * Math.PI) / 180;
      const Δφ = ((destinationPos.lat - originPos.lat) * Math.PI) / 180;
      const Δλ = ((destinationPos.lng - originPos.lng) * Math.PI) / 180;

      const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

      const distance = radius * c; // in metres
      return parseFloat(distance.toFixed(2));
    },
    resetMarker() {
      this.displayVehicleList = false;
      this.marker.setMap(null);
    },
  },
};
</script>
<style lang="scss" scoped>
.nearByBtn {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #5044df;
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 15px 19px 15px 19px;
  color: #5044df;
}
.result-location-data {
  position: relative;
  padding: 2rem 0 2rem 0;
  margin: 0rem 1rem;
  border-bottom: 1px solid #b1b1b1;
}
.btn {
  display: flex;
  justify-content: center;
}
.btn span {
  padding-left: 10px;
}
</style>
