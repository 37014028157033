<template>
  <div class="vehicle-drawer">
    <vehicleStatus pageType="other"/>
    <div class="all-vehicle">
      <div v-if="enabledNearByFeature()">
        <nearByContainer :clickVehicle="clickVehicle" :googleMap="googleMap" />
      </div>
      <div v-else>
        <h3>รถทั้งหมด</h3>
        <div class="heliot-search">
          <input
            type="text"
            class="heliot-search-input"
            placeholder="ค้นหารถ"
            v-model="keyword"
          />
        </div>
        <listResultCar :clickVehicle="clickVehicle" :keyword="keyword" />
      </div>
    </div>
  </div>
</template>
<script>
import vehicleStatus from '@/views/components/vehicleStatus';
import listResultCar from '@/views/components/listResultCar';
import nearByContainer from '@/views/components/nearby/nearByContainer';

export default {
  components: { listResultCar, vehicleStatus, nearByContainer },
  props: ['clickVehicle', 'googleMap'],
  data() {
    return {
      keyword: '',
    };
  },
  methods: {
    enabledNearByFeature() {
      const enabledPath = ['/track', '/'];
      return enabledPath.includes(this.$router.currentRoute.path);
    },
  },
};
</script>

<style lang="scss" scoped>
$md: 768px;
$sm: 450px;

.heliot-search-input {
  width: 180px;
  height: 30px;
  background: #eee 0% 0% no-repeat padding-box;
  border-radius: 30px !important;
  border: 1px solid #d9d9d9;
  opacity: 1;
  padding: 15px 20px;
  margin-bottom: 20px;
  @media screen and (max-width: $md) {
    width: 100%;
  }
}
.vehicle-drawer {
  @media screen and (max-width: $md) {
    width: 120px;
    padding: 5px 5px;
    // padding: 0px 0px;
  }
  width: 230px;
  display: inline-block;
  background-color: #fafafa;
  // border: 1px solid #d3d4d6;
  //padding: 15px 20px;
  padding: 0 0;
  height: 100vh;
  overflow-y: scroll;
}
.all-vehicle {
  @media screen and (max-width: $md) {
    padding: 0px 0px;
  }
  padding: 16px 8px;
}
</style>
