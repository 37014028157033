<template>
  <defaultLayout>
    <div id="report__container">
      <div class="page__title">
        <div class="title">รายงาน</div>
        <div class="sub-title">ดูรายงานประวัติและดาวน์โหลด</div>
      </div>
      <div class="page__content">
        <div class="content__header">
          <div class="header">
            <div v-if="currentPage !== reportPage.CREATE" class="header__icon">
              <a-icon
                :type="
                  currentPage === reportPage.QUEUE ? 'sync' : 'cloud-download'
                "
              />
            </div>
            <div v-html="renderContentTitle"></div>
          </div>
          <div class="action">
            <custom-button
              v-if="currentPage === reportPage.QUEUE"
              :label="'refresh'"
              @onClick="onClickRefresh"
            >
              <template v-slot:icon>
                <a-icon type="sync" />
              </template>
            </custom-button>
            <custom-button
              v-if="isShowCreatePageButton"
              type="main"
              :label="'สร้างรายงานใหม่'"
              @onClick="onClickChangePage(reportPage.CREATE)"
            >
              <template v-slot:icon>
                <a-icon type="plus" />
              </template>
            </custom-button>
            <custom-button
              v-if="isShowQueuePageButton"
              type="main"
              :label="'คิวดาวน์โหลดรายงาน'"
              @onClick="onClickChangePage(reportPage.QUEUE)"
            >
              <template v-slot:icon>
                <a-icon type="sync" />
              </template>
            </custom-button>
            <custom-button
              v-if="isShowFinishPageButton"
              type="main"
              :label="'รับไฟล์'"
              @onClick="onClickChangePage(reportPage.FINISH)"
            >
              <template v-slot:icon>
                <a-icon :type="'cloud-download'" />
              </template>
            </custom-button>
          </div>
        </div>
        <div class="content__body">
          <reportDrawer
            v-if="currentPage === reportPage.CREATE"
            @onClickDownload="onCreateReportQueue"
          />
          <reportDataTable
            v-else
            :isSync="isSync"
            :dataList="reportDataList"
            @onClickDeleteButton="onClickDeleteButton"
            @onClickNextInqueue="updateNextInqueue"
          />
        </div>
      </div>
    </div>

    <modal id="row-delete-modal" name="row-delete-modal" :clickToClose="false">
      <a-icon
        v-if="!isDeleting"
        type="close"
        class="close"
        @click="hideRowDeleteModal()"
      />
      <div v-if="isDeleting" style="text-align: center">
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 56px"
            spin
          />
        </a-spin>
      </div>
      <div v-else class="modal__content">
        <div class="content__icon">
          <img
            v-if="!isRowDeleted"
            src="@/assets/icons/exclamation.svg"
            alt="exclamation icon"
          />
          <img v-else src="@/assets/icons/checkmark.svg" alt="checkmark icon" />
        </div>
        <div class="content__body">
          <h1>{{ isRowDeleted ? 'ลบรายงานสำเร็จ' : 'คุณกำลังลบรายงาน' }}</h1>
        </div>
        <div v-if="isRowDeleted" class="content__actions">
          <custom-button label="ปิดหน้าต่าง" @onClick="hideRowDeleteModal()" />
        </div>
        <div v-else class="content__actions">
          <custom-button label="ยกเลิก" @onClick="hideRowDeleteModal()" />
          <custom-button type="danger" label="ยืนยัน" @onClick="deleteRow()" />
        </div>
      </div>
    </modal>

    <modal id="alert-modal" name="alert-modal" :clickToClose="false">
      <a-icon type="close" class="close" @click="hideAlertModal()" />
      <div class="modal__content">
        <div class="content__icon">
          <img src="@/assets/icons/exclamation.svg" alt="exclamation icon" />
        </div>
        <div class="content__body">
          <h1>ไม่สามารถสร้างรายงานได้ <br />เนื่องจากเกินขีดจำกัด (10 คิว)</h1>
        </div>
        <div class="content__actions">
          <custom-button label="ปิดหน้าต่าง" @onClick="hideAlertModal()" />
        </div>
      </div>
    </modal>

    <transition name="fade">
      <div
        v-if="currentPage === reportPage.QUEUE && isNotifyVisible"
        id="notify-modal"
      >
        <a-icon
          class="close"
          type="close-circle"
          @click="
            () => {
              isNotifyVisible = false;
            }
          "
        />
        <div class="modal__content">
          รายงานพร้อมแล้ว
          <a
            style="color: #00c246; text-decoration: underline; cursor: pointer"
            @click="onClickChangePage(reportPage.FINISH)"
            >รับไฟล์</a
          >
        </div>
      </div>
    </transition>
  </defaultLayout>
</template>
<script>
import moment from '@/lib/time';
import AxiosService from '@/plugins/axios';
import endpoints from '@/config/config';
import { mapState, mapGetters } from 'vuex';
import defaultLayout from '@/views/layouts/defaultLayout.vue';
import reportDrawer from '@/views/components/report/reportDrawer.vue';
import reportDataTable from '@/views/components/report/reportDataTable.vue';
import customButton from './components/customButton.vue';
const reportPage = Object.freeze({
  CREATE: 'create',
  QUEUE: 'queue',
  FINISH: 'finish',
});

export default {
  name: 'ReportPage',
  components: {
    defaultLayout,
    reportDrawer,
    reportDataTable,
    customButton,
  },
  data() {
    return {
      reportPage,
      currentPage: reportPage.CREATE,
      reportDataList: [],
      inqueueList: 0,
      selectedRowId: '',
      isRowDeleted: false,
      isDeleting: false,
      isSync: false,
      isNotifyVisible: false,
      axiosController: null,
      checkingListController: null,
      reportQueueListInterval: null,
      reportFinishListInterval: null,
    };
  },
  computed: {
    ...mapState({
      selectedGroup: (state) => state.authen.selectedGroup,
      vehicleGroups: (state) => state.vehicle.vehicleGroups,
    }),
    ...mapGetters({
      vehicles: 'vehicle/vehicles',
    }),
    isShowCreatePageButton() {
      return this.currentPage !== reportPage.CREATE;
    },
    isShowQueuePageButton() {
      return this.currentPage !== reportPage.QUEUE;
    },
    isShowFinishPageButton() {
      return this.currentPage !== reportPage.FINISH;
    },
    renderContentTitle() {
      if (this.currentPage === reportPage.QUEUE) {
        return `<div> คิวดาวน์โหลดรายงาน <span>(สูงสุด 10 คิว)</span> </div>`;
      }
      if (this.currentPage === reportPage.FINISH) {
        return `<div> รายงานพร้อมดาวน์โหลด </div>`;
      }
      return '';
    },
  },
  watch: {
    async currentPage(p) {
      if (this.axiosController) {
        this.axiosController.abort();
        console.log('axiosController request aborted');
      }
      if (this.checkingListController) {
        this.checkingListController.abort();
        console.log('checkingListController request aborted');
      }
      if (this.reportFinishListInterval) {
        clearInterval(this.reportFinishListInterval);
        this.reportFinishListInterval = null;
      }

      if (this.reportQueueListInterval) {
        clearInterval(this.reportQueueListInterval);
        this.reportQueueListInterval = null;
      }

      if (p === reportPage.QUEUE || p === reportPage.FINISH) {
        const checkingLength = await this.checkingInqueueListLength();
        if (checkingLength && checkingLength > 0) {
          if (p === reportPage.QUEUE) {
            this.isSync = true;
            await this.onSyncReportQueueList();

            this.reportQueueListInterval = setInterval(async () => {
              await this.onSyncReportQueueList();
              this.inqueueList = await this.checkingInqueueListLength();
            }, 5000);
          }

          if (p === reportPage.FINISH) {
            this.isSync = true;
            await this.onSyncReportFinishList();

            this.reportFinishListInterval = setInterval(async () => {
              await this.onSyncReportFinishList();
              this.inqueueList = await this.checkingInqueueListLength();
            }, 5000);
          }
        } else {
          if (p === reportPage.FINISH) {
            this.isSync = true;
            await this.onSyncReportFinishList();
          }

          if (p === reportPage.QUEUE) {
            this.isSync = true;
            await this.onSyncReportQueueList();
          }
        }
      }
    },
    async inqueueList(n, o) {
      /* if report is generated */
      if (n - o < 0) {
        this.isNotifyVisible = true;
      }

      if (n === 0) {
        if (this.reportFinishListInterval) {
          clearInterval(this.reportFinishListInterval);
          this.reportFinishListInterval = null;
        }

        if (this.reportQueueListInterval) {
          clearInterval(this.reportQueueListInterval);
          this.reportQueueListInterval = null;
        }
      }
    },
  },
  methods: {
    showRowDeleteModal(rowId) {
      /* reset */
      this.isRowDeleted = false;
      this.selectedRowId = '';

      this.$modal.show('row-delete-modal');
      this.selectedRowId = rowId;
    },
    hideAlertModal() {
      this.$modal.hide('alert-modal');
    },
    async hideRowDeleteModal() {
      this.$modal.hide('row-delete-modal');

      if (this.isRowDeleted) {
        if (this.currentPage === reportPage.QUEUE) {
          await this.onSyncReportQueueList();
        }

        if (this.currentPage === reportPage.FINISH) {
          await this.onSyncReportFinishList();
        }
      }
    },
    formatDataRow(raw) {
      const {
        _id,
        start_time,
        end_time,
        vehicles,
        type,
        status,
        vehiclegroup_id,
        url,
        created_at,
        finished_at,
      } = raw;

      const dateString = `${moment
        .unix(start_time)
        .format('D MMM YYYY HH:mm')} - ${moment
        .unix(end_time)
        .format('D MMM YYYY HH:mm')}`;
      const vehicleGroupLabel = this.vehicleGroups.find(
        (vg) => vg.id === vehiclegroup_id
      ).label;

      const nowUnix = parseInt(moment.utc().valueOf() / 1_000);
      const createdDateUnix = parseInt(
        moment.utc(created_at).valueOf() / 1_000
      );

      const timeDuration = moment.duration(
        nowUnix - createdDateUnix,
        'seconds'
      );

      let isJustFinishing = false;
      if (status === 'finish') {
        const finishedTimeUnix = parseInt(
          moment(finished_at).valueOf() / 1_000
        );

        if (localStorage.getItem('finishPageLatest')) {
          isJustFinishing =
            localStorage.getItem('finishPageLatest') < finishedTimeUnix;
        } else {
          isJustFinishing = nowUnix - finishedTimeUnix < 30;
        }
      }

      return {
        key: _id,
        _id,
        type,
        dates: dateString,
        vehiclegroup: vehicleGroupLabel,
        url,
        status,
        isLinkExpired: timeDuration.days() > 7,
        isJustFinishing,
        vehicles: vehicles.map((imei) => {
          return this.vehicles.find((v) => v.unit_id === imei.toString())
            .plate_number;
        }),
      };
    },
    async onClickChangePage(page) {
      this.currentPage = page;
    },
    async onCreateReportQueue(data) {
      const { reportType, vehicleGroup, vehicles, dateRange, additional } =
        data;

      const bodyRequest = {
        type: reportType,
        start_time: dateRange[0].unix(),
        end_time: dateRange[1].unix(),
        vehicles: vehicles.map((id) => {
          return parseInt(this.vehicles.find((v) => v.id === id).unit_id);
        }),
        customergroup_id: this.selectedGroup.id,
        vehiclegroup_id: vehicleGroup,
        additional,
      };

      await AxiosService.post(
        `${endpoints.coreApi}/report-file-info`,
        bodyRequest
      )
        .then(() => {
          this.onClickChangePage(this.reportPage.QUEUE);
          this.isNotifyVisible = false;
        })
        .catch((error) => {
          console.log(error);
          const { data } = error.response;
          if (data && data.message) {
            if (data.message.includes('full')) {
              this.$modal.show('alert-modal');
            }
          }
        });
    },
    async onSyncReportQueueList() {
      this.axiosController = new AbortController();
      await AxiosService.get(
        `${endpoints.coreApi}/report-file-info?customergroup_id=${this.selectedGroup.id}`,
        {
          signal: this.axiosController.signal,
        }
      )
        .then((response) => {
          const { data } = response;
          this.reportDataList = data.map((d) => {
            return this.formatDataRow(d);
          });

          this.reportDataList.sort((a, b) => {
            if (a.status === 'inqueue' || a.status === 'running') {
              if (b.status === 'waiting') return -1;
              return b.status.charCodeAt(0) - a.status.charCodeAt(0);
            }
            return 0;
          });
        })
        .catch((error) => {
          console.log(error);
        });

      this.isSync = false;
    },
    async onSyncReportFinishList() {
      this.axiosController = new AbortController();
      await AxiosService.get(
        `${endpoints.coreApi}/report-file-info?customergroup_id=${this.selectedGroup.id}&status=finish`,
        {
          signal: this.axiosController.signal,
        }
      )
        .then((response) => {
          const { data } = response;
          this.reportDataList = data.map((d) => {
            return this.formatDataRow(d);
          });
        })
        .catch((error) => {
          console.log(error);
        });
      this.isSync = false;

      if (!this.reportFinishListInterval) {
        localStorage.setItem(
          'finishPageLatest',
          parseInt(moment.utc().valueOf() / 1_000)
        );
      }
    },
    async onClickDeleteButton(id) {
      this.showRowDeleteModal(id);
    },
    async updateNextInqueue(id) {
      this.isSync = true;
      await AxiosService.post(
        `${endpoints.coreApi}/report-file-info/next-inqueue`,
        {
          id,
          customergroup_id: this.selectedGroup.id,
        }
      )
        .then((response) => {
          const { matchedCount } = response.data;
          if (matchedCount > 0) {
            this.onSyncReportQueueList();
          }
        })
        .catch((error) => {
          console.log(error);
        });
      this.isSync = false;
    },
    async deleteRow() {
      this.isDeleting = true;
      try {
        await AxiosService.delete(
          `${endpoints.coreApi}/report-file-info/${this.selectedRowId}?customergroup_id=${this.selectedGroup.id}`
        )
          .then(async (response) => {
            const { deletedCount } = response.data;
            this.isDeleting = false;

            if (deletedCount > 0) {
              this.isRowDeleted = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.isDeleting = false;
          });
      } catch (err) {
        console.log(err);
        this.isDeleting = false;
      }
    },
    onClickRefresh() {
      this.isSync = true;
      this.onSyncReportQueueList();
    },
    async checkingInqueueListLength() {
      this.checkingListController = new AbortController();
      const response = await AxiosService.get(
        `${endpoints.coreApi}/report-file-info?customergroup_id=${this.selectedGroup.id}`,
        {
          signal: this.checkingListController.signal,
        }
      )
        .then((response) => {
          const { data } = response;
          return data.length;
        })
        .catch((err) => {
          console.log('checkingInqueueListLength', err);
          return null;
        });
      return response;
    },
    destroyed() {
      if (this.reportQueueListInterval) {
        clearInterval(this.reportQueueListInterval);
      }
      if (this.reportFinishListInterval) {
        clearInterval(this.reportFinishListInterval);
      }
    },
  },
};
</script>
<style lang="scss">
$text-primary: #5e5e5e;

#report__container {
  box-sizing: border-box;
  background-color: #ffffff !important;
  color: $text-primary;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;

  .page__title {
    display: flex;
    align-items: center;
    padding: 24px 36px;
    gap: 26px;
    border-block-end: 1px solid #f5f5f5;

    > .title {
      font-size: 1.5rem;
      font-weight: 200;
      color: #000000;
    }
    > .sub-title {
      font-size: 1.125rem;
    }
  }
  .page__content {
    flex: 1;
    padding: 32px;

    .content__header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .header {
        display: flex;
        align-items: center;
        gap: 8px;

        .header__icon {
          background-color: #523ee8;
          border-radius: 50%;
          color: #ffffff;

          width: 32px;
          height: 32px;

          font-size: 1.5rem;

          display: flex;
          justify-content: center;
          align-items: center;
        }

        div {
          font-size: 1.5rem;
          font-weight: 200;
          span {
            font-size: 1rem;
            font-weight: 400;
            color: inherit;
          }
        }
      }

      > .action {
        display: flex;
        gap: 32px;
      }
    }
  }
}
#row-delete-modal,
#alert-modal {
  .vm--modal {
    .close {
      position: absolute;
      font-size: 20px;
      right: 15px;
      top: 15px;
      cursor: pointer;
    }
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    width: 400px !important;
    height: auto !important;

    padding: 32px;
    border-radius: 6px;
    font-size: 16px !important;

    .modal__content {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .content__icon {
        margin-inline: auto;
      }
      * {
        margin: 0;
      }
      h1 {
        font-size: 1.25rem;
        width: fit-content;
        margin-inline: auto;
        color: $text-primary;
      }

      .content__body {
        text-align: center;
      }

      .content__actions {
        font-size: 1.25rem;
        display: flex;
        justify-content: center;
        gap: 32px;
      }
    }
  }
}

#notify-modal {
  position: absolute;
  font-size: 1rem;
  bottom: 24px;
  right: 45%;
  transform: translateX(50%);
  border-radius: 6px;
  padding: 12px 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 25%);

  .close {
    position: absolute;
    top: -10px;
    right: -10px;
    color: #c9c9c9;
    font-size: 18px;
    background-color: #ffffff;
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
