import {
  apolloClient
} from "../../strapi-apollo";
const state = () => ({
  licensetypes: [],
});

import licenseTypes from "@/graphql/licenseTypes.gql";

// getters
const getters = {
  licensetypes: (state) => state.licensetypes,
  licenseTypeByPLT: (state) => (code) => {
    //console.log('licenseTypeByPLT',state.licensetypes.length);
    if (state.licensetypes.length > 0) {
      const resultlicensetypes = state.licensetypes.find((type) => {
        // console.log("No.All",driver.license_no , licenseNo);
        return type.plt_code == code
      });
       //console.log('found driver',code,resultlicensetypes)
      return resultlicensetypes;
    } else return {};
  },
};

// actions
const actions = {
  async getAlllicensetypes({
    commit
  }) {
    await apolloClient.resetStore();
    const alllicensetypes = await apolloClient
      .query({
        // Query
        query: licenseTypes
      })
      .then(async (eventData) => {
        //console.log(eventData)
        return eventData;
      })
      .catch((err) => {
        console.log(err);
        return;
      });    
    if (alllicensetypes && alllicensetypes.data) {      
      commit("ALL_LICENSETYPES", alllicensetypes.data.licenseTypes);
    }

  },
  resetState({
    commit
  }) {
    commit("RESET_STATE");
  },
};
// mutations
const mutations = {
  ALL_LICENSETYPES(state, payload) {
    state.licensetypes = payload;
  },
  RESET_STATE(state) {
    state.licensetypes = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};