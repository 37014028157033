var render = function render(){var _vm=this,_c=_vm._self._c;return _c('defaultLayout',[_c('div',{staticClass:"trip"},[_c('tripDrawer',{on:{"setCenterPath":_vm.setCenterPath}}),_c('div',{class:[_vm.showTripInfo ? 'google-map-tripinfo' : 'google-map'],style:(_vm.getWidthOfMap())},[_c('div',{staticClass:"google-map-toolbar"},[_c('div',{staticClass:"toggle-geofence-button",on:{"click":_vm.toggleGeofence}},[_c('a-checkbox',{attrs:{"checked":_vm.showGeofence}}),_vm._v("แสดงสถานี ")],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedPosition),expression:"selectedPosition"}],staticClass:"google-map-button",on:{"click":_vm.showGeofenceModal}},[_vm._v(" สร้างสถานี ")])]),_c('GmapMap',{key:"mapInTripPage",ref:"TripGoogleMap",staticStyle:{"width":"100%","height":"99vh"},attrs:{"center":{ lat: 13.7460067, lng: 100.5440535 },"zoom":this.mapZoom,"map-type-id":"roadmap","options":{
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: false,
          streetViewControl: true,
          rotateControl: false,
          fullscreenControl: false,
          disableDefaultUi: true,
          zoomControlOptions: {
            position: 3,
          },
          streetViewControlOptions: {
            position: 3,
          },
          clickableIcons: true,
        }},on:{"drag":_vm.startDragMap,"bounds_changed":_vm.startDragMap,"idle":_vm.endDragMap,"zoom_changed":_vm.zoomLevelChange}},[(_vm.mapMoving === false)?[(_vm.path.length > 0)?_c('gmap-polyline',{key:`${'line' + Math.random()}`,ref:"vehicleTrip",attrs:{"path":_vm.path,"editable":false,"options":{ strokeColor: '#3633FF', strokeWeight: 3 }}}):_vm._e(),_vm._l((_vm.positionsDirection),function(position){return _c('gmap-custom-marker',{key:`direction-icon-${position.timestamp}`,attrs:{"marker":{
              lat: position.latitude / 10000000,
              lng: position.longitude / 10000000,
            },"alignment":"center"}},[_c('a-icon',{class:['direction-arrow', position.engineStatus],style:({
                transform: _vm.getDirectionClass(position.angle),
              }),attrs:{"type":"caret-up"}})],1)}),(_vm.selectedPosition)?_c('gmap-custom-marker',{key:`position-${_vm.selectedPosition.timestamp + Math.random()}`,attrs:{"marker":{
              lat: _vm.selectedPosition
                ? _vm.selectedPosition.latitude / 10000000
                : 0,
              lng: _vm.selectedPosition
                ? _vm.selectedPosition.longitude / 10000000
                : 0,
            },"offsetX":-20,"offsetY":-20}},[_c('img',{class:[
                'device-marker',
                'vehicle-status',
                _vm.getFilterClass(
                  _vm.selectedPosition
                    ? _vm.selectedPosition.engineStatus
                    : 'ENGINE_STOP'
                ),
              ],style:({
                transform: _vm.getDirectionClass(
                  _vm.selectedPosition ? _vm.selectedPosition.angle : 0
                ),
              }),attrs:{"src":require("../assets/top-car4.png")}})]):_vm._e()]:_vm._e(),(
            _vm.showGeofence === true &&
            _vm.mapMoving === false &&
            _vm.showBoundryGeofence === true
          )?[_vm._l((_vm.geofencesCircle),function(geofence,index){return _c('GmapCircle',{key:`geofence-circle-${index}`,attrs:{"center":geofence.Positions[0],"radius":geofence.radius,"visible":_vm.showGeofence,"options":{
              fillColor: '#00c246',
              fillOpacity: 0.3,
              strokeColor: '#00c246',
              strokeOpacity: 0.8,
            }}})}),_vm._l((_vm.geofencesPolygon),function(geofence,index){return _c('GmapPolygon',{key:`geofence-polygon-${index}`,attrs:{"path":geofence.Positions,"visible":_vm.showGeofence,"options":{
              fillColor: '#00c246',
              fillOpacity: 0.3,
              strokeColor: '#00c246',
              strokeOpacity: 0.8,
            }}})}),_vm._l((_vm.geofencesList),function(geofence,index){return _c('gmap-custom-marker',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGeofence),expression:"showGeofence"}],key:`geofence-name-${index}`,attrs:{"marker":geofence.Positions[0],"alignment":"center"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGeofence),expression:"showGeofence"}],staticClass:"geofence-name"},[_vm._v(" "+_vm._s(geofence.name)+" ")])])})]:_vm._e(),(_vm.showGeofence === true && _vm.mapMoving === false && _vm.mapZoom < 16)?_vm._l((_vm.geofencesList),function(m,index){return _c('gmap-marker',{key:index,attrs:{"position":{
              lat: parseFloat(m.Positions[0].lat),
              lng: parseFloat(m.Positions[0].lng),
            },"clickable":true,"draggable":false},on:{"click":function($event){return _vm.setCenterPathGeofence(m)}}})}):_vm._e()],2)],1)],1),_c('a-modal',{attrs:{"visible":_vm.createGeofenceModalVisible,"confirm-loading":_vm.saveGeofenceLoading},on:{"ok":_vm.createGeofenceSubmit,"cancel":_vm.cancelCreateGeofence}},[_c('h2',[_vm._v("สร้างสถานีจากตำแหน่ง "+_vm._s(_vm.selectedVehicleTrip.plate_number))]),_c('a-form',{attrs:{"layout":"inline","form":_vm.form}},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-input',{attrs:{"placeholder":"ชื่อสถานี","maxLength":30},model:{value:(_vm.geofenceName),callback:function ($$v) {_vm.geofenceName=$$v},expression:"geofenceName"}})],1)],1),_c('a-row',{staticStyle:{"margin-top":"10px"}},[_c('a-col',{attrs:{"span":24}},[_c('a-input',{attrs:{"type":"number","addon-before":"รัศมี","addon-after":"เมตร","min":10,"max":500.0,"step":10},model:{value:(_vm.geofenceRadius),callback:function ($$v) {_vm.geofenceRadius=$$v},expression:"geofenceRadius"}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }